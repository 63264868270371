{
  "bank_account": "Bank Account",
  "cancel": "Cancel",
  "crypto_wallet": "Crypto wallet",
  "exchange": "Exchange",
  "order_has_been_canceled": "Order has been cancelled",
  "order_has_been_proceeded": "Order has been proceeded",
  "order_has_been_suspended": "Order has been suspended",
  "order_status_history": "Order Status History",
  "orders": "Orders",
  "payment_information": "Payment recipient information",
  "proceed": "Proceed",
  "rates": "Rates",
  "return": "Return",
  "setting_language_preference_text": "Choose language in which you prefer to display website and receive emails.",
  "setting_send_email_after_login": "Send email after every log in.",
  "suspend": "Suspend",
  "wallet_updated_successfully": "Wallet updated successfully",
  "we_will_send_you_money_back_shortly": "We will send you the money back shortly",
  "guest_login_and_signup_order_separation": "or",
  "order_button_create_order": "Create order",
  "guest_login": "Log in",
  "guest_signup": "Sign Up",
  "menu_guest_link_about_us": "About us",
  "menu_guest_link_help": "Help",
  "menu_guest_link_terms": "Terms of Services",
  "order_form_caption": "Create your order right here",
  "order_form_email": "E-mail",
  "forgot_password": "Forgot password?",
  "login_form_submit_button": "Log in",
  "page_login_title": "Log in to {{brandName}}",
  "page_signup_title": "Sign up",
  "signup_form_please_fill": "Sign up",
  "signup_form_submit_button": "Sign up",
  "order_form_placeholder_currency": "Currency",
  "refund_widget_add_new_refund_address": "Add New Wallet",
  "refund_address_select_label": "Refund Address",
  "copied": "Copied",
  "suspended_widget_return_money_back": "Return money back",
  "suspended_widget_top_alert_message": "Order is suspended, now waiting for your decision. ",
  "add_order": "Create new order",
  "language": "Language",
  "logged_in": "Logged In",
  "search": "Search",
  "status_direction": "Status direction",
  "status_direction_description_cancelled": "Cancel order",
  "status_direction_description_proceed": "Proceed order",
  "status_direction_description_return_to_client": "Return my money/coins back",
  "status_direction_description_suspended": "Suspend order",
  "status_final": "Final status",
  "status_name_cancelled": "Cancelled",
  "status_name_confirming_incoming": "Confirming Incoming",
  "status_name_confirming_outgoing": "Confirming Outgoing",
  "status_name_delivered": "Delivered",
  "status_name_done": "Done",
  "status_name_new": "New",
  "status_name_proceed": "Proceed",
  "status_name_received": "Received",
  "status_name_return_to_client": "Return to Client",
  "status_name_returned": "Returned",
  "status_name_suspended": "Suspended",
  "suspended_widget_table_change_percent": "Change",
  "client_bank_account_list_add_new_account": "Add New Account",
  "account_form_create_button": "Add bank account",
  "account_form_save_button": "Save",
  "create_new_wallet_header": "Add New Wallet",
  "delete_account": "Delete Account",
  "delete_wallet": "Delete Wallet",
  "update_account": "Update Account",
  "update_wallet": "Update Wallet",
  "wallet_form_create_button": "Add Wallet",
  "header_what_now": "What Now",
  "profile_page_header_my_documents": "Attached Files",
  "profile_page_header_my_profile": "My Profile",
  "profile_page_ready_for_verification_we_are_checking_your_profile": "Your profile is being checked.",
  "profile_page_save_button": "Save",
  "profile_page_tab_level1": "Identification",
  "profile_page_tab_level2": "Proof of funds",
  "profile_page_tab_this_level_is_accepted": "This level is accepted",
  "refund_address_set_successfully": "Refund address set successfully ",
  "profile_level_checkbox_ready_for_verification_you_would_not_be_able_to_modify_your_information_it_sent_to_admin": "If you click checkbox below and submit the form, you will not be able to modify and delete your files. The data will be submitted for a review to our moderators.",
  "client_sidebar_avatar_hash_description": "Remember this avatar. It was generated exclusively for your account. If it has changed, it is possible that someone is trying to impersonate our pages. Check the address bar of your browser if the address is correct and it's showing green padlock on the left from it.",
  "navigation_link_logout": "Logout",
  "navigation_link_rates": "Rates",
  "company_about": "Simplecoin is a European crypto currency exchange with headquarters in the very heart of Europe in Prague, Czech Republic. Our main goal is to make use of bitcoin and other cryptocurrencies very easy. With Simplecoin you can buy, sell or send bitcoins safely and easily.",
  "sidebar_navigation_orders": "Orders",
  "sidebar_navigation_profile": "Profile",
  "sidebar_navigation_security": "Security",
  "sidebar_navigation_settings": "Settings",
  "company_header": "About {{brandName}}",
  "company_header_contacts": "Contacts",
  "company_header_social": "Social",
  "company_subheader": "SIMPLE CRYPTO-CURRENCY EXCHANGE",
  "company_social_group": "{{brandName}} group ",
  "company_header_billing_address": "Billing Address",
  "page_header_company": "{{brandName}} Bitcoin & Cryptocurrency Exchange | BTC LTC ETH BCH XRP",
  "footer_sidebar_link_about": "About",
  "client_order_reset_all_label": "Reset All",
  "client_order_search_btn": "Search Orders",
  "transaction_accepted_by_banks_now_waiting_for_transaction_being_listed_on_bank_record": "Request to send money was accepted by bank and now we are waiting for the transaction to be confirmed on list of outgoing bank transactions.",
  "error": "Error",
  "client_bank_accounts_widget_header": "Bank Account Documents",
  "new_return_to_client_waiting_for_transaction_to_be_matched_and_return_your_funds": "We are waiting for transaction to be matched. We send it back when it comes because the order direction is \"return to client\".",
  "page_subheader_settings_order_emails": "Order Emails",
  "page_subheader_settings_security_emails": "Security Emails",
  "client_setting_hint_send_email_when_order_confirming_incoming": "Send me email when order was paired with incoming crypto transaction, which is waiting to be confirmed",
  "client_setting_hint_send_email_when_order_confirming_outgoing": "Send me email when we sent you bank or crypto transaction and we are waiting for it to be confirmed",
  "client_setting_hint_send_email_when_order_done": "Send me email when some order's status was changed to done.",
  "client_setting_hint_send_email_when_order_proceed": "Send me email when some order's status was changed to proceed.",
  "client_setting_hint_send_email_when_order_received": "Send me email when a bank payment for order is received or incoming crypto payment was confirmed",
  "client_setting_hint_send_email_when_order_return_to_client": "Send me an email when some order's direction changed to returned.",
  "client_setting_hint_send_email_when_order_suspended": "Send me email when order direction was changed to suspended.",
  "client_settings_label_order_confirming_incoming": "Confirming Incoming",
  "client_settings_label_order_confirming_outgoing": "Confirming Outgoing",
  "client_settings_label_order_done": "Done",
  "client_settings_label_order_proceed": "Proceed",
  "client_settings_label_order_received": "Received",
  "client_settings_label_order_return_to_client": "Return To Client",
  "client_settings_label_order_suspended": "Suspended",
  "page_subheader_settings_general": "General",
  "profile_page_level1_my_document_subheader_paragraph": "Any other document which can be used for your online identification. You can upload multiple files here.",
  "profile_page_level2_my_document_subheader_paragraph": "You can upload multiple files here",
  "profile_page_level2_note_description": " If registering as company, state what is your position there, company name and registration number. Explain any non standard situation like receiving payments from third parties, making orders for somebody else etc.",
  "profile_page_level2_trading_purpose_description": "Please state what is the purpose of your exchange. We are required to ask this by Czech regulators. ",
  "profile_page_level2_trading_source_of_income_description": "Please describe how you earned funds for your trades. For very large amounts we will ask for any kind of document which can support your statement. If you can not upload any, write it explicitly that there is no such evidence",
  "action_reset_password_error_email_not_sent": "We are unable to reset password for the provided email address.",
  "action_reset_password_success": "Check your email for further instructions.",
  "address_was_deleted": "Address was deleted.",
  "bank_account_was_added": "Bank account was added.",
  "bank_account_was_deleted": "Bank account was deleted.",
  "bank_account_was_updated": "Bank account was updated.",
  "change": "Change",
  "change_password": "Change password",
  "delete": "Delete",
  "email_confirmed": "Your email was confirmed.",
  "enter_email_address_to_recover_account": "Please fill out your email. A link to reset password will be sent there.",
  "file_was_deleted": "File was deleted",
  "information_successfully_saved": "Your information was saved successfully.",
  "new_address_was_added": "New address was added.",
  "new_password_saved": "New password saved.",
  "order_has_been_created_guest": "Order has been created. We have sent you instruction on how to proceed to your email.",
  "pls_choose_new_password": "Please choose your new password:",
  "request_password_reset": "Request Password Reset",
  "reset_password": "Reset password",
  "save": "Save",
  "security": "Security",
  "send": "Send",
  "signup": "Sign up",
  "update": "Update",
  "are_you_sure_you_want_to_delete_this_file": "Are you sure you want to delete this file?",
  "client_bank_account_message_warning_about_symbol": "* Our symbol will be added to the message when we send you the money.",
  "from_amount_currency_label": "From account",
  "to_amount_currency_label": "To account",
  "menu_guest_link_rates": "Rates",
  "is_automatic_proceed_on_duplicate_payment": "Set Status \"Proceed\" On Duplicate Orders Automatically",
  "is_automatic_proceed_on_late_payment": "Automatically Proceed On Late Payment",
  "setting_hint_is_automatic_proceed_on_duplicate_payment": "When new duplicate payment comes (payment matched with order which was already paid), the system will automatically copy the paid order. Normally that copied order is suspended and will wait for your decision. If this box is checked, copied order will be updated to current price and status set to \"proceed\". This way you can exchange currencies just by sending payments. No interaction with our web page will be needed.",
  "setting_hint_is_automatic_proceed_on_late_payment": "When late payment arrives, the system will automatically accept new market price and proceed order for you. By default order will be suspended and wait for you decision.",
  "no_camera_found": "No camera found or access is not provided.",
  "scan_address": "Scan QR",
  "button_disable": "Disable",
  "confirm": "Confirm",
  "header_disable_google_authenticator": "Disable Two-factor Authentication (2FA)",
  "header_enable_google_authenticator": "Enable Two-factor Authentication (2FA)",
  "label_2fa_password": "Password",
  "label_2fa_secret": "Secret",
  "2fa_secret_description": "Note: Back-up this code and do not tell it to anyone. It can be used to recover access to two-factor authentication in case you lose your phone.",
  "btn_disable": "Disable",
  "btn_enable": "Enable",
  "two_factor_authentication": "Two-Factor Authentication (2FA)",
  "fill_information_below_to_disable_two_factor_authentication": "Fill information below to disable two-factor authentication:",
  "alert_success_email_link_was_resent_again": "New email activation was sent successfully.",
  "hint_login_form_2fa_code": "Code is not required when two-factor authentication is not enabled on account.",
  "footer_sidebar_link_terms": "Terms of Services",
  "button_download_order_pdf": "Download PDF",
  "button_hint_download_order_pdf": "Download order's information as PDF",
  "alert_success_affiliate_payout_request_successfully_created": "Affiliate withdraw request received successfully.",
  "alert_success_you_have_become_affiliate": "You have successfully became our affiliate.",
  "button_create": "Create",
  "button_save": "Save",
  "page_affiliate_dashboard_copy_url_header": "Affiliate URL",
  "page_affiliate_dashboard_overview_header": "Affiliate Overview",
  "page_affiliate_dashboard_overview_paragraph": "Below you may see summary of your affiliate account.",
  "page_affiliate_dashboard_paragraph": "On this page you may see overall summary of you affiliate account, see detailed statistics and request withdrawal in any currency {{brandName}} supports.  ",
  "page_affiliate_statistics_button": "Statistics",
  "page_affiliate_stats_first_paragraph": "On this page you may see statistics of you affiliate program. How many times it was used by end users and when.",
  "page_affiliate_stats_label_conversion": "Banner Clicks",
  "page_affiliate_stats_label_period": "Date",
  "page_affiliate_withdraw_button": "Withdraw",
  "page_become_affiliate_first_paragraph": "If you would like to become our affiliate, please check the checkbox below to confirm that you agree with our affiliate conditions and submit the form to proceed.",
  "page_header_affiliate_dashboard": "Affiliate Dashboard",
  "page_header_become_affiliate": "Become Affiliate",
  "sidebar_navigation_affiliate": "Affiliate",
  "label_agree_with_affiliate_conditions": "I agree with affiliate conditions",
  "page_affiliate_withdraw_request_paragraph": "Choose either bank account or address to withdraw your funds from your affiliate balance.",
  "page_affiliate_finance_first_paragraph": "On this page you see list of orders associated with your account and profit you received per each one in EUR.",
  "page_affiliate_finance_label_order": "Order",
  "page_affiliate_finance_button": "Income",
  "page_affiliate_finance_label_profit_in_eur": "Profit (EUR)",
  "page_affiliate_asset_banners_paragraph": "You include interactive banners below on your website. Copy any of the codes below and insert it in the place you would it to be displayed.",
  "page_affiliate_assets_button": "Promotion Materials",
  "api_order_unsupported_currency_pair": "Exchange is not available for this currency pair",
  "footer_sidebar_link_data_processing": "GDPR",
  "alert_success_ticket_created": "Ticket created successfully.",
  "alert_success_ticket_reply_sent": "Reply to the ticket sent successfully.",
  "button_send": "Send",
  "page_header_ticket_create": "New Ticket",
  "page_header_ticket_list": "Tickets",
  "page_header_ticket_reply": "Reply to Ticket",
  "page_ticket_reply_you": "You",
  "sidebar_navigation_ticket": "Support",
  "alert_success_ticket_closed": "Ticket marked as resolved.",
  "button_create_support_ticket": "Support",
  "button_hint_create_support_ticket": "You may create a support ticket if you have any question or problem with this order.",
  "page_tickets_pending_tickets": "Pending Tickets",
  "page_tickets_solved_tickets": "Solved Tickets",
  "page_tickets_no_pending_tickets": "No pending or created tickets yet.",
  "page_tickets_no_solved_tickets": "No solved tickets yet.",
  "take_selfie_button": "Take selfie",
  "no_camera_access_provided": "No camera access provided. Please check camera permissions and make sure they are not disabled.",
  "menu_guest_link_privacy_policy": "Privacy policy",
  "change_password_social_reset_password_claim": "Please use button below to change your password. After you press on it, we will send you email with link to set new password.",
  "alert_change_password_email_was_sent": "Please check your email where you can find the link to set your password.",
  "page_confirm_two_factor": "Confirm Two Factor Authentication",
  "page_confirm_two_factor_paragraph": "Please enter code from your two factor authenticator app:",
  "cookie_consent_i_agree": "I understand",
  "cookie_consent_message": "This site uses cookies to assist with navigation and your ability to provide feedback, analyse your use of our products and services. <0>Cookie policy.</0>",
  "try_again_button": "Try again",
  "dropable_or_pastable_from_clipboard": "Drag & drop here files from your PC or paste here an image in the clipboard by pressing CTRL-V",
  "hint_enter_two_factor_code_to_confirm_action": "Enter code from Google Authenticator.",
  "add_new_ticket": "Add ticket",
  "close_ticket": "Mark as solved",
  "client_setting_hint_send_email_limit_price_changed": "Send me an email when order's limit price was set or changed.",
  "client_settings_label_order_limit_price_changed": "Limit Price",
  "button_set_limit": "Set limit",
  "suspended_widget_header_limit_rate": "Limit rate",
  "suspended_widget_header_limit_rate_hint": "By setting limit rate you will have your order accepted automatically when market rate will reach limit rate set by you.",
  "suspended_widget_header_market_rate": "Market rate",
  "suspended_widget_header_market_rate_hint": "Market rate for your order which we can offer you. All fees are included so it's the exact rate you will get.",
  "suspended_widget_header_order_rate": "Order rate",
  "suspended_widget_header_order_rate_hint": "This is the rate we set up for you the moment you created new order. It includes all fees already.",
  "button_change_limit": "Change",
  "button_limit_cancel": "Cancel",
  "suspended_widget_limit_form_paragraph": "These two fields represent the same rate. Second field's value contains the inverse value of the first and they get updated accordingly. It allows you to enter the rate the way you are used to.",
  "hint_close_ticket_button": "If you click on this button, ticket will be marked as solved and no further discussion could be made unless you create new ticket.",
  "heading_chart": "Chart",
  "button_close": "Close",
  "boolean_option_no": "No",
  "boolean_option_yes": "Yes",
  "generic_link_learn_more": "Learn more",
  "profile_page_header_my_documents_level0_paragraph": "<strong>Please attach the documents for your identification</strong><br>\nWe need good quality scans (300dpi) or photos which are fully readable, no parts of any document can be edited nor removed\n\"Other document\" can be any document showing identification information of physical person, type and number of id documet, issuing country and authority and validity period.\n",
  "profile_page_header_my_documents_level1_paragraph": "<strong>Please attach the following documents:</strong><br>\n- any document which can help proving your source of income<br>\n- photo of you holding your ID<br>\n- company registration documents if applicable<br>\n- any other document our staff asked for",
  "chart_module_market_price_inverse": "Inverse",
  "chart_module_no_data_available": "No data available.",
  "bank_account_verified": "Verified",
  "profile_page_bank_account_header": "Your Bank Accounts",
  "profile_page_bank_accounts_paragraph": "For successful  identification over internet we need to to verify at least one bank account. On recipients page set up your account then attach required document according instructions and alternatively send tests payment.",
  "bank_account_file_upload_unverified_level0_paragraph": "In order to finish your identification over internet (Level 1 profile verification) we need proof of your bank account existence.  Please attach document issued by bank, where we can see account owner and account number. It can be either account statement or account maintenance contract. Copy of paper document  or PDF file is accepted. Please attach the document here.",
  "guest_market_table": "Price table",
  "validation_error_not_matching_passwords": "Passwords don't match",
  "react_send_from_amount_here": "Send {{amount}} {{currency}} to the following address:",
  "react_we_received_your_payment_but_you_decided_to_get_it_we_will_send_it_shortly": "We received your payment, but you decided to get it back. We will send {{amount}} <0>{{currency}}</0> to you shortly.",
  "react_we_received_currency_from_your_order_and_we_will_send_you_currency_to": "We received {{amountFrom}} <0>{{currencyFrom}}</0> for your order and we will send you {{amountTo}} <1>{{currencyTo}}</1>",
  "react_confirming_incoming_crypto_transaction_waiting_for_more_confirmations": "We see incoming crypto transaction for {{amount}} <0>{{currency}}</0>. At this moment, we are waiting for more confirmations.",
  "react_confirming_outgoing_crypto_transaction_waiting_for_more_confirmations": "We sent outgoing crypto transaction for {{amount}} <0>{{currency}}</0>. At this moment, we are waiting for more confirmations.",
  "react_suspended_widget_accept_btn": "Accept {{amount}} {{currency}}",
  "react_suspended_widget_return_btn": "Return {{amount}} {{currency}}",
  "react_order_created_high_market_volatility_message": "Because of high market volatility we are able to keep this rate only for {{duration}} minutes. Speed of your crypto transaction depends on the transaction fee so it is important to use the correct transaction fee in order for transaction to be verified soon. If your payment arrives after this time and at the same time the rate changes notably, we will contact you by email to offer these options - accepting a new exchange rate and proceeding with the order or returning your funds back. ",
  "react_order_suspend_over_limit_message": "Order is suspended, because you have reached your trading limit.",
  "chart_timeframe_option_all": "All",
  "react_bank_account_file_upload_unverified_paragraph": "We will also need to see incoming bank transaction from this account so we can see account holder name. If you plan to send funds from this account to us, no extra steps are needed. Only if you plan to receive funds to this account as first time time transaction, please send any minimum amount to <0>any of our accounts</0> first. ",
  "react_2fa_qr_code_description": "For extra security of your account, you may enable two-factor authentication (2FA). We use Google Authenticator. In order to start using it, install <0>Google Authenticator application</0> into your phone, scan QR code shown on this page, enter current password of the account and code form the application.",
  "chart_timeframe_option_day": "Day",
  "chart_timeframe_option_month": "Month",
  "chart_timeframe_option_week": "Week",
  "chart_timeframe_option_year": "Year",
  "chart_timeframe_option_year_to_date": "Year to date",
  "react_profile_page_level1_paragraph_before_form": "Level 1 allows trading for volume up to equivalent of {{limit_level_2}} EUR in {{tradeLimitDays}} days.",
  "react_profile_page_level2_paragraph_before_form": "Level 2 allows clients to trade cumulative volume above equivalent of {{limit_level_2}} EUR in {{tradeLimitDays}} days. In some cases additional AML questioning may be conducted.",
  "react_page_header_posts_by_category": "Category: {{name}}",
  "chart_timeframe_option_3months": "3 months",
  "ticket_load_more": "Load more",
  "ticket_type_your_message": "Type your message here...",
  "no_results_found": "No results found.",
  "validation_field_required": "This field is required",
  "validation_min_characters": "Field must contain at least ${min} characters",
  "uploaded_file_information_complete": "Information about this document is complete",
  "uploaded_file_click_to_fill_information": "Click here to fill additional information",
  "react_order_header": "Order {{id}}",
  "profile_page_header_my_personal_data": "Personal information",
  "validation_error_incorrect_phone_number": "Incorrect phone number ",
  "react_file_upload_extension_error": "Only the following extensions are allowed: {{extensions}}",
  "qr_code_scan_success": "Successfully scanned QR code",
  "upload_success": "Upload was successful",
  "button_open_form": "Open form",
  "client_bank_trade_volume_label": "Trade volume of bank account",
  "qr_payment_hint": "You can use QR Platba. Scan code below using smart banking application in your phone",
  "react_signup_to_get_trading_volume_or_personal_discount": "<0>Sign up</0> and get trading volume and/or personal discount. ",
  "sidebar_user_traded_volume": "{{tradeLimitDays}} days volume: {{traded}}/{{limit}}EUR",
  "trading_limits_info_message": "Unverified volume limit: {{limitLevel1}} EUR/{{level1TradeLimitDays}} days <br/>\nLevel 1 volume limit: {{limitLevel2}} EUR/{{level2TradeLimitDays}} days <br/>\nLevel 2 volume limit is not formally limited",
  "sidebar_user_traded_volume_sum": "Trade volume: {{traded}} EUR",
  "react_refund_widget_already_chosen_refund_address": "You have chosen {{refundAddress}} as the refund address.",
  "react_we_received_your_crypto_payment_but_you_decided_to_get_it_we_will_send_it_shortly": "We have received your coins already. You have decided to get them back. Return payment has additional fee. You will get {{amount}} {{currency}} back to your refund address.",
  "refund_widget_btn_change_refund_address": "Change refund address",
  "react_order_crypto_return_fee_info_message": "Returning the order has additional fee. You will get {{amount}} {{currency}} back to selected address.",
  "react_suspended_widget_return_currency_btn": "Return {{amount}} {{currency}} to this wallet",
  "we_will_send_you_crypto_back_shortly": "We will send you coins back shortly.",
  "button_remove_limit": "Remove limit",
  "react_alert_limit_removed": "Limit removed successfully",
  "unconfirmed_email_please_confirm_or_resend": "Please confirm your email address first or <0>resend activation email</0>",
  "button_back": "Back",
  "button_next": "Next",
  "button_submit_profile": "Submit profile",
  "you_need_to_log_out_before_seeing_anonymous_order": "You need to log out before seeing anonymous order",
  "react_form_rate_explenation": "How is your price calculated?",
  "sign_up_to_return_guest_order": "In order to return your order please sign up and add a wallet.",
  "error_page_not_found": "Page Not Found",
  "react_page_affiliate_withdraw_request_button": "Withdraw <strong>{{amount}} {{currency}}</strong>",
  "affiliate_withdraw_history_table_converted_amount": "Converted amount",
  "react_page_affiliate_dashboard_copy_url_paragraph": "Copy URL below and share it on your blog, social media or with your friends. Anybody who clicks on this link and registers or create order within {{duration}} days will associated to your affiliate account. You will receive provision from every exchange which was made by user you invited.",
  "react_affiliaite_balance_information": "Balance: {{balance}}<br/>Total profit: {{totalProfit}}",
  "react_affiliaite_balance_information_tooltip": "Balance is your current amount which you can withdraw. Total profit is what you earned since you joined affiliate program.",
  "label_affiliate_overview_joined_at": "Joined",
  "label_repeat_password": "Repeat Password",
  "react_consent_to_tos": "By continuing I declare that I have read and agree with the <0>Terms of Services</0> and <1>Privacy Policy</1> of {{companyName}}",
  "label_delete_account": "Delete account",
  "button_delete_account": "Delete my account",
  "delete_account_warning_paragraph": "If you proceed, your account's data will be permanently deleted. We are sorry to see you go. You can register again any time with the same email address but your account will be empty.",
  "account_delete_success": "Your account has been deleted",
  "log_out_success": "You have been logged out.",
  "session_time_remaining_label": "Session time remaining",
  "session_expire_in_label": "Your session will expire",
  "order_filter_label": "Filter orders",
  "order_filter_button_all": "All",
  "to_currency_amount_field_label": "I want to receive",
  "status_name_unfinished": "Unfinished",
  "status_description_unfinished": "Order does not have a final status yet",
  "filter_drawer_incomplete_filters_warning": "Please select at least one status from every category",
  "help_pages_label": "Help Center",
  "react_file_upload_size_error": "The file you tried to upload is too big. The maximum file size is {{size}}MB",
  "alert_submit_previous_level_first": "Please submit previous profile verification level first.",
  "wrong_vs_format_message": "Variable symbol should only contain digits (up to 10)",
  "wrong_ss_format_message": "Spacific symbol should only contain digits (up to 10)",
  "wrong_ks_format_message": "Constant symbol should only contain digits (up to 4)",
  "button_extend_session": "Extend session",
  "fiat_payment_recipient_name": "Recipient name",
  "fiat_payment_recipient_street": "Recipient street",
  "fiat_payment_recipient_city": "Recipient city",
  "fiat_payment_recipient_country": "Recipient country",
  "react_you_may_send_money_on_one_of_the_following_bank_accounts_singular": "Send {{amount}} <0>{{currency}}</0> to the following bank account",
  "crypto_qr_hint": "You can scan the code below or click on it to open wallet on your device.",
  "react_validation_error_insufficient_funds": "Insufficient funds. You have only {{amount}} of {{currencyName}}.",
  "client_settings_label_affiliate_new_income": "New Funds Credited",
  "client_setting_hint_affiliate_new_income": "Send me an email when I get new credit to my affiliate account.",
  "page_subheader_settings_affiliate": "Affiliate",
  "check_address_on_trezor_header": "Check address on Trezor",
  "check_address_on_trezor_body": "And press the continue button on it",
  "show_address_on_trezor_button_label": "Show address on Trezor",
  "send_by_trezor_button": "Pay by Trezor",
  "send_by_trezor_description_tooltip": "You can use this feature to send your payment safely from connected Trezor wallet. ",
  "trezor_address_import_dialog_header": "The following addresses belong to the chosen Trezor account. Please select one.",
  "import_from_trezor_button": "Import from Trezor",
  "address_used": "used",
  "trezor_transaction_sent_dialog_title": "The transaction has been successfully sent.",
  "trezor_transaction_sent_dialog_content": "You have paid with Trezor! The hash is: {{txid}}. Please wait for the transaction to be paired to your order.",
  "trezor_address_import_available_for_currencies": "Trezor address import in available for: {{currencies}}",
  "trezor_link_learn_more": "Learn more about using Trezor on {{brandName}}",
  "page_refresh_button_label": "Refresh",
  "profile_level_one_send_transfer_instead_of_document_upload": "I will send bank transfer from an account held in my name instead of uploading a document.",
  "profile_level1_primary_document_front_label": "The front of the primary document",
  "profile_level1_primary_document_reverse_label": "The reverse of the primary document",
  "profile_level1_secondary_document_front_label": "The front of the secondary document",
  "profile_level1_secondary_document_reverse_label": "The reverse of the secondary document",
  "profile_page_level1_primary_document_label": "Primary document",
  "profile_page_level1_secondary_document_label": "Secondary document",
  "profile_page_level1_additional_documents_label": "Additional documents",
  "profile_page_level1_document_passport": "Passport",
  "profile_page_level1_document_national_id": "Personal ID",
  "profile_page_level1_document_other": "Other document",
  "profile_page_level1_document_driving_license": "Driving License",
  "profile_page_level1_primary_document_type_select_information": "Choose your primary document type",
  "profile_page_level1_secondary_document_type_select_information": "Choose your secondary document type",
  "profile_page_level1_additional_document_type_select_information": "Add additional document",
  "profile_page_level1_additional_document_skip_label": "I do not have any additional documents to upload",
  "address_format_bech32_wallet_support_warning": "This address is in bech32 format which is not fully supported by Trezor Wallet. For full functionality please select type \"Segwit accounts\" or \"Legacy Accounts\". Address in bech32 format can now be used in electrum.org wallet.",
  "label_document_title": "Document title",
  "react_new_app_version_available_dialog_title": "New version of {{brandName}} is available",
  "update_application_version": "Update",
  "third_party_guest_order_provide_email_paragraph": "In order to continue please provide an email address.",
  "third_party_order_claim_email_verification_message": "Please check your email where you can find a confirmation link. ",
  "third_party_guest_order_confirm_or_resend_email": "Please confirm your email address or use the button below to send it again.",
  "log_in_to_see_order_registered_client": "Log in to view order",
  "client_recipients_list_header": "Recipients",
  "sidebar_navigation_recipients": "Recipients",
  "recipients_filter_label": "Filter recipients",
  "client_add_new_recipient_button": "Add recipient",
  "reset_filters_button": "Reset",
  "create_new_recipient_header": "Add new recipient",
  "disable_social_login_label": "Unlink social network account",
  "btn_unlink": "Unlink",
  "alert_error_please_set_password_to_disable_social_login": "Please set account password first to unlink social media account login.",
  "disable_social_login_warning_paragraph": "If you proceed, your social media account will be unlinked. You will only be able to log in using your {{brandName}} account password. ",
  "social_login_disable_success": "Social login disabled successfully",
  "file_attached_to_ticket_auto_message": "File attached: {{fileNames}}",
  "network_error_warning": "Oops, it looks like you are offline",
  "password_strength_very_weak": "Very weak password",
  "password_strength_weak": "Weak password",
  "password_strength_good": "Acceptable password",
  "password_strength_great": "Strong password",
  "status_description_on_hold": "Order is on hold",
  "status_direction_description_on_hold": "Order is on hold",
  "exchange_form_fee_included_tooltip_title": "The rate you see includes the following transaction fee",
  "exchange_form_fee_not_included_tooltip_title": "The following transaction fees are not included in rate shown. Specify amount to see the final rate.",
  "exchange_form_total_fee_eur": "Total fees: ~{{totalFeeEur}} EUR",
  "exchange_form_fee_tooltip_fee_in": "Incoming transaction fee: {{amount}} {{currency}}",
  "exchange_form_fee_tooltip_fee_out": "Outgoing transaction fee: {{amount}} {{currency}}",
  "exchange_form_fee_label_no_fee": "Zero fee",
  "exchange_form_fee_label_no_fee_explanation": "This trade will have no additional transaction fee",
  "exchange_form_fee_label_included": "Including transaction fee",
  "exchange_form_fee_label_excluded": "Excluding transaction fee",
  "order_view_back_to_vendor_button_label": "Go back to {{providerName}}",
  "table_sort_label": "Sort",
  "pagination_next_page": "Next Page",
  "pagination_previous_page": "Previous Page",
  "pagination_rows_per_page": "Rows per page",
  "pagination_display_rows": "of",
  "button_download_csv": "Download visible as CSV",
  "button_print": "Print",
  "table_view_columns": "View Columns",
  "table_filter": "Filter Table",
  "table_filters_label": "Filters",
  "show_columns_label": "Show Columns",
  "table_rows_selected": "row(s) selected",
  "add_account": "Add account",
  "account_balance": "Balance",
  "account_minimal_balance_label": "Minimal balance:",
  "page_header_accounts": "Accounts",
  "sidebar_navigation_accounts": "Accounts",
  "sidebar_navigation_trade": "Trade",
  "landing_page_chart_widget_buy_button": "Buy",
  "exchange_form_from_amount_label": "Buy for",
  "exchange_form_to_amount_label": "You get",
  "footer_trading_since": "Trading cryptocurrencies since 2013",
  "footer_headers_subscribe_to_newsletter": "Subscribe to our newsletter",
  "footer_rights_reserved": "\u00a9 {{year}} {{companyName}} All rights reserved.",
  "landing_header": "Cryptocurrency exchange trusted since 2013",
  "landing_subheader": "Trading made easy. <br /> Operates over 30 currency pairs.",
  "landing_button_join": "I'm all in",
  "landing_button_about_us": "About us",
  "landing_recent_orders_header": "Recent orders",
  "landing_statistics_30_day_order_count_label": "Number of exchanges made in the last 30 days",
  "landing_statistics_verified_clients_count": "Verified clients",
  "landing_statistics_volume_in_currency": "30 days volume in {{currency}}",
  "landing_header_help": "Help",
  "button_buy_now": "Buy now",
  "footer_header_support": "Support",
  "footer_link_help": "Help",
  "footer_header_company": "Company",
  "footer_header_features": "Features",
  "footer_features_trezor_support": "Trezor support",
  "footer_features_limit_order": "Limit order",
  "footer_email_newsletter_form_text": "Join our newsletter and never miss anything important happening on {{brandName}}.",
  "landing_email_newsletter_subscribe_button": "Subscribe",
  "landing_email_newsletter_subscribe_field_placeholder": "Enter your email address here",
  "landing_email_subscribe_consent_warning": "By entering your email you agree to our <0>Terms of Services</0> and <1>Privacy Policy</1>",
  "footer_link_transaction_fees": "Transaction fees",
  "footer_link_trade_limits": "Trading limits",
  "add_the_following_comment_to_payment": "Add the following comment to the payment",
  "alert_error_please_set_password_to_enable_two_factor": "Please set account password first to enable two factor authentication.",
  "alert_success_google_authenticator_disabled_successfully": "Two-factor authentication disabled successfully.",
  "alert_success_google_authenticator_enabled_successfully": "Two-factor authentication enabled successfully.",
  "confirmed": "Confirmed",
  "created_at": "Created At",
  "enter_form_email_placeholder": "Email",
  "enter_form_password_placeholder": "Password",
  "fiat_payments_without_note_cause_delay_warning": "Payments without this message must be processed manually, which may cause a delay in order processing and a subsequent exchange rate change.",
  "form_label_new_password": "New password",
  "incoming_bank_transaction": "Incoming Bank Transaction",
  "incoming_crypto_transaction": "Incoming Crypto Transaction",
  "label_2fa_code": "2FA Code",
  "label_account_constant": "Constant symbol",
  "label_account_label": "Label",
  "label_account_number": "Bank account number",
  "label_account_specific": "Specific symbol",
  "label_account_vs": "Variable symbol",
  "label_address": "Address",
  "label_address_label": "Label",
  "label_addresses": "Address",
  "label_affiliate_overview_affiliate_id": "Affiliate ID",
  "label_affiliate_overview_estimated_profit": "Orders In Progress",
  "label_affiliate_overview_referrals_count": "Invited Users",
  "label_affiliate_overview_url_hits_count": "Banner Clicks",
  "label_amount": "Amount",
  "label_arbitrary_data": "Destination Tag",
  "label_bank_account": "Bank account",
  "label_bank_account_id": "Bank Account",
  "label_bank_accounts": "Bank Accounts",
  "label_birthday": "Date of birth",
  "label_city": "City",
  "label_confirmations": "Confirmations (current / required minimum)",
  "label_country": "Country",
  "label_created_at": "Created At",
  "label_currency": "Currency",
  "label_current_password": "Current Password",
  "label_document_number": "Document number",
  "label_expires_at": "Expires at",
  "label_first_name": "First Name",
  "label_from_amount_currency": "From",
  "label_hash": "Hash",
  "label_id": "ID",
  "label_in_block_id": "Block ID",
  "label_is_politician": "Polliticaly exposed person",
  "label_issued_by": "Issued By",
  "label_issued_by_country": "Issued by Country",
  "label_last_name": "Last Name",
  "label_limit_price": "Limit Rate",
  "label_message": "Message for recipient",
  "label_monthly_volume": "Monthly Volume",
  "label_new_password_again": "New Password Again",
  "label_note": "Note",
  "label_other_account_number": "Bank Account",
  "label_our_bank_account_id": "{{brandName}} Bank Account",
  "label_owner_address": "Owner Address\n",
  "label_owner_city": "Owner City",
  "label_owner_country": "Owner Country",
  "label_owner_name": "Owner Name",
  "label_paid_at": "Paid At",
  "label_personal_id": "Personal ID",
  "label_phone": "Phone",
  "label_rate": "Exchange rate for order",
  "label_ready_for_verification": "Ready for verification",
  "label_receive_address": "Receive Address",
  "label_status": "Status",
  "label_status_direction": "Direction Status",
  "label_status_final": "Final Status",
  "label_street": "Street",
  "label_ticket_body": "Ticket Description",
  "label_ticket_category": "Category",
  "label_ticket_title": "Subject",
  "label_to_amount_currency": "To",
  "label_trading_purpose": "Trading Purpose",
  "label_trading_source_of_income": "Source Of Income",
  "label_zipcode": "Zip code",
  "mail_client_identification_bullet_point_fill_personal_information": "Fill in personal information",
  "oops_something_went_wrong": "Oops, something went wrong. Please try again later.",
  "order_id": "Order ID",
  "outgoing_bank_transaction": "Outgoing Bank Transaction",
  "outgoing_crypto_transaction": "Outgoing Crypto Transaction",
  "page_header_settings": "Settings",
  "password_was_changed": "Password was changed",
  "settings_updated": "Settings were updated",
  "social_login_with_facebook": "Sign in with Facebook ",
  "social_login_with_vkontakte": "Sign In with Vkontakte",
  "social_signin_with_google": "Sign in with Google",
  "social_signin_with_linkedin": "Sign in with LinkedIn",
  "status": "Status",
  "status_description_cancelled": "Order is cancelled",
  "status_description_confirming_incoming": "Order is confirming new incoming transaction",
  "status_description_confirming_outgoing": "Order is confirming new outgoing transaction",
  "status_description_delivered": "Order is delivered",
  "status_description_done": "Order is completed",
  "status_description_new": "Order is created, there is no incoming payment received yet",
  "status_description_proceed": "Order is planned to be executed in normal fashion as it was created.",
  "status_description_received": "Order received bank/crypto transaction and ready to continue",
  "status_description_return_to_client": "Order is set to be returned to the client",
  "status_description_returned": "Order's transaction was returned to client",
  "status_description_suspended": "Order is suspended. Some of the possible reasons: payment time exceeded, bank transaction note is not correct, monthly volume is exceeded or verification is required or you suspended the order manually",
  "ticket_category_affiliate": "Affiliate",
  "ticket_category_api": "API",
  "ticket_category_business_verification": "Business Verification",
  "ticket_category_order": "Orders",
  "ticket_category_other": "Other",
  "ticket_category_security": "Security",
  "ticket_category_suggestion": "Suggestion",
  "ticket_category_verification": "Verification",
  "validation_error_incorrect_email_format": "Incorrect email format",
  "you_have_accepted_current_suspended_deal": "You have accepted current suspended deal",
  "close": "Close",
  "status_on_hold": "On hold",
  "submit": "Submit",
  "resend": "Resend",
  "reviews": "Wrote about us",
  "label_counter_address": "Counter address",
  "react_you_may_send_money_on_one_of_the_following_bank_accounts_plural": "Send {{amount}} <0>{{currency}}</0> to one of the following bank accounts",
  "order_filter_button_new": "New",
  "order_filter_button_in_progress": "In progress",
  "order_filter_button_finished": "Finished",
  "order_filter_button_custom": "Custom",
  "client_order_status_base_label": "Status",
  "client_order_status_direction_label": "Direction",
  "client_order_status_final_label": "Final Status",
  "ticket_status_solved": "Solved",
  "ticket_status_in_progress": "In Progress",
  "ticket_status_waiting_for_admin": "Sent",
  "ticket_status_waiting_for_client": "New message",
  "member_of": "Member of",
  "level_one_verification_warning_back_transfer_instead_of_statement": "Please refer to <0>this article</0> for more information about bank account verification",
  "label_bank_account_number": "Bank account number",
  "label_bank_account_details": "Bank account information",
  "validation_max_characters": "Field may only contain up to ${max} characters",
  "verification_required_if_order_made_warning_for_registered_clients": "In order to proceed with this order you will have to increase your verification level. Your order will be suspended automatically and you will have to accept new rate after the verification is done. For information about limits and verification visit <0>this page</0>.",
  "verification_required_if_order_made_warning_for_guests": "In order to proceed with this order you will have to sign up and then increase your verification level. Your order will be suspended automatically and you will have to accept new rate after the verification is done. For information about limits and verification visit <0>this page</0>.",
  "dialog_text_anonymous_trading_disabled_please_log_in_or_register": "Please log in or sign up to create an order",
  "get_verified_to_start_trading_warning_message": "You need to increase your verification level in order to start trading. You currently have <strong>level {{currentVerificationLevel}}</strong> and need <strong>level {{requiredVerificationLevel}}</strong> to start trading. Please go to the profile tab and fill in the verification form.",
  "offer_will_expire_in": "This offer expires",
  "offer_has_expired": "This offer has expired. Please create a new one on our partner's site or directly on <0>{{company}}</0>.",
  "order_offer_header": "{{companyName}} exchange offer",
  "verification_required_for_order_creation_partner_order": "It is now required to reach verification level {{level}} on {{brandName}} before processing your order. Please sign up and verify your profile in order to continue.",
  "label_specify_destination_tag": "Important: enter {{destinationTag}} as destination tag.",
  "validation_error_required_characters_exactly": "Field ${path} must be exactly ${length} characters",
  "csv_export_column_from_currency": "From currency",
  "csv_export_column_to_currency": "To currency",
  "react_order_crypto_no_fee_info_message": "We do not charge any fees for this order. You will receive full refund.",
  "login_confirmation_form_check_email_paragraph": "A verification code has been sent to your email. Please check your inbox and paste the code below to confirm your login.",
  "login_confirmation_title": "Email verification",
  "login_confirmation_email_resent": "Confirmation email send successfully",
  "login_confirmation_email_not_received": "Did not receive verification email? Please check your spam folder. It can take several minutes.",
  "login_confirmation_code_input_placeholder": "6 digit code",
  "lost_2fa_device_contact_support": "Lost your device? Contact support at {{companyEmail}} or {{companyPhone}} ",
  "higher_verification_limit_required_warning": "Please increase your verification level to see payment instructions",
  "verification_through_onfido_disclaimer": "Identity verification will be performed by Onfido.  ",
  "signup_and_verification_required_warning": "Please <0>sign up</0> and verify your account in order to proceed with your order.",
  "go_to_profile_button": "Go to profile",
  "profile_page_divex_address_ownership_disclaimer_heading": "Wallet security statement",
  "profile_page_divex_address_ownership_disclaimer_message": "Please attach a scan or a photocopy of the declaration of wallet ownership which shows that you are the only one with access to the cryptocurrency wallet that you will use within Divex.",
  "profile_page_divex_address_ownership_disclaimer_warning": "The statement is required to ensure that your advisor does not have access to your cryptocurrency wallet and therefore can not steal your funds in future. </br> If you would like to know more about recommended wallet security, <0>click here</0>.",
  "signup_for_invited_only_divex_message": "Registration is only for Divex exchange clients. </br>\nDo you want to become a client? Contact one of our partners to help you register and set up the entire process. </br>\nThe list of all partners is available <0>here</0>.",
  "divex_address_ownership_disclaimer_upload_label": "Attach document here",
  "email_verification_token_expired_log_in_again": "This verification link is incorrect or has expired. Please log in again",
  "pagination_total_visible_rows": "{visibleCount} of {totalCount}",
  "phone_hint_include_prefix": "Please provide the number with prefix",
  "sidebar_navigation_transactions": "Transactions",
  "account_balance_formatted": "Balance: {{balanceWithCurrency}}",
  "transfer_sent_successfully": "Transfer sent successfully",
  "cookie_consent_i_agree_to_all": "Enable all",
  "cookie_consent_i_agree_to_required": "Enable functional cookies only",
  "divex_affil_find_more": "Find out more about affiliate program in <0>this article.<0/>",
  "button_i_confirm": "I confirm",
  "wallet_ownership_declaration_paragraph_1": "I have been advised to use the Trezor hardware wallet for storage of digital assets and use it with PIN code.",
  "wallet_ownership_declaration_paragraph_2": "I am the owner of the master seed (12-24 words) that I have stored securely. I am aware that the cryptocurrencies in the wallet can be spent using the master seed, even without the device itself.",
  "wallet_ownership_declaration_paragraph_3": "The advisor who was helping me set up my wallet did not have access to my master seed and could not make a copy of it.",
  "page_header_exchanges": "Exchange currencies",
  "sidebar_navigation_dashboard": "Dashboard",
  "dashboard_header": "Dashboard",
  "page_header_transfers": "Transfers",
  "settings_button_general_settings": "General",
  "settings_button_security_settings": "Security",
  "settings_button_email_settings": "Rules for sending emails",
  "settings_button_profile_verification": "Profile verification",
  "action_activate_account_email_sent": "We have sent you an e-mail with link for activation of your password to the e-mail address.",
  "action_activate_account_error_email_not_sent": "The e-mail you have provided does not match account that may be activated this way. Please choose another e-mail.",
  "request_account_activation": "Account activation",
  "account_activation_enter_email_provided_to_support": "Activate your Divex account by entering the email you used to register for the Introduction to Bitcoin or Diverzo Academy course.",
  "account_activate_button": "Activate account",
  "account_activation_password_saved": "Your password has been saved. You can now log in.",
  "breadcrumb_category_order": "Order",
  "breadcrumb_subcategory_order_id": "Order #{{id}}",
  "page_header_general_settings": "General settings",
  "page_header_email_settings": "Email rules",
  "dashboard_order_table_last_orders": "Last orders",
  "dashboard_order_table_all_orders": "All orders",
  "user_discount": "Your discount",
  "get_discount": "Get discount",
  "volume_discount": "Volume discount",
  "personal_discount": "Personal discount",
  "ticket_status_all": "All",
  "id": "ID",
  "exchange_form_total_fee": "Total fees",
  "exchange_form_out_fee_crypto": "Outgoing transaction fee",
  "exchange_form_out_fee_fiat": "Outgoing bank transfer fee",
  "exchange_form_in_fee_crypto": "Incoming network fee",
  "exchange_form_in_fee_fiat": "Incoming bank transfer fee",
  "support": "Support",
  "tickets": "Tickets",
  "ticket": "Ticket",
  "message": "Message",
  "upload_file": "Upload file",
  "you": "You",
  "page_become_affiliate_partner_header": "Become Simplecoin <0>affiliate</0> partner",
  "page_become_affiliate_partner_bonus": "Recommend our exchange office to people and get a <strong>  commission of <br> up to {{affiliatePercent}}%</strong> of each exchange they make.",
  "page_become_affiliate_partner_involved": "I want to get involved",
  "page_become_affiliate_partner_information": "More information",
  "page_affiliate_dashboard_header": "Affiliate program",
  "page_affiliate_dashboard_current_balance_header": "Current balance",
  "page_affiliate_dashboard_current_balance_withdraw": "Withdraw balance",
  "order_related_support": "Support related to order",
  "page_affiliate_dashboard_total_profit": "Total profit",
  "page_become_affiliate_partner_copy_link_header": "Your affiliate link",
  "page_become_affiliate_partner_bonus_start": "Start of cooperation:",
  "page_become_affiliate_partner_bonus_tip": "<strong> Tip! </strong> Also use pre-made promotional banners in various sizes and motifs.",
  "page_become_affiliate_partner_promotional": "Show promotional materials",
  "document_issued_by_government": "Document issued by government",
  "document_back_side_scan": "Document back side scan",
  "document_front_side_scan": "Document front side scan",
  "document_type": "Document type",
  "scan_quality_warning": "Good quality scans (300dpi) or photos which are fully readable are required. No parts of any document can be edited nor removed.",
  " page_become_affiliate_partner_income_header": "Overview of income",
  "page_become_affiliate_partner_withdrawal_header": "Overview of withdrawal",
  "page_become_affiliate_partner_statistics_header": "Overwiew of statistics",
  "secondary_document": "Secondary document",
  "selfie_with_id": "Selfie with ID card",
  "affiliate_withdraw_breadcrumb": "Withdraw",
  "affiliate_withdraw_header": "Withdraw affiliate balance",
  "affiliate_withdraw_info_alert": "Your affiliate balance is <strong>{{balanceCurrencyEur}}</strong>. You may withdraw it in any of the supported currencies. You can see the converted amount after selecting the currency and recipient. ",
  "bank_account_create_form_header": "Add a new {{currencyCode}} bank account",
  "crypto_address_create_form_header": "Add a new {{currencyCode}} wallet",
  "settings_social_create_password_button": "Set password",
  "recipient_page_table_name": "Name",
  "recipient_page_table_address": "Address / Bank account no.",
  "recipient_page_table_currency": "Currency",
  "recipient_page_table_header": "List",
  "wallet_welcome_page": "You are about to go to <br /> Simplecoin Wallet",
  "wallet_welcome_page_condions": "I agree with the terms and <0>conditions</0> of Simplecoin Wallet",
  "wallet_welcome_page_button": "Continue to Simplecoin Wallet",
  "wallet_welcome_page_benefits_1": "Create a portfolio at Simplecoin",
  "wallet_welcome_page_benefits_2": "Easy deposit of funds",
  "wallet_welcome_page_benefits_3": "Trade cryptocurrencies easier and faster",
  "wallet_welcome_page_benefits_4": "More advantageous shift fees",
  "wallet_welcome_page_benefits_5": "Priority support",
  "verification_transaction": "Verification transaction",
  "verification_call_info": "As part of the verification process, we also need to verify your phone number, so we will call you to confirm that the verification was completed without any problems. You can choose the approximate time that is most convenient for you for the final phone call.",
  "verification_call": "Verification call",
  "when_should_we_call": "When should we call you",
  "asap": "As soon as possible",
  "email": "E-mail",
  "verification_and_limits": "Verifications and limits",
  "profile_verification": "Profile verification",
  "limits": "Limits",
  "start_with_verification": "Start with verification",
  "verification_in_progress": "Verification in progress",
  "level1": "Level 1",
  "done": "Done",
  "ok": "OK",
  "profile_verification_done_info": "We've emailed you the verification payment information in case you want to get back to them. We will let you know by email that you have been verified.",
  "sidebar_navigation_portfolios": "Porfolio",
  "page_dashboard_insert_deposit": "Deposit funds",
  "page_trade_head_title": "Trade",
  "transaction_tabs_title_exchange": "Exchange",
  "transaction_tabs_title_deposit": "Deposit",
  "transaction_tabs_title_withdraw": "Withdraw",
  "transaction_tabs_title_transfer": "Transfer",
  "label_summary": "Summary",
  "increase_limit": "Increase limit",
  "read_only": "Read-only",
  "page_portfolio_header": "Portfolio",
  "amount": "Amount",
  "purpose_of_crypto_trading": "Purpose of trading with cryptocurrencies",
  "what_is_purpose_of_crypto_trading": "What is your purpose of trading with cryptocurencies",
  "get_familiar_with_cryptocurrencies": "Get familiar with cryptocurencies",
  "occasional_buing_selling": "Occasional buying/selling",
  "one_off_investment": "One-off investment",
  "regular_savings": "Regular savings",
  "other": "Other",
  "speculations": "Speculations",
  "describe_your_purpose_of_cryptotrading": "Describe your purpose of trading with cryptocurrencies",
  "today": "today",
  "morning": "morning",
  "evening": "evening",
  "afternoon": "afternoon",
  "tomorrow": "tomorrow",
  "proof_of_income": "Proof of income",
  "proof_of_income_info": "The Anti-Money Laundering and Anti-Terrorism Financing Act (AML Act) requires to verify the source of your income if you want to trade with higher amounts. We therefore need to ask you some questions about the origin of the money you want to trade with us.",
  "income": "Income",
  "one-off_income": "one-off income",
  "monthly_income": "monthly income",
  "quarterly_income": "quarterly income",
  "yearly_income": "yearly income",
  "frequency": "Frequency",
  "source_of_income": "Source of income",
  "employment": "Employment",
  "position_in_company": "Position in company",
  "company_name": "Company name",
  "running_own_business": "Running own business",
  "rental": "Rental",
  "share_of_profit": "Share of profit",
  "pension": "Pension",
  "documents_supporting_the_income": "Documents supporting the income",
  "income_source_description": "Description",
  "add_income": "Add next income",
  "footer_sidebar_link_blog": "Blog",
  "proof_of_basic_income": "Proof of basic income",
  "proof_of_basic_income_info": "In order to set at least a basic trading and deposit limit, we need to know if you have at least a minimum income. There is no problem to increase this basic limit later if you want to trade \"at full throttle\". Even if you don't have that income, it is still OK. Later you will describe the source of the funds you want to trade with and we will set the limit accordingly.",
  "step": "Step",
  "basic_income_agreement": "I confirm that my income corresponds to at least 600 EUR per month",
  "semi-yearly_income": "semi-yearly income",
  "incomes": "Incomes",
  "income_state_submited": "awaits approval",
  "income_state_approved": "approved",
  "income_state_new": "new",
  "updating_incomes_info": "Any modification of your incomes (adding a new income or deleting an existing one) must be assessed by us and we will adjust your trading limit accordingly.",
  "submit_for_approval": "Submit for approval",
  "income_state_unapproved_change": "unapproved change",
  "prooved_incomes": "Prooved incomes",
  "verification_and_incomes": "Verification and incomes",
  "trading_limits": "Trading limits",
  "current_allowed_trading_amount": "Allowed amount for trading",
  "current_allowed_trading_amount_info": "This is the maximum amount you can right now deposit into Simplecoin Wallet or exchange in Simplecoin Exchange. This amount changes over time depending on how often you trade. It can gradually decrease to zero or increase back to your original limit. Only paid orders and confirmed deposits counts.",
  "individual_limits": "Individual limits",
  "time_period": "Time period",
  "limit_extension": "Limit extension",
  "remaining_limit_amount": "Remaining amount you can still draw down",
  "state_of_limit_execution": "State of limit execution",
  "in_time_period": "in",
  "individual_trading_limits_info": "If you have more individual limits, you must meet all of them. The one that is currently the most restrictive is always applied.",
  "trading_limit_extension_info": "If the standard limit is not enough for you, we can increase your limit even further by providing proof of one-off income. We will gradually reduce this limit whenever you trade above the standard limit.",
  "income_state_deleted": "deleted",
  "account_update_success": "Account was updated",
  "account_label_edit": "Edit account",
  "account_action_archive": "Archive account",
  "waiting_for_payment": "waiting for payment",
  "waiting_for_payment_approval": "waiting for payment approval",
  "verification_transaction_info": "For successful remote identification, you will need to verify at least one of your bank accounts (in your name). For this we only need to receive a single euro from this account.",
  "verification_transaction_received_info": "The verification transaction has been received. All we have to do is verify that it came from your bank account.",
  "verification_transaction_approved_info": "Your bank account has been verified. So we can skip this step.",
  "export_to_csv": "Export to CSV",
  "new_account_button": "Create new Account",
  "remove_recipient": "Remove recipient",
  "recipient_detail": "Recipient detail",
  "account_label": "Account label",
  "veriffied": "veriffied",
  "not_verified": "not verified",
  "label_create_trade": "Create trade",
  "remove_income": "Remove income",
  "transaction_detail_header": "Transaction detail",
  "transaction_type": "Type",
  "trade": "Trade",
  "withdraw_label_about": "You are about to withdraw",
  "withdraw_summary_fees": "Summary and fees",
  "Choose-destination-bank-account": "Choose destination bank account",
  "wallet_account_destination": "Chose destination {{currencyName}} address",
  "account_unique_name_label": "Provide a unique name of this account",
  "account_note_label": "You can add any additional note here",
  "account_detail_suspend_alert": "Your account is suspended. Please email us for support.",
  "current_balance_label": "Current balance",
  "balance_blocked_label": "Blocked balance",
  "label_not_choosen_account": "You do not have an account selected",
  "label_not_enough_money": "You have insufficient funds",
  "label_set_all_funds": "Set all funds",
  "label_available_balance": "Available balance",
  "label_withdraw": "Withdraw",
  "account_label_description": "Account description",
  "label_balance_after_transaction": "Balance after transaction",
  "label_fee": "Fee",
  "label_counter_party": "Counterparty",
  "label_operation_confirm_withdraw": "Confirm your withdrawal",
  "label_operation_confirm_transfer": "Confirm your transfer",
  "label_operation_confirm_exchange": "Confirm your exchange",
  "label_no_account_this_currency": "You do not have an account in this currency",
  "transaction_info_acc_head": "Amount and currency",
  "label_from_account": "From account",
  "label_target_account": "Target account",
  "exchanges_balances_alert_fiat": "Conversion between non-crypto is currently not possible",
  "account_create_success": "Account was created",
  "withdraw_label_requests": "Withdraw requests",
  "operations_label_dashboard_header": "Operations",
  "label_currency_general": "Base currency",
  "income_state_draft": "unverified",
  "label_client_currency_balance": "Balance in base currency",
  "label_show_all": "Show all",
  "dashboard_balances_last_transactions": "Latest transactions",
  "dashboard_balances_no_accounts": "You don't have an account yet",
  "dashboard_balances_no_withdrawal_requests": "No withdrawal requests",
  "dashboard_balances_no_transaction": "You don't have transactions yet",
  "general_settings_label_base_currency": "This currency will be used as default throughout the app",
  "general_settings_header_base_currency": "Base currency",
  "page_portfolio_closed_suspend_accounts_header": "Suspended and canceled Accounts",
  "confirm_withdraw_button": "Withdraw",
  "button_withdraw_continue_to_confirmation": "Continue",
  "recipient_name": "Recipient name",
  "button_create_transfer": "Create transfer",
  "button_transfer_continue_to_confirmation": "Continue",
  "button_exchange_continue_to_confirmation": "Continue",
  "total_balance": "Total balance",
  "no_trading_limit_extension": "There is no extension of the trading limit.",
  "deposit_component_automatic_create_acc_label": "You do not have an account at Simplecoin in this currency yet. We'll set it up for you automatically.",
  "deposit": "Deposit",
  "withdraw": "Withdraw",
  "create_default_account_label": "Default account for",
  "create_default_account_description": "Automatically created account when depositing funds",
  "create_default_account_button": "Create default {{currencyCode}} account",
  "dashboard_blog_posts_header": "Last blog posts",
  "dashboard_link_to_blog": "Blog",
  "credited_amount": "Credited amount",
  "exchange_rate": "Exchange rate",
  "accounting_time": "Accounting time",
  "date": "Date",
  "amounts": "Amounts",
  "success_order_header": "What's next? Pay for your order to complete.",
  "success_order_email": "We have sent you payment instructions by email <0>{{email}}</0>.",
  "finop_type_DEPOSIT": "deposit",
  "finop_type_EXCHANGE": "exchange",
  "finop_type_TRANSFER": "transfer",
  "finop_type_WITHDRAWAL": "withdraw",
  "exchange_made": "Exchange successful",
  "from_account": "From account",
  "to_account": "To account",
  "accounts": "Accounts",
  "portfolio_balance_summary_fiat_header": "Fiat:",
  "portfolio_balance_summary_crypto_header": "Cryptocurrency:",
  "order_success_check_crypto": "Please check if your wallet address is correct.",
  "success_order_label_id": "Your order number is <0>{{order_id}}</0> created. Thank you!",
  "exchange_form_crypto_address_field_label": "Cryptocurrency address",
  "order_success_check_bank": "Please check if the bank account is correct.",
  "other_currencies": "Other currencies",
  "no_funds_in_portfolio": "You have no funds in your portfolio now. Deposit fiat or crypto funds into your accounts and then you can start trading immediately.",
  "exchange_form_register": "for higher limits.",
  "list": "List",
  "withdrawal_request_status_new": "Pending",
  "withdrawal_request_status_confirming_outgoing": "Dispatched",
  "withdrawal_request_status_done": "Done",
  "withdrawal_request_status_canceled": "Cancelled",
  "landing_main_header": "<0>The biggest</0> cryptocurrency exchange in Czechia",
  "next_landing_subheader": "Exchanging cryptocurrencies <0>since 2013</0>.",
  "header_link_about_us": "About us",
  "header_link_get_started": "Get started",
  "header_link_blog": "Blog",
  "header_service_hours": "Mo-Su 9-21",
  "buy_now_button": "Buy now",
  "chart_header": "Buy and sell",
  "exchange_form_label": "Buy and sell",
  "how_it_works_paragraph_1_content": "It only takes a minute to place an order. Up to 200 \u20ac / 10 days, all we need is your email and cryptocurrency wallet address. From higher amounts you will need to register and get verified.",
  "how_it_works_paragraph_1_heading": "Order",
  "how_it_works_paragraph_2_heading": "Pay",
  "how_it_works_paragraph_3_heading": "Receive cryptocurrency",
  "how_it_works_paragraph_2_content": "Once your order is complete, we will provide you with payment instructions.",
  "how_it_works_paragraph_3_content": "Upon receiving of your payment, we will send the cryptocurrency to your wallet. We can usually do it within 30 minutes!",
  "landing_support_section_paragraph": "Need help with exchanging cryptocurrencies or using the Trezor hardware wallet? Contact us anytime via email, phone, or visit our client center for personalized assistance.",
  "landing_support_section_heading": "Genuine people, real customer support",
  "contact_us_button": "Contact",
  "landing_advantages_1_heading": "Simple and safe cryptocurrency trade",
  "landing_advantages_1_text": "In only takes a minute to create an order. And once the funds are credited to our account, we will manually verify the transaction and send you the cryptocurrency within 30 minutes on average.",
  "landing_advantages_2_heading": "Real people, human customer support",
  "landing_advantages_3_heading": "Biggest and oldest exchange in Czechia",
  "landing_advantages_2_text": "Need advice on exchanging cryptocurrencies? You can always write, call or send us a ticket. We handle most requests promptly.",
  "landing_advantages_3_text": "We have been exchanging cryptocurrencies since 2013. We have exchanged cryptocurrencies for more than 21 thousand people with a total value of over 8 billion CZK. We are one of the founding members of <0>\u010cKMA</0>.",
  "ckma_founder": "Founding member of",
  "newsletter_field_placeholder": "Your e-mail",
  "footer_sub_header_newsletter_subscription": "Sign up for news",
  "footer_link_career": "Career",
  "footer_link_affiliate_program": "Affiliate program",
  "footer_link_privacy_notice": "Privacy",
  "footer_link_terms_and_conditions": "Terms and conditions",
  "footer_link_contact": "Contact",
  "footer_link_limits_and_verification": "Limits and account verification",
  "footer_link_faq": "Frequently asked questions",
  "footer_link_blog": "Blog",
  "footer_header_company_info": "We have exchanged over 8 billion Czech Crowns since 2013.",
  "landing_bottom_section_header_trade_easily": "Trade coins with ease",
  "landing_bottom_section_button_buy_coins": "Buy cryptocurrency",
  "how_it_works_header": "How does it work?",
  "landing_wrote_about_us_header": "Wrote about us",
  "landing_last_orders_header": "Last transactions",
  "header_faq": "Frequently asked questions",
  "faq_general_questions_header": "General",
  "log_in_button": "Log in",
  "header_button_buy_crypto": "Buy cryptocurrency",
  "header_short_button_buy": "Buy",
  "search_button": "Search",
  "header_support": "Support",
  "send_button": "Send",
  "email_subscribe_header": "Subscribe for news from the cryptocurrency world",
  "affiliate_advantage_payout_anytime": "Payout possible at any moment",
  "affiliate_advantage_banner": "Banners for your website or blog",
  "affiliate_advantage_referral_link": "Own referral link",
  "affiliate_advantage_fiat_or_crypto": "Get paid in crypto or fiat currency",
  "affiliate_advantage_cookie": "Cookies for {{affiliateCookieValidityDays}} days",
  "affiliate_advantage_reward_from_margin": "{{affiliateMarginReward}}% reward from our margin",
  "affiliate_header": "Affiliate program",
  "affiliate_subheader": "Become a Simplecoin affiliate partner and get commission from every exchange done by people you brought.",
  "header_earn_with_us": "Earn with {{brand}}",
  "create_account_button": "Sign up",
  "affiliate_how_it_works_header": "How does it work?",
  "affiliate_how_it_works_step_1_header": "Create an account and sign up for our affiliate program",
  "affiliate_how_it_works_step_2_header": "A unique link will be created for you",
  "affiliate_how_it_works_step_3_text": "Every person who comes to the Simplecoin site via your link receives a cookie. If they create an order within 7 days, you will earn a profit of {{affiliateMarginReward}}% of our margin. You will earn these returns for every order he trades with us. For orders created after the 7 day limit, the link is no longer active.",
  "affiliate_how_it_works_step_2_text": "You have a URL and banners that you can share on your website, blog, social media or with your friends. Anyone who uses this link and then signs up or creates an order within 7 days will be entered into your affiliate program for two years.",
  "affiliate_how_it_works_step_1_text": "Both registration and verification are required to join the affiliate program. Registration allows you to access the administration and manage your account. Verification is required for payment of rewards. We follow good manners and do not encourage unfair practices or dealings. We expect the same from our partners.",
  "affiliate_how_it_works_step_4_text": "You can view how much you have earned at any time in your affiliate program administration. When you feel like it, you can choose the amount in crypto or fiat currency. We will send the payment to your bank account or crypto wallet.",
  "affiliate_how_it_works_step_3_header": "You will receive {{affiliateMarginReward}}% from our profit margin for every customer you bring",
  "affiliate_how_it_works_step_4_header": "You can withdraw your earnings at any time",
  "limits_and_verification_header": "Limits and verification",
  "limits_and_verification_subheader": "For legal reasons, like all other exchanges, we must <0>verify every transaction above a certain limit.</0> If this applies to you, you can easily register and have your account verified. See the boxes below for information on limits and verification.",
  "limits_table_level_0_header": "up to {{limitLevel0Eur}} \u20ac",
  "limits_table_level_0_subheader": "per {{limitLevel0DurationDays}} days",
  "limits_table_registration": "Compulsory registration and identification",
  "limits_table_row_2_documents_photo": "Photo of 2 personal documents",
  "limits_table_row_selfie": "Selfie with document",
  "limits_table_row_verification_bank_transfer": "Verification bank transfer",
  "limits_table_row_verification_phone_call": "Verification phone call",
  "limits_table_row_purpose_od_crypto_purchase": "Purpose of cryptocurrency purchase",
  "limits_table_row_source_of_income": "Source of income",
  "limits_table_row_individual_limits_base_on_income_source": "Individual limits based of source of income",
  "limits_table_level_1_header": "{{limitLevel0Eur}} \u2014 {{limitLevel1Eur}} \u20ac",
  "limits_table_level_1_subheader": "per {{limitLevel1DurationDays}} days",
  "limits_table_level_2_subheader": "per {{limitLevel2DurationDays}} days",
  "limits_table_level_2_header": "over {{limitLevel2EurStart}} \u20ac ",
  "footer_mobile_navigation_expand_label": "Site navigation",
  "support_search_placeholder": "What would you like to know?",
  "error_404_header": "We couldn't find that page",
  "error_404_subheader": "But you can continue to...",
  "error_page_contact_us": "If you think this is a mistake, write us on {{supportEmail}}. Thank you!",
  "link_blog_label": "What's new on Simplecoin",
  "link_contact_label": "Contact us",
  "link_faq_label": "Find answers here",
  "about_page_recommendations_section_header": "Recommended by",
  "cookie_dialog_text_content": "{{brand}} uses cookies to make using our services more comfortable and to constantly improve user experience. You can can choose which types of cookies will be used below. For more information read our <0>cookie policy</0>",
  "cookie_dialog_necessary_cookies_header": "Necessary cookies",
  "cookie_dialog_necessary_cookies_text": "Cookies which are needed for the functioning of the website",
  "cookie_dialog_necessary_cookies_always_active": "Always active",
  "cookie_dialog_statistical_cookies_header": "Statistical cookies",
  "cookie_dialog_statistical_cookies_text": "Cookies which help us measure and understand how you use our services",
  "cookie_dialog_marketing_cookies_header": "Marketing cookies",
  "cookie_dialog_marketing_cookies_text": "Cookies that help us in some way",
  "cookie_dialog_personalization_cookies_header": "Personalization cookies",
  "cookie_dialog_personalization_cookies_text": "Cookies thanks to which you will not need to set some things twice",
  "cookie_dialog_accept_all_button": "Accept all",
  "cookie_dialog_confirm_choices_button": "Confirm my choices",
  "cookie_dialog_header": "Your cookie preferences",
  "support_label_breadcrumbs_navigation": "Help",
  "buy_currency_button": "Buy {{currencyName}}",
  "exchange_form_get_wallet_help": "No wallet?",
  "scan_address_qr_code": "Scan QR",
  "exchange_form_crypto_address_field_placeholder": "bc1...",
  "order_form_email_and_account_step_label": "Order information",
  "order_form_amounts_step_label": "Amount and currency",
  "order_form_header": "Order",
  "landing_login_dialog_title": "Introducing Simplecoin Pro. A new platform with lower fees and instant currency exchanges. No need for a crypto wallet.\n",
  "landing_login_dialog_pro_header": "Try Simplecoin Pro",
  "landing_login_dialog_exchange_header": "Go to Simplecoin Exchange",
  "landing_login_dialog_exchange_text": "Simple crypto exchange service you know and love",
  "landing_login_dialog_pro_text": "Try Pro for even better trading experience",
  "landing_login_dialog_pro_login_button": "Log in to Pro",
  "landing_login_dialog_exchange_login_button": "Log in to Exchange",
  "about_us_section_small_header": "About us",
  "about_us_section_1_header": "We make the world of buying or selling <0>Bitcoin</0> and other digital currencies <0>easier</0>.",
  "about_us_section_1_subheader": "The world of cryptocurrencies erases boundaries and brings you real freedom and a new perspective on finance.",
  "about_us_section_2_header": "We are cryptocurrency experts",
  "about_us_section_2_subheader": "We help put money back to regular people's hands",
  "about_us_section_2_content": "Simplecoin is first and foremost a technology company that relies on the expertise of our programmers. We invest time and attention specifically in a high standard of security for your client accounts and data.",
  "about_us_section_3_header": "We've been making it easy for people to buy cryptocurrencies <0>since 2013</0>",
  "about_us_section_3_text": "From the very beginning, we have been a purely Czech company with a clear ownership structure. Anyone can trade cryptocurrencies. We bring another possibility of working with finances that are not managed by banks and the state. We bring you options that you manage yourself. We show people that the cryptocurrency world is simple.",
  "about_us_section_4_header": "Modern technology and security",
  "about_us_section_4_subheader": "We take care of the high security of user accounts",
  "about_us_section_4_text": "Simplecoin is primarily a technology company. We base our experience on the expert knowledge of our programmers. We invest time and attention in a high level of security for our clients' accounts and data.",
  "contact_header": "Get in touch",
  "contact_page_email_label": "Email",
  "contact_page_phone_label": "Phone",
  "contact_page_customer_center": "Customer center",
  "contact_page_billing_info": "Billing information",
  "support_search_result_count": "{{resultsCount}} results",
  "limits_table_item_not_needed": "No",
  "limits_table_item_required": "Required",
  "how_to_get_started_step_1_name": "Step 1",
  "how_to_get_started_step_1_header": "You need a crypto wallet",
  "how_to_get_started_step_1_cta": "How to set up a crypto wallet",
  "how_to_get_started_step_1_text": "The wallet is used to store cryptocurrencies. You will need it when making a purchase. If you don't have a wallet, you can download it as an app on your mobile phone or computer. We'll show you how. ",
  "how_to_get_started_step_2_name": "Step 2",
  "how_to_get_started_step_2_header": "Sign up",
  "how_to_get_started_step_2_subheader": "It has its advantages",
  "how_to_get_started_step_2_text": "You will need an email address and a secure password. You can place an order without registering, but you will lose the volume discount and the ability to manage your order in your user account.",
  "how_to_get_started_step_3_name": "Step 3",
  "how_to_get_started_step_3_header": "Create an order",
  "how_to_get_started_step_3_subheader": "Now it only takes a minute",
  "how_to_get_started_step_3_text": "Fill out the order form - you already have everything you need (email address and crypto wallet address).  We will send you payment instructions by email. Once we have verified the funds have been credited, we will send you the desired currency. We can usually do this within 30 min.",
  "how_to_get_started_step_3_hint": "Pay attention to payment instructions. Remember to insert the text in the message to the recipient as instructed. This will help us process your order faster.",
  "how_to_get_started_step_4_name": "",
  "how_to_get_started_step_4_header": "",
  "how_to_get_started_step_4_subheader": "",
  "how_to_get_started_step_4_text": "",
  "how_to_get_started_step_4_cta": "",
  "landing_clients_since_2013": "clients since 2013",
  "landing_monthly_volume": "of volume in the last 30 days ",
  "landing_exchanges_per_month": "exchanges in the last 30 days",
  "cookie_bar_header": "<0>Simplecoin</0> uses cookies to function correctly",
  "cookie_bar_text": "We use cookies to for login as well as navigation and traffic analysis. Thank you for your consent to their use. Select your cookies.",
  "cookie_bar_agree_and_continue_button": "Agree and continue",
  "cookie_bar_manage_cookie_settings_button": "Cookie preferences",
  "order_detail_info_header": "Order details",
  "order_detail_order_id": "Order number",
  "order_detail_created_at": "Created at",
  "order_detail_status": "Status",
  "order_detail_rate": "Order rate",
  "order_detail_button_cancel": "Cancel",
  "order_detail_button_suspend": "Suspend",
  "order_detail_button_download": "Download in PDF",
  "order_detail_button_new_ticket": "Create a ticket",
  "order_detail_history_table_header": "Order history",
  "order_history_datetime_column_name": "Date and time",
  "order_history_status_column_name": "Status",
  "order_detail_outgoing_payment_label": "Outgoing payment",
  "order_detail_incoming_payment_label": "Your payment",
  "order_detail_incoming_payment_waiting_for_transaction": "Your transaction has not been detected yet",
  "order_detail_incoming_payment_waiting_for_outgoing_transaction": "We are waiting for your payment",
  "order_payment_instructions_header": "Send payment with the following details",
  "order_form_payment_instructions_bank_account_number_label": "Bank account number",
  "order_form_payment_instructions_bank_amount_label": "Amount",
  "order_form_payment_instructions_bank_message_label": "Payment message",
  "order_form_payment_instructions_use_qr": "Or scan the following QR code",
  "order_form_payment_instructions_choose_one_of_company_accounts": "Company account",
  "order_status_done": "Done",
  "order_status_delivered": "Delivered",
  "order_status_canceled": "Canceled",
  "order_status_returned": "Returned",
  "order_status_in_progress": "In progress",
  "order_status_confirming_incoming": "Confirming incoming",
  "order_status_confirming_outgoing": "Confirming outgoing",
  "order_status_new": "New",
  "order_status_suspended": "Suspended",
  "order_status_received": "Received",
  "order_detail_outgoing_payment_waiting_for_incoming_transaction": "Your transaction has not been detected yet",
  "show_payment_instructions_dialog_opener_button": "Show payment instructions",
  "support_section_header": "Did not find the answer?",
  "support_section_text": "The customer service line can be reached Monday to Sunday between 9:00 and 21:00. Or send us an email. Our colleagues will always be happy to advise you and guide you safely through your purchase.",
  "label_crypto_sent_to_address": "Address",
  "label_transaction_id": "Transaction ID",
  "order_detail_button_proceed": "Proceed",
  "label_amount_and_currency": "Amount",
  "label_payment_sent_at": "Created",
  "order_detail_outgoing_payment_waiting_to_confirm_incoming_transaction": "Incoming transaction is being confirmed",
  "order_detail_outgoing_payment_order_suspended": "Order is suspended, now waiting for your decision. ",
  "order_detail_outgoing_payment_order_return_to_client": "Your payment will be returned",
  "order_form_payment_instructions_company_billing_details": "Billing information",
  "order_has_been_created": "Order successfully created",
  "fiat_payment_instructions_amount_label": "Amount",
  "order_detail_button_return": "Return",
  "order_view_original_price_label": "Previous offer",
  "order_view_limit_price_label": "Limit price",
  "order_view_current_price_label": "Current offer",
  "cryypto_payment_instructions_amount_label": "Amount",
  "payment_instruction_hint_fill_amount_to_show_qr": "Fill amount filed to generate QR code",
  "deposit_payment_instruction_amount_label": "Amount to deposit",
  "form_email_field": "Email",
  "confirm_button": "Confirm",
  "email_placeholder": "Email",
  "change_in_24hours": "Change in the last 24 hours",
  "order_offer_amounts_label": "Your offer",
  "order_offer_your_crypto_address": "Your cryptocurrency address",
  "order_offer_continue_to_login": "Continue with {{companyName}} account",
  "order_offer_or_separator": "or",
  "order_offer_continue_without_registration": "Continue without registration",
  "order_offer_validity_notice": "This offer will expire in <strong>{{timeRemaining}}</strong>. Please log in or continue with email. ",
  "order_offer_continue_as_guest": "Continue as guest",
  "order_offer_existing_email_please_click_continue_button": "This email is already registered. Please choose the \"Continue with {{companyName}} account\" option",
  "landing_header_references": "References",
  "crypto_payment_instructions_amount_label": "Amount",
  "crypto_payment_instructions_destination_tag_field_label": "Destination tag",
  "order_form_mail_help_text": "We will send you instructions for completing the order to this e-mail",
  "validation_required_email": "Enter your e-mail address",
  "validation_required_crypto_account": "Enter your crypto account",
  "landing_help_section_header": "Here you will find answers to the most common questions. In addition to written instructions, we can also help you on our customer service line at +420 608 746 753 or by email at info@simplecoin.eu, Monday to Sunday from 9 a.m. to 9 p.m. We can guide you through your first purchase, help you order or register, advise you on how to set up your wallet, or answer your questions. Feel free to ask us anything.",
  "guest_exchange_form_limit_alert": "Purchase without registration is possible up to {{code}} {{limit}}. For higher amounts, please log in or register.",
  "exchange_form_email_label": "Your e-mail",
  "exchange_form_alert_change": "The exchange rate between these currencies cannot be traded. Please adjust your currency selection.",
  "welcome_in_wallet": "Welcome in Wallet!",
  "start_with_funding_text_2": "You have been verified. Now you can deposit funds and start trading.",
  "start_with_funding_text_1": "Well done!",
  "amount_in_fiat_payment_instructions_info": "The amount is required to generate the QR code",
  "limit_table_level_0_text": "You can buy up to \u20ac{{limitLevel0Eur}} within 10 days without registration and without he need to verify personal documents.",
  "label_go_to_order": "Create Order",
  "label_register": "Register",
  "account_detail": "Account detail",
  "signup_form_subtitle": "You will get a better rate and higher trading limits. You will have an overview of your transactions.",
  "label_guest_login": "If you already have an account <0>Login</0>",
  "label_guest_signup": "If you don't have an account yet, <0>sign up</0>",
  "label_register_success": "Registration was successful",
  "label_register_success_verify": "Please activate your account now",
  "label_register_success_sended_verify": "We have sent you a link to activate your account to the e-mail {{mail}}.",
  "label_account_notes": "Account notes",
  "deposit_request_card_head": "Deposit requests",
  "dashboard_balances_no_deposit_requests": "No deposit requests",
  "deposit_request_status_pending": "Pending",
  "exchange_form_terms_must_be_accepted": "Terms and conditions should be accepted",
  "validation_required_bank_account": "Enter your bank account number",
  "order_status_dispatched": "Dispatched",
  "order_status_paid": "Paid",
  "order_status_paidUnconfirmed": "Paid (1/2)",
  "order_status_tooltip_new": "The order is waiting to be paid.",
  "order_status_tooltip_paidUnconfirmed": "We see an incoming transaction. It is waiting to be confirmed on the block-chain.",
  "order_status_tooltip_paid": "The order has been paid. We're working on dispatching the ordered currency.",
  "order_status_tooltip_paid_suspended": "The order has been paid.",
  "order_status_tooltip_dispatched": "We have dispatched the ordered currency to the target address/account. We are waiting for the confirmation on the block-chain/bank.",
  "order_status_tooltip_done": "The order is successfully processed.",
  "order_status_tooltip_canceled": "The order has been canceled.",
  "suspended_order_tooltip": "Order processing is suspended.",
  "order_detail_outgoing_payment_waiting_to_be_sent": "We are working to dispatch the ordered funds as soon as possible.",
  "exchange_limit_for_verification_level_2_text": "Your trading limit is not formally set. For large amounts, it will be assessed individually according to your <0>documented income</0>.",
  "your_limit_is": "Your limit is",
  "trading_limit": "Trading limit",
  "exchange_trading_limit_info": "The limit is automatically adjusted after we receive payment for your order. New unpaid orders do not affect the limit.",
  "you_have_no_suitable_wallet_among_recipients": "You have no wallet for {{currencyCode}} currency among your recipients.",
  "you_have_no_suitable_account_among_recipients": "You have no bank account for {{currencyCode}} currency among your recipients.",
  "rate_when_order_created": "Rate when order created",
  "order_status_proceed": "Proceed processing",
  "waiting_for_limit": "Waiting for limit",
  "button_more": "More ...",
  "exchange_form_expand_optional_fields": "Expand optional fields",
  "return_fiat_payment_info": "We have received your payment already. You have decided to get it back. But return payment has additional fee. You can get {{amount}} {{currency}} back to original bank account.",
  "cancel_order_and_return_payment": "Cancel order and return payment",
  "remaining_limit": "Remaining",
  "trading_limit_reached": "You have reached your trading limit for given period.",
  "attached_files": "Attached files",
  "there_are_no_files": "There are no uploaded files",
  "why_we_need_verification_info": "The anti money laundering law (AML) requires us to know our clients better. Therefore, it is necessary for you to tell us some information about yourself, which of course remains safely stored only with us.",
  "proceed_to_verification_action": "Proceed to verification, please",
  "verification_request_line2": "There are a few steps in our guide and then you can make full use of our wallet.",
  "verification_request_line1": "Now we need to get to know each other a little bit",
  "document_issued_by_government_info": "Please attach a government-issued document confirming the information provided in the previous step.",
  "secondary_document_info": "Please attach another document that confirms the information provided in the first step.",
  "selfie_with_id_info": "Please attach a photo of you holding the personal document  in your hand. The details on the document must be legible and your face must be visible.",
  "understood_button": "Understood",
  "no_prooved_incomes_info": "There is no proved income yet. You can still trade cryptocurrencies up to the base limit (10.000 EUR/month for verified clients). To increase the trading limit above 10 000 EUR/month, you will need to provide proof of income.",
  "verified": "Verified",
  "payment_instructions": "Payment instructions",
  "make_payment_to_one_of_our_accounts": "Make payment to one of our accounts",
  "payment_in": "Payment in",
  "iban_payment_instructions": "IBAN payment instructions",
  "new_price_is_being_loaded": "New price is being loaded...",
  "price_refresh_timer_tooltip": "We update the displayed price and rate for you every few seconds.",
  "send_to_address": "Send to address",
  "payment_specific_data": "Payment data",
  "bank_account_owner": "Bank account owner",
  "filter_all_currencies": "All currencies",
  "ticket_detail": "Ticket detail",
  "last_verification_step_warning": "This is the last step of the verification process. Once you have submitted it, you will no longer be able to change the information you have entered. Your profile will be verified by our back-office staff.",
  "exchange_form_limit_alert": "The order of this amount already exceeds your allowed trading limit {{limit}} {{code}}. You can still create the order, but it will not be processed until you verify yourself or provide proof of appropriate incomes.",
  "order_limit_alert": "Once you will pay for this order, you will exceed your allowed trading limit and the order will not be processed until you verify yourself or provide proof of appropriate incomes.",
  "remove_recipient_confirmation_question": "Do you really want to delete this recipient? You will not be able to undo this action.",
  "label_bank_account_iban": "IBAN",
  "validation_given_field_required": "Field '{{fieldName}}' is required",
  "inheritance": "Inheritance",
  "relationship_to_testator": "Relationship to testator",
  "inherited_from": "Inherited from (name)",
  "landing_how_to_get_started_button": "How to get started",
  "affiliate_partners_must_be_verified": "To become our affiliate partner, we first need to grab a few information about you.",
  "regular_income": "Regular income",
  "tax_declaration": "Tax declaration",
  "annual_report": "Annual report",
  "date_of_acceptance": "Date of acceptance",
  "document_confirming_acceptance_of_inheritance": "Document confirming acceptance of inheritance",
  "financial_closing_statement": "Financial closing statement",
  "decision_on_sharing_profit": "Decision on sharing profit",
  "sale_contract": "Sales contract",
  "real_estate_registry_extract": "Real estate registry extract",
  "typical_income_attachments": "Typical attachments",
  "sale_of_immovable_property": "Sale of immovable property",
  "address_of_property": "Address of property",
  "date_of_sale": "Date of sale",
  "sale_channel": "Sales channel / Way of sale",
  "paycheck": "Paycheck",
  "employment_contract": "Employment contract",
  "field_of_business": "Field of business",
  "company_id": "Company ID",
  "monetary_donation": "Monetary donation",
  "donor": "Donator",
  "donor_relationship_to_recipient": "Relationship of donor to recipient",
  "purpose_of_donation": "Purpose of donation",
  "source_of_donated_funds": "Source of donated funds",
  "email_subscribe_success": "You have successfully subscribed to our newsletter.",
  "landing_index_meta_tag_title": "Simplecoin - The largest cryptocurrency exchange in the Czech Republic",
  "landing_index_meta_tag_description": "Trusted since 2013",
  "landing_how_to_get_started_meta_tag_title": "Simplecoin - How to start buying cryptocurrencies",
  "landing_how_to_get_started_meta_tag_description": "A simple step-by-step guide for everyone",
  "landing_about_meta_tag_description": "We are cryptocurrency experts",
  "landing_about_meta_tag_title": "Simplecoin - About us",
  "landing_faq_meta_tag_description": "What do I need to buy cryptocurrencies? What do I gain by signing up?",
  "landing_faq_meta_tag_title": "Simplecoin - FAQ",
  "landing_support_meta_tag_title": "Simplecoin - Help",
  "landing_support_meta_tag_description": "Detailed instructions on how to set up a wallet, place an order or set up a DCA",
  "landing_tos_meta_tag_description": "Fair trading with cryptocurrencies",
  "landing_tos_meta_tag_title": "Simplecoin - Terms and conditions",
  "landing_privacy_meta_tag_title": "Simplecoin - Privacy Policy",
  "landing_privacy_meta_tag_description": "Your data is safe with us",
  "landing_affiliate_meta_tag_description": "Become a Simplecoin partner and earn commission",
  "landing_affiliate_meta_tag_title": "Simplecoin - Affiliate Program",
  "landing_limits_meta_tag_description": "Purchase options without registration and when you need to be verified",
  "landing_limits_meta_tag_title": "Simplecoin - Limits and Verification",
  "landing_contact_meta_tag_description": "Book a personal appointment at our client centre or take advantage of our client support 9am-9pm every day of the week.",
  "landing_contact_meta_tag_title": "Simplecoin - contact",
  "react_new_app_version_available_dialog_content": "A new version is available. Please refresh the page to ensure you're using the latest version.",
  "wallet_recipient_warning_1": "Anyone who knows the access secrets to the crypto-wallet (especially the recovery seed - 12-24 words used to recover the wallet) can freely use the funds in this wallet.",
  "wallet_recipient_warning_2": "If the wallet was created by someone else than myself, he/she probably has access to this wallet and can freely use the funds in this wallet.",
  "wallet_recipient_warning_3": "If I lose access to my wallet and do not have a backup (recovery seed), I lose access to all funds in my wallet.",
  "wallet_recipient_warning_4": "If I add an address of crypto-wallet that is not mine, I will not be able to directly use of the funds in that wallet. It is up to the third party to allow me to dispose of the funds.",
  "warning": "Warning",
  "ticket_attachments_info": "Attachments can be added to the ticket in the next step (after the ticket is created).",
  "one_off_income": "One-off income",
  "button_previous": "Previous",
  "years_of_service": "Years of service",
  "primary_or_secondary_activity": "Primary / secondary activity",
  "rental_contract": "Rental contract",
  "bank_statement_with_incoming_payment": "Bank statement with incoming payment",
  "pension_confirmation_letter": "Pension confirmation letter",
  "loan": "Loan",
  "loan_maturity_date": "Maturity date",
  "loan_effective_date": "Effective date",
  "lender": "Lender",
  "loan_agreement": "Loan agreement",
  "donation_agreement": "Donation agreement",
  "savings": "Savings",
  "bank_statement": "Bank statement",
  "sale_of_movable_property": "Sale of movable property",
  "sale_of_company_share": "Sale of company share",
  "investment_return": "Investment return",
  "investment_period": "Investment period",
  "crypto_mining": "Crypto mining",
  "mining_target_crypto_address": "Target crypto-address of mined coins",
  "verified_clients_limit_info": "Verified clients can trade up to EUR 10.000 per month without providing further proof of incomes.",
  "expected_trading_volume": "Expected trading volume",
  "expected_trading_volume_info": "Setting up expected trading volume is optional and is not a commitment. It will help us to better set your trading limits and thus speed up your trading.",
  "add_expected_trading_volume": "Add expected trading volume",
  "trading_volume_period": "Trading period",
  "landing_quarterly_volume": "90 day volume",
  "landing_quarterly_exchanges": "Exchanges in the last 90 days",
  "income_state_revoked": "revoked",
  "crypto_deposit_fee_info": "Speed of your crypto transaction depends on the transaction fee so it is important to use the correct transaction fee in order for transaction to be verified soon.",
  "deposit_on_account": "Deposit on account",
  "show": "show",
  "hide": "hide",
  "account_status_suspended": "suspended",
  "account_status_closed": "closed",
  "account_status_active": "active",
  "suspended_accounts": "Suspended accounts",
  "closed_accounts": "Closed accounts",
  "active_accounts": "Active accounts",
  "create_wallet_account_form_header": "Add a new account",
  "account_number": "Account number",
  "accounted_transactions": "Accounted transactions",
  "counterparty": "Counterparty",
  "all_financial_operations": "All",
  "accounting_balance": "Accounting balance",
  "pending_deposits": "Pending deposits",
  "pending_withdrawals": "Pending withdrawals",
  "pending_transactions": "Pending transactions",
  "blocked_amount_alert": "There is an amount {{amount}} {{currency}} blocked on this account. Please contact support for more information.",
  "available_balance_tooltip": "Available balance is the amount of money you currently have available for trading. The available balance does not include pending deposits but already takes into account amounts blocked, e.g. due to pending withdrawals.",
  "total_balance_tooltip": "The account total balance is the sum of all confirmed transactions in your account.",
  "deposit_requests_total_tooltip": "Pending deposits are incoming transactions to your account for which we are waiting for confirmation on the block-chain or have not been credited due to exceeding your trading limit.",
  "withdrawal_requests_total_tooltip": "Pending withdrawals are withdrawals awaiting confirmation on the block-chain.",
  "cancel_account": "Close account",
  "cancel_account_confirmation_question": "Are you sure you want to close this account? Closing the account is an irreversible operation.",
  "empty_account_required_to_close_it": "Only accounts with zero balance can be closed.",
  "account_closed_success": "The account has been closed.",
  "approved_incomes": "Approved incomes",
  "pending_incomes": "Pending incomes",
  "show_details": "show details",
  "hide_details": "hide details",
  "hide_approved_incomes": "Hide approved incomes",
  "show_approved_incomes": "Show approved incomes",
  "increase_trading_limit": "Increase trading limit",
  "save_as_draft": "Save as draft",
  "withdrawal_success": "Withdrawal has been successfully requested",
  "withdrawal_confirmation_transaction_fee_label": "Transaction fee: {{feeAmount}} {{feeCurrencyCode}}",
  "withdrawal_form_fee_in_amount_switch_label": "Withdrawal fee included",
  "withdrawal_recap_table_fee": "Fee amount",
  "withdrawal_recap_table_deducted_amount": "Withdrawal amount",
  "withdrawal_recap_table_net_withdraw_amount": "Recipient will get",
  "remove_income_confirmation": "Do you really want to delete this income?",
  "deposit_form_show_instructions": "Show deposit instructions",
  "income_state_readyForApproval": "awaits approval",
  "withdrawal_form_show_bank_fields_toggle": "Show modifiable fields",
  "end_income": "End income",
  "edit_income": "Edit income",
  "delete_income": "Delete income",
  "pending_transaction_detail": "Pending transaction detail",
  "account": "Account",
  "verification_wizard": "Verification wizard",
  "portfolio_volume": "Your portfolio volume",
  "profile_verification_info_message_for_business": "For business verification <0>read our guide</0>.",
  "unknown_source": "unknown source",
  "amount_netto": "Amount (netto)",
  "deposit_request_status_on_hold": "On Hold",
  "exchange_form_verified_client_discount_message": "Verified clients can save up to <0>{{nominalDiscount}} {{discountCurrency}}</0> per {{toCurrency}}. </1>",
  "sign_up_to_get_discount": "Sign up to get the discount",
  "exchange_form_verified_client_discount_message_generic": "Verified clients get a better price",
  "exchange_form_verified_client_discount_message_from_crypto": "Verified clients can immediately get <0>{{nominalDiscount}} {{discountCurrency}}</0> more per {{toCurrency}}.",
  "price_for_verified_clients": "Price for verified clients:",
  "contact_page_customer_center_reservation_info": "If you are interested in visiting our office, please <0>make an appintment</0>",
  "label_house_number": "House No",
  "react_file_upload_count_error": "the maximum number of uploaded files is {{maxFiles}}",
  "sorry_no_bank_for_given_currency": "We are sorry, but payments in {{currency}} are not possible at this moment.",
  "payment_instructions_delay_warning": "Due to the new provider of banking services, bank transfers may take longer than usual.\n<br/>\nTo ensure the original exchange rate from the order, you have the option to attach a PDF with payment confirmation from your bank. The price extension service is available daily from 9:00 to 21:00. Each request is assessed individually, and we will contact you as soon as possible.",
  "payment_confirmation_upload_info": "The duration of original exchange-rate is evaluated on an individual basis and depends on your payment history and order amount.",
  "attach_payment_confirmation": "Attach payment confirmation",
  "price_fixed_until": "The price is fixed until {{time}}",
  "payment_confirmation_attached": "Payment confirmation attached",
  "validation_incorrect_format_generic": "Invalid format",
  "exchange_fee_percentage_info": "Fee makes {{feePercentage}}% of the traded amount",
  "citizenship": "Citizenship",
  "second_citizenship": "Second citizenship",
  "second_citizenship_helper": "If you have two citizenships state both",
  "dashboard_intro_text_how_to_start": "Our wallet works similarly to internet banking. In order to start using Simplecoin Wallet, you need to deposit funds into virtual accounts you create in it. To get started, we recommend you set up one account each for the currencies you want to start trading. Later, of course, you can add more currency accounts.",
  "how_to_start_using_wallet": "How to start using Simplecoin wallet?",
  "create_accounts": "Create accounts",
  "default_account_label": "{{currencyCode}} default account",
  "default_account_description": "This account was created automatically for you by the initial wizard.",
  "about_virtual_account_info": "A virtual account is where you keep your funds in your Simplecoin wallet. If you want to hold funds in your chosen currency, you must create at least one account for that currency. Setting up and maintaining an account is free. Trading is done by transferring funds between accounts, \nwhich may be in different currencies.",
  "preferred_currencies_question": "Which currencies will you most often trade in?",
  "crypto": "Crypto",
  "fiat": "Fiat",
  "create_accounts_for_selected_currencies": "Create accounts for selected currencies",
  "accounts_successfully_created": "Accounts has been successfully created! We have created {{accountsCount}} accounts for you.",
  "create_your_first_accounts": "Create your first accounts",
  "wallet_logo": "wallet",
  "just_one_citizenship": "just one citizenship",
  "information_about_verification_status_change": "We will inform you about the change of verification status to e-mail {{email}}.",
  "amount_too_low": "Amount too low",
  "footer_link_legal_documents": "Legal documents",
  "log_into_wallet": "Wallet",
  "log_into_exchange": "Exchange",
  "log_into_exchange_caption": "One-off purchases to your wallet",
  "log_into_wallet_caption": "The easiest cryptocurrency trading",
  "payment_request": "Payment request",
  "payment_hash": "Payment hash",
  "memo": "Memo",
  "scan_invoice_qr": "Scan invoice QR",
  "lightning_payment": "Lightning payment",
  "scan_qr": "Scan QR",
  "pay": "Pay"
}