import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {
    fetchPrice,
    fetchRate,
    GuestOrderResponseValue,
    invalidatePrice,
    invalidateRate,
    PriceEndpointProps,
    RateEndpointProps,
    updatePrice,
    updateRate,
} from '~/redux/exchangeFormSlice';
import * as yup from 'yup';
import {useFormik} from 'formik';
import {RootState, useCoreAppDispatch} from '~/redux/store';
import useInterval from '../../hooks/useInterval';
import {getFormErrors, manageResponse} from '~/helpers/api';
import {ExchangeContainerProps, InitialValuesInterface} from '~/typings/ExchangeFormTypings';
import {enqueueSnackbar} from '~/redux/snackbarSlice';
import useBackendSettings from '../../hooks/useBackendSettings';
import {Currencies} from '~/typings/currency';
import {normalizeAmount} from '~/helpers/price';
import {useTranslation} from 'react-i18next';
import {getConfigValue} from '~/helpers/config';
import {validateAddress} from '~/helpers/crypto';

const reCaptchaRef = React.createRef<{reset: () => void}>();

// Keeps typing interval ID to limit number of requests to the API
let typingTimeout: NodeJS.Timeout | undefined = undefined;

const initialValues: InitialValuesInterface = {
    fromCurrencyCode: '',
    fromAmount: undefined,
    toCurrencyCode: '',
    toAmount: undefined,
    email: '',
    bankAccount: '',
    cryptoAccount: '',
    arbitraryData: undefined,
    termsAndCondition: false,
    currency_id: '',
    account_label: '',
    account_number: '',
    owner_name: '',
    owner_address: '',
    owner_country: '',
    owner_city: '',
    account_vs: '',
    account_specific: '',
    account_constant: '',
    message: '',
};

/**
 * Returns true is provided value is equal to CZK
 * @param value
 */
function notCZK(value?: string): boolean {
    // eslint-disable-next-line no-console
    console.warn(`Not czk returns ${value?.toUpperCase?.() !== 'CZK'} for ${value}`);
    return value?.toUpperCase?.() !== 'CZK';
}

/**
 * Manages the logic of exchange form. The data fetching, form values are managed here and passed to renderForm function arguments.
 * This component should be independent of layout
 */
export default function ExchangeContainer({
    fromCurrencyCodePrefill,
    toCurrencyCodePrefill,
    fromAmountPrefill,
    toAmountPrefill,
    onFromChange,
    onToChange,
    onSwap,
    onSubmitSuccess,
    onSubmit,
    rootStyles = {},
    showCaptcha = false,
    submitAction,
    renderForm,
    isGuest = false,
}: ExchangeContainerProps) {
    const dispatch = useCoreAppDispatch();
    const {
        rate,
        price,
        authCreate: authOrderReducer,
        guestCreate: guestOrderReducer,
    } = useSelector((state: RootState) => state.exchangeForm);
    const rateValue = rate?.response;
    const priceValue = price?.response;
    const rateOrPriceErrorObject = price?.error ?? rate?.error;
    let rateOrPriceError;
    // Interval in seconds for refreshing proposed price / exchange rate
    const PRICE_REFRESH_INTERVAL = 30;
    // Countdown timer for refresh interval.
    const [timer, setTimer] = useState(PRICE_REFRESH_INTERVAL);

    if (typeof rateOrPriceErrorObject === 'string') {
        rateOrPriceError = rateOrPriceErrorObject;
    } else {
        // todo: unwrap form error
    }

    const {t} = useTranslation();
    const currencies: Currencies = useBackendSettings('currencies', {});
    const [priceParams, setPriceParams] = useState<PriceEndpointProps>();
    const [showCrypto, setShowWalletDropdown] = useState<boolean>(true);
    const [rateParams, setRateParams] = useState<RateEndpointProps>({
        fromCurrencyCode: fromCurrencyCodePrefill ?? '',
        toCurrencyCode: toCurrencyCodePrefill ?? '',
    });

    const schemaGetter = () => {
        if (isGuest) {
            const guestSchema = {
                fromAmount: yup
                    .string()
                    .required(t('validation_given_field_required', {fieldName: t('exchange_form_from_amount_label')})),
                toAmount: yup
                    .string()
                    .required(t('validation_given_field_required', {fieldName: t('exchange_form_to_amount_label')})),
                reCaptcha: showCaptcha ? yup.string().required() : yup.string(),
                email: yup
                    .string()
                    .email(t('validation_error_incorrect_email_format'))
                    .required(t('validation_required_email')),
                termsAndCondition: yup
                    .boolean()
                    .required(t('exchange_form_terms_must_be_accepted'))
                    .isTrue(t('exchange_form_terms_must_be_accepted')),
            };

            if (showCrypto) {
                return yup.object().shape({
                    ...guestSchema,
                    cryptoAccount: yup
                        .string()
                        .required(t('validation_required_crypto_account'))
                        .test('is-valid-address', t('validation_incorrect_format_generic'), async function (value) {
                            // @ts-ignore
                            const toCurrencyCode = this.options.parent.toCurrencyCode;
                            if (toCurrencyCode) {
                                return await validateAddress(value, toCurrencyCode);
                            }
                            return false;
                        }),
                });
            } else {
                return yup.object().shape({
                    ...guestSchema,
                    account_label: yup.string(),
                    account_number: yup.string().max(255).required(t('validation_required_bank_account')),
                    account_vs: yup.string().matches(/^\d{1,10}$/, t('wrong_vs_format_message')),
                    account_specific: yup.string().matches(/^\d{1,10}$/, t('wrong_ss_format_message')),
                    account_constant: yup.string().matches(/^\d{1,4}$/, t('wrong_ks_format_message')),
                    message: yup.string().max(125),
                    // fields below should be required only if currency_id is not CZK
                    owner_name: yup.string().when('toCurrencyCode', {
                        is: notCZK,
                        then: () => yup.string().required(),
                        otherwise: () => yup.string(),
                    }),
                    owner_address: yup.string().when('toCurrencyCode', {
                        is: notCZK,
                        then: () => yup.string().required(),
                        otherwise: () => yup.string(),
                    }),
                    owner_country: yup.string().when('toCurrencyCode', {
                        is: notCZK,
                        then: () => yup.string().max(255).required(),
                        otherwise: () => yup.string(),
                    }),
                    owner_city: yup.string().when('toCurrencyCode', {
                        is: notCZK,
                        then: () => yup.string().max(255).required(),
                        otherwise: () => yup.string(),
                    }),
                });
            }
        } else {
            if (showCrypto) {
                return yup.object().shape({
                    cryptoAccount: yup.string().required(),
                });
            } else {
                return yup.object().shape({
                    bankAccount: yup.string().required(),
                });
            }
        }
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: schemaGetter,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values) => {
            if (typeof submitAction === 'function') {
                submitAction(values);
                setSubmitting(false);
            }
            onSubmit?.(values);
        },
    });

    const [showBank, setShowBankDropdown] = useState(false);
    const [showEmail, setShowEmail] = useState(true);
    const {fromCurrencyCode, toCurrencyCode, fromAmount, toAmount} = formik.values;
    const {
        handleSubmit,
        handleChange,
        setFieldValue,
        resetForm,
        errors,
        setSubmitting,
        setFieldError,
        handleBlur,
        setFieldTouched,
        touched,
    } = formik;
    const {reCaptcha: reCaptchaError} = useMemo(() => errors, [errors]);

    /**
     * Resetting amounts
     */
    function formInvalidate() {
        resetForm();
        setFieldValue('fromAmount', '');
        setFieldValue('toAmount', '');
    }

    useEffect(() => {
        manageResponse({
            reducer: guestOrderReducer,
            formik: formik,
            onSuccess: (response: GuestOrderResponseValue) => {
                onSubmitSuccess?.({
                    orderValueEur: priceValue?.from_amount_in_eur ?? 0,
                    id: response.order_id,
                    values: formik.values,
                });
                formInvalidate();
                dispatch(invalidateRate());
                dispatch(invalidatePrice());

                if (reCaptchaRef && reCaptchaRef.current) {
                    // Refresh reCaptcha on submission, as we should get fresh token for backend to check
                    reCaptchaRef.current.reset();
                    setFieldValue('reCaptcha', '');
                }
            },
            onError: (error) => {
                // nested error nightmare, should be changed on backend
                error?.['crypto']?.map((item) => {
                    item?.['address']?.map((errorMessage) => {
                        setFieldError('cryptoAccount', errorMessage);
                    });
                });
            },
        });
    }, [guestOrderReducer]);

    useEffect(() => {
        // Invalidate the rate fetched from rate end-point when price has been loaded. Use the rate from price end-point.
        manageResponse({
            reducer: price,
            onAny: () => {
                dispatch(invalidateRate());
            },
        });
    }, [price]);

    useEffect(() => {
        if (authOrderReducer.status === 'fail') {
            const formikErrors = getFormErrors(authOrderReducer, {asArray: true});
            if (formikErrors) {
                formikErrors.forEach((error) => {
                    dispatch(enqueueSnackbar({message: error, variant: 'error'}));
                });
            }
        } else if (authOrderReducer.response?.id && priceValue) {
            onSubmitSuccess?.({
                id: authOrderReducer.response.id,
                orderValueEur: priceValue.from_amount_in_eur,
            });
            formInvalidate();
            dispatch(invalidateRate());
            dispatch(invalidatePrice());
        }
    }, [authOrderReducer]);

    useEffect(() => {
        if (priceParams) {
            if (priceParams.toCurrencyCode === priceParams.fromCurrencyCode) {
                // same currency should not be called
                dispatch(invalidatePrice());
            } else {
                dispatch(fetchPrice(priceParams));
            }
        }
    }, [priceParams?.toCurrencyCode, priceParams?.fromCurrencyCode, priceParams?.fromAmount, priceParams?.toAmount]);

    useEffect(() => {
        if (rateParams) {
            if (rateParams.toCurrencyCode === rateParams.fromCurrencyCode) {
                // same currency should not be called
                dispatch(invalidateRate());
            } else {
                dispatch(fetchRate(rateParams));
            }
        }
    }, [rateParams?.fromCurrencyCode, rateParams?.toCurrencyCode]);

    // should refetch price/rate periodicaly based on PRICE_REFRESH_INTERVAL
    useInterval(() => {
        if (timer <= 0) {
            if (fromCurrencyCode && toCurrencyCode) {
                if (fromAmount && toAmount) {
                    // both from and to amount are non-empty, will use the field that was touched
                    if (touched['fromAmount']) {
                        dispatch(
                            updatePrice({
                                fromCurrencyCode,
                                toCurrencyCode,
                                fromAmount: fromAmount,
                            })
                        );
                    } else {
                        dispatch(
                            updatePrice({
                                fromCurrencyCode,
                                toCurrencyCode,
                                toAmount: toAmount,
                            })
                        );
                    }
                } else if (fromAmount || toAmount) {
                    dispatch(
                        updatePrice({
                            fromCurrencyCode,
                            toCurrencyCode,
                            fromAmount: fromAmount,
                            toAmount: toAmount,
                        })
                    );
                } else {
                    dispatch(
                        updateRate({
                            fromCurrencyCode,
                            toCurrencyCode,
                        })
                    );
                }
            }
            setTimer(PRICE_REFRESH_INTERVAL);
        } else {
            setTimer(timer - 1);
        }
    }, 1000);

    useEffect(() => {
        if (getConfigValue('environment') === 'development' && Object.keys(errors).length) {
            // eslint-disable-next-line no-console
            console.warn('Form errors', errors);
        }
    }, [errors]);

    // Handles rate fetching on mount
    useEffect(() => {
        if (fromCurrencyCodePrefill && toCurrencyCodePrefill) {
            setFieldValue('fromCurrencyCode', fromCurrencyCodePrefill);
            onFromChange?.(fromCurrencyCodePrefill);
            setFieldValue('toCurrencyCode', toCurrencyCodePrefill);
            onToChange?.(toCurrencyCodePrefill);
        }
        if (fromAmountPrefill) {
            setFieldValue('fromAmount', fromAmountPrefill);
        } else if (toAmountPrefill) {
            setFieldValue('toAmount', toAmountPrefill);
        }
    }, []);

    /**
     * Handles change of from/to currencies and fetches price if it is having at least one amount and rate fetching if price not requested
     */
    useEffect(() => {
        if (fromCurrencyCode && toCurrencyCode) {
            if (fromCurrencyCode === toCurrencyCode) {
                dispatch(invalidateRate());
                dispatch(invalidatePrice());
                return;
            }

            const currenciesChanged = rateValue?.from !== fromCurrencyCode || rateValue?.to !== toCurrencyCode;

            if (fromAmount || toAmount) {
                if (
                    price.requestMeta?.toCurrencyCode !== toCurrencyCode ||
                    price.requestMeta.fromCurrencyCode !== fromCurrencyCode
                ) {
                    setPriceParams({
                        fromCurrencyCode,
                        toCurrencyCode,
                        fromAmount,
                        toAmount,
                    });
                }
            } else if (!rate || (!rate.isFetching && currenciesChanged)) {
                setRateParams({fromCurrencyCode, toCurrencyCode});
            }
        }

        showAddressOrBankAccount();
    }, [fromCurrencyCode, toCurrencyCode]);

    // Sets default currencies on the form which is received from rate reducer
    // It prefills currencies only if fromPrefill and toPrefill are empty, otherwise
    // specified currencies would be applied
    useEffect(() => {
        if (rateValue) {
            if (toCurrencyCode !== rateValue.to) {
                setFieldValue('toCurrencyCode', rateValue.to);
                if (typeof onToChange === 'function') {
                    onToChange(rateValue.to);
                }
            }

            if (fromCurrencyCode !== rateValue.from) {
                setFieldValue('fromCurrencyCode', rateValue.from);
                if (typeof onFromChange === 'function') {
                    onFromChange(rateValue.from);
                }
            }
            setTimer(PRICE_REFRESH_INTERVAL);
        }
    }, [rateValue]);

    /**
     * Handles price reducer changes.
     * Also it changes whether to show list of crypto/bank accounts depending on what type of "to" currency was chosen.
     */
    useEffect(() => {
        const priceValue = price?.response;

        if (priceValue) {
            if (price.requestMeta?.fromAmount) {
                // from amount was specified
                setFieldValue('toAmount', priceValue.price);
                showAddressOrBankAccount();
            } else {
                setFieldValue('fromAmount', priceValue.price);
                showAddressOrBankAccount();
            }

            setShowEmail(true);
            setTimer(PRICE_REFRESH_INTERVAL);
        } else if (!price.isFetching) {
            showAddressOrBankAccount();
        }
    }, [price]);

    /**
     * Show address or bank account depending on to currency type.
     */
    function showAddressOrBankAccount() {
        if (toCurrencyCode && currencies[toCurrencyCode]) {
            if (currencies[toCurrencyCode]?.type === 'crypto') {
                setShowWalletDropdown(true);
                setShowBankDropdown(false);
            } else {
                setShowWalletDropdown(false);
                setShowBankDropdown(true);
            }
        }
    }

    /**
     * Handles changes of from currency select.
     */
    const onFromCurrencyChange = useCallback((e) => {
        handleChange(e);
        handleBlur(e);
        setFieldTouched('toCurrencyCode', false); // make sure toCurrencyCode is not touched
        onFromChange?.(e.target.value);
        setFieldError('fromCurrencyCode', undefined);
    }, []);

    /**
     * Handles changes of from currency select.
     */
    const onToCurrencyChange = useCallback((e) => {
        handleChange(e);
        handleBlur(e);
        setFieldTouched('fromCurrencyCode', false); // make sure fromCurrencyCode is not touched

        // invalidate crypto/bank account
        setFieldValue('bankAccount', '');
        setFieldValue('cryptoAccount', '');
        onToChange?.(e.target.value);
        setFieldError('toCurrencyCode', undefined);
    }, []);

    /**
     * Handles from amount value change.
     */
    const onFromAmountChange = useCallback(
        (e) => {
            let {value} = e.target;
            value = normalizeAmount(value);
            e.target.value = value;
            handleChange(e);
            handleBlur(e);
            setFieldTouched('toAmount', false); // make sure toAmount is not touched
            setFieldValue('fromAmount', value);
            setFieldValue('toAmount', null);

            if (!fromCurrencyCode || !toCurrencyCode || !value) {
                return;
            }

            if (fromCurrencyCode === toCurrencyCode) {
                return;
            }

            if (typingTimeout !== undefined) {
                clearTimeout(typingTimeout);
            }

            typingTimeout = setTimeout(() => {
                if (value) {
                    setPriceParams({
                        fromCurrencyCode,
                        toCurrencyCode,
                        fromAmount: value,
                        toAmount: undefined,
                    });
                }
                // @ts-ignore
                clearTimeout(typingTimeout);
            }, 1000);
        },
        [fromCurrencyCode, toCurrencyCode, fromAmount]
    );

    /**
     * Handles to amount value change.
     */
    const onToAmountChange = useCallback(
        (e) => {
            let {value} = e.target;
            value = normalizeAmount(value);
            e.target.value = value;
            handleChange(e);
            handleBlur(e);
            setFieldTouched('fromAmount', false); // make sure toAmount is not touched
            setFieldValue('fromAmount', null);

            if (!fromCurrencyCode || !toCurrencyCode || !value) {
                return;
            }

            if (fromCurrencyCode === toCurrencyCode) {
                return;
            }

            if (typingTimeout !== undefined) {
                clearTimeout(typingTimeout);
            }

            typingTimeout = setTimeout(() => {
                if (value) {
                    setPriceParams({
                        fromCurrencyCode,
                        toCurrencyCode,
                        fromAmount: undefined,
                        toAmount: value,
                    });
                }

                // @ts-ignore
                clearTimeout(typingTimeout);
            }, 1000);
        },
        [fromCurrencyCode, toCurrencyCode]
    );

    /**
     * Triggers view swap state as well as switching fields such as currencies and amounts.
     */
    const swapCurrencies = useCallback(() => {
        onSwap?.();

        if (toCurrencyCode) {
            onFromChange?.(toCurrencyCode);
        }

        if (fromCurrencyCode) {
            onToChange?.(fromCurrencyCode);
        }

        setFieldValue('fromCurrencyCode', toCurrencyCode);
        setFieldValue('toCurrencyCode', fromCurrencyCode);
        setFieldValue('bankAccount', '');
        setFieldValue('cryptoAccount', '');

        if (toAmount && touched.toAmount) {
            setFieldValue('toAmount', '');
            setFieldValue('fromAmount', toAmount);
            setFieldTouched('fromAmount', true);
            setFieldTouched('toAmount', false);
        } else if (fromAmount && touched.fromAmount) {
            setFieldValue('fromAmount', '');
            setFieldValue('toAmount', fromAmount);
            setFieldTouched('toAmount', true);
            setFieldTouched('fromAmount', false);
        }
    }, [toCurrencyCode, fromCurrencyCode, toAmount, fromAmount, touched]);

    return (
        <form onSubmit={handleSubmit} style={rootStyles} name='exchange-form' className='Exchange-form-root'>
            {renderForm({
                formik: formik,
                onFromCurrencyChange,
                onFromAmountChange,
                swapCurrencies,
                onToCurrencyChange,
                onToAmountChange,
                rateResponse: rateValue,
                priceResponse: priceValue,
                rateOrPriceError,
                showCrypto,
                showBank,
                showEmail,
                reCaptchaRef,
                reCaptchaError,
                timer,
            })}
        </form>
    );
}

ExchangeContainer.displayName = 'ExchangeContainer';
