{
  "bank_account": "Bankov\u00fd \u00fa\u010det",
  "cancel": "Zru\u0161i\u0165",
  "crypto_wallet": "Krypto pe\u0148a\u017eenka",
  "exchange": "Zmen\u00e1re\u0148",
  "order_has_been_canceled": "Objedn\u00e1vka bola zru\u0161en\u00e1",
  "order_has_been_proceeded": "Objedn\u00e1vka bola odbaven\u00e1",
  "order_has_been_suspended": "Objedn\u00e1vka bola suspendovan\u00e1",
  "order_status_history": "Hist\u00f3ria stavov objedn\u00e1vky",
  "orders": "Objedn\u00e1vky",
  "payment_information": "Inform\u00e1cie o platbe pre pr\u00edjemcu",
  "proceed": "Odbavi\u0165",
  "rates": "Kurzy",
  "return": "Vr\u00e1ti\u0165",
  "setting_language_preference_text": "Zvo\u013ete si jazyk, v ktorom si prajete zobrazi\u0165 na\u0161e webov\u00e9 str\u00e1nky a prij\u00edma\u0165 emaily.",
  "setting_send_email_after_login": "Odosla\u0165 email po ka\u017edom prihl\u00e1sen\u00ed.",
  "suspend": "Suspendova\u0165",
  "wallet_updated_successfully": "Pe\u0148a\u017eenka bola aktualizovan\u00e1",
  "we_will_send_you_money_back_shortly": "Peniaze v\u00e1m vr\u00e1time za mal\u00fa chv\u00ed\u013eu",
  "guest_login_and_signup_order_separation": "alebo",
  "order_button_create_order": "Vytvori\u0165 objedn\u00e1vku",
  "guest_login": "Prihl\u00e1si\u0165 sa",
  "guest_signup": "Registr\u00e1cia",
  "menu_guest_link_about_us": "O n\u00e1s",
  "menu_guest_link_help": "Pomoc",
  "menu_guest_link_terms": "Podmienky",
  "order_form_caption": "Vytvorte si objedn\u00e1vku priamo tu",
  "order_form_email": "E-mail",
  "forgot_password": "Zabudli ste heslo?",
  "login_form_submit_button": "Prihl\u00e1si\u0165 sa",
  "page_login_title": "Prihl\u00e1si\u0165 sa do {{brandName}}",
  "page_signup_title": "Registr\u00e1cia",
  "signup_form_please_fill": "Pros\u00edm, vypl\u0148te nasleduj\u00face polia pre registr\u00e1ciu:",
  "signup_form_submit_button": "Zaregistrova\u0165",
  "order_form_placeholder_currency": "Mena",
  "refund_widget_add_new_refund_address": "Prida\u0165 nov\u00fa pe\u0148a\u017eenku",
  "refund_address_select_label": "Spiato\u010dn\u00e1 adresa",
  "copied": "Skop\u00edrovan\u00e9",
  "suspended_widget_return_money_back": "Vr\u00e1ti\u0165 sp\u00e4\u0165 peniaze",
  "suspended_widget_top_alert_message": "Objedn\u00e1vka je suspendovan\u00e1, \u010dak\u00e1 sa na rozhodnutie.",
  "add_order": "Vytvori\u0165 nov\u00fa objedn\u00e1vku",
  "language": "Jazyk",
  "logged_in": "Prihl\u00e1senie",
  "search": "Vyh\u013eada\u0165",
  "status_direction": "Smer stavu",
  "status_direction_description_cancelled": "Zru\u0161i\u0165 objedn\u00e1vku",
  "status_direction_description_proceed": "Odbavi\u0165 objedn\u00e1vku",
  "status_direction_description_return_to_client": "Posla\u0165 peniaze/coiny sp\u00e4\u0165",
  "status_direction_description_suspended": "Suspendova\u0165 objedn\u00e1vku",
  "status_final": "Kone\u010dn\u00fd stav",
  "status_name_cancelled": "Zru\u0161en\u00e1",
  "status_name_confirming_incoming": "Potvrdzovanie prijatia",
  "status_name_confirming_outgoing": "Potvrdzovanie odoslania",
  "status_name_delivered": "Doru\u010den\u00e1",
  "status_name_done": "Hotov\u00e1",
  "status_name_new": "Nov\u00e1",
  "status_name_proceed": "Odbavi\u0165",
  "status_name_received": "Prijat\u00e1",
  "status_name_return_to_client": "Vr\u00e1ti\u0165 klientovi",
  "status_name_returned": "Vr\u00e1ten\u00e1",
  "status_name_suspended": "Suspendovan\u00e1",
  "suspended_widget_table_change_percent": "Zmeni\u0165",
  "client_bank_account_list_add_new_account": "Prida\u0165 nov\u00fd \u00fa\u010det",
  "account_form_create_button": "Prida\u0165 bankov\u00fd \u00fa\u010det",
  "account_form_save_button": "Ulo\u017ei\u0165",
  "create_new_wallet_header": "Pridanie novej pe\u0148a\u017eenky",
  "delete_account": "Odstr\u00e1ni\u0165 \u00fa\u010det",
  "delete_wallet": "Odstr\u00e1ni\u0165 pe\u0148a\u017eenku",
  "update_account": "Aktualizova\u0165 \u00fa\u010det",
  "update_wallet": "Zmeni\u0165 pe\u0148a\u017eenku",
  "wallet_form_create_button": "Prida\u0165 pe\u0148a\u017eenku",
  "header_what_now": "A \u010do teraz?",
  "profile_page_header_my_documents": "Pripojen\u00e9 dokumenty",
  "profile_page_header_my_profile": "M\u00f4j profil",
  "profile_page_ready_for_verification_we_are_checking_your_profile": "V\u00e1\u0161 profil sa kontroluje.",
  "profile_page_save_button": "Ulo\u017ei\u0165",
  "profile_page_tab_level1": "\u00darove\u0148 1",
  "profile_page_tab_level2": "\u00darove\u0148 2",
  "profile_page_tab_this_level_is_accepted": "T\u00e1to \u00farove\u0148 je akceptovan\u00e1",
  "refund_address_set_successfully": "Adresa na vr\u00e1tenie bola \u00faspe\u0161ne nastaven\u00e1",
  "profile_level_checkbox_ready_for_verification_you_would_not_be_able_to_modify_your_information_it_sent_to_admin": "Po za\u0161krtnut\u00ed pol\u00ed\u010dka a odoslan\u00ed formul\u00e1ra nebude mo\u017en\u00e9 s\u00fabory zmeni\u0165 alebo odstr\u00e1ni\u0165. Inform\u00e1cie budu doslan\u00e9 ku kontrole na\u0161imi moder\u00e1tormi.",
  "client_sidebar_avatar_hash_description": "Pam\u00e4tajte si tento avatar. Bol vytvoren\u00fd v\u00fdhradne pre v\u00e1\u0161 \u00fa\u010det. V pr\u00edpade, \u017ee d\u00f4jde k jeho zmene, je pravdepodobn\u00e9, \u017ee sa niekto pok\u00fa\u0161a vyd\u00e1va\u0165 za n\u00e1s. Pros\u00edm, skontrolujte webov\u00fa adresu vo va\u0161om webovom prehliada\u010di, \u010di je adresa spr\u00e1vna s val\u00eddnym HTTPS certifik\u00e1tom.",
  "navigation_link_logout": "Odhl\u00e1si\u0165",
  "navigation_link_rates": "Kurzy",
  "company_about": "Simple Coin je eur\u00f3pska zmen\u00e1re\u0148 krypto mien so s\u00eddlom v srdci Eur\u00f3py - v Prahe, \u010ceskej Republike. Na\u0161\u00edm cie\u013eom je u\u013eah\u010di\u0165 pou\u017e\u00edvanie bitcoinu a in\u00fdch kryptomien. Simple Coin umo\u017e\u0148uje jednoduch\u00fd n\u00e1kup, predaj a odosielanie bitcoinu.",
  "sidebar_navigation_orders": "Objedn\u00e1vky",
  "sidebar_navigation_profile": "Profil",
  "sidebar_navigation_security": "Bezpe\u010dnos\u0165",
  "sidebar_navigation_settings": "Nastavenia",
  "company_header": "O spolo\u010dnosti {{brandName}}",
  "company_header_contacts": "Kontakty",
  "company_header_social": "Soci\u00e1lne",
  "company_subheader": "JEDNODUCH\u00c1 ZMEN\u00c1RE\u0147 KRYPTOMIEN",
  "company_social_group": "Skupina {{brandName}}",
  "company_header_billing_address": "Faktura\u010dn\u00e1 adresa",
  "page_header_company": "{{brandName}} - Zmen\u00e1re\u0148 Bitcoinu a kryptom\u011bn | BTC LTC ETH BCH XRP",
  "footer_sidebar_link_about": "O n\u00e1s",
  "client_order_reset_all_label": "Vynulova\u0165 v\u0161etko",
  "client_order_search_btn": "Preh\u013ead\u00e1va\u0165 objedn\u00e1vky",
  "transaction_accepted_by_banks_now_waiting_for_transaction_being_listed_on_bank_record": "Po\u017eiadavka na odoslanie pe\u0148az\u00ed bola prijat\u00e1 bankou. Teraz \u010dak\u00e1me na potvrdenie transakcie.",
  "error": "Chyba",
  "client_bank_accounts_widget_header": "Dokumenty k bankov\u00e9mu \u00fa\u010dtu",
  "new_return_to_client_waiting_for_transaction_to_be_matched_and_return_your_funds": "\u010cak\u00e1me, a\u017e sa transakcia sp\u00e1ruje. Transakciu okam\u017eite vr\u00e1time sp\u00e4\u0165, preto\u017ee jej objedn\u00e1vka je v stave \"vr\u00e1ti\u0165 klientovi\".",
  "page_subheader_settings_order_emails": "Objedn\u00e1vkov\u00e9 emaily",
  "page_subheader_settings_security_emails": "Bezpe\u010dnostn\u00e9 emaily",
  "client_setting_hint_send_email_when_order_confirming_incoming": "Odosla\u0165 email po sp\u00e1rovan\u00ed objedn\u00e1vky s prich\u00e1dzaj\u00facou krypto transakciou, ktor\u00e1 \u010dak\u00e1 na potvrdenie.",
  "client_setting_hint_send_email_when_order_confirming_outgoing": "Odosla\u0165 email po zadan\u00ed bankovej alebo krypto transakcie, ktor\u00e1 \u010dak\u00e1 na potvrdenie",
  "client_setting_hint_send_email_when_order_done": "Odosla\u0165 email, ke\u010f d\u00f4jde k zmene stavu objedn\u00e1vky na odbaven\u00e9.",
  "client_setting_hint_send_email_when_order_proceed": "Odosla\u0165 email, ke\u010f d\u00f4jde k zmene stavu objedn\u00e1vky na proceed.",
  "client_setting_hint_send_email_when_order_received": "Odosla\u0165 email po prijat\u00ed bankov\u00e9ho prevodu alebo po potvrden\u00ed prich\u00e1dzaj\u00facej krypto platby",
  "client_setting_hint_send_email_when_order_return_to_client": "Odosla\u0165 email po zmene stavu objedn\u00e1vky na \"vr\u00e1ti\u0165 klientovi\".",
  "client_setting_hint_send_email_when_order_suspended": "Odosla\u0165 email po zmene stavu objedn\u00e1vky na \"suspendovan\u00e1\".",
  "client_settings_label_order_confirming_incoming": "Potvrdzovanie prijatia",
  "client_settings_label_order_confirming_outgoing": "Potvrdzovanie odoslania",
  "client_settings_label_order_done": "Objedn\u00e1vka odbaven\u00e1",
  "client_settings_label_order_proceed": "Objedn\u00e1vka pripraven\u00e1 na odbavenie",
  "client_settings_label_order_received": "Objedn\u00e1vka prijat\u00e1",
  "client_settings_label_order_return_to_client": "Objedn\u00e1vku vr\u00e1ti\u0165 klientovi",
  "client_settings_label_order_suspended": "Objedn\u00e1vka suspendovan\u00e1",
  "page_subheader_settings_general": "V\u0161eobecn\u00e9",
  "profile_page_level1_my_document_subheader_paragraph": "In\u00fd dokument, ktor\u00fd m\u00f4\u017ee by\u0165 pou\u017eit\u00fd pre online identifik\u00e1ciu. Sem m\u00f4\u017eete prilo\u017ei\u0165 viacero dokumentov.",
  "profile_page_level2_my_document_subheader_paragraph": "Tu m\u00f4\u017eete prilo\u017ei\u0165 viacero s\u00faborov",
  "profile_page_level2_note_description": "V pr\u00edpade, \u017ee sa registrujete ako spolo\u010dnos\u0165, uve\u010fte, pros\u00edm, ak\u00e1 je va\u0161a poz\u00edcia, meno spolo\u010dnosti a registra\u010dn\u00e9 \u010d\u00edslo. \u017diadame v\u00e1s taktie\u017e o uvedenie ne\u0161tandardn\u00fdch okolnost\u00ed ako napr\u00edklad prij\u00edmanie platieb od tret\u00edch str\u00e1n, vytv\u00e1ranie objedn\u00e1vok v mene klientov a podobne.",
  "profile_page_level2_trading_purpose_description": "Pros\u00edm, uve\u010fte d\u00f4vod va\u0161ich obchodov. Sme n\u00faten\u00ed \u017eiada\u0165 o toto kv\u00f4li regul\u00e1ci\u00e1m v \u010ceskej Republike.",
  "profile_page_level2_trading_source_of_income_description": "Pros\u00edm, uve\u010fte p\u00f4vod prostriedkov, s ktor\u00fdmi obchodujete. Pre ka\u017ed\u00fa objedn\u00e1vku vo vysokej hodnote budeme po\u017eadova\u0165 dolo\u017eenie dokument\u00e1cie, ktor\u00e1 bude dokazova\u0165 va\u0161e stanovisko. V pr\u00edpade, \u017ee nie je mo\u017en\u00e9 dolo\u017ei\u0165 p\u00f4vod prostriedkov, uve\u010fte zretelne, \u017ee toto nie je mo\u017en\u00e9 dolo\u017ei\u0165.",
  "action_reset_password_error_email_not_sent": "Nebolo mo\u017en\u00e9 obnovi\u0165 heslo pre t\u00fato email adresu.",
  "action_reset_password_success": "Skontrolujte si svoj email pre \u010fal\u0161ie in\u0161trukcie.",
  "address_was_deleted": "Adresa bola odstr\u00e1nen\u00e1.",
  "bank_account_was_added": "Bankov\u00fd \u00fa\u010det bol pridan\u00fd.",
  "bank_account_was_deleted": "Bankov\u00fd \u00fa\u010det bol odstr\u00e1nen\u00fd.",
  "bank_account_was_updated": "Bankov\u00fd \u00fa\u010det bol aktualizovan\u00fd.",
  "change": "Zmeni\u0165",
  "change_password": "Zmeni\u0165 heslo",
  "delete": "Odstr\u00e1ni\u0165",
  "email_confirmed": "V\u00e1\u0161 email bol overen\u00fd.",
  "enter_email_address_to_recover_account": "Pros\u00edm, zadajte svoj email. Odkaz na reset hesla bude odoslan\u00fd na zadan\u00fd email.",
  "file_was_deleted": "S\u00fabor bol odstr\u00e1nen\u00fd",
  "information_successfully_saved": "Va\u0161e inform\u00e1cie boli \u00faspe\u0161ne ulo\u017een\u00e9.",
  "new_address_was_added": "Nov\u00e1 adresa bola pridan\u00e1.",
  "new_password_saved": "Nov\u00e9 heslo bolo ulo\u017een\u00e9.",
  "order_has_been_created_guest": "Objedn\u00e1vka bola vytvoren\u00e1. Pros\u00edm, skontrolujte si svoj email pre viac inform\u00e1ci\u00ed o va\u0161ej objedn\u00e1vke.",
  "pls_choose_new_password": "Pros\u00edm, zvo\u013ete si nov\u00e9 heslo:",
  "request_password_reset": "Po\u017eiada\u0165 o reset hesla",
  "reset_password": "Resetova\u0165 heslo",
  "save": "Ulo\u017ei\u0165",
  "security": "Bezpe\u010dnos\u0165",
  "send": "Odosla\u0165",
  "signup": "Zaregistrova\u0165 sa",
  "update": "Aktualizova\u0165",
  "are_you_sure_you_want_to_delete_this_file": "Naozaj si prajete odstr\u00e1ni\u0165 tento s\u00fabor?",
  "client_bank_account_message_warning_about_symbol": "N\u00e1\u0161 symbol bude pridan\u00fd do tejto spr\u00e1vy",
  "from_amount_currency_label": "Na mno\u017estvo",
  "to_amount_currency_label": "Na mno\u017estvo",
  "menu_guest_link_rates": "Kurzy",
  "is_automatic_proceed_on_duplicate_payment": "Automaticky odbavi\u0165 pri prijat\u00ed duplicitnej platby",
  "is_automatic_proceed_on_late_payment": "Automaticky odbavi\u0165 pri neskorej platbe",
  "setting_hint_is_automatic_proceed_on_duplicate_payment": "V pr\u00edpade prijatia duplicitnej platby (platba sp\u00e1rovan\u00e1 s objedn\u00e1vkou, ktor\u00e1 u\u017e bola uhraden\u00e1) syst\u00e9m automaticky vytvor\u00ed nov\u00fa objedn\u00e1vku. V predvolenom nastaven\u00ed bude objedn\u00e1vka suspendovan\u00e1 a \u010dakaj\u00faca na va\u0161e rozhodnutie. V pr\u00edpade aktiv\u00e1cie tejto vo\u013eby, novo vytvoren\u00e1 objedn\u00e1vka s aktu\u00e1lnym kurzom bude nastaven\u00e1 na stav \"odbavi\u0165\". T\u00fdmto sp\u00f4sobom m\u00f4\u017eete vykon\u00e1va\u0165 kurzov\u00e9 zmeny jednoduch\u00fdm odoslan\u00edm platby na n\u00e1\u0161 \u00fa\u010det bez nutnej interakcie s na\u0161ou web str\u00e1nkou.",
  "setting_hint_is_automatic_proceed_on_late_payment": "V pr\u00edpade, \u017ee k n\u00e1m platba doraz\u00ed neskoro, syst\u00e9m automaticky akceptuje nov\u00fd kurz a objedn\u00e1vku odbav\u00ed. V predvolenom nastaven\u00ed bude objedn\u00e1vka suspendovan\u00e1 a \u010dakaj\u00faca na va\u0161e rozhodnutie.",
  "no_camera_found": "Nena\u0161la sa \u017eiadna kamera alebo bol zamietnut\u00fd pr\u00edstup.",
  "scan_address": "Oskenova\u0165 QR k\u00f3d",
  "button_disable": "Deaktivova\u0165",
  "confirm": "Potvrdi\u0165",
  "header_disable_google_authenticator": "Deaktivova\u0165 2-faktorov\u00fa autentifik\u00e1ciu (2FA)",
  "header_enable_google_authenticator": "Povoli\u0165 2-faktorov\u00fa autentifik\u00e1ciu (2FA)",
  "label_2fa_password": "Heslo",
  "label_2fa_secret": "Tajn\u00fd re\u0165azec",
  "2fa_secret_description": "Pozn\u00e1mka: Tento k\u00f3d si bezpe\u010dne ulo\u017ete. Bude vy\u017eadovan\u00fd v pr\u00edpade obnovenia pr\u00edstupu k 2-faktorovej autentifik\u00e1cii, ak strat\u00edte svoj telef\u00f3n.",
  "btn_disable": "Deaktivova\u0165",
  "btn_enable": "Povoli\u0165",
  "two_factor_authentication": "2-faktorov\u00e1 autentifik\u00e1cia (2FA)",
  "fill_information_below_to_disable_two_factor_authentication": "Pre vypnutie 2-faktorovej autentifik\u00e1cie vyplnte nasleduj\u00face inform\u00e1cie:",
  "alert_success_email_link_was_resent_again": "Nov\u00fd aktiva\u010dn\u00fd email bol \u00faspe\u0161ne odoslan\u00fd.",
  "hint_login_form_2fa_code": "K\u00f3d nie je vy\u017eadovan\u00fd v pr\u00edpade, ze 2-faktorov\u00e1 autentifik\u00e1cia nie je aktivovan\u00e1 pre v\u00e1\u0161 \u00fa\u010det.",
  "footer_sidebar_link_terms": "Podmienky",
  "button_download_order_pdf": "Stiahnu\u0165 PDF",
  "button_hint_download_order_pdf": "Stiahnu\u0165 PDF s inform\u00e1ciami o objedn\u00e1vke",
  "alert_success_affiliate_payout_request_successfully_created": "\u017diados\u0165 na v\u00fdber \u00faspe\u0161ne prijat\u00e1.",
  "alert_success_you_have_become_affiliate": "Stali ste sa sprostredkovate\u013eom.",
  "button_create": "Vytvori\u0165",
  "button_save": "Ulo\u017ei\u0165",
  "page_affiliate_dashboard_copy_url_header": "Sprostredkovate\u013esk\u00e1 URL",
  "page_affiliate_dashboard_overview_header": "Preh\u013ead sprostredkovate\u013esk\u00e9ho \u00fa\u010dtu",
  "page_affiliate_dashboard_overview_paragraph": "Ni\u017e\u0161ie m\u00f4\u017eete vidie\u0165 zhrnutie v\u00e1\u0161ho sprostredkovate\u013esk\u00e9ho \u00fa\u010dtu.",
  "page_affiliate_dashboard_paragraph": "Na tejto str\u00e1nke m\u00f4\u017eete vidie\u0165 celkov\u00e9 zhrnutie v\u00e1\u0161ho sprostredkovate\u013esk\u00e9ho \u00fa\u010dtu, detailn\u00e9 \u0161tatistiky a vy\u017eiadan\u00e9 v\u00fdbery v \u013eubovo\u013enej mene podporovanej {{brandName}}.",
  "page_affiliate_statistics_button": "\u0160tatistiky",
  "page_affiliate_stats_first_paragraph": "Na tejto st\u00e1nke m\u00f4\u017eete vidie\u0165 \u0161tatistiky v\u00e1\u0161ho sprostredkovate\u013esk\u00e9ho \u00fa\u010dtu. Ko\u013ekokr\u00e1t bol vyu\u017eit\u00fd kliemtami a kedy.",
  "page_affiliate_stats_label_conversion": "Kliky na banner",
  "page_affiliate_stats_label_period": "D\u00e1tum",
  "page_affiliate_withdraw_button": "Vybra\u0165",
  "page_become_affiliate_first_paragraph": "V pr\u00edpade, \u017ee m\u00e1te z\u00e1ujem sta\u0165 sa na\u0161\u00edm sprostredkovate\u013eom, pros\u00edm, pred odoslan\u00edm formul\u00e1ra za\u0161krtnite pol\u00ed\u010dko ni\u017e\u0161ie pre potvrdenie s\u00fahlasu s podmienkami sprostredkovate\u013esk\u00e9ho programu.",
  "page_header_affiliate_dashboard": "Dashboard sprostredkovania",
  "page_header_become_affiliate": "Sta\u0165 sa sprostredkovate\u013eom",
  "sidebar_navigation_affiliate": "Sprostredkovanie",
  "label_agree_with_affiliate_conditions": "S\u00fahlas\u00edm s podmienkami sprostredkovate\u013esk\u00e9ho programu.",
  "page_affiliate_withdraw_request_paragraph": "Zvo\u013ete si bankov\u00fd \u00fa\u010det alebo adresu pre v\u00fdber prostriedkov z v\u00e1\u0161ho sprostredkovate\u013esk\u00e9ho \u00fa\u010dtu",
  "page_affiliate_finance_first_paragraph": "Na tejto str\u00e1nke vid\u00edte zoznam objedn\u00e1vok spojen\u00fdch s va\u0161\u00edm \u00fa\u010dtom a z\u00e1robok, ktor\u00fd ste obdr\u017eali pre ka\u017ed\u00fa z nich v eur\u00e1ch.",
  "page_affiliate_finance_label_order": "Objedn\u00e1vka",
  "page_affiliate_finance_button": "Pr\u00edjem",
  "page_affiliate_finance_label_profit_in_eur": "Zisk (EUR)",
  "page_affiliate_asset_banners_paragraph": "Vlo\u017ete interakt\u00edvne bannery zobrazen\u00e9 ni\u017e\u0161ie na va\u0161e webstr\u00e1nky. Skop\u00edrujte niektor\u00fd z k\u00f3dov ni\u017e\u0161ie a vlo\u017ete ho na miesto, kde chcete banner zobrazi\u0165.",
  "page_affiliate_assets_button": "Propaga\u010dn\u00e9 materi\u00e1ly",
  "api_order_unsupported_currency_pair": "Zmena nie je k mo\u017en\u00e1 pre tento menov\u00fd p\u00e1r",
  "footer_sidebar_link_data_processing": "GDPR",
  "alert_success_ticket_created": "Tiket bol \u00faspe\u0161ne vytvoren\u00fd.",
  "alert_success_ticket_reply_sent": "Odpove\u010f k tiketu bola \u00faspe\u0161ne odoslan\u00e1.",
  "button_send": "Odosla\u0165",
  "page_header_ticket_create": "Nov\u00fd tiket",
  "page_header_ticket_list": "Tikety",
  "page_header_ticket_reply": "Odpoveda\u0165 na tiket",
  "page_ticket_reply_you": "Vy",
  "sidebar_navigation_ticket": "Podpora",
  "alert_success_ticket_closed": "Tiket bol \u00faspe\u0161ne uzavret\u00fd.",
  "button_create_support_ticket": "Podpora",
  "button_hint_create_support_ticket": "V pr\u00edpade probl\u00e9mov alebo ot\u00e1zok k tejto objedn\u00e1vke m\u00f4\u017eete vytvori\u0165 tiket na podporu.",
  "page_tickets_pending_tickets": "\u010cakaj\u00face tikety",
  "page_tickets_solved_tickets": "Vyrie\u0161en\u00e9 tikety",
  "page_tickets_no_pending_tickets": "\u017diadne \u010dakaj\u00face alebo nov\u00e9 tikety.",
  "page_tickets_no_solved_tickets": "\u017diadne vyrie\u0161en\u00e9 tikety.",
  "take_selfie_button": "Odfoti\u0165 selfie",
  "no_camera_access_provided": "Nebol povolen\u00fd pr\u00edstup ku kamere. Pros\u00edm, skontrolujte pr\u00e1va.",
  "menu_guest_link_privacy_policy": "Z\u00e1sady ochrany osobn\u00fdch \u00fadajov",
  "change_password_social_reset_password_claim": "Pros\u00edm stla\u010dte tla\u010didlo ni\u017e\u0161ie. N\u00e1sledne v\u00e1m za\u0161leme email s linkom, ktor\u00fd v\u00e1m umo\u017en\u00ed zmeni\u0165 heslo.",
  "alert_change_password_email_was_sent": "Pros\u00edm skontrolujte si email, kde n\u00e1jdete link na nastavenie hesla.",
  "page_confirm_two_factor": "Potvr\u010fte dvojfaktorov\u00e9 overenie",
  "page_confirm_two_factor_paragraph": "Pros\u00edm zadajte k\u00f3d z va\u0161ej aplik\u00e1cie na dvojfaktorov\u00e9 overenie:",
  "cookie_consent_i_agree": "Rozumiem",
  "cookie_consent_message": "T\u00e1to str\u00e1nka pou\u017e\u00edva s\u00fabory cookie na pomoc pri navig\u00e1cii a va\u0161ej schopnosti poskytova\u0165 sp\u00e4tn\u00fa v\u00e4zbu, analyzova\u0165 va\u0161e pou\u017e\u00edvanie na\u0161ich produktov a slu\u017eieb. <0>Z\u00e1sady pou\u017e\u00edvania s\u00faborov cookie.</0>",
  "try_again_button": "Sk\u00fasi\u0165 znovu",
  "dropable_or_pastable_from_clipboard": "Pretiahnite v\u00e1\u0161 s\u00fabor alebo vlo\u017ete obr\u00e1zok zo schr\u00e1nky do tejto oblasti kl\u00e1vesovou skratkou CTRL-V",
  "hint_enter_two_factor_code_to_confirm_action": "Zadajte Google Authenticator k\u00f3d",
  "add_new_ticket": "Vytvori\u0165 nov\u00fd tiket",
  "close_ticket": "Ozna\u010di\u0165 ako vyrie\u0161en\u00e9",
  "client_setting_hint_send_email_limit_price_changed": "Odosla\u0165 email, ak sa zmen\u00ed cenov\u00fd limit mojej objedn\u00e1vky.",
  "client_settings_label_order_limit_price_changed": "Cenov\u00fd limit",
  "button_set_limit": "Nastavi\u0165 limit",
  "suspended_widget_header_limit_rate": "Limit kurzu",
  "suspended_widget_header_limit_rate_hint": "Nastaven\u00edm limitu kurzu umo\u017en\u00edte akceptovanie nov\u00e9ho kurzu automaticky, ak sa trhov\u00fd kurz zmeni vr\u00e1mci v\u00e1\u0161ho nastaven\u00e9ho limitu.",
  "suspended_widget_header_market_rate": "Trhov\u00fd kurz",
  "suspended_widget_header_market_rate_hint": "Trhov\u00fd kurz pre va\u0161u objedn\u00e1vku, ktor\u00fd v\u00e1m pon\u00fakame. V\u0161etky poplatky s\u00fa u\u017e zapo\u010d\u00edtan\u00e9. Jedn\u00e1 sa o kurz, ktor\u00fd je pre v\u00e1s kone\u010dn\u00fd.",
  "suspended_widget_header_order_rate": "Kurz objedn\u00e1vky",
  "suspended_widget_header_order_rate_hint": "Tento kurz sme pre v\u00e1s nastavili v momente vytvorenia objedn\u00e1vky. Obsahuje v\u0161etky poplatky.",
  "button_change_limit": "Zmeni\u0165",
  "button_limit_cancel": "Zru\u0161i\u0165",
  "suspended_widget_limit_form_paragraph": "Tieto dve polia reprezentuj\u00fa jednu hodnotu. Hodnota druh\u00e9ho po\u013ea je inverzia prv\u00e9ho. Hodnoty sa aktualizuj\u00fa automaticky. To v\u00e1m umo\u017e\u0148uje zada\u0165 po\u017eadovan\u00fd kurz sp\u00f4sobom, ak\u00fdm ste zvyknut\u00fd.",
  "hint_close_ticket_button": "Po kliknut\u00ed na toto tla\u010d\u00edtko bude ticket ozna\u010den\u00fd ako vyrie\u0161en\u00fd. \u010eal\u0161ia komunik\u00e1cia v tickete nebude mo\u017en\u00e1.",
  "heading_chart": "Graf",
  "button_close": "Zavrie\u0165",
  "boolean_option_no": "Nie",
  "boolean_option_yes": "\u00c1no",
  "generic_link_learn_more": "Dozvedie\u0165 sa viac",
  "profile_page_header_my_documents_level0_paragraph": "<strong>Pros\u00edm, prilo\u017ete dokumenty pre va\u0161u identifik\u00e1ciu</strong><br>\nVy\u017eadujeme skeny v dobrej kvalite (300dpi) alebo fotografie, ktor\u00e9 s\u00fa \u010ditate\u013en\u00e9, \u017eiadne \u010dasti dokumentu nem\u00f4\u017eu by\u0165 zakryt\u00e9 alebo upraven\u00e9.\n\"In\u00fd dokument\" m\u00f4\u017ee by\u0165 \u013eubovo\u013en\u00fd dokument dokl\u00e1daj\u00faci toto\u017enost fyzickej osoby, typ a \u010d\u00edslo dokumentu, vydavate\u013ea dokumentu, autoritu a platnos\u0165.",
  "profile_page_header_my_documents_level1_paragraph": "<strong>Pros\u00edm, prilo\u017ete nasleduj\u00face dokumenty:</strong><br>\n- \u013eubovo\u013en\u00fd dokument dokazuj\u00faci v\u00e1\u0161 zdroj pr\u00edjmov<br>\n- fotografiu v\u00e1s, ako dr\u017e\u00edte svoj OP<br>\n- dokumenty zalo\u017eenia va\u0161ej spolo\u010dnosti, ak s\u00fa k dispoz\u00edcii<br>\n- in\u00fd vy\u017eiadan\u00fd dokument",
  "chart_module_market_price_inverse": "Oto\u010di\u0165",
  "chart_module_no_data_available": "\u017diadne \u00fadaje",
  "bank_account_verified": "Overen\u00fd",
  "profile_page_bank_account_header": "Va\u0161e bankov\u00e9 \u00fa\u010dty",
  "profile_page_bank_accounts_paragraph": "Pre \u00faspe\u0161n\u00e9 overenie v\u00e1\u0161ho profilu cez Internet potrebujeme overi\u0165 aspo\u0148 jeden v\u00e1\u0161 bankov\u00fd \u00fa\u010det. V sekcii pr\u00edjemcovia, pros\u00edm, prilo\u017ete po\u017eadovan\u00e9 dokumenty pod\u013ea in\u0161trukci\u00ed a taktie\u017e, ak to bude vy\u017eadovan\u00e9, vykonajte overovac\u00ed bank. prevod.",
  "bank_account_file_upload_unverified_level0_paragraph": "Aby sme mohli dokon\u010di\u0165 Va\u0161e overenie na dia\u013eku (overenie profilu na \u00farove\u0148 1) budeme potrebova\u0165 dolo\u017ei\u0165 existenciu bankov\u00e9ho \u00fa\u010dtu na Va\u0161e meno.\nPros\u00edm, pripojte dokument vydan\u00fd bankou, na ktorom uvid\u00edme \u010dislo \u00fa\u010dtu a meno majite\u013ea. M\u00f4\u017ee to by\u0165 zmluva o veden\u00ed \u00fa\u010dtu alebo pravideln\u00fd v\u00fdpis. S\u00fabor PDF alebo sken papierov\u00e9ho dokumentu je vyhovuj\u00faci.",
  "guest_market_table": "Cenov\u00e1 tabu\u013eka",
  "validation_error_not_matching_passwords": "Hesl\u00e1 sa nezhoduj\u00fa",
  "react_send_from_amount_here": "Odo\u0161lite {{amount}} <0>{{currency}}</0> na adresu:",
  "react_we_received_your_payment_but_you_decided_to_get_it_we_will_send_it_shortly": "Prijali sme va\u0161u platbu, ale rozhodli ste sa, ze ju chcete vr\u00e1ti\u0165. O mal\u00fa chv\u00ed\u013eu v\u00e1m odo\u0161leme {{amount}} <0>{{currency}}</0>.",
  "react_we_received_currency_from_your_order_and_we_will_send_you_currency_to": "Prijali sme {{amountForm}} <0>{{currencyForm}}</0> k va\u0161ej objedn\u00e1vke a odo\u0161leme v\u00e1m {{amountTo}} <1>{{currencyTo}}</1>",
  "react_confirming_incoming_crypto_transaction_waiting_for_more_confirmations": "Zaznamenali sme prich\u00e1dzaj\u00facu krypto transakciu v hodnote {{amount}} <0>{{currency}}</0>. Moment\u00e1lne \u010dak\u00e1me pre potvrdenie transakcie.",
  "react_confirming_outgoing_crypto_transaction_waiting_for_more_confirmations": "Zadali sme krypto transakciu v hodnote {{amount}} <0>{{currency}}</0>. Moment\u00e1lne \u010dak\u00e1me pre potvrdenie transakcie.",
  "react_suspended_widget_accept_btn": "Prija\u0165 {{amount}} {{currency}}",
  "react_suspended_widget_return_btn": "Vr\u00e1ti\u0165 {{amount}} {{currency}}",
  "react_order_created_high_market_volatility_message": "Z d\u00f4vodu vysokej premenlivosti trhu sme schopn\u00ed dr\u017ea\u0165 tento kurz iba po dobu {{duration}} min\u00fat. R\u00fdchlos\u0165 va\u0161ej krypto transakcie z\u00e1vis\u00ed na \u0161pecifikovanej v\u00fd\u0161ke transak\u010dn\u00e9ho poplatku. Preto je d\u00f4le\u017eit\u00e9 zada\u0165 primeran\u00fa v\u00fd\u0161ku transak\u010dn\u00e9ho poplatku, aby bola transakcia overen\u00e1 \u010do najr\u00fdchlej\u0161ie. Ak va\u0161a platba doraz\u00ed po tejto dobe, a z\u00e1rove\u0148 sa kurz v\u00fdraznej\u0161ie zmen\u00ed, budeme v\u00e1s kontaktova\u0165 s nasleduj\u00facimi vo\u013ebami - prijatie nov\u00e9ho kurzu a pokra\u010dovanie v objedn\u00e1vke alebo zru\u0161enie objedn\u00e1vky a vr\u00e1tenie prostriedkov sp\u00e4\u0165 k V\u00e1m.",
  "react_order_suspend_over_limit_message": "Objedn\u00e1vka je suspendovan\u00e1 z d\u00f4vodu dosiahnutia {{limitDays}} dni limitu obchodovania. Pre pokra\u010dovanie je potrebn\u00e9 overi\u0165 v\u00e1\u0161 \u00fa\u010det na \u00farove\u0148 {{level}}.",
  "chart_timeframe_option_all": "V\u0160ETKO",
  "react_bank_account_file_upload_unverified_paragraph": "Taktie\u017e potrebujeme vidie\u0165 v\u00fdpis v\u00e1\u0161ho bank. \u00fa\u010dtu pre overenie vlastn\u00edka \u00fa\u010dtu. V pr\u00edpade, \u017ee budete vytv\u00e1ra\u0165 nov\u00fa objedn\u00e1vku, ktor\u00e1 bude hraden\u00e1 z tohto bank. \u00fa\u010dtu, nie je potreba robi\u0165 ni\u010d. Ak na tento bank. \u00fa\u010det pl\u00e1nujete platbu len prij\u00edma\u0165, odo\u0161lite n\u00e1m mal\u00fa platbu, napr. 0.1 EUR, na <0>niektor\u00fd z na\u0161ich \u00fa\u010dtov</0>.",
  "react_2fa_qr_code_description": "Pre zv\u00fd\u0161en\u00fa bezpe\u010dnos\u0165 odpor\u00fa\u010dame nastavenie 2-faktorovej autentifik\u00e1cie (2FA). Podporujeme Google Authenticator. Pre nastavenie, stiahnite <0>aplik\u00e1ciu Google Authenticator</0> do v\u00e1\u0161ho telef\u00f3nu, oskenujte QR k\u00f3d zobrazen\u00fd na tejto str\u00e1nke, zadajte va\u0161e heslo k va\u0161emu \u00fa\u010dtu a k\u00f3d z aplik\u00e1cie.",
  "chart_timeframe_option_day": "De\u0148",
  "chart_timeframe_option_month": "Mesiac",
  "chart_timeframe_option_week": "T\u00fd\u017ede\u0148",
  "chart_timeframe_option_year": "Rok",
  "chart_timeframe_option_year_to_date": "YTD",
  "react_profile_page_level1_paragraph_before_form": "Obchodovac\u00ed limit pre \u00farove\u0148 1 je {{limit_level_2}} EUR v priebehu {{tradeLimitDays}} dn\u00ed.",
  "react_profile_page_level2_paragraph_before_form": "\u00darove\u0148 2 umo\u017enuje na\u0161im klientom zobchodova\u0165 {{limit_level_2}} EUR v priebehu {{tradeLimitDays}} dn\u00ed. V ojedinel\u00fdch pr\u00edpadoch sa vy\u017eaduje dolo\u017eenie dodato\u010dn\u00fdch inform\u00e1ci\u00ed.",
  "react_page_header_posts_by_category": "Kateg\u00f3ria: {{name}}",
  "chart_timeframe_option_3months": "3 mesiace",
  "ticket_load_more": "Na\u010d\u00edta\u0165 viac",
  "ticket_type_your_message": "Nap\u00ed\u0161te spr\u00e1vu tu...",
  "no_results_found": "\u017diadne v\u00fdsledky.",
  "validation_field_required": "Toto pole je povinn\u00e9",
  "validation_min_characters": "Minim\u00e1lny po\u010det znakov pre toto pole je ${min}",
  "uploaded_file_information_complete": "Inform\u00e1cie o tomto dokumente s\u00fa kompletn\u00e9",
  "uploaded_file_click_to_fill_information": "Kliknite sem pre doplnenie \u010fal\u0161\u00edch inform\u00e1ci\u00ed",
  "react_order_header": "Objedn\u00e1vka {{id}}",
  "profile_page_header_my_personal_data": "Osobn\u00e9 inform\u00e1cie",
  "validation_error_incorrect_phone_number": "Nespr\u00e1vne tel. \u010d\u00edslo",
  "react_file_upload_extension_error": "Povolen\u00e9 s\u00fa iba nasleduj\u00face roz\u0161\u00edrenia: {{extensions}}",
  "qr_code_scan_success": "QR k\u00f3d bol \u00faspe\u0161ne naskenovan\u00fd",
  "upload_success": "Odosielanie \u00faspe\u0161ne dokon\u010den\u00e9",
  "button_open_form": "Otvori\u0165 formul\u00e1r",
  "client_bank_trade_volume_label": "Obchodovac\u00ed limit v\u00e1\u0161ho bank. \u00fa\u010dtu",
  "qr_payment_hint": "M\u00f4\u017eete vyu\u017ei\u0165 QR platbu. Oskenujte k\u00f3d ni\u017e\u0161ie pomocou va\u0161ej bankovej aplik\u00e1cie v telef\u00f3ne",
  "react_signup_to_get_trading_volume_or_personal_discount": "<0>Zaregistrujte sa</0>, za\u010dnite obchodovat a z\u00edskajte osobn\u00fa z\u013eavu.",
  "sidebar_user_traded_volume": "Objem za {{tradeLimitDays}} dn\u00ed: {{traded}}/{{limit}}EUR",
  "trading_limits_info_message": "Neoveren\u00fd limit obchodov: {{limitLevel1}} EUR/{{level1TradeLimitDays}} dn\u00ed <br/>\nLevel 1 limit obchodov: {{limitLevel2}} EUR/{{level2TradeLimitDays}} dn\u00ed <br/>\nLevel 2 limit obchodov: form\u00e1lne nelimitovan\u00fd",
  "sidebar_user_traded_volume_sum": "Objem obchodov: {{traded}} EUR",
  "react_refund_widget_already_chosen_refund_address": "N\u00e1vratov\u00e1 adresa bola zvolen\u00e1 {{refundAdress}}.",
  "react_we_received_your_crypto_payment_but_you_decided_to_get_it_we_will_send_it_shortly": "Prijali sme va\u0161e coiny, ale vy ste sa rozhodli, \u017ee ich chcete odosla\u0165 sp\u00e4\u0165. Pri vr\u00e1ten\u00ed je \u00fa\u010dtovan\u00fd poplatok. Na va\u0161u n\u00e1vratov\u00fa adresu sa v\u00e1m vr\u00e1ti {{amount}} <0>{{currency}}</0>.",
  "refund_widget_btn_change_refund_address": "Zmeni\u0165 n\u00e1vratov\u00fa adresu",
  "react_order_crypto_return_fee_info_message": "Pri vr\u00e1ten\u00ed je \u00fa\u010dtovan\u00fd poplatok. Na va\u0161u n\u00e1vratov\u00fa adresu sa v\u00e1m vr\u00e1ti\u00a0{{amount}} <0>{{currency}}</0>.",
  "react_suspended_widget_return_currency_btn": "Vr\u00e1ti\u0165 {{amount}} {{currency}} do va\u0161ej pe\u0148a\u017eenky",
  "we_will_send_you_crypto_back_shortly": "O mal\u00fa chv\u00ed\u013eu v\u00e1m odo\u0161leme sp\u00e4\u0165 va\u0161e coiny.",
  "button_remove_limit": "Odstr\u00e1ni\u0165 limit",
  "react_alert_limit_removed": "Limit bol \u00faspe\u0161ne odstr\u00e1nen\u00fd",
  "unconfirmed_email_please_confirm_or_resend": "Pros\u00edm, najprv potvr\u010fte svoju emailov\u00fa adresu alebo po\u017eiadajte o <0>op\u00e4tovn\u00e9 odoslanie aktiva\u010dn\u00e9ho emailu</0>.",
  "button_back": "Sp\u00e4\u0165",
  "button_next": "\u010eal\u0161\u00ed",
  "button_submit_profile": "Odosla\u0165 profil",
  "you_need_to_log_out_before_seeing_anonymous_order": "Pre zobrazenie anonymnej objedn\u00e1vky sa mus\u00edte najprv odhl\u00e1si\u0165",
  "react_form_rate_explenation": "Ako je va\u0161a cena vypo\u010d\u00edtan\u00e1?",
  "sign_up_to_return_guest_order": "Pre vr\u00e1tenie va\u0161ej objedn\u00e1vky sa, pros\u00edm, zaregistrujte a pridajte svoju pena\u017eenku.",
  "error_page_not_found": "Str\u00e1nka nebola n\u00e1jden\u00e1",
  "react_page_affiliate_withdraw_request_button": "Vybra\u0165 {{amount}} {{currency}}",
  "affiliate_withdraw_history_table_converted_amount": "Konvertovan\u00fd obnos",
  "react_page_affiliate_dashboard_copy_url_paragraph": "Skop\u00edrujte URL zobrazen\u00fa ni\u017e\u0161ie a zdie\u013eajte ju na va\u0161om blogu, soci\u00e1lnych sie\u0165ach alebo s va\u0161imi priate\u013emi. Ka\u017ed\u00fd, kto klikne na v\u00e1\u0161 odkaz a zaregistruje sa na na\u0161ich str\u00e1nkach alebo vytvor\u00ed objedn\u00e1vku v priebehu {{duration}} dn\u00ed, bu\u010fe pridan\u00fd k v\u00e1\u0161mu partnersk\u00e9mu \u00fa\u010dtu. Za ka\u017ed\u00fa zrealizovan\u00fa objedn\u00e1vku z\u00edskate prov\u00edziu.",
  "react_affiliaite_balance_information": "Zostatok: {{balance}}<br/>Celkov\u00fd zisk: {{totalProfit}}",
  "react_affiliaite_balance_information_tooltip": "Zostatok je v\u00e1\u0161 aktu\u00e1lny obnos, ktor\u00fd si m\u00f4\u017eete vybra\u0165. Celkov\u00fd zisk zobrazuje v\u00e1\u0161 celkov\u00fd z\u00e1robok od doby vstupu do n\u00e1\u0161ho partnersk\u00e9ho programu.",
  "label_affiliate_overview_joined_at": "Pripojen\u00fd",
  "label_repeat_password": "Opakova\u0165 heslo",
  "react_consent_to_tos": "Prehlasujem, \u017ee som si pre\u010d\u00edtal <0>Pravidl\u00e1 pou\u017e\u00edvania</0> a <1>Z\u00e1sady ochrany osobn\u00fdch \u00fadajov</1> spolo\u010dnosti {{companyName}} a s\u00fahlas\u00edm s nimi.",
  "label_delete_account": "Odstr\u00e1ni\u0165 \u00fa\u010det",
  "button_delete_account": "Odstr\u00e1ni\u0165 m\u00f4j \u00fa\u010det",
  "delete_account_warning_paragraph": "Ak budete pokra\u010dova\u0165, va\u0161e u\u017e\u00edvate\u013esk\u00e9 d\u00e1ta bud\u00fa natrvalo odstr\u00e1nen\u00e9. Je n\u00e1m \u013e\u00fato, \u017ee odch\u00e1dzate. M\u00f4\u017eete sa kedyko\u013evek znovu zaregistrova\u0165 s rovnak\u00fdm emailom, av\u0161ak v\u00e1\u0161 nov\u00fd \u00fa\u010det bude pr\u00e1zdny.",
  "account_delete_success": "V\u00e1\u0161 \u00fa\u010det bol odstr\u00e1nen\u00fd",
  "log_out_success": "Boli ste odhl\u00e1sen\u00fd.",
  "session_time_remaining_label": "Zost\u00e1vaj\u00faci \u010das rel\u00e1cie",
  "session_expire_in_label": "Va\u0161a rel\u00e1cia bude ukon\u010den\u00e1",
  "order_filter_label": "Filtrova\u0165 objedn\u00e1vky",
  "order_filter_button_all": "V\u0161etky",
  "to_currency_amount_field_label": "Chcem prija\u0165",
  "status_name_unfinished": "Nedokon\u010den\u00e9",
  "status_description_unfinished": "Objedn\u00e1vka zatia\u013e nedosiahla kone\u010dn\u00fd stav.",
  "filter_drawer_incomplete_filters_warning": "Pros\u00edm, zvo\u013ete aspo\u0148 jeden status z ka\u017edej kateg\u00f3rie",
  "help_pages_label": "Centrum pomoci",
  "react_file_upload_size_error": "S\u00fabor, ktor\u00fd sa sna\u017e\u00edte prilo\u017ei\u0165, je pr\u00edli\u0161 ve\u013ek\u00fd. Maxim\u00e1lna ve\u013ekos\u0165 s\u00faboru je {{size}}MB.",
  "alert_submit_previous_level_first": "Pros\u00edm, najprv odo\u0161lite \u017eiados\u0165 o verifik\u00e1ciu profilu o \u00farove\u0148 ni\u017e\u0161ie.",
  "wrong_vs_format_message": "Variabiln\u00fd symbol m\u00f4\u017ee obsahova\u0165 len \u010d\u00edsla (maxim\u00e1lne 10)",
  "wrong_ss_format_message": "\u0160pecifick\u00fd symbol m\u00f4\u017ee obsahova\u0165 len \u010d\u00edsla (maxim\u00e1lne 10)",
  "wrong_ks_format_message": "Kon\u0161tantn\u00fd symbol m\u00f4\u017ee obsahova\u0165 len \u010d\u00edsla (maxim\u00e1lne 4)",
  "button_extend_session": "Pred\u013a\u017ei\u0165 sedenie",
  "fiat_payment_recipient_name": "Meno pr\u00edjemcu",
  "fiat_payment_recipient_street": "Adresa pr\u00edjemcu",
  "fiat_payment_recipient_city": "Mesto pr\u00edjemcu",
  "fiat_payment_recipient_country": "Krajina pr\u00edjemcu",
  "react_you_may_send_money_on_one_of_the_following_bank_accounts_singular": "Odosla\u0165 {{amount}} <0>{{currency}}</0> na nasleduj\u00faci bank. \u00fa\u010det",
  "crypto_qr_hint": "Pre otvorenie pe\u0148a\u017eenky na va\u0161om zariaden\u00ed m\u00f4\u017eete oskenova\u0165 k\u00f3d ni\u017e\u0161ie alebo na neho klikn\u00fa\u0165.",
  "react_validation_error_insufficient_funds": "Nedostatok prostriedkov. Na \u00fa\u010dte m\u00e1te len {{amount}} {{currencyName}}.",
  "client_settings_label_affiliate_new_income": "Prip\u00edsan\u00e9 nov\u00e9 prostriedky",
  "client_setting_hint_affiliate_new_income": "Odosla\u0165 email po prip\u00edsan\u00ed prov\u00edzie na m\u00f4j partnersk\u00fd \u00fa\u010det",
  "page_subheader_settings_affiliate": "Partnersk\u00fd \u00fa\u010det",
  "check_address_on_trezor_header": "Skontrolujte adresu na Trezore",
  "check_address_on_trezor_body": "A kliknite na tla\u010d\u00edtko pokra\u010dova\u0165",
  "show_address_on_trezor_button_label": "Uk\u00e1za\u0165 adresu na Trezore",
  "send_by_trezor_button": "Posla\u0165 z Trezoru",
  "send_by_trezor_description_tooltip": "M\u00f4\u017eete vyu\u017ei\u0165 t\u00fato funkciu pre bezpe\u010dn\u00e9 zaslanie platby z pripojen\u00e9ho zariadenia Trezor.",
  "trezor_address_import_dialog_header": "N\u00e1sleduj\u00face adresy patria k vybran\u00e9mu \u00fa\u010dtu Trezoru. Pros\u00edm, vyberte si jednu.",
  "import_from_trezor_button": "Prida\u0165 z Trezoru",
  "address_used": "pou\u017eit\u00e9",
  "trezor_transaction_sent_dialog_title": "Transakcia bola \u00faspe\u0161ne odoslan\u00e1.",
  "trezor_transaction_sent_dialog_content": "ID transakcie je: {{txid}} Pros\u00edm, chv\u00ed\u013eu po\u010dkajte k\u00fdm sa transakcia sp\u00e1ruje s objedn\u00e1vkou. M\u00f4\u017eete pou\u017ei\u0165 ikonu obnovenia pre znovu na\u010d\u00edtanie objedn\u00e1vky.",
  "trezor_address_import_available_for_currencies": "Importovanie adries z Trezoru je mo\u017en\u00e9 pre: {{currencies}}",
  "trezor_link_learn_more": "Zistite viac o pou\u017e\u00edvan\u00ed Trezoru v {{brandName}}",
  "page_refresh_button_label": "Obnovi\u0165",
  "profile_level_one_send_transfer_instead_of_document_upload": "Namiesto prilo\u017eenia dokumentu odo\u0161lem platbu z bank. \u00fa\u010dtu veden\u00e9ho na moje meno.",
  "profile_level1_primary_document_front_label": "Predn\u00e1 strana prim\u00e1rneho dokumentu",
  "profile_level1_primary_document_reverse_label": "Zadn\u00e1 strana prim\u00e1rneho dokumentu",
  "profile_level1_secondary_document_front_label": "Predn\u00e1 strana sekund\u00e1rneho dokumentu",
  "profile_level1_secondary_document_reverse_label": "Zadn\u00e1 strana sekund\u00e1rneho dokumentu",
  "profile_page_level1_primary_document_label": "Prim\u00e1rny dokument",
  "profile_page_level1_secondary_document_label": "Sekund\u00e1rny dokument",
  "profile_page_level1_additional_documents_label": "Dodato\u010dn\u00e9 dokumenty",
  "profile_page_level1_document_passport": "Pas",
  "profile_page_level1_document_national_id": "Ob\u010diansky preukaz",
  "profile_page_level1_document_other": "In\u00fd dokument",
  "profile_page_level1_document_driving_license": "Vodi\u010dsk\u00fd preukaz",
  "profile_page_level1_primary_document_type_select_information": "Zvo\u013ete prim\u00e1rny typ dokumentu",
  "profile_page_level1_secondary_document_type_select_information": "Zvo\u013ete v\u00e1\u0161 sekund\u00e1rny typ dokumentu",
  "profile_page_level1_additional_document_type_select_information": "Prida\u0165 dodato\u010dn\u00fd dokument",
  "profile_page_level1_additional_document_skip_label": "Nem\u00e1m \u017eiadne in\u00e9 dokumenty na nahratie",
  "address_format_bech32_wallet_support_warning": "T\u00e1to adresa je vo form\u00e1te bech32, ktor\u00fd nie je plno podporovan\u00fd pe\u0148a\u017eenkou Trezor. Pre pln\u00fa funkcionalitu, pros\u00edm, zvo\u013ete \"Segwit \u00fa\u010dty\" alebo \"Star\u0161ie \u00fa\u010dty\". Adresu v bech32 form\u00e1te m\u00f4\u017eete pou\u017ei\u0165 v pe\u0148a\u017eenke electrum.org",
  "label_document_title": "N\u00e1zov dokumentu",
  "react_new_app_version_available_dialog_title": "Je k dispoz\u00edcii nov\u00e1 verzia {{brandName}}",
  "update_application_version": "Aktualizova\u0165",
  "third_party_guest_order_provide_email_paragraph": "Pre pokra\u010dovanie pros\u00edm vypl\u0148te mailov\u00fa adresu.",
  "third_party_order_claim_email_verification_message": "Pros\u00edm, skontrolujte si svoj mail, kde n\u00e1jdete odkaz na potvrdenie.",
  "third_party_guest_order_confirm_or_resend_email": "Pros\u00edm, potvr\u010fte svoju emailov\u00fa adresu alebo kliknite na tla\u010didlo ni\u017e\u0161ie pre op\u00e4tovn\u00e9 odoslanie mailu.",
  "log_in_to_see_order_registered_client": "Pre zobrazenie objedn\u00e1vky sa prihl\u00e1ste",
  "client_recipients_list_header": "Pr\u00edjemcovia",
  "sidebar_navigation_recipients": "Pr\u00edjemcovia",
  "recipients_filter_label": "Filtrova\u0165 pr\u00edjemcov",
  "client_add_new_recipient_button": "Prida\u0165 pr\u00edjemcu",
  "reset_filters_button": "Resetova\u0165",
  "create_new_recipient_header": "Prida\u0165 pr\u00edjemcu",
  "disable_social_login_label": "Odpoji\u0165 \u00fa\u010det soci\u00e1lnej siete",
  "btn_unlink": "Odpoji\u0165",
  "alert_error_please_set_password_to_disable_social_login": "Pre odpojenie soci\u00e1lneho \u00fa\u010dtu si, pros\u00edm, najprv nastavte heslo pre v\u00e1\u0161 Simplecoin \u00fa\u010det.",
  "disable_social_login_warning_paragraph": "Ak budete pokra\u010dovat, prihlasovanie pomocou soci\u00e1lneho \u00fa\u010dtu bude odpojen\u00e9. Budete sa m\u00f4c\u0165 prihl\u00e1si\u0165 len s va\u0161\u00edm {{brandName}} u\u017e\u00edvate\u013esk\u00fdm menom a heslom.",
  "social_login_disable_success": "Prihl\u00e1senie pomocou soci\u00e1lneho \u00fa\u010dtu bolo \u00faspe\u0161ne deaktivovan\u00e9",
  "file_attached_to_ticket_auto_message": "S\u00fabor bol pripojen\u00fd: {{fileNames}}",
  "network_error_warning": "Internetov\u00e9 pripojenie nedostupn\u00e9",
  "password_strength_very_weak": "Ve\u013emi slab\u00e9 heslo",
  "password_strength_weak": "Slab\u00e9 heslo",
  "password_strength_good": "Prijate\u013en\u00e9 heslo",
  "password_strength_great": "Siln\u00e9 heslo",
  "status_description_on_hold": "Objedn\u00e1vka je zadr\u017ean\u00e1",
  "status_direction_description_on_hold": "Objedn\u00e1vka je zadr\u017ean\u00e1",
  "exchange_form_fee_included_tooltip_title": "Kurz, ktor\u00fd vid\u00edte, je vr\u00e1tane transak\u010dn\u00fdch poplatkov.",
  "exchange_form_fee_not_included_tooltip_title": "Ni\u017e\u0161ie uveden\u00e9 poplatky nie s\u00fa zahrnut\u00e9 v kurze. Pre ich zapo\u010d\u00edtanie zadajte mno\u017estvo.",
  "exchange_form_total_fee_eur": "Poplatok celkom: ~{{totalFeeEur}} EUR",
  "exchange_form_fee_tooltip_fee_in": "Poplatok za prich\u00e1dzaj\u00facu transakciu: {{amount}} {{currency}}",
  "exchange_form_fee_tooltip_fee_out": "Poplatok za odch\u00e1dzaj\u00facu transakciu: {{amount}} {{currency}}",
  "exchange_form_fee_label_no_fee": "Bez poplatku",
  "exchange_form_fee_label_no_fee_explanation": "T\u00e1to zmena nem\u00e1 \u017eiaden transak\u010dn\u00fd poplatok.",
  "exchange_form_fee_label_included": "Vr\u00e1tane transak\u010dn\u00e9ho poplatku.",
  "exchange_form_fee_label_excluded": "transak\u010dn\u00fd poplatok nie je zahrnut\u00fd",
  "order_view_back_to_vendor_button_label": "\u00cds\u0165 sp\u00e4\u0165 na {{providerName}}",
  "table_sort_label": "Zoradi\u0165",
  "pagination_next_page": "\u010eal\u0161ia str\u00e1nka",
  "pagination_previous_page": "Predch\u00e1dzaj\u00faca str\u00e1nka",
  "pagination_rows_per_page": "Riadkov na str\u00e1nku",
  "pagination_display_rows": "z",
  "button_download_csv": "Stiahnu\u0165 CSV",
  "button_print": "Tla\u010di\u0165",
  "table_view_columns": "Zobrazi\u0165 st\u013apce",
  "table_filter": "Filtrova\u0165 tabu\u013eku",
  "table_filters_label": "Filtre",
  "show_columns_label": "Zobrazi\u0165 st\u013apce",
  "table_rows_selected": "vybran\u00fd riadok",
  "add_account": "Prida\u0165 \u00fa\u010det",
  "account_balance": "Zostatok",
  "account_minimal_balance_label": "Minim\u00e1lny zostatok",
  "page_header_accounts": "\u00da\u010dty",
  "sidebar_navigation_accounts": "\u00da\u010dty",
  "sidebar_navigation_trade": "Obchodova\u0165",
  "landing_page_chart_widget_buy_button": "K\u00fapi\u0165",
  "exchange_form_from_amount_label": "Za\u0161lete",
  "exchange_form_to_amount_label": "Dostanete",
  "footer_trading_since": "Men\u00edme kryptomeny od roku 2013",
  "footer_headers_subscribe_to_newsletter": "Odoberajte na\u0161e novinky",
  "footer_rights_reserved": "\u00a9 {{year}} {{companyName}} V\u0161etky pr\u00e1va vyhraden\u00e9.",
  "landing_header": "Zmen\u00e1re\u0148 kryptomien s d\u00f4verou od roku 2013",
  "landing_subheader": "Jednoduch\u00e9 obchodovanie. Poskytujeme viac ne\u017e 30 menov\u00fdch p\u00e1rov.",
  "landing_button_join": "Chcem sa pripoji\u0165",
  "landing_button_about_us": "O n\u00e1s",
  "landing_recent_orders_header": "Posledn\u00e9 objedn\u00e1vky",
  "landing_statistics_30_day_order_count_label": "Po\u010det zmien preveden\u00fdch v posledn\u00fdch 30 d\u0148och",
  "landing_statistics_verified_clients_count": "Overen\u00fdch klient\u016f",
  "landing_statistics_volume_in_currency": "30 denn\u00fd objem v {{currency}}",
  "landing_header_help": "Pomoc",
  "button_buy_now": "K\u00fapi\u0165 teraz",
  "footer_header_support": "Podpora",
  "footer_link_help": "Pomoc",
  "footer_header_company": "Spolo\u010dnos\u0165",
  "footer_header_features": "Funkcie",
  "footer_features_trezor_support": "Podpora Trezoru",
  "footer_features_limit_order": "Limitn\u00e1 objedn\u00e1vka",
  "footer_email_newsletter_form_text": "Nenechajte si ni\u010d ujs\u0165! Prihl\u00e1ste sa k odberu newsletteru od {{brandName}}",
  "landing_email_newsletter_subscribe_button": "Odobera\u0165",
  "landing_email_newsletter_subscribe_field_placeholder": "Tu vlo\u017ete svoju emailov\u00fa adresu",
  "landing_email_subscribe_consent_warning": "Vlo\u017een\u00edm emailu s\u00fahlas\u00edte s obchodn\u00fdmi podmienkami a spracovan\u00edm osobn\u00fdch \u00fadajov",
  "footer_link_transaction_fees": "Poplatky za transakciu",
  "footer_link_trade_limits": "Limity zmien",
  "add_the_following_comment_to_payment": "Pridajte nasleduj\u00facu spr\u00e1vu k v\u00e1\u0161mu pr\u00edkazu na prevod",
  "alert_error_please_set_password_to_enable_two_factor": "Najprv pros\u00edm nastavte heslo pre zapnutie dvojfaktorov\u00e9ho overenia.",
  "alert_success_google_authenticator_disabled_successfully": "2-faktorov\u00e1 autentifik\u00e1cia bola \u00faspe\u0161ne deaktivovan\u00e1.",
  "alert_success_google_authenticator_enabled_successfully": "2-faktorov\u00e1 autentifik\u00e1cia bola \u00faspe\u0161ne povolen\u00e1.",
  "confirmed": "Potvrden\u00e9",
  "created_at": "Vytvoren\u00e9",
  "enter_form_email_placeholder": "Email",
  "enter_form_password_placeholder": "Heslo",
  "fiat_payments_without_note_cause_delay_warning": "V\u0161etky platby bez tohto textu bude nutn\u00e9 manu\u00e1lne skontrolova\u0165, \u010do m\u00f4\u017ee sp\u00f4sobi\u0165 pred\u013a\u017eenie odbavenia objedn\u00e1vky.",
  "form_label_new_password": "Nov\u00e9 heslo",
  "incoming_bank_transaction": "Prich\u00e1dzaj\u00faca bankov\u00e1 transakcia",
  "incoming_crypto_transaction": "Prich\u00e1dzaj\u00faca krypto transakcia",
  "label_2fa_code": "K\u00f3d 2FA",
  "label_account_constant": "Kon\u0161tantn\u00fd symbol",
  "label_account_label": "Menovka",
  "label_account_number": "\u010c\u00edslo \u00fa\u010dtu",
  "label_account_specific": "\u0160pecifick\u00fd symbol",
  "label_account_vs": "Variabiln\u00fd symbol",
  "label_address": "Adresa",
  "label_address_label": "Menovka",
  "label_addresses": "Adresa",
  "label_affiliate_overview_affiliate_id": "ID sprostredkovate\u013ea",
  "label_affiliate_overview_estimated_profit": "Nedokon\u010den\u00e9 objedn\u00e1vky",
  "label_affiliate_overview_referrals_count": "Pozvan\u00ed klienti",
  "label_affiliate_overview_url_hits_count": "Kliky na banner",
  "label_amount": "Mno\u017estvo",
  "label_arbitrary_data": "Destination Tag",
  "label_bank_account": "Bankov\u00fd \u00fa\u010det",
  "label_bank_account_id": "Bankov\u00fd \u00fa\u010det",
  "label_bank_accounts": "Bankov\u00e9 \u00fa\u010dty",
  "label_birthday": "D\u00e1tum narodenia",
  "label_city": "Mesto",
  "label_confirmations": "Potvrdenie",
  "label_country": "Krajina",
  "label_created_at": "Vytvoren\u00e9",
  "label_currency": "Mena",
  "label_current_password": "Aktu\u00e1lne heslo",
  "label_document_number": "\u010c\u00edslo dokumentu",
  "label_expires_at": "Koniec platnosti",
  "label_first_name": "Meno",
  "label_from_amount_currency": "Z",
  "label_hash": "Hash",
  "label_id": "ID",
  "label_in_block_id": "ID bloku",
  "label_is_politician": "Politicky exponovan\u00e1 osoba",
  "label_issued_by": "Vydan\u00e9",
  "label_issued_by_country": "Vydan\u00e9 krajinou",
  "label_last_name": "Priezvisko",
  "label_limit_price": "Cenov\u00fd limit",
  "label_message": "Spr\u00e1va pre pr\u00edjemcu",
  "label_monthly_volume": "Mesa\u010dn\u00fd objem",
  "label_new_password_again": "Nov\u00e9 heslo znova",
  "label_note": "Pozn\u00e1mka",
  "label_other_account_number": "Bankov\u00fd \u00fa\u010det",
  "label_our_bank_account_id": "Bankov\u00e9 \u010d\u00edslo \u00fa\u010dtu {{brandName}}",
  "label_owner_address": "Adresa majite\u013ea",
  "label_owner_city": "Mesto majite\u013ea",
  "label_owner_country": "Krajina majite\u013ea",
  "label_owner_name": "Meno majite\u013ea",
  "label_paid_at": "Vyplaten\u00e9",
  "label_personal_id": "Rodn\u00e9 \u010d\u00edslo",
  "label_phone": "Telef\u00f3n",
  "label_rate": "V\u00fdmenn\u00fd kurz pre objedn\u00e1vku",
  "label_ready_for_verification": "Pripraven\u00fd na overenie",
  "label_receive_address": "Prij\u00edmacia adresa",
  "label_status": "Stav",
  "label_status_direction": "Smer stavu",
  "label_status_final": "Kone\u010dn\u00fd stav",
  "label_street": "Ulica",
  "label_ticket_body": "Popis po\u017eiadavky",
  "label_ticket_category": "Kateg\u00f3ria",
  "label_ticket_title": "Predmet",
  "label_to_amount_currency": "Na",
  "label_trading_purpose": "D\u00f4vod obchodovania",
  "label_trading_source_of_income": "Zdroj pr\u00edjmu",
  "label_zipcode": "Po\u0161tov\u00e9 smerovacie \u010d\u00edslo",
  "mail_client_identification_bullet_point_fill_personal_information": "Vyplnte osobn\u00e9 inform\u00e1cie",
  "oops_something_went_wrong": "Hopla, nie\u010do sa pokazilo. Pros\u00edm, sk\u00faste to znova.",
  "order_id": "ID objedn\u00e1vky",
  "outgoing_bank_transaction": "Odch\u00e1dzaj\u00faca bankov\u00e1 transakcia",
  "outgoing_crypto_transaction": "Odch\u00e1dzaj\u00faca krypto transakcia",
  "page_header_settings": "Nastavenia",
  "password_was_changed": "Heslo bolo zmenen\u00e9",
  "settings_updated": "Nastavenia boli aktualizovan\u00e9",
  "social_login_with_facebook": "Prihl\u00e1si\u0165 sa \u00fa\u010dtom Facebook",
  "social_login_with_vkontakte": "Prihl\u00e1si\u0165 sa \u00fa\u010dtom VKontakte",
  "social_signin_with_google": "Prihl\u00e1ste sa \u00fa\u010dtom Google",
  "social_signin_with_linkedin": "Prihl\u00e1si\u0165 sa pomocou LinkedIn \u00fa\u010dtu",
  "status": "Stav",
  "status_description_cancelled": "Objedn\u00e1vka je zru\u0161en\u00e1.",
  "status_description_confirming_incoming": "Objedn\u00e1vka overuje nov\u00fa prich\u00e1dzaj\u00facu transakciu",
  "status_description_confirming_outgoing": "Objedn\u00e1vka overuje nov\u00fa odch\u00e1dzaj\u00facu transakciu",
  "status_description_delivered": "Objedn\u00e1vka je doru\u010den\u00e1",
  "status_description_done": "Objedn\u00e1vka je odbaven\u00e1",
  "status_description_new": "Objedn\u00e1vka je vytvoren\u00e1. Doposia\u013e sme k nej nezaznamenali \u017eiadnu prich\u00e1dzaj\u00facu platbu",
  "status_description_proceed": "Objedn\u00e1vka je pripraven\u00e1 na odbavenie za podmienok zobrazen\u00fdch v \u010dase vytvorenia.",
  "status_description_received": "Prijat\u00e1 bankov\u00e1/krypto transakcia pre objedn\u00e1vku, je mo\u017en\u00e9 pokra\u010dova\u0165",
  "status_description_return_to_client": "Objedn\u00e1vka je v stave vr\u00e1ti\u0165 klientovi",
  "status_description_returned": "Transakcia objedn\u00e1vky bola vr\u00e1ten\u00e1 klientovi",
  "status_description_suspended": "Objedn\u00e1vka je suspendovan\u00e1. Mo\u017en\u00e9 pr\u00ed\u010diny: prekro\u010den\u00e1 doba \u010dakania na platbu, mesa\u010dn\u00fd limit na obchodovanie bol prekro\u010den\u00fd, je vy\u017eadovan\u00e1 verifik\u00e1cia alebo ste objedn\u00e1vku suspendovali manu\u00e1lne",
  "ticket_category_affiliate": "Sprostredkovanie",
  "ticket_category_api": "API",
  "ticket_category_business_verification": "Overenie firmy",
  "ticket_category_order": "Objedn\u00e1vky",
  "ticket_category_other": "In\u00e9",
  "ticket_category_security": "Bezpe\u010dnos\u0165",
  "ticket_category_suggestion": "N\u00e1vrh",
  "ticket_category_verification": "Overenie",
  "validation_error_incorrect_email_format": "Nespr\u00e1vny form\u00e1t emailu",
  "you_have_accepted_current_suspended_deal": "Prijali ste nov\u00e9 podmienky pre suspendovan\u00fd obchod",
  "close": "Zavrie\u0165",
  "status_on_hold": "Zadr\u017ean\u00e1",
  "submit": "Odosla\u0165",
  "resend": "Posla\u0165 znovu",
  "reviews": "Nap\u00edsali o n\u00e1s",
  "label_counter_address": "Adresa proti\u00fa\u010dtu",
  "react_you_may_send_money_on_one_of_the_following_bank_accounts_plural": "Odosla\u0165 {{amount}} <0>{{currency}}</0> na niektor\u00fd z nasleduj\u00facich bank. \u00fa\u010dtov",
  "order_filter_button_new": "Nov\u00e9",
  "order_filter_button_in_progress": "Prebieha",
  "order_filter_button_finished": "Ukon\u010den\u00e9",
  "order_filter_button_custom": "Vlastn\u00e9",
  "client_order_status_base_label": "Stav",
  "client_order_status_direction_label": "Smer",
  "client_order_status_final_label": "Kone\u010dn\u00fd stav",
  "ticket_status_solved": "Vyrie\u0161en\u00fd",
  "ticket_status_in_progress": "Vybavuje sa",
  "ticket_status_waiting_for_admin": "\u010cak\u00e1 sa na odpove\u010f podpory",
  "ticket_status_waiting_for_client": "\u010cak\u00e1 sa na odpove\u010f klienta",
  "member_of": "\u010clen",
  "level_one_verification_warning_back_transfer_instead_of_statement": "Pre viac inform\u00e1ci\u00ed o overen\u00ed bankov\u00e9ho \u00fa\u010dtu, prejdite na <0>tento \u010dl\u00e1nok</0>",
  "label_bank_account_number": "\u010c\u00edslo \u00fa\u010dtu",
  "label_bank_account_details": "Inform\u00e1cie o bank. \u00fa\u010dte",
  "validation_max_characters": "Pole nesmie obsahova\u0165 viac ako ${max} znakov",
  "verification_required_if_order_made_warning_for_registered_clients": "Pre odbavenie tejto objedn\u00e1vky je potreba zv\u00fd\u0161i\u0165 va\u0161u \u00farove\u0148 overenia. Po \u00faspe\u0161nom overen\u00ed bude va\u0161a objedn\u00e1vka automaticky pozastaven\u00e1 a bude potreba akceptova\u0165 nov\u00fd zmenov\u00fd kurz. Pre viac inform\u00e1ci\u00ed o limitoch a \u00farovniach overen\u00ed nav\u0161t\u00edvte <0>t\u00fato str\u00e1nku<0>.",
  "verification_required_if_order_made_warning_for_guests": "Pre odbavenie tejto objedn\u00e1vky je potreba sa prihl\u00e1si\u0165 a zv\u00fd\u0161i\u0165 va\u0161u \u00farove\u0148 overenia. Po \u00faspe\u0161nom overen\u00ed bude va\u0161a objedn\u00e1vka automaticky pozastaven\u00e1 a bude potreba akceptova\u0165 nov\u00fd zmenov\u00fd kurz. Pre viac inform\u00e1ci\u00ed o limitoch a \u00farovniach overen\u00ed nav\u0161t\u00edvte <0>t\u00fato str\u00e1nku<0>.",
  "dialog_text_anonymous_trading_disabled_please_log_in_or_register": "Pre vytvorenie objedn\u00e1vky sa, pros\u00edm, prihl\u00e1ste alebo sa zaregistrujte",
  "get_verified_to_start_trading_warning_message": "Pre za\u010diatok obchodovania je potrebn\u00e9 zv\u00fd\u0161i\u0165 va\u0161u \u00farove\u0148 overenia. Va\u0161a aktu\u00e1lna \u00farove\u0148 overenia je <strong>\u00farove\u0148 {{currentVerificationLeve}}</strong> a potrebujete <strong>\u00farove\u0148 {{requiredVerificationLevel}}</strong>. V sekcii Profil n\u00e1jdete formul\u00e1r so \u017eiados\u0165ou o overenie.",
  "offer_will_expire_in": "T\u00e1to ponuka vypr\u0161\u00ed",
  "offer_has_expired": "Platnos\u0165 tejto ponuky vypr\u0161ala. Pros\u00edm, vytvorte nov\u00fa na str\u00e1nke n\u00e1\u0161ho partnera alebo priamo na <0>{{company}}</0>.",
  "order_offer_header": "{{company}} ponuka",
  "verification_required_for_order_creation_partner_order": "Pre dokon\u010denie va\u0161ej objedn\u00e1vky na {{brandName}} je potrebn\u00e9 dosiahn\u00fa\u0165 \u00farove\u0148 overenia {{level}}. Pros\u00edm, zaregistrujte sa a overte svoj profil pre pokra\u010dovanie.",
  "label_specify_destination_tag": "D\u00f4le\u017eit\u00e9: zadajte {{destinationTag}} ako destination tag.",
  "validation_error_required_characters_exactly": "Pole ${path} mus\u00ed obsahova\u0165 presne ${length} znakov",
  "csv_export_column_from_currency": "Na menu",
  "csv_export_column_to_currency": "Z meny",
  "react_order_crypto_no_fee_info_message": "T\u00e1to vo\u013eba je bez poplatku. Sp\u00e4tn\u00fa platbu dostanete v plnej v\u00fd\u0161ke.",
  "login_confirmation_form_check_email_paragraph": "Overovac\u00ed k\u00f3d bol odoslan\u00fd na v\u00e1\u0161 email. Pros\u00edm, skontrolujte si v\u00e1\u0161 email a vlo\u017ete k\u00f3d ni\u017e\u0161ie pre overenie prihl\u00e1senia.",
  "login_confirmation_title": "Overenie emailu",
  "login_confirmation_email_resent": "Potvrdzovac\u00ed email bol \u00faspe\u0161ne odoslan\u00fd",
  "login_confirmation_email_not_received": "Nedostali ste overovac\u00ed email?",
  "login_confirmation_code_input_placeholder": "6-miestny k\u00f3d",
  "lost_2fa_device_contact_support": "Stratili ste svoje zariadenie? <0>Kontaktujte podporu",
  "higher_verification_limit_required_warning": "Pre zobrazenie platob\u00fdch in\u0161trukci\u00ed je potrebn\u00e9 overi\u0165 v\u00e1\u0161 \u00fa\u010det na vy\u0161\u0161iu \u00farove\u0148",
  "verification_through_onfido_disclaimer": "Overenie toto\u017enosti bude vykonan\u00e1 splo\u010dnos\u0165ou Onfido.",
  "signup_and_verification_required_warning": "Pre pokra\u010dovanie odbavovania objedn\u00e1vky sa, pros\u00edm, <0>zaregistrujte</0> a overte svoj \u00fa\u010det.",
  "go_to_profile_button": "\u00cds\u0165 do profilu",
  "profile_page_divex_address_ownership_disclaimer_heading": "Prehl\u00e1senie o bezpe\u010dnosti pe\u0148a\u017eenky",
  "profile_page_divex_address_ownership_disclaimer_message": "Pros\u00edm, prilo\u017ete sken alebo fotografiu \u010destn\u00e9ho prehl\u00e1senia o vlastn\u00edctve, ktor\u00fdm preukazujete, \u017ee ste jedin\u00fd, kto m\u00e1 pr\u00edstup ku krypto adres\u00e1m, ktor\u00e9 budete pou\u017e\u00edva\u0165 vr\u00e1mci zmen\u00e1rne Divex.",
  "profile_page_divex_address_ownership_disclaimer_warning": "Je vy\u017eadovan\u00e9 prehl\u00e1senie, \u017ee v\u00e1\u0161 poradca nem\u00e1 pr\u00edstup do va\u0161ej krypto pe\u0148a\u017eenky, a t\u00fdm nem\u00e1 mo\u017enos\u0165 sa v bud\u00facnosti zmocni\u0165 va\u0161ich prostriedkov. </br> Pre viac inform\u00e1ci\u00ed o odpor\u00fa\u010danej bezpe\u010dnosti pena\u017eeniek, <0>kliknite sem</0>.",
  "signup_for_invited_only_divex_message": "Registr\u00e1cia ur\u010den\u00e1 iba pre klientov Divex zmen\u00e1rne. </br>\nChcete sa sta\u0165 klientom? Kontaktujte niektor\u00e9ho z na\u0161ich partnerov, ktor\u00ed v\u00e1m pom\u00f4\u017eu s cel\u00fdm registra\u010dn\u00fdm procesom.</br>\nZoznam na\u0161ich partnerov je k dispoz\u00edcii <0>tu</0>.",
  "divex_address_ownership_disclaimer_upload_label": "Prilo\u017eit dokument sem",
  "email_verification_token_expired_log_in_again": "This verification link is incorrect or has expired. Please log in again",
  "pagination_total_visible_rows": "{visibleCount} of {totalCount}",
  "phone_hint_include_prefix": "Please provide the number with prefix",
  "sidebar_navigation_transactions": "Transactions",
  "account_balance_formatted": "Balance: {{balanceWithCurrency}}",
  "transfer_sent_successfully": "Transfer sent successfully",
  "cookie_consent_i_agree_to_all": "Povoli\u0165 v\u0161etky",
  "cookie_consent_i_agree_to_required": "Povolenie iba funk\u010dn\u00fdch s\u00faborov cookie",
  "divex_affil_find_more": "Find out more about affiliate program in <0>this article.<0/>",
  "button_i_confirm": "I confirm",
  "wallet_ownership_declaration_paragraph_1": "I have been advised to use the Trezor hardware wallet for storage of digital assets and use it with PIN code.",
  "wallet_ownership_declaration_paragraph_2": "I am the owner of the master seed (12-24 words) that I have stored securely. I am aware that the cryptocurrencies in the wallet can be spent using the master seed, even without the device itself.",
  "wallet_ownership_declaration_paragraph_3": "The advisor who was helping me set up my wallet did not have access to my master seed and could not make a copy of it.",
  "page_header_exchanges": "Exchange currencies",
  "sidebar_navigation_dashboard": "Dashboard",
  "dashboard_header": "Dashboard",
  "page_header_transfers": "Transfers",
  "settings_button_general_settings": "General",
  "settings_button_security_settings": "Security",
  "settings_button_email_settings": "Rules for sending emails",
  "settings_button_profile_verification": "Profile verification",
  "action_activate_account_email_sent": "We have sent you an e-mail with link for activation of your password to the e-mail address.",
  "action_activate_account_error_email_not_sent": "The e-mail you have provided does not match account that may be activated this way. Please choose another e-mail.",
  "request_account_activation": "Account activation",
  "account_activation_enter_email_provided_to_support": "Activate your Divex account by entering the email you used to register for the Introduction to Bitcoin or Diverzo Academy course.",
  "account_activate_button": "Activate account",
  "account_activation_password_saved": "Your password has been saved. You can now log in.",
  "breadcrumb_category_order": "Order",
  "breadcrumb_subcategory_order_id": "Order #{{id}}",
  "page_header_general_settings": "General settings",
  "page_header_email_settings": "Email rules",
  "dashboard_order_table_last_orders": "Last orders",
  "dashboard_order_table_all_orders": "All orders",
  "user_discount": "Your discount",
  "get_discount": "Get discount",
  "volume_discount": "Volume discount",
  "personal_discount": "Personal discount",
  "ticket_status_all": "All",
  "id": "ID",
  "exchange_form_total_fee": "Total fees",
  "exchange_form_out_fee_crypto": "Outgoing transaction fee",
  "exchange_form_out_fee_fiat": "Outgoing bank transfer fee",
  "exchange_form_in_fee_crypto": "Incoming network fee",
  "exchange_form_in_fee_fiat": "Incoming bank transfer fee",
  "support": "Support",
  "tickets": "Tickets",
  "ticket": "Ticket",
  "message": "Message",
  "upload_file": "Upload file",
  "you": "You",
  "page_become_affiliate_partner_header": "Become Simplecoin <0>affiliate</0> partner",
  "page_become_affiliate_partner_bonus": "Recommend our exchange office to people and get a <strong>  commission of <br> up to {{affiliatePercent}}%</strong> of each exchange they make.",
  "page_become_affiliate_partner_involved": "I want to get involved",
  "page_become_affiliate_partner_information": "More information",
  "page_affiliate_dashboard_header": "Affiliate program",
  "page_affiliate_dashboard_current_balance_header": "Current balance",
  "page_affiliate_dashboard_current_balance_withdraw": "Withdraw balance",
  "order_related_support": "Support related to order",
  "page_affiliate_dashboard_total_profit": "Total profit",
  "page_become_affiliate_partner_copy_link_header": "Your affiliate link",
  "page_become_affiliate_partner_bonus_start": "Start of cooperation:",
  "page_become_affiliate_partner_bonus_tip": "<strong> Tip! </strong> Also use pre-made promotional banners in various sizes and motifs.",
  "page_become_affiliate_partner_promotional": "Show promotional materials",
  "document_issued_by_government": "Document issued by government",
  "document_back_side_scan": "Document back side scan",
  "document_front_side_scan": "Document front side scan",
  "document_type": "Document type",
  "scan_quality_warning": "Good quality scans (300dpi) or photos which are fully readable are required. No parts of any document can be edited nor removed.",
  " page_become_affiliate_partner_income_header": "Overview of income",
  "page_become_affiliate_partner_withdrawal_header": "Overview of withdrawal",
  "page_become_affiliate_partner_statistics_header": "Overwiew of statistics",
  "secondary_document": "Secondary document",
  "selfie_with_id": "Selfie with ID card",
  "affiliate_withdraw_breadcrumb": "Withdraw",
  "affiliate_withdraw_header": "Withdraw affiliate balance",
  "affiliate_withdraw_info_alert": "Your affiliate balance is <strong>{{balanceCurrencyEur}}</strong>. You may withdraw it in any of the supported currencies. You can see the converted amount after selecting the currency and recipient. ",
  "bank_account_create_form_header": "Add a new {{currencyCode}} bank account",
  "crypto_address_create_form_header": "Add a new {{currencyCode}} wallet",
  "settings_social_create_password_button": "Set password",
  "recipient_page_table_name": "Name",
  "recipient_page_table_address": "Address / Bank account no.",
  "recipient_page_table_currency": "Currency",
  "recipient_page_table_header": "List",
  "wallet_welcome_page": "You are about to go to <br /> Simplecoin Wallet",
  "wallet_welcome_page_condions": "I agree with the terms and <0>conditions</0> of Simplecoin Wallet",
  "wallet_welcome_page_button": "Continue to Simplecoin Wallet",
  "wallet_welcome_page_benefits_1": "Create a portfolio at Simplecoin",
  "wallet_welcome_page_benefits_2": "Easy deposit of funds",
  "wallet_welcome_page_benefits_3": "Trade cryptocurrencies easier and faster",
  "wallet_welcome_page_benefits_4": "More advantageous shift fees",
  "wallet_welcome_page_benefits_5": "Priority support",
  "verification_transaction": "Verification transaction",
  "verification_call_info": "As part of the verification process, we also need to verify your phone number, so we will call you to confirm that the verification was completed without any problems. You can choose the approximate time that is most convenient for you for the final phone call.",
  "verification_call": "Verification call",
  "when_should_we_call": "When should we call you",
  "asap": "As soon as possible",
  "email": "E-mail",
  "verification_and_limits": "Verifications and limits",
  "profile_verification": "Profile verification",
  "limits": "Limits",
  "start_with_verification": "Start with verification",
  "verification_in_progress": "Verification in progress",
  "level1": "Level 1",
  "done": "Done",
  "ok": "OK",
  "profile_verification_done_info": "We've emailed you the verification payment information in case you want to get back to them. We will let you know by email that you have been verified.",
  "sidebar_navigation_portfolios": "Porfolio",
  "page_dashboard_insert_deposit": "Deposit funds",
  "page_trade_head_title": "Trade",
  "transaction_tabs_title_exchange": "Exchange",
  "transaction_tabs_title_deposit": "Deposit",
  "transaction_tabs_title_withdraw": "Withdraw",
  "transaction_tabs_title_transfer": "Transfer",
  "label_summary": "Summary",
  "increase_limit": "Increase limit",
  "read_only": "Read-only",
  "page_portfolio_header": "Portfolio",
  "amount": "Amount",
  "purpose_of_crypto_trading": "Purpose of trading with cryptocurrencies",
  "what_is_purpose_of_crypto_trading": "What is your purpose of trading with cryptocurencies",
  "get_familiar_with_cryptocurrencies": "Get familiar with cryptocurencies",
  "occasional_buing_selling": "Occasional buying/selling",
  "one_off_investment": "One-off investment",
  "regular_savings": "Regular savings",
  "other": "Other",
  "speculations": "Speculations",
  "describe_your_purpose_of_cryptotrading": "Describe your purpose of trading with cryptocurrencies",
  "today": "today",
  "morning": "morning",
  "evening": "evening",
  "afternoon": "afternoon",
  "tomorrow": "tomorrow",
  "proof_of_income": "Proof of income",
  "proof_of_income_info": "The Anti-Money Laundering and Anti-Terrorism Financing Act (AML Act) requires to verify the source of your income if you want to trade with higher amounts. We therefore need to ask you some questions about the origin of the money you want to trade with us.",
  "income": "Income",
  "one-off_income": "one-off income",
  "monthly_income": "monthly income",
  "quarterly_income": "quarterly income",
  "yearly_income": "yearly income",
  "frequency": "Frequency",
  "source_of_income": "Source of income",
  "employment": "Employment",
  "position_in_company": "Position in company",
  "company_name": "Company name",
  "running_own_business": "Running own business",
  "rental": "Rental",
  "share_of_profit": "Share of profit",
  "pension": "Pension",
  "documents_supporting_the_income": "Documents supporting the income",
  "income_source_description": "Description",
  "add_income": "Add next income",
  "footer_sidebar_link_blog": "Blog",
  "proof_of_basic_income": "Proof of basic income",
  "proof_of_basic_income_info": "In order to set at least a basic trading and deposit limit, we need to know if you have at least a minimum income. There is no problem to increase this basic limit later if you want to trade \"at full throttle\". Even if you don't have that income, it is still OK. Later you will describe the source of the funds you want to trade with and we will set the limit accordingly.",
  "step": "Step",
  "basic_income_agreement": "I confirm that my income corresponds to at least 600 EUR per month",
  "semi-yearly_income": "semi-yearly income",
  "incomes": "Incomes",
  "income_state_submited": "awaits approval",
  "income_state_approved": "approved",
  "income_state_new": "new",
  "updating_incomes_info": "Any modification of your incomes (adding a new income or deleting an existing one) must be assessed by us and we will adjust your trading limit accordingly.",
  "submit_for_approval": "Submit for approval",
  "income_state_unapproved_change": "unapproved change",
  "prooved_incomes": "Prooved incomes",
  "verification_and_incomes": "Verification and incomes",
  "trading_limits": "Trading limits",
  "current_allowed_trading_amount": "Allowed amount for trading",
  "current_allowed_trading_amount_info": "This is the maximum amount you can right now deposit into Simplecoin Wallet or exchange in Simplecoin Exchange. This amount changes over time depending on how often you trade. It can gradually decrease to zero or increase back to your original limit. Only paid orders and confirmed deposits counts.",
  "individual_limits": "Individual limits",
  "time_period": "Time period",
  "limit_extension": "Limit extension",
  "remaining_limit_amount": "Remaining amount you can still draw down",
  "state_of_limit_execution": "State of limit execution",
  "in_time_period": "in",
  "individual_trading_limits_info": "If you have more individual limits, you must meet all of them. The one that is currently the most restrictive is always applied.",
  "trading_limit_extension_info": "If the standard limit is not enough for you, we can increase your limit even further by providing proof of one-off income. We will gradually reduce this limit whenever you trade above the standard limit.",
  "income_state_deleted": "deleted",
  "account_update_success": "Account was updated",
  "account_label_edit": "Edit account",
  "account_action_archive": "Archive account",
  "waiting_for_payment": "waiting for payment",
  "waiting_for_payment_approval": "waiting for payment approval",
  "verification_transaction_info": "For successful remote identification, you will need to verify at least one of your bank accounts (in your name). For this we only need to receive a single euro from this account.",
  "verification_transaction_received_info": "The verification transaction has been received. All we have to do is verify that it came from your bank account.",
  "verification_transaction_approved_info": "Your bank account has been verified. So we can skip this step.",
  "export_to_csv": "Export to CSV",
  "new_account_button": "Create new Account",
  "remove_recipient": "Remove recipient",
  "recipient_detail": "Recipient detail",
  "account_label": "Account label",
  "veriffied": "veriffied",
  "not_verified": "not verified",
  "label_create_trade": "Create trade",
  "remove_income": "Remove income",
  "transaction_detail_header": "Transaction detail",
  "transaction_type": "Type",
  "trade": "Trade",
  "withdraw_label_about": "You are about to withdraw",
  "withdraw_summary_fees": "Summary and fees",
  "Choose-destination-bank-account": "Choose destination bank account",
  "wallet_account_destination": "Chose destination {{currencyName}} address",
  "account_unique_name_label": "Provide a unique name of this account",
  "account_note_label": "You can add any additional note here",
  "account_detail_suspend_alert": "Your account is suspended. Please email us for support.",
  "current_balance_label": "Current balance",
  "balance_blocked_label": "Blocked balance",
  "label_not_choosen_account": "You do not have an account selected",
  "label_not_enough_money": "You have insufficient funds",
  "label_set_all_funds": "Set all funds",
  "label_available_balance": "Available balance",
  "label_withdraw": "Withdraw",
  "account_label_description": "Account description",
  "label_balance_after_transaction": "Balance after transaction",
  "label_fee": "Fee",
  "label_counter_party": "Counterparty",
  "label_operation_confirm_withdraw": "Confirm your withdrawal",
  "label_operation_confirm_transfer": "Confirm your transfer",
  "label_operation_confirm_exchange": "Confirm your exchange",
  "label_no_account_this_currency": "You do not have an account in this currency",
  "transaction_info_acc_head": "Amount and currency",
  "label_from_account": "From account",
  "label_target_account": "Target account",
  "exchanges_balances_alert_fiat": "Conversion between non-crypto is currently not possible",
  "account_create_success": "Account was created",
  "withdraw_label_requests": "Withdraw requests",
  "operations_label_dashboard_header": "Operations",
  "label_currency_general": "Base currency",
  "income_state_draft": "unverified",
  "label_client_currency_balance": "Balance in base currency",
  "label_show_all": "Show all",
  "dashboard_balances_last_transactions": "Latest transactions",
  "dashboard_balances_no_accounts": "You don't have an account yet",
  "dashboard_balances_no_withdrawal_requests": "No withdrawal requests",
  "dashboard_balances_no_transaction": "You don't have transactions yet",
  "general_settings_label_base_currency": "This currency will be used as default throughout the app",
  "general_settings_header_base_currency": "Base currency",
  "page_portfolio_closed_suspend_accounts_header": "Suspended and canceled Accounts",
  "confirm_withdraw_button": "Withdraw",
  "button_withdraw_continue_to_confirmation": "Continue",
  "recipient_name": "Recipient name",
  "button_create_transfer": "Create transfer",
  "button_transfer_continue_to_confirmation": "Continue",
  "button_exchange_continue_to_confirmation": "Continue",
  "total_balance": "Total balance",
  "no_trading_limit_extension": "There is no extension of the trading limit.",
  "deposit_component_automatic_create_acc_label": "You do not have an account at Simplecoin in this currency yet. We'll set it up for you automatically.",
  "deposit": "Deposit",
  "withdraw": "Withdraw",
  "create_default_account_label": "Default account for",
  "create_default_account_description": "Automatically created account when depositing funds",
  "create_default_account_button": "Create default {{currencyCode}} account",
  "dashboard_blog_posts_header": "Last blog posts",
  "dashboard_link_to_blog": "Blog",
  "credited_amount": "Credited amount",
  "exchange_rate": "Exchange rate",
  "accounting_time": "Accounting time",
  "date": "Date",
  "amounts": "Amounts",
  "success_order_header": "What's next? Pay for your order to complete.",
  "success_order_email": "We have sent you payment instructions by email <0>{{email}}</0>.",
  "finop_type_DEPOSIT": "deposit",
  "finop_type_EXCHANGE": "exchange",
  "finop_type_TRANSFER": "transfer",
  "finop_type_WITHDRAWAL": "withdraw",
  "exchange_made": "Exchange successful",
  "from_account": "From account",
  "to_account": "To account",
  "accounts": "Accounts",
  "portfolio_balance_summary_fiat_header": "Fiat:",
  "portfolio_balance_summary_crypto_header": "Cryptocurrency:",
  "order_success_check_crypto": "Please check if your wallet address is correct.",
  "success_order_label_id": "Your order number is <0>{{order_id}}</0> created. Thank you!",
  "exchange_form_crypto_address_field_label": "Cryptocurrency address",
  "order_success_check_bank": "Please check if the bank account is correct.",
  "other_currencies": "Other currencies",
  "no_funds_in_portfolio": "You have no funds in your portfolio now. Deposit fiat or crypto funds into your accounts and then you can start trading immediately.",
  "exchange_form_register": "for higher limits.",
  "list": "List",
  "withdrawal_request_status_new": "Pending",
  "withdrawal_request_status_confirming_outgoing": "Dispatched",
  "withdrawal_request_status_done": "Done",
  "withdrawal_request_status_canceled": "Cancelled",
  "landing_main_header": "<0>The biggest</0> cryptocurrency exchange in Czechia",
  "next_landing_subheader": "Exchanging cryptocurrencies <0>since 2013</0>.",
  "header_link_about_us": "About us",
  "header_link_get_started": "Get started",
  "header_link_blog": "Blog",
  "header_service_hours": "Po-Ne 9-21",
  "buy_now_button": "Buy now",
  "chart_header": "Buy and sell",
  "exchange_form_label": "Buy and sell",
  "how_it_works_paragraph_1_content": "It only takes a minute to place an order. Up to 200 \u20ac / 10 days, all we need is your email and cryptocurrency wallet address. From higher amounts you will need to register and get verified.",
  "how_it_works_paragraph_1_heading": "Order",
  "how_it_works_paragraph_2_heading": "Pay",
  "how_it_works_paragraph_3_heading": "Receive cryptocurrency",
  "how_it_works_paragraph_2_content": "Once your order is complete, we will provide you with payment instructions.",
  "how_it_works_paragraph_3_content": "Upon receiving of your payment, we will send the cryptocurrency to your wallet. We can usually do it within 30 minutes!",
  "landing_support_section_paragraph": "Need help with exchanging cryptocurrencies or using the Trezor hardware wallet? Contact us anytime via email, phone, or visit our client center for personalized assistance.",
  "landing_support_section_heading": "Genuine people, real customer support",
  "contact_us_button": "Contact",
  "landing_advantages_1_heading": "Simple and safe cryptocurrency trade",
  "landing_advantages_1_text": "In only takes a minute to create an order. And once the funds are credited to our account, we will manually verify the transaction and send you the cryptocurrency within 30 minutes on average.",
  "landing_advantages_2_heading": "Real people, human customer support",
  "landing_advantages_3_heading": "Biggest and oldest exchange in Czechia",
  "landing_advantages_2_text": "Need advice on exchanging cryptocurrencies? You can always write, call or send us a ticket. We handle most requests promptly.",
  "landing_advantages_3_text": "We have been exchanging cryptocurrencies since 2013. We have exchanged cryptocurrencies for more than 21 thousand people with a total value of over 8 billion CZK. We are one of the founding members of <0>\u010cKMA</0>.",
  "ckma_founder": "Founding member of",
  "newsletter_field_placeholder": "Your e-mail",
  "footer_sub_header_newsletter_subscription": "Sign up for news",
  "footer_link_career": "Career",
  "footer_link_affiliate_program": "Affiliate program",
  "footer_link_privacy_notice": "Privacy",
  "footer_link_terms_and_conditions": "Terms and conditions",
  "footer_link_contact": "Contact",
  "footer_link_limits_and_verification": "Limits and account verification",
  "footer_link_faq": "Frequently asked questions",
  "footer_link_blog": "Blog",
  "footer_header_company_info": "We have exchanged over 8 billion Czech Crowns since 2013.",
  "landing_bottom_section_header_trade_easily": "Trade coins with ease",
  "landing_bottom_section_button_buy_coins": "Buy cryptocurrency",
  "how_it_works_header": "How does it work?",
  "landing_wrote_about_us_header": "Wrote about us",
  "landing_last_orders_header": "Last transactions",
  "header_faq": "Frequently asked questions",
  "faq_general_questions_header": "General",
  "log_in_button": "Log in",
  "header_button_buy_crypto": "Buy cryptocurrency",
  "header_short_button_buy": "Buy",
  "search_button": "Search",
  "header_support": "Support",
  "send_button": "Send",
  "email_subscribe_header": "Subscribe for news from the cryptocurrency world",
  "affiliate_advantage_payout_anytime": "Payout possible at any moment",
  "affiliate_advantage_banner": "Banners for your website or blog",
  "affiliate_advantage_referral_link": "Own referral link",
  "affiliate_advantage_fiat_or_crypto": "Get paid in crypto or fiat currency",
  "affiliate_advantage_cookie": "Cookies for {{affiliateCookieValidityDays}} days",
  "affiliate_advantage_reward_from_margin": "{{affiliateMarginReward}}% reward from our margin",
  "affiliate_header": "Affiliate program",
  "affiliate_subheader": "Become a Simplecoin affiliate partner and get commission from every exchange done by people you brought.",
  "header_earn_with_us": "Earn with {{brand}}",
  "create_account_button": "Sign up",
  "affiliate_how_it_works_header": "How does it work?",
  "affiliate_how_it_works_step_1_header": "Create an account and sign up for our affiliate program",
  "affiliate_how_it_works_step_2_header": "A unique link will be created for you",
  "affiliate_how_it_works_step_3_text": "Every person who comes to the Simplecoin site via your link receives a cookie. If they create an order within 7 days, you will earn a profit of {{affiliateMarginReward}}% of our margin. You will earn these returns for every order he trades with us. For orders created after the 7 day limit, the link is no longer active.",
  "affiliate_how_it_works_step_2_text": "You have a URL and banners that you can share on your website, blog, social media or with your friends. Anyone who uses this link and then signs up or creates an order within 7 days will be entered into your affiliate program for two years.",
  "affiliate_how_it_works_step_1_text": "Both registration and verification are required to join the affiliate program. Registration allows you to access the administration and manage your account. Verification is required for payment of rewards. We follow good manners and do not encourage unfair practices or dealings. We expect the same from our partners.",
  "affiliate_how_it_works_step_4_text": "You can view how much you have earned at any time in your affiliate program administration. When you feel like it, you can choose the amount in crypto or fiat currency. We will send the payment to your bank account or crypto wallet.",
  "affiliate_how_it_works_step_3_header": "You will receive {{affiliateMarginReward}}% from our profit margin for every customer you bring",
  "affiliate_how_it_works_step_4_header": "You can withdraw your earnings at any time",
  "limits_and_verification_header": "Limits and verification",
  "limits_and_verification_subheader": "For legal reasons, like all other exchanges, we must <0>verify every transaction above a certain limit.</0> If this applies to you, you can easily register and have your account verified. See the boxes below for information on limits and verification.",
  "limits_table_level_0_header": "up to {{limitLevel0Eur}} \u20ac",
  "limits_table_level_0_subheader": "per {{limitLevel0DurationDays}} days",
  "limits_table_registration": "Compulsory registration and identification",
  "limits_table_row_2_documents_photo": "Photo of 2 personal documents",
  "limits_table_row_selfie": "Selfie with document",
  "limits_table_row_verification_bank_transfer": "Verification bank transfer",
  "limits_table_row_verification_phone_call": "Verification phone call",
  "limits_table_row_purpose_od_crypto_purchase": "Purpose of cryptocurrency purchase",
  "limits_table_row_source_of_income": "Source of income",
  "limits_table_row_individual_limits_base_on_income_source": "Individual limits based of source of income",
  "limits_table_level_1_header": "{{limitLevel0Eur}} \u2014 {{limitLevel1Eur}} \u20ac",
  "limits_table_level_1_subheader": "per {{limitLevel1DurationDays}} days",
  "limits_table_level_2_subheader": "per {{limitLevel2DurationDays}} days",
  "limits_table_level_2_header": "over {{limitLevel2EurStart}} \u20ac ",
  "footer_mobile_navigation_expand_label": "Site navigation",
  "support_search_placeholder": "What would you like to know?",
  "error_404_header": "We couldn't find that page",
  "error_404_subheader": "But you can continue to...",
  "error_page_contact_us": "If you think this is a mistake, write us on {{supportEmail}}. Thank you!",
  "link_blog_label": "What's new on Simplecoin",
  "link_contact_label": "Contact us",
  "link_faq_label": "Find answers here",
  "about_page_recommendations_section_header": "Recommended by",
  "cookie_dialog_text_content": "{{brand}} uses cookies to make using our services more comfortable and to constantly improve user experience. You can can choose which types of cookies will be used below. For more information read our <0>cookie policy</0>",
  "cookie_dialog_necessary_cookies_header": "Necessary cookies",
  "cookie_dialog_necessary_cookies_text": "Cookies which are needed for the functioning of the website",
  "cookie_dialog_necessary_cookies_always_active": "Always active",
  "cookie_dialog_statistical_cookies_header": "Statistical cookies",
  "cookie_dialog_statistical_cookies_text": "Cookies which help us measure and understand how you use our services",
  "cookie_dialog_marketing_cookies_header": "Marketing cookies",
  "cookie_dialog_marketing_cookies_text": "Cookies that help us in some way",
  "cookie_dialog_personalization_cookies_header": "Personalization cookies",
  "cookie_dialog_personalization_cookies_text": "Cookies thanks to which you will not need to set some things twice",
  "cookie_dialog_accept_all_button": "Accept all",
  "cookie_dialog_confirm_choices_button": "Confirm my choices",
  "cookie_dialog_header": "Your cookie preferences",
  "support_label_breadcrumbs_navigation": "Help",
  "buy_currency_button": "Buy {{currencyName}}",
  "exchange_form_get_wallet_help": "No wallet?",
  "scan_address_qr_code": "Oskenova\u0165 QR k\u00f3d",
  "exchange_form_crypto_address_field_placeholder": "bc1...",
  "order_form_email_and_account_step_label": "Order information",
  "order_form_amounts_step_label": "Amount and currency",
  "order_form_header": "Order",
  "landing_login_dialog_title": "Introducing Simplecoin Pro. A new platform with lower fees and instant currency exchanges. No need for a crypto wallet.\n",
  "landing_login_dialog_pro_header": "Try Simplecoin Pro",
  "landing_login_dialog_exchange_header": "Go to Simplecoin Exchange",
  "landing_login_dialog_exchange_text": "Simple crypto exchange service you know and love",
  "landing_login_dialog_pro_text": "Try Pro for even better trading experience",
  "landing_login_dialog_pro_login_button": "Log in to Pro",
  "landing_login_dialog_exchange_login_button": "Log in to Exchange",
  "about_us_section_small_header": "About us",
  "about_us_section_1_header": "We make the world of buying or selling <0>Bitcoin</0> and other digital currencies <0>easier</0>.",
  "about_us_section_1_subheader": "The world of cryptocurrencies erases boundaries and brings you real freedom and a new perspective on finance.",
  "about_us_section_2_header": "We are cryptocurrency experts",
  "about_us_section_2_subheader": "We help put money back to regular people's hands",
  "about_us_section_2_content": "Simplecoin is first and foremost a technology company that relies on the expertise of our programmers. We invest time and attention specifically in a high standard of security for your client accounts and data.",
  "about_us_section_3_header": "We've been making it easy for people to buy cryptocurrencies <0>since 2013</0>",
  "about_us_section_3_text": "From the very beginning, we have been a purely Czech company with a clear ownership structure. Anyone can trade cryptocurrencies. We bring another possibility of working with finances that are not managed by banks and the state. We bring you options that you manage yourself. We show people that the cryptocurrency world is simple.",
  "about_us_section_4_header": "Modern technology and security",
  "about_us_section_4_subheader": "We take care of the high security of user accounts",
  "about_us_section_4_text": "Simplecoin is primarily a technology company. We base our experience on the expert knowledge of our programmers. We invest time and attention in a high level of security for our clients' accounts and data.",
  "contact_header": "Get in touch",
  "contact_page_email_label": "Email",
  "contact_page_phone_label": "Phone",
  "contact_page_customer_center": "Customer center",
  "contact_page_billing_info": "Billing information",
  "support_search_result_count": "{{resultsCount}} results",
  "limits_table_item_not_needed": "No",
  "limits_table_item_required": "Required",
  "how_to_get_started_step_1_name": "Step 1",
  "how_to_get_started_step_1_header": "You need a crypto wallet",
  "how_to_get_started_step_1_cta": "How to set up a crypto wallet",
  "how_to_get_started_step_1_text": "The wallet is used to store cryptocurrencies. You will need it when making a purchase. If you don't have a wallet, you can download it as an app on your mobile phone or computer. We'll show you how. ",
  "how_to_get_started_step_2_name": "Step 2",
  "how_to_get_started_step_2_header": "Sign up",
  "how_to_get_started_step_2_subheader": "It has its advantages",
  "how_to_get_started_step_2_text": "You will need an email address and a secure password. You can place an order without registering, but you will lose the volume discount and the ability to manage your order in your user account.",
  "how_to_get_started_step_3_name": "Step 3",
  "how_to_get_started_step_3_header": "Create an order",
  "how_to_get_started_step_3_subheader": "Now it only takes a minute",
  "how_to_get_started_step_3_text": "Fill out the order form - you already have everything you need (email address and crypto wallet address).  We will send you payment instructions by email. Once we have verified the funds have been credited, we will send you the desired currency. We can usually do this within 30 min.",
  "how_to_get_started_step_3_hint": "Pay attention to payment instructions. Remember to insert the text in the message to the recipient as instructed. This will help us process your order faster.",
  "how_to_get_started_step_4_name": "",
  "how_to_get_started_step_4_header": "",
  "how_to_get_started_step_4_subheader": "",
  "how_to_get_started_step_4_text": "",
  "how_to_get_started_step_4_cta": "",
  "landing_clients_since_2013": "clients since 2013",
  "landing_monthly_volume": "of volume in the last 30 days ",
  "landing_exchanges_per_month": "exchanges in the last 30 days",
  "cookie_bar_header": "<0>Simplecoin</0> uses cookies to function correctly",
  "cookie_bar_text": "We use cookies to for login as well as navigation and traffic analysis. Thank you for your consent to their use. Select your cookies.",
  "cookie_bar_agree_and_continue_button": "Agree and continue",
  "cookie_bar_manage_cookie_settings_button": "Cookie preferences",
  "order_detail_info_header": "Order details",
  "order_detail_order_id": "Order number",
  "order_detail_created_at": "Created at",
  "order_detail_status": "Status",
  "order_detail_rate": "Order rate",
  "order_detail_button_cancel": "Cancel",
  "order_detail_button_suspend": "Suspend",
  "order_detail_button_download": "Download in PDF",
  "order_detail_button_new_ticket": "Create a ticket",
  "order_detail_history_table_header": "Order history",
  "order_history_datetime_column_name": "Date and time",
  "order_history_status_column_name": "Status",
  "order_detail_outgoing_payment_label": "Outgoing payment",
  "order_detail_incoming_payment_label": "Your payment",
  "order_detail_incoming_payment_waiting_for_transaction": "Your transaction has not been detected yet",
  "order_detail_incoming_payment_waiting_for_outgoing_transaction": "We are waiting for your payment",
  "order_payment_instructions_header": "Send payment with the following details",
  "order_form_payment_instructions_bank_account_number_label": "Bank account number",
  "order_form_payment_instructions_bank_amount_label": "Amount",
  "order_form_payment_instructions_bank_message_label": "Payment message",
  "order_form_payment_instructions_use_qr": "Or scan the following QR code",
  "order_form_payment_instructions_choose_one_of_company_accounts": "Company account",
  "order_status_done": "Done",
  "order_status_delivered": "Delivered",
  "order_status_canceled": "Canceled",
  "order_status_returned": "Returned",
  "order_status_in_progress": "In progress",
  "order_status_confirming_incoming": "Confirming incoming",
  "order_status_confirming_outgoing": "Confirming outgoing",
  "order_status_new": "New",
  "order_status_suspended": "Suspended",
  "order_status_received": "Received",
  "order_detail_outgoing_payment_waiting_for_incoming_transaction": "Your transaction has not been detected yet",
  "show_payment_instructions_dialog_opener_button": "Show payment instructions",
  "support_section_header": "Did not find the answer?",
  "support_section_text": "The customer service line can be reached Monday to Sunday between 9:00 and 21:00. Or send us an email. Our colleagues will always be happy to advise you and guide you safely through your purchase.",
  "label_crypto_sent_to_address": "Address",
  "label_transaction_id": "Transaction ID",
  "order_detail_button_proceed": "Proceed",
  "label_amount_and_currency": "Amount",
  "label_payment_sent_at": "Created",
  "order_detail_outgoing_payment_waiting_to_confirm_incoming_transaction": "Incoming transaction is being confirmed",
  "order_detail_outgoing_payment_order_suspended": "Order is suspended, now waiting for your decision. ",
  "order_detail_outgoing_payment_order_return_to_client": "Your payment will be returned",
  "order_form_payment_instructions_company_billing_details": "Billing information",
  "order_has_been_created": "Order successfully created",
  "fiat_payment_instructions_amount_label": "Amount",
  "order_detail_button_return": "Return",
  "order_view_original_price_label": "Previous offer",
  "order_view_limit_price_label": "Limit price",
  "order_view_current_price_label": "Current offer",
  "cryypto_payment_instructions_amount_label": "Amount",
  "payment_instruction_hint_fill_amount_to_show_qr": "Fill amount filed to generate QR code",
  "deposit_payment_instruction_amount_label": "Amount to deposit",
  "form_email_field": "Email",
  "confirm_button": "Confirm",
  "email_placeholder": "Email",
  "change_in_24hours": "Change in the last 24 hours",
  "order_offer_amounts_label": "Your offer",
  "order_offer_your_crypto_address": "Your cryptocurrency address",
  "order_offer_continue_to_login": "Continue with {{companyName}} account",
  "order_offer_or_separator": "or",
  "order_offer_continue_without_registration": "Continue without registration",
  "order_offer_validity_notice": "This offer will expire in <strong>{{timeRemaining}}</strong>. Please log in or continue with email. ",
  "order_offer_continue_as_guest": "Continue as guest",
  "order_offer_existing_email_please_click_continue_button": "This email is already registered. Please choose the \"Continue with {{companyName}} account\" option",
  "landing_header_references": "References",
  "crypto_payment_instructions_amount_label": "Amount",
  "crypto_payment_instructions_destination_tag_field_label": "Destination tag",
  "order_form_mail_help_text": "We will send you instructions for completing the order to this e-mail",
  "validation_required_email": "Enter your e-mail address",
  "validation_required_crypto_account": "Enter your crypto account",
  "landing_help_section_header": "Tu n\u00e1jdete odpovede na naj\u010dastej\u0161ie ot\u00e1zky. Okrem p\u00edsomn\u00fdch pokynov v\u00e1m m\u00f4\u017eeme pom\u00f4c\u0165 aj na na\u0161ej z\u00e1kazn\u00edckej linke +420 608 746 753 alebo e-mailom na adrese info@simplecoin.eu, od pondelka do nedele od 9:00 do 21:00. Porad\u00edme v\u00e1m pri prvom n\u00e1kupe, pom\u00f4\u017eeme s objedn\u00e1vkou alebo registr\u00e1ciou, porad\u00edme v\u00e1m, ako nastavi\u0165 pe\u0148a\u017eenku, alebo odpovieme na va\u0161e ot\u00e1zky. Nev\u00e1hajte sa n\u00e1s na \u010doko\u013evek op\u00fdta\u0165.",
  "guest_exchange_form_limit_alert": "Purchase without registration is possible up to {{code}} {{limit}}. For higher amounts, please log in or register.",
  "exchange_form_email_label": "Your e-mail",
  "exchange_form_alert_change": "The exchange rate between these currencies cannot be traded. Please adjust your currency selection.",
  "welcome_in_wallet": "Welcome in Wallet!",
  "start_with_funding_text_2": "You have been verified. Now you can deposit funds and start trading.",
  "start_with_funding_text_1": "Well done!",
  "amount_in_fiat_payment_instructions_info": "The amount is required to generate the QR code",
  "limit_table_level_0_text": "You can buy up to \u20ac{{limitLevel0Eur}} within 10 days without registration and without he need to verify personal documents.",
  "label_go_to_order": "Create Order",
  "label_register": "Register",
  "account_detail": "Account detail",
  "signup_form_subtitle": "You will get a better rate and higher trading limits. You will have an overview of your transactions.",
  "label_guest_login": "If you already have an account <0>Login</0>",
  "label_guest_signup": "If you don't have an account yet, <0>sign up</0>",
  "label_register_success": "Registration was successful",
  "label_register_success_verify": "Please activate your account now",
  "label_register_success_sended_verify": "We have sent you a link to activate your account to the e-mail {{mail}}.",
  "label_account_notes": "Account notes",
  "deposit_request_card_head": "Deposit requests",
  "dashboard_balances_no_deposit_requests": "No deposit requests",
  "deposit_request_status_pending": "Pending",
  "exchange_form_terms_must_be_accepted": "Terms and conditions should be accepted",
  "validation_required_bank_account": "Enter your bank account number",
  "order_status_dispatched": "Dispatched",
  "order_status_paid": "Paid",
  "order_status_paidUnconfirmed": "Paid (1/2)",
  "order_status_tooltip_new": "The order is waiting to be paid.",
  "order_status_tooltip_paidUnconfirmed": "We see an incoming transaction. It is waiting to be confirmed on the block-chain.",
  "order_status_tooltip_paid": "The order has been paid. We're working on dispatching the ordered currency.",
  "order_status_tooltip_paid_suspended": "The order has been paid.",
  "order_status_tooltip_dispatched": "We have dispatched the ordered currency to the target address/account. We are waiting for the confirmation on the block-chain/bank.",
  "order_status_tooltip_done": "The order is successfully processed.",
  "order_status_tooltip_canceled": "The order has been canceled.",
  "suspended_order_tooltip": "Order processing is suspended.",
  "order_detail_outgoing_payment_waiting_to_be_sent": "We are working to dispatch the ordered funds as soon as possible.",
  "exchange_limit_for_verification_level_2_text": "Your trading limit is not formally set. For large amounts, it will be assessed individually according to your <0>documented income</0>.",
  "your_limit_is": "Your limit is",
  "trading_limit": "Trading limit",
  "exchange_trading_limit_info": "The limit is automatically adjusted after we receive payment for your order. New unpaid orders do not affect the limit.",
  "you_have_no_suitable_wallet_among_recipients": "You have no wallet for {{currencyCode}} currency among your recipients.",
  "you_have_no_suitable_account_among_recipients": "You have no bank account for {{currencyCode}} currency among your recipients.",
  "rate_when_order_created": "Rate when order created",
  "order_status_proceed": "Proceed processing",
  "waiting_for_limit": "Waiting for limit",
  "button_more": "More ...",
  "exchange_form_expand_optional_fields": "Expand optional fields",
  "return_fiat_payment_info": "We have received your payment already. You have decided to get it back. But return payment has additional fee. You can get {{amount}} {{currency}} back to original bank account.",
  "cancel_order_and_return_payment": "Cancel order and return payment",
  "remaining_limit": "Remaining",
  "trading_limit_reached": "You have reached your trading limit for given period.",
  "attached_files": "Attached files",
  "there_are_no_files": "There are no uploaded files",
  "why_we_need_verification_info": "The anti money laundering law (AML) requires us to know our clients better. Therefore, it is necessary for you to tell us some information about yourself, which of course remains safely stored only with us.",
  "proceed_to_verification_action": "Proceed to verification, please",
  "verification_request_line2": "There are a few steps in our guide and then you can make full use of our wallet.",
  "verification_request_line1": "Now we need to get to know each other a little bit",
  "document_issued_by_government_info": "Please attach a government-issued document confirming the information provided in the previous step.",
  "secondary_document_info": "Please attach another document that confirms the information provided in the first step.",
  "selfie_with_id_info": "Please attach a photo of you holding the personal document  in your hand. The details on the document must be legible and your face must be visible.",
  "understood_button": "Understood",
  "no_prooved_incomes_info": "There is no proved income yet. You can still trade cryptocurrencies up to the base limit (10.000 EUR/month for verified clients). To increase the trading limit above 10 000 EUR/month, you will need to provide proof of income.",
  "verified": "Verified",
  "payment_instructions": "Payment instructions",
  "make_payment_to_one_of_our_accounts": "Make payment to one of our accounts",
  "payment_in": "Payment in",
  "iban_payment_instructions": "IBAN payment instructions",
  "new_price_is_being_loaded": "New price is being loaded...",
  "price_refresh_timer_tooltip": "We update the displayed price and rate for you every few seconds.",
  "send_to_address": "Send to address",
  "payment_specific_data": "Payment data",
  "bank_account_owner": "Bank account owner",
  "filter_all_currencies": "All currencies",
  "ticket_detail": "Ticket detail",
  "last_verification_step_warning": "This is the last step of the verification process. Once you have submitted it, you will no longer be able to change the information you have entered. Your profile will be verified by our back-office staff.",
  "exchange_form_limit_alert": "The order of this amount already exceeds your allowed trading limit {{limit}} {{code}}. You can still create the order, but it will not be processed until you verify yourself or provide proof of appropriate incomes.",
  "order_limit_alert": "Once you will pay for this order, you will exceed your allowed trading limit and the order will not be processed until you verify yourself or provide proof of appropriate incomes.",
  "remove_recipient_confirmation_question": "Do you really want to delete this recipient? You will not be able to undo this action.",
  "label_bank_account_iban": "IBAN",
  "validation_given_field_required": "Field '{{fieldName}}' is required",
  "inheritance": "Inheritance",
  "relationship_to_testator": "Relationship to testator",
  "inherited_from": "Inherited from (name)",
  "landing_how_to_get_started_button": "How to get started",
  "affiliate_partners_must_be_verified": "To become our affiliate partner, we first need to grab a few information about you.",
  "regular_income": "Regular income",
  "tax_declaration": "Tax declaration",
  "annual_report": "Annual report",
  "date_of_acceptance": "Date of acceptance",
  "document_confirming_acceptance_of_inheritance": "Document confirming acceptance of inheritance",
  "financial_closing_statement": "Financial closing statement",
  "decision_on_sharing_profit": "Decision on sharing profit",
  "sale_contract": "Sales contract",
  "real_estate_registry_extract": "Real estate registry extract",
  "typical_income_attachments": "Typical attachments",
  "sale_of_immovable_property": "Sale of immovable property",
  "address_of_property": "Address of property",
  "date_of_sale": "Date of sale",
  "sale_channel": "Sales channel / Way of sale",
  "paycheck": "Paycheck",
  "employment_contract": "Employment contract",
  "field_of_business": "Field of business",
  "company_id": "Company ID",
  "monetary_donation": "Monetary donation",
  "donor": "Donator",
  "donor_relationship_to_recipient": "Relationship of donor to recipient",
  "purpose_of_donation": "Purpose of donation",
  "source_of_donated_funds": "Source of donated funds",
  "email_subscribe_success": "You have successfully subscribed to our newsletter.",
  "landing_index_meta_tag_title": "Simplecoin - The largest cryptocurrency exchange in the Czech Republic",
  "landing_index_meta_tag_description": "Trusted since 2013",
  "landing_how_to_get_started_meta_tag_title": "Simplecoin - How to start buying cryptocurrencies",
  "landing_how_to_get_started_meta_tag_description": "A simple step-by-step guide for everyone",
  "landing_about_meta_tag_description": "We are cryptocurrency experts",
  "landing_about_meta_tag_title": "Simplecoin - About us",
  "landing_faq_meta_tag_description": "What do I need to buy cryptocurrencies? What do I gain by signing up?",
  "landing_faq_meta_tag_title": "Simplecoin - FAQ",
  "landing_support_meta_tag_title": "Simplecoin - Help",
  "landing_support_meta_tag_description": "Detailed instructions on how to set up a wallet, place an order or set up a DCA",
  "landing_tos_meta_tag_description": "Fair trading with cryptocurrencies",
  "landing_tos_meta_tag_title": "Simplecoin - Terms and conditions",
  "landing_privacy_meta_tag_title": "Simplecoin - Privacy Policy",
  "landing_privacy_meta_tag_description": "Your data is safe with us",
  "landing_affiliate_meta_tag_description": "Become a Simplecoin partner and earn commission",
  "landing_affiliate_meta_tag_title": "Simplecoin - Affiliate Program",
  "landing_limits_meta_tag_description": "Purchase options without registration and when you need to be verified",
  "landing_limits_meta_tag_title": "Simplecoin - Limits and Verification",
  "landing_contact_meta_tag_description": "Book a personal appointment at our client centre or take advantage of our client support 9am-9pm every day of the week.",
  "landing_contact_meta_tag_title": "Simplecoin - contact",
  "react_new_app_version_available_dialog_content": "A new version is available. Please refresh the page to ensure you're using the latest version.",
  "wallet_recipient_warning_1": "Anyone who knows the access secrets to the crypto-wallet (especially the recovery seed - 12-24 words used to recover the wallet) can freely use the funds in this wallet.",
  "wallet_recipient_warning_2": "If the wallet was created by someone else than myself, he/she probably has access to this wallet and can freely use the funds in this wallet.",
  "wallet_recipient_warning_3": "If I lose access to my wallet and do not have a backup (recovery seed), I lose access to all funds in my wallet.",
  "wallet_recipient_warning_4": "If I add an address of crypto-wallet that is not mine, I will not be able to directly use of the funds in that wallet. It is up to the third party to allow me to dispose of the funds.",
  "warning": "Warning",
  "ticket_attachments_info": "Attachments can be added to the ticket in the next step (after the ticket is created).",
  "one_off_income": "One-off income",
  "button_previous": "Previous",
  "years_of_service": "Years of service",
  "primary_or_secondary_activity": "Primary / secondary activity",
  "rental_contract": "Rental contract",
  "bank_statement_with_incoming_payment": "Bank statement with incoming payment",
  "pension_confirmation_letter": "Pension confirmation letter",
  "loan": "Loan",
  "loan_maturity_date": "Maturity date",
  "loan_effective_date": "Effective date",
  "lender": "Lender",
  "loan_agreement": "Loan agreement",
  "donation_agreement": "Donation agreement",
  "savings": "Savings",
  "bank_statement": "Bank statement",
  "sale_of_movable_property": "Sale of movable property",
  "sale_of_company_share": "Sale of company share",
  "investment_return": "Investment return",
  "investment_period": "Investment period",
  "crypto_mining": "Crypto mining",
  "mining_target_crypto_address": "Target crypto-address of mined coins",
  "verified_clients_limit_info": "Verified clients can trade up to EUR 10.000 per month without providing further proof of incomes.",
  "expected_trading_volume": "Expected trading volume",
  "expected_trading_volume_info": "Setting up expected trading volume is optional and is not a commitment. It will help us to better set your trading limits and thus speed up your trading.",
  "add_expected_trading_volume": "Add expected trading volume",
  "trading_volume_period": "Trading period",
  "landing_quarterly_volume": "90 day volume",
  "landing_quarterly_exchanges": "Exchanges in the last 90 days",
  "income_state_revoked": "revoked",
  "crypto_deposit_fee_info": "Speed of your crypto transaction depends on the transaction fee so it is important to use the correct transaction fee in order for transaction to be verified soon.",
  "deposit_on_account": "Deposit on account",
  "show": "show",
  "hide": "hide",
  "account_status_suspended": "suspended",
  "account_status_closed": "closed",
  "account_status_active": "active",
  "suspended_accounts": "Suspended accounts",
  "closed_accounts": "Closed accounts",
  "active_accounts": "Active accounts",
  "create_wallet_account_form_header": "Add a new account",
  "account_number": "Account number",
  "accounted_transactions": "Accounted transactions",
  "counterparty": "Counterparty",
  "all_financial_operations": "All",
  "accounting_balance": "Accounting balance",
  "pending_deposits": "Pending deposits",
  "pending_withdrawals": "Pending withdrawals",
  "pending_transactions": "Pending transactions",
  "blocked_amount_alert": "There is an amount {{amount}} {{currency}} blocked on this account. Please contact support for more information.",
  "available_balance_tooltip": "Available balance is the amount of money you currently have available for trading. The available balance does not include pending deposits but already takes into account amounts blocked, e.g. due to pending withdrawals.",
  "total_balance_tooltip": "The account total balance is the sum of all confirmed transactions in your account.",
  "deposit_requests_total_tooltip": "Pending deposits are incoming transactions to your account for which we are waiting for confirmation on the block-chain or have not been credited due to exceeding your trading limit.",
  "withdrawal_requests_total_tooltip": "Pending withdrawals are withdrawals awaiting confirmation on the block-chain.",
  "cancel_account": "Close account",
  "cancel_account_confirmation_question": "Are you sure you want to close this account? Closing the account is an irreversible operation.",
  "empty_account_required_to_close_it": "Only accounts with zero balance can be closed.",
  "account_closed_success": "The account has been closed.",
  "approved_incomes": "Approved incomes",
  "pending_incomes": "Pending incomes",
  "show_details": "show details",
  "hide_details": "hide details",
  "hide_approved_incomes": "Hide approved incomes",
  "show_approved_incomes": "Show approved incomes",
  "increase_trading_limit": "Increase trading limit",
  "save_as_draft": "Save as draft",
  "withdrawal_success": "Withdrawal has been successfully requested",
  "withdrawal_confirmation_transaction_fee_label": "Transaction fee: {{feeAmount}} {{feeCurrencyCode}}",
  "withdrawal_form_fee_in_amount_switch_label": "Withdrawal fee included",
  "withdrawal_recap_table_fee": "Fee amount",
  "withdrawal_recap_table_deducted_amount": "Withdrawal amount",
  "withdrawal_recap_table_net_withdraw_amount": "Recipient will get",
  "remove_income_confirmation": "Do you really want to delete this income?",
  "deposit_form_show_instructions": "Show deposit instructions",
  "income_state_readyForApproval": "awaits approval",
  "withdrawal_form_show_bank_fields_toggle": "Show modifiable fields",
  "end_income": "End income",
  "edit_income": "Edit income",
  "delete_income": "Delete income",
  "pending_transaction_detail": "Pending transaction detail",
  "account": "Account",
  "verification_wizard": "Verification wizard",
  "portfolio_volume": "Your portfolio volume",
  "profile_verification_info_message_for_business": "For business verification <0>read our guide</0>.",
  "unknown_source": "unknown source",
  "amount_netto": "Amount (netto)",
  "deposit_request_status_on_hold": "On Hold",
  "exchange_form_verified_client_discount_message": "Verified clients can save up to <0>{{nominalDiscount}} {{discountCurrency}}</0> per {{toCurrency}}. </1>",
  "sign_up_to_get_discount": "Sign up to get the discount",
  "exchange_form_verified_client_discount_message_generic": "Verified clients get a better price",
  "exchange_form_verified_client_discount_message_from_crypto": "Verified clients can immediately get <0>{{nominalDiscount}} {{discountCurrency}}</0> more per {{toCurrency}}.",
  "price_for_verified_clients": "Price for verified clients:",
  "contact_page_customer_center_reservation_info": "If you are interested in visiting our office, please <0>make an appintment</0>",
  "label_house_number": "House No",
  "react_file_upload_count_error": "the maximum number of uploaded files is {{maxFiles}}",
  "sorry_no_bank_for_given_currency": "We are sorry, but payments in {{currency}} are not possible at this moment.",
  "payment_instructions_delay_warning": "Due to the new provider of banking services, bank transfers may take longer than usual.\n<br/>\nTo ensure the original exchange rate from the order, you have the option to attach a PDF with payment confirmation from your bank. The price extension service is available daily from 9:00 to 21:00. Each request is assessed individually, and we will contact you as soon as possible.",
  "payment_confirmation_upload_info": "The duration of original exchange-rate is evaluated on an individual basis and depends on your payment history and order amount.",
  "attach_payment_confirmation": "Attach payment confirmation",
  "price_fixed_until": "The price is fixed until {{time}}",
  "payment_confirmation_attached": "Payment confirmation attached",
  "validation_incorrect_format_generic": "Invalid format",
  "exchange_fee_percentage_info": "Fee makes {{feePercentage}}% of the traded amount",
  "citizenship": "Citizenship",
  "second_citizenship": "Second citizenship",
  "second_citizenship_helper": "If you have two citizenships state both",
  "dashboard_intro_text_how_to_start": "Our wallet works similarly to internet banking. In order to start using Simplecoin Wallet, you need to deposit funds into virtual accounts you create in it. To get started, we recommend you set up one account each for the currencies you want to start trading. Later, of course, you can add more currency accounts.",
  "how_to_start_using_wallet": "How to start using Simplecoin wallet?",
  "create_accounts": "Create accounts",
  "default_account_label": "{{currencyCode}} default account",
  "default_account_description": "This account was created automatically for you by the initial wizard.",
  "about_virtual_account_info": "A virtual account is where you keep your funds in your Simplecoin wallet. If you want to hold funds in your chosen currency, you must create at least one account for that currency. Setting up and maintaining an account is free. Trading is done by transferring funds between accounts, \nwhich may be in different currencies.",
  "preferred_currencies_question": "Which currencies will you most often trade in?",
  "crypto": "Crypto",
  "fiat": "Fiat",
  "create_accounts_for_selected_currencies": "Create accounts for selected currencies",
  "accounts_successfully_created": "Accounts has been successfully created! We have created {{accountsCount}} accounts for you.",
  "create_your_first_accounts": "Create your first accounts",
  "wallet_logo": "wallet",
  "just_one_citizenship": "just one citizenship",
  "information_about_verification_status_change": "We will inform you about the change of verification status to e-mail {{email}}.",
  "amount_too_low": "Amount too low",
  "footer_link_legal_documents": "Legal documents",
  "log_into_wallet": "Wallet",
  "log_into_exchange": "Exchange",
  "log_into_exchange_caption": "One-off purchases to your wallet",
  "log_into_wallet_caption": "The easiest cryptocurrency trading",
  "payment_request": "Payment request",
  "payment_hash": "Payment hash",
  "memo": "Memo",
  "scan_invoice_qr": "Scan invoice QR",
  "lightning_payment": "Lightning payment",
  "scan_qr": "Scan QR",
  "pay": "Pay"
}