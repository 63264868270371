import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    RippleAddress,
    AccountInfo,
    PrecomposedTransaction,
    EthereumAddress,
    Address as BtcLikeAddress,
    Success,
    Unsuccessful,
} from 'trezor-connect';

export interface TrezorDeviceInfo {
    label?: string;
    device_id?: string;
    model?: string;
}
export type TrezorResponse<T> = Success<T> | Unsuccessful;

interface TrezorConnectState {
    isConnected: boolean;
    deviceInfo?: TrezorDeviceInfo;
    showAddress?: TrezorResponse<BtcLikeAddress | EthereumAddress | RippleAddress>;
    accountInfo?: TrezorResponse<AccountInfo>;
    transaction?: TrezorResponse<PrecomposedTransaction[]>;
}

// Define the initial state using that type
const initialState: TrezorConnectState = {isConnected: false};

export const trezorConnectSlice = createSlice({
    name: 'trezorConnect',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setConnected: (state, action: PayloadAction<TrezorDeviceInfo>) => {
            state.deviceInfo = action.payload;
            state.isConnected = true;
        },
        setDisconnected: (state) => {
            state.isConnected = false;
        },
        verifyAddressInvalidate: (state) => {
            state.showAddress = undefined;
        },
        setAddress: (
            state,
            action: PayloadAction<TrezorResponse<BtcLikeAddress | EthereumAddress | RippleAddress>>
        ) => {
            state.showAddress = action.payload;
        },
        getAccountInfoInvalidate: (state) => {
            state.accountInfo = undefined;
        },
        setAccountInfo: (state, action: PayloadAction<TrezorResponse<AccountInfo>>) => {
            state.accountInfo = action.payload;
        },
        transactionSent: (state, action: PayloadAction<TrezorResponse<PrecomposedTransaction[]>>) => {
            state.transaction = action.payload;
        },
    },
});

export const {
    verifyAddressInvalidate,
    getAccountInfoInvalidate,
    setAccountInfo,
    transactionSent,
    setDisconnected,
    setConnected,
    setAddress,
} = trezorConnectSlice.actions;
