import React from 'react';
import {useSelector} from 'react-redux';
import {MenuItem, Typography, ListItemText, ListItemIcon, styled, useMediaQuery, Link} from '@mui/material';
import Menu from '@mui/material/Menu';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {RootState} from '~/src/redux/store';
import expandIcon from '~/src/assets/svg/expand-icon.svg';
import settingsIcon from '~/src/assets/svg/settings-icon.svg';
import helpIcon from '~/src/assets/svg/help-icon.svg';
import verificationIcon from '~/src/assets/svg/verification-icon.svg';
import logoutIcon from '~/src/assets/svg/logout-icon.svg';
import UserAvatar from './UserAvatar';
import {useTheme} from '@mui/material/styles';
import {WidthBreakpoint} from '~/src/config/localParams';
import {getConfigValue} from '@simplecoin/core';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

const ArrowDownIcon = styled('img')`
    padding-left: 16px;
    @media (max-width: ${WidthBreakpoint.lg}) {
        padding-left: 10px;
    }
`;

const ProfileRectangle = styled('div')`
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(92, 62, 168, 0.05), 0px 0px 0px 1px rgba(92, 62, 168, 0.05);
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    cursor: pointer;
    min-width: 100%;
    height: 60px;
    @media (max-width: ${WidthBreakpoint.lg}) {
        justify-content: flex-start;
    }
`;

const StyledMenuItem = styled(MenuItem)`
    min-width: 240px;
    margin: 4px 12px;
    padding: 12px 16px;

    :hover {
        background: rgba(92, 62, 168, 0.1);
        border-radius: 12px;
    }
`;

/**
 * Renders user profile component
 */
export default function UserProfile() {
    const theme = useTheme();
    const isWideScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const {auth: user} = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const userData = user?.response?.user;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const landingUrl = getConfigValue('landingUrl', '');
    const helpLandingUrl = `${landingUrl}/support`;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        navigate('/logout');
    };

    const handleRedirectTo = (target) => {
        setAnchorEl(null);
        navigate(target);
    };

    if (!userData) {
        return null;
    }

    return (
        <div>
            <ProfileRectangle onClick={handleClick}>
                <UserAvatar src={userData?.avatar_url} />
                {isWideScreen ? (
                    <>
                        <Typography sx={{paddingLeft: '12px'}}>
                            {userData.first_name
                                ? `${userData?.first_name} ${userData?.last_name}`
                                : `${userData?.email}`}
                        </Typography>
                    </>
                ) : null}

                {/* <ArrowDownIcon src={expandIcon} /> */}
                <KeyboardArrowDownRoundedIcon color='secondary' />
            </ProfileRectangle>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <StyledMenuItem onClick={() => handleRedirectTo('/settings')}>
                    <ListItemIcon>
                        <img src={settingsIcon} />
                    </ListItemIcon>
                    <ListItemText>{t('sidebar_navigation_settings')}</ListItemText>
                </StyledMenuItem>
                <StyledMenuItem onClick={() => handleRedirectTo('/verification')}>
                    <ListItemIcon>
                        <img src={verificationIcon} />
                    </ListItemIcon>
                    <ListItemText>{t('verification_and_limits')}</ListItemText>
                </StyledMenuItem>
                <Link component='a' underline='none' href={helpLandingUrl}>
                    <StyledMenuItem>
                        <ListItemIcon>
                            <img src={helpIcon} />
                        </ListItemIcon>
                        <ListItemText>{t('menu_guest_link_help')}</ListItemText>
                    </StyledMenuItem>
                </Link>

                <StyledMenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <img src={logoutIcon} />
                    </ListItemIcon>
                    <ListItemText>{t('navigation_link_logout')}</ListItemText>
                </StyledMenuItem>
            </Menu>
        </div>
    );
}

UserProfile.displayName = 'UserProfile';
