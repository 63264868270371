import {createTheme, Link as MuiLink, SvgIcon, ThemeOptions, LinkProps} from '@mui/material';
import React, {forwardRef} from 'react';
import {TypographyOptions} from '@mui/material/styles/createTypography';
import {Link as RouterLink, LinkProps as RouterLinkProps, useInRouterContext} from 'react-router-dom';

function SelectDropdownIcon(props) {
    return (
        <SvgIcon fill='none' viewBox='0 0 24 24' {...props}>
            <path
                stroke='currentColor'
                d='m 15.88933,10.500017 -3.88933,3 -3.88933,-3'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
                fill='none'
            />
        </SvgIcon>
    );
}

declare module '@mui/material/styles' {
    interface Theme {
        cool: {
            grey100: string;
            grey90: string;
            grey80: string;
            grey70: string;
            grey60: string;
            grey50: string;
            grey40: string;
            grey30: string;
            grey20: string;
            grey10: string;
            grey00: string;
        };
        warm: {
            grey100: string;
            grey90: string;
            grey80: string;
            grey70: string;
            grey60: string;
            grey50: string;
            grey40: string;
            grey30: string;
            grey20: string;
            grey10: string;
            grey00: string;
        };
        orange: {
            bg1: string;
            bg2: string;
        };
        purple: {
            bg1: string;
            bg2: string;
            l1: string;
            l2: string;
            d1: string;
            d3: string;
        };
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        cool: {
            grey100: string;
            grey90: string;
            grey80: string;
            grey70: string;
            grey60: string;
            grey50: string;
            grey40: string;
            grey30: string;
            grey20: string;
            grey10: string;
            grey00: string;
        };
        warm: {
            grey100: string;
            grey90: string;
            grey80: string;
            grey70: string;
            grey60: string;
            grey50: string;
            grey40: string;
            grey30: string;
            grey20: string;
            grey10: string;
            grey00: string;
        };
        orange: {
            bg1: string;
            bg2: string;
        };
        purple: {
            bg1: string;
            bg2: string;
            l1: string;
            l2: string;
            d1: string;
            d3: string;
        };
    }

    interface BreakpointOverrides {
        desktopContent: true;
    }

    interface Palette {
        orderStatus: {
            new: string;
            paidUnconfirmed: string;
            paid: string;
            dispatched: string;
            done: string;
            canceled: string;
        };
        status: {
            new: {
                primary: string;
                light: string;
            };
            waiting: {
                primary: string;
                light: string;
            };
            active: {
                primary: string;
                light: string;
            };
            done: {
                primary: string;
                light: string;
            };
            canceled: {
                primary: string;
                light: string;
            };
            error: {
                primary: string;
                light: string;
            };
        };
    }

    interface PaletteOptions {
        orderStatus?: {
            new: string;
            paidUnconfirmed: string;
            paid: string;
            dispatched: string;
            done: string;
            canceled: string;
        };
        status?: {
            new: {
                primary: string;
                light: string;
            };
            waiting: {
                primary: string;
                light: string;
            };
            active: {
                primary: string;
                light: string;
            };
            done: {
                primary: string;
                light: string;
            };
            canceled: {
                primary: string;
                light: string;
            };
            error: {
                primary: string;
                light: string;
            };
        };
    }
}

// allow configuration using `createTheme`
interface ExtendedTypographyVariantsOptions extends TypographyOptions {
    text: React.CSSProperties;
    textL: React.CSSProperties;
    textM: React.CSSProperties;
    textS: React.CSSProperties;
    textXs: React.CSSProperties;
    label: React.CSSProperties;
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        text: true;
        textL: true;
        textM: true;
        textS: true;
        textXs: true;
        label: true;
    }
}

const defaultMuiTheme = createTheme();

const simplecoinBaseTheme = createTheme({
    palette: {
        primary: {
            main: '#41277E',
            contrastText: '#fff',
        },
        secondary: {
            main: '#FD6300',
            contrastText: '#fff',
        },
        background: {
            default: '#FAF9FF',
        },
        success: {
            main: '#3FB92B',
            contrastText: '#fff',
        },
        info: {
            main: '#41277E',
            contrastText: '#fff',
        },
        warning: {
            main: '#FD6300',
            contrastText: '#fff',
        },
        error: {
            main: '#FF4D26',
            contrastText: '#fff',
        },
        text: {
            primary: '#141619',
            secondary: '#23262b',
            disabled: '#898E9E',
        },
        divider: '#F3F3F6',
        orderStatus: {
            new: '#795DC8',
            paid: '#25882B',
            paidUnconfirmed: '#25882B',
            dispatched: '#4F525F',
            done: '#4F525F',
            canceled: '#4F525F',
        },
        status: {
            new: {
                primary: '#795DC8',
                light: '#F4F2FF',
            },
            active: {
                primary: '#25882B',
                light: '#E9F9EA',
            },
            waiting: {
                primary: '#D28900',
                light: '#FFF1E7',
            },
            done: {
                primary: '#4F525F',
                light: '#F3F3F6',
            },
            canceled: {
                primary: '#4F525F',
                light: '#F3F3F6',
            },
            error: {
                primary: '#E02D3C',
                light: '#FEF1F2',
            },
        },
    },
    cool: {
        grey100: '#141619',
        grey90: '#23262b',
        grey80: '#363940',
        grey70: '#4f525f',
        grey60: '#6b6f7e',
        grey50: '#898e9e',
        grey40: '#a4a8b8',
        grey30: '#c3c6d4',
        grey20: '#dddfe8',
        grey10: '#f3f3f6',
        grey00: '#fafafa',
    },
    warm: {
        grey100: '#161616',
        grey90: '#262626',
        grey80: '#393939',
        grey70: '#525252',
        grey60: '#6F6F6F',
        grey50: '#8D8D8D',
        grey40: '#A8A8A8',
        grey30: '#C6C6C6',
        grey20: '#E0E0E0',
        grey10: '#F4F4F4',
        grey00: '#FAFAFA',
    },
    orange: {
        bg1: '#fff1e7',
        bg2: '#fff9f4',
    },
    purple: {
        bg1: '#f4f2ff',
        bg2: '#faf9ff',
        l1: '#5c3ea8',
        l2: '#795dc8',
        d1: '#2a1c52',
        d3: '#031724',
    },
    typography: {
        fontSize: 16,
        fontFamily: ['Simplecoin Sans'].join(','),
        h1: {
            fontSize: '64px',
            color: '#41277E',
            fontWeight: 700,
            [defaultMuiTheme.breakpoints.down('sm')]: {
                fontSize: '36px',
            },
        },
        h2: {
            fontSize: '48px',
            color: '#41277E',
            fontWeight: 700,
            [defaultMuiTheme.breakpoints.down('sm')]: {
                fontSize: '30px',
            },
        },
        h3: {
            fontSize: '27px',
            color: '#41277E',
            fontWeight: 700,
            [defaultMuiTheme.breakpoints.down('sm')]: {
                fontSize: '24px',
            },
        },
        h4: {
            fontSize: '24px',
            color: '#41277E',
            fontWeight: 700,
            [defaultMuiTheme.breakpoints.down('sm')]: {
                fontSize: '21px',
            },
        },
        h5: {
            fontSize: '21px',
            color: '#41277E',
            fontWeight: 700,
        },
        h6: {
            fontSize: '18px',
            color: '#41277E',
            fontWeight: 700,
        },
        body1: {
            fontSize: '20px',
            color: '#4F525F',
        },
        body2: {
            fontSize: '17px',
            color: '#4F525F',
        },
        subtitle1: {
            fontSize: '15px',
            color: '#4F525F',
        },
        subtitle2: {
            fontSize: '13px',
            color: '#4F525F',
        },
        text: {
            fontSize: '17px',
        },
        label: {
            fontSize: '15px',
            color: '#41277E',
            fontWeight: 700,
        },
        textL: {
            fontSize: '20px',
            color: '#6B6F7E',
            fontWeight: 400,
        },
        textM: {
            fontSize: '17px',
            color: '#6B6F7E',
            fontWeight: 400,
        },
        textS: {
            fontSize: '15px',
            color: '#6B6F7E',
            fontWeight: 400,
        },
        textXs: {
            fontSize: '13px',
            color: '#6B6F7E',
            fontWeight: 400,
        },
    } as ExtendedTypographyVariantsOptions,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button": {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },
                "input[type='number']": {
                    '-moz-appearance': 'textfield',
                },
                '::-webkit-scrollbar': {
                    width: '20px',
                },
                '::-webkit-scrollbar-track': {
                    'background-color': 'transparent',
                },

                '::-webkit-scrollbar-thumb': {
                    backgroundColor: '#d6dee1',
                    borderRadius: '20px',
                    border: ' 6px solid transparent',
                    backgroundClip: 'content-box',
                },

                '::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#a8bbbf',
                },
                '@font-face': {
                    fontFamily: 'Simplecoin Sans',
                    fontStyle: 'normal',
                    fontDisplay: 'swap',
                    fontWeight: 400,
                    src: `local('Simplecoin Sans'), url('/fonts/simplecoinSans/SimplecoinSans-Regular.woff2') format('woff2')`,
                },
                fallbacks: [
                    {
                        '@font-face': {
                            fontFamily: 'Simplecoin Sans',
                            fontStyle: 'normal',
                            fontDisplay: 'swap',
                            fontWeight: 500,
                            src: `local('Simplecoin Sans'), url('/fonts/simplecoinSans/SimplecoinSans-Medium.woff2') format('woff2')`,
                        },
                    },
                    {
                        '@font-face': {
                            fontFamily: 'Simplecoin Sans',
                            fontStyle: 'normal',
                            fontDisplay: 'swap',
                            fontWeight: 700,
                            src: `local('Simplecoin Sans'), url('/fonts/simplecoinSans/SimplecoinSans-Bold.woff2') format('woff2')`,
                        },
                    },
                    {
                        '@font-face': {
                            fontFamily: 'Simplecoin Sans',
                            fontStyle: 'italic',
                            fontDisplay: 'swap',
                            fontWeight: 400,
                            src: `local('Simplecoin Sans'), url('/fonts/simplecoinSans/SimplecoinSans-RegularItalic.woff2') format('woff2')`,
                        },
                    },
                    {
                        '@font-face': {
                            fontFamily: 'Simplecoin Sans',
                            fontStyle: 'italic',
                            fontDisplay: 'swap',
                            fontWeight: 500,
                            src: `local('Simplecoin Sans'), url('/fonts/simplecoinSans/SimplecoinSans-MediumItalic.woff2') format('woff2')`,
                        },
                    },
                    {
                        '@font-face': {
                            fontFamily: 'Simplecoin Sans',
                            fontStyle: 'italic',
                            fontDisplay: 'swap',
                            fontWeight: 700,
                            src: `local('Simplecoin Sans'), url('/fonts/simplecoinSans/SimplecoinSans-BoldItalic.woff2') format('woff2')`,
                        },
                    },
                ],
                'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus':
                    {
                        '-webkit-box-shadow': '0 0 0px 1000px #fff inset',
                    },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '20px',
                    fontSize: '17px',
                    lineHeight: '31px',
                    color: '#41277E',
                },
                notchedOutline: {
                    fontSize: '20px',
                    letterspacing: '-0.01em',
                },
                input: {
                    display: 'flex',
                    alignItems: 'center',
                },
            },
            defaultProps: {
                color: 'secondary',
            },
        },
        MuiCheckbox: {
            defaultProps: {
                color: 'secondary',
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    '&.MuiPaper-root': {
                        padding: '4px',
                        borderRadius: '20px',
                    },
                },
                outlined: {
                    color: '#41277E',
                },
            },
            defaultProps: {
                IconComponent: (props) => <SelectDropdownIcon {...props} fill='secondary' />,
                color: 'secondary',
            },
        },
        MuiAutocomplete: {
            defaultProps: {
                popupIcon: <SelectDropdownIcon sx={{color: '#23262B'}} />,
                color: 'secondary',
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    backgroundColor: '#FFF',
                    color: '#363940',
                    borderRadius: '18px',
                    fontSize: '17px',
                    '&.MuiMenuItem-root:hover': {
                        backgroundColor: '#F4F2FF',
                        color: '#41277E',
                        fontWeight: 400,
                    },
                    [defaultMuiTheme.breakpoints.down('sm')]: {
                        fontSize: '15px',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '30px',
                    fontWeight: 700,
                    textTransform: 'none',
                    '&.Mui-disabled': {
                        background: '#F3F3F6',
                        color: '#C3C6D4',
                    },
                },
                sizeSmall: {
                    height: '36px',
                    fontSize: '15px',
                    padding: '4px 20px',
                },
                sizeMedium: {
                    height: '48px',
                    fontSize: '16px',
                    padding: '4px 24px',
                },
                sizeLarge: {
                    height: '56px',
                    fontSize: '17px',
                    padding: '4px 36px',
                },
                outlinedSecondary: {
                    boxShadow: 'none',
                    border: '1px solid rgba(253, 99, 0, 0.12)',
                },
                containedSecondary: {
                    boxShadow: '0px 4px 16px rgba(253, 99, 0, 0.2), 0px 1px 2px rgba(253, 99, 0, 0.25)',
                    ':hover': {
                        backgroundColor: '#E84D00',
                    },
                },
                containedError: {
                    backgroundColor: '#E02D3C',
                    color: '#FFFFFF',
                    boxShadow: 'none',
                    ':hover': {
                        backgroundColor: '#BA2532',
                    },
                    ':active': {
                        backgroundColor: '#981B25',
                    },
                    ':disabled': {
                        backgroundColor: '#F3F3F6',
                        color: '#898E9E',
                    },
                },
            },
            defaultProps: {
                variant: 'contained',
                color: 'secondary',
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    borderRadius: '15px',
                    border: '1px solid #ECE9F2',
                    textTransform: 'none',
                    '&.Mui-disabled': {
                        background: '#F3F3F6',
                        color: '#C3C6D4',
                        border: '1px solid #C3C6D4',
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&.MuiInputLabel-shrink': {
                        color: '#41277E',
                        padding: '0',
                    },
                    '&.MuiInputLabel-shrink.Mui-error': {
                        color: '#FF4D26',
                    },
                },
            },
        },
        MuiAlert: {
            defaultProps: {
                variant: 'filled',
            },
            styleOverrides: {
                root: {
                    borderRadius: '16px',
                    padding: '20px 24px',
                    lineHeight: '180%',
                    alignItems: 'center',
                },
                icon: {
                    marginRight: '21px',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    padding: '28px 32px',
                    [defaultMuiTheme.breakpoints.down('sm')]: {
                        padding: '12px 12px',
                    },
                },
                elevation1: {
                    boxShadow: '0px 4px 16px rgba(92, 62, 168, 0.05), 0px 0px 0px 1px rgba(92, 62, 168, 0.05)',
                },
                rounded: {
                    borderRadius: '16px',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    padding: '28px 32px',
                    borderRadius: '16px',
                    boxShadow: '0px 4px 16px rgba(92, 62, 168, 0.05), 0px 0px 0px 1px rgba(92, 62, 168, 0.05)',
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    [defaultMuiTheme.breakpoints.down('sm')]: {
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    },
                },
                action: {
                    [defaultMuiTheme.breakpoints.down('sm')]: {
                        width: '100%',
                        textAlign: 'center',
                        marginTop: '16px',
                    },
                },
                title: {
                    textAlign: 'left',
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    textAlign: 'left',
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    padding: '28px 32px',
                    borderRadius: '16px',
                    boxShadow: '0px 4px 16px rgba(92, 62, 168, 0.05), 0px 0px 0px 1px rgba(92, 62, 168, 0.05)',
                    '&:first-of-type': {
                        borderTopLeftRadius: '16px',
                        borderTopRightRadius: '16px',
                    },
                    '&:last-of-type': {
                        borderBottomLeftRadius: '16px',
                        borderBottomRightRadius: '16px',
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    color: '#41277E',
                    fontWeight: '700',
                    textTransform: 'none',
                },
            },
        },
        MuiTabs: {
            defaultProps: {
                textColor: 'secondary',
                indicatorColor: 'secondary',
            },
        },
        MuiCircularProgress: {
            defaultProps: {
                color: 'secondary',
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    lineHeight: '150%',
                    fontSize: '0.85rem',
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    padding: '0 8px',
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                InputLabelProps: {shrink: true},
            },
            styleOverrides: {
                root: {
                    '& legend span': {
                        fontSize: '1em',
                    },
                },
                outlined: {
                    '&.MuiInputLabel-shrink': {
                        fontWeight: '700',
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '&.MuiTextField-root .MuiInputLabel-root': {
                        color: '#41277E',
                    },
                },
            },
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            desktopContent: 1300,
        },
    },
} as ThemeOptions);

//simplecoinBaseTheme = responsiveFontSizes(simplecoinBaseTheme, {factor: 2, disableAlign: true});

const simplecoinLandingTheme = createTheme(simplecoinBaseTheme, {
    components: {
        MuiAlert: {
            styleOverrides: {
                standardWarning: {
                    backgroundColor: 'rgba(253, 99, 0, 0.1)',
                    color: simplecoinBaseTheme.palette.secondary.main,
                },
                filledInfo: {
                    backgroundColor: '#F4F2FF',
                    color: simplecoinBaseTheme.palette.text.secondary,
                },
                filledWarning: {
                    backgroundColor: 'rgba(253, 99, 0, 0.1)',
                    color: simplecoinBaseTheme.palette.secondary.main,
                },
                filledError: {
                    backgroundColor: '#FEF1F2',
                    color: '#E02D3C',
                },
                filledSuccess: {
                    backgroundColor: '#E9F9EA',
                    color: simplecoinBaseTheme.palette.success.main,
                },
            },
        },
    },
});

/**
 * Use to make the default component of MUI Link/Button a RouterLink.
 * See https://mui.com/material-ui/guides/routing/
 */
// eslint-disable-next-line react/display-name
const AppLinkBehavior = forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & {href: RouterLinkProps['to']}>(
    (props, ref) => {
        // detect react-router to make sure it's safe to use its link component
        const isRunningInRouterContext = useInRouterContext();

        const {href, ...other} = props;

        let isLinkWithinApp = false;

        if (href && typeof href === 'string' && href.startsWith('/')) {
            // link within the app. Using router link with href to en external page would break it
            isLinkWithinApp = true;
        }

        if (href && isLinkWithinApp && isRunningInRouterContext) {
            return <RouterLink ref={ref} to={href} {...other} role={undefined} />;
        }

        // Warning: returning mui link without specifying component will result in a recursion
        // @ts-ignore
        return <MuiLink ref={ref} href={href} {...other} component='a' />;
    }
);

const simplecoinAppTheme = createTheme(simplecoinBaseTheme, {
    typography: {
        fontSize: 15,
        fontFamily: ['Simplecoin Sans', 'Arial', 'Helvetica'].join(','),
        h1: {
            fontSize: '36px',
            color: '#41277E',
            fontWeight: 700,
            [simplecoinBaseTheme.breakpoints.down('sm')]: {
                fontSize: '32px',
            },
        },
        h2: {
            fontSize: '24px',
            color: '#41277E',
            fontWeight: 700,
            [simplecoinBaseTheme.breakpoints.down('sm')]: {
                fontSize: '18px',
            },
        },
        h3: {
            fontSize: '20px',
            color: '#41277E',
            fontWeight: 700,
            [simplecoinBaseTheme.breakpoints.down('sm')]: {
                fontSize: '15px',
            },
        },
        h4: {
            fontSize: '16px',
            color: '#41277E',
            fontWeight: 700,
            [simplecoinBaseTheme.breakpoints.down('sm')]: {
                fontSize: '15px',
            },
        },
        h5: {
            fontSize: '16px',
            color: '#41277E',
            fontWeight: 700,
            [simplecoinBaseTheme.breakpoints.down('sm')]: {
                fontSize: '15px',
            },
        },
        body1: {
            fontSize: '20px',
            color: '#141619',
            letterSpacing: '-0.01em',
            lineHeight: '180%',
            [simplecoinBaseTheme.breakpoints.down('sm')]: {
                fontSize: '18px',
            },
        },
        body2: {
            fontSize: '15px',
            color: '#141619',
        },
        subtitle1: {
            fontSize: '14px',
            color: '#4F525F',
        },
        subtitle2: {
            fontSize: '13px',
            color: '#4F525F',
        },
        caption: {
            fontSize: '14px',
        },
        text: {
            fontSize: '15px',
            fontWeight: 400,
        },
        label: {
            fontSize: '15px',
            color: '#41277E',
            fontWeight: 700,
        },
    },
    components: {
        MuiChip: {
            styleOverrides: {
                label: {
                    fontSize: '14px',
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                select: {
                    '&.MuiTablePagination-select': {
                        border: '1px solid #ECE9F2',
                        borderRadius: '99px',
                        fontSize: '15px',
                        fontWeight: '700',
                        color: '#41277E',
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    padding: '48px',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: '16px 0',
                },
            },
        },
        MuiDialogContent: {
            defaultProps: {
                margin: 'dense',
            },
            styleOverrides: {
                root: {
                    // todo: remove once the issue is addressed: https://github.com/mui/material-ui/issues/31185
                    padding: '16px 0 !important',
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '16px 0 0 0',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    padding: '0',
                    borderRadius: '16px',
                    boxShadow: '0px 4px 16px rgba(92, 62, 168, 0.05), 0px 0px 0px 1px rgba(92, 62, 168, 0.05)',
                    [simplecoinBaseTheme.breakpoints.down('sm')]: {
                        padding: '0',
                    },
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    padding: '28px 32px',
                    textAlign: 'left',
                    [simplecoinBaseTheme.breakpoints.down('sm')]: {
                        padding: '12px 12px',
                    },
                },
                title: {
                    textAlign: 'left',
                },
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '28px 32px',
                    textAlign: 'left',
                    [simplecoinBaseTheme.breakpoints.down('sm')]: {
                        padding: '12px 12px',
                    },
                },
            },
        },

        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '28px 32px',
                    textAlign: 'left',
                    [simplecoinBaseTheme.breakpoints.down('sm')]: {
                        padding: '12px 12px',
                    },
                },
            },
        },
        MuiMobileStepper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#FFFF',
                    boxShadow: 'none',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                standardWarning: {
                    backgroundColor: 'rgba(253, 99, 0, 0.1)',
                    color: simplecoinBaseTheme.palette.secondary.main,
                },
                filledInfo: {
                    backgroundColor: '#F4F2FF',
                    color: simplecoinBaseTheme.palette.text.secondary,
                },
                filledWarning: {
                    backgroundColor: 'rgba(253, 99, 0, 0.1)',
                    color: simplecoinBaseTheme.palette.secondary.main,
                },
                filledError: {
                    backgroundColor: '#FEF1F2',
                    color: '#E02D3C',
                },
                filledSuccess: {
                    backgroundColor: '#E9F9EA',
                    color: simplecoinBaseTheme.palette.success.main,
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                cell: {
                    borderBottom: '1px solid #F3F3F6',
                },
                columnHeaders: {
                    borderBottom: '1px solid #F3F3F6',
                },
                toolbarContainer: {
                    marginTop: '0px',
                },
            },
        },
        MuiLink: {
            defaultProps: {
                component: AppLinkBehavior,
            } as LinkProps,
        },
        MuiButtonBase: {
            defaultProps: {
                LinkComponent: AppLinkBehavior,
            },
        },
    },
});

const simplecoinExchangeAppTheme = createTheme(simplecoinAppTheme, {});

const simplecoinProAppTheme = createTheme(simplecoinAppTheme, {});

export {simplecoinBaseTheme, simplecoinExchangeAppTheme, simplecoinLandingTheme, simplecoinProAppTheme};
