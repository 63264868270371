import React, {memo, useState} from 'react';
import {Select, MenuItem, TextField, styled, FormControl} from '@mui/material';
import {useCurrenciesArray} from '~/hooks/currency';
import useBackendSettings from '~/hooks/useBackendSettings';

const InnerSelect = styled(Select)`
    height: 64px;
    border: none;
    font-size: 24px;
    padding: 16.5px 2px;
    margin-left: -1px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    .MuiOutlinedInput-root {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-color: #dddfe8;
    }
`;

const CurrencyAmountField = styled(TextField)(
    ({theme}) => `
    .MuiFormControl-root {
        margin-bottom: 0;
        margin-top: 0;
    }

    .MuiOutlinedInput-root {
        height: 64px;
        font-weight: 700;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .MuiOutlinedInput-notchedOutline {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-color: #dddfe8;
    }

    .MuiInputLabel-shrink {
        color: ${theme.palette.text.primary};
    }
`);

const Wrapper = styled(FormControl)`
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    .MuiFormControl-root {
        margin-bottom: 0;
        margin-top: 0;
    }
`;

const inputProps = {step: 'any', inputMode: 'decimal'};

export interface CurrencyFieldProps {
    currencyCode: string;
    onCurrencyChange: (e: any) => void;
    amount?: number;
    onAmountChange: (e: any) => void;
    handleBlur: (e: any) => void;
    amountPlaceholder?: string;
    amountLabel: string;
    currencySelectProps?: any;
    amountFieldProps?: any;
    amountError?: string;
    currencyError?: string;
    currencyDirection?: 'from' | 'to';
}

/**
 * Renders amount field with currency dropdown
 */
const CurrencyField = memo(
    ({
        currencyCode = '',
        onCurrencyChange,
        amount,
        onAmountChange,
        handleBlur,
        amountPlaceholder,
        amountLabel,
        amountFieldProps,
        currencySelectProps,
        amountError,
        currencyError,
        currencyDirection,
        ...props
    }: CurrencyFieldProps) => {
        const currencyArray = useCurrenciesArray(currencyDirection);
        const [focus, setFocus] = useState(false);
        const isWallet = useBackendSettings('buildType') === 'wallet';

        // filter by type
        const filteredCurrencies = isWallet ? currencyArray.filter((currency) => currency.is_wallet) : currencyArray;

        /**
         * Adds closing snackbars
         */
        function handleCurrencyChange(e: any) {
            onCurrencyChange(e);
        }

        return (
            <Wrapper id='currency-field' onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} focused={focus}>
                <CurrencyAmountField
                    value={amount ?? ''}
                    onChange={onAmountChange}
                    onBlur={handleBlur}
                    margin='normal'
                    variant='outlined'
                    placeholder={amountPlaceholder}
                    type='text'
                    inputProps={inputProps}
                    label={amountLabel}
                    color='secondary'
                    error={!!amountError}
                    {...amountFieldProps}
                    {...props}
                />

                <InnerSelect
                    name={currencyCode}
                    value={currencyCode}
                    onChange={handleCurrencyChange}
                    onBlur={handleBlur}
                    sx={{fontWeight: 700}}
                    error={!!currencyError}
                    {...currencySelectProps}
                    {...props}
                >
                    {filteredCurrencies.map((currency, i) => (
                        <MenuItem key={i} value={currency?.name}>
                            {currency?.name}
                        </MenuItem>
                    ))}
                </InnerSelect>
            </Wrapper>
        );
    }
);

CurrencyField.displayName = 'CurrencyField';
export default CurrencyField;
