import React, {useEffect} from 'react';
import RateDisclaimer from './RateDisclaimer';
import ExchangeContainer from './ExchangeContainer';
import ExchangeFormCurrencySelects from './ExchangeFormCurrencySelects';
import {Button, Paper, Stack, Typography, styled, Box} from '@mui/material';
import exchangeIcon from './exchangeIcon.svg';
import Space from '../Space';
import {useTranslation} from 'react-i18next';
import {RenderFormProps} from '~/typings/ExchangeFormTypings';
import {initiateAxiosApi} from '~/api';
import renderWithSettings from '../../renderWithSettings';
import DiscountedPrice from '~/components/exchange-form/DiscountedPrice';
import {useSelector} from 'react-redux';
import {RootState} from '~/redux/store';

interface IsLandingProps {
    isLanding: boolean;
}

const VerticalStyledPaper = styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'isLanding', // prevents forwarding the isLanding property to the DOM element
})<IsLandingProps>(({theme, isLanding}) => ({
    padding: isLanding ? '40px' : '36px',
    maxWidth: '450px',
    width: 'auto',
    background: '#ffffff',
    boxShadow: isLanding
        ? '0px 24px 128px rgba(42, 28, 82, 0.05)'
        : '0px 4px 16px rgba(92, 62, 168, 0.05), 0px 0px 0px 1px rgba(92, 62, 168, 0.05)',
    borderRadius: '20px',

    [theme.breakpoints.down('sm')]: {
        maxWidth: '350px',
        width: 'auto',
        padding: '32px 24px',
    },
}));

const ExchangeIcon = styled('img')`
    margin-right: 12px;
    margin-bottom: 4px;
    height: 100%;
    vertical-align: middle;
`;

interface SubmitProps {
    fromAmount?: number;
    toAmount?: number;
    fromCurrencyCode: string;
    toCurrencyCode: string;
}

interface LandingExchangeForm extends RenderFormProps {
    isLandingPage?: boolean;
}

export type SubmitFunction = (props: SubmitProps) => void;

export interface LandingExchangeFormTypes {
    onSubmit: SubmitFunction;
    onSubmitButtonClick: () => void;
    apiUrl: string;
    isLanding?: boolean;
    requiredControl?: boolean;
    clientCurrency?: string;
    userVerificationLevel?: number;
}

/**
 * Landing page exchange form
 * @return {JSX.Element}
 * @constructor
 */
function LandingExchangeForm({
    onSubmit,
    onSubmitButtonClick,
    apiUrl,
    isLanding = false,
    requiredControl = false,
    clientCurrency = 'CZK',
    userVerificationLevel = 0,
}: LandingExchangeFormTypes) {
    const {t} = useTranslation();

    useEffect(() => {
        initiateAxiosApi(apiUrl);
    }, []);

    /**
     * Renders exchange form with data provided by the ExchangeContainer component
     * */
    function renderExchangeForm({
        onFromCurrencyChange,
        onFromAmountChange,
        swapCurrencies,
        onToCurrencyChange,
        onToAmountChange,
        rateResponse,
        priceResponse,
        rateOrPriceError,
        formik,
        timer,
    }: LandingExchangeForm) {
        const {toCurrencyCode, toAmount, fromAmount, fromCurrencyCode} = formik.values;

        const sxProps = isLanding ? {fontSize: '27px', lineHeight: '34px'} : {};

        return (
            <VerticalStyledPaper elevation={3} isLanding={isLanding}>
                <Stack direction='column' alignItems='center'>
                    <Typography variant='h4' align={isLanding ? 'center' : 'left'} sx={sxProps}>
                        {isLanding && <ExchangeIcon src={exchangeIcon} width={24} height={24} alt='exchange icon' />}
                        {t('exchange_form_label')}
                    </Typography>

                    <Space size={32} />

                    <ExchangeFormCurrencySelects
                        onFromAmountChange={onFromAmountChange}
                        onFromCurrencyChange={onFromCurrencyChange}
                        onToAmountChange={onToAmountChange}
                        onToCurrencyChange={onToCurrencyChange}
                        onBlur={formik.handleBlur}
                        swapCurrencies={swapCurrencies}
                        fromAmount={fromAmount}
                        fromCurrencyCode={fromCurrencyCode}
                        toAmount={toAmount}
                        toCurrencyCode={toCurrencyCode}
                        errors={formik.errors}
                    />

                    {typeof rateOrPriceError === 'string' && (
                        <Box padding={1}>
                            <Typography variant='body2' color='error'>
                                {rateOrPriceError}
                            </Typography>
                        </Box>
                    )}

                    <Space size={16} />
                    <RateDisclaimer
                        rate={rateResponse}
                        price={priceResponse}
                        errorFetchingRate={typeof rateOrPriceError === 'string'}
                        timer={timer}
                        isLanding={isLanding}
                    />
                    <Space size={16} />

                    <Button
                        type='submit'
                        size='large'
                        onClick={() => onSubmitButtonClick?.()}
                        variant='contained'
                        color='secondary'
                        sx={{width: '100%', justifyContent: 'center'}}
                    >
                        {t('buy_now_button')}
                    </Button>

                    <Space size={24} />

                    {userVerificationLevel < 1 && (
                        <DiscountedPrice
                            rate={rateResponse}
                            price={priceResponse}
                            isLanding={isLanding}
                            errorFetchingRate={typeof rateOrPriceError === 'string'}
                        />
                    )}
                </Stack>
            </VerticalStyledPaper>
        );
    }

    return (
        <ExchangeContainer
            onSubmit={onSubmit}
            showCaptcha={false}
            fromCurrencyCodePrefill={clientCurrency}
            toCurrencyCodePrefill='BTC'
            renderForm={renderExchangeForm}
            rootStyles={{display: 'flex', justifyContent: 'center'}}
            requiredControl={requiredControl}
        />
    );
}

export default renderWithSettings<LandingExchangeFormTypes>(LandingExchangeForm);
