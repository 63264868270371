{
  "bank_account": "T\u00e0i kho\u1ea3n ng\u00e2n h\u00e0ng",
  "cancel": "H\u1ee7y b\u1ecf",
  "crypto_wallet": "V\u00ed ti\u1ec1n \u0111i\u1ec7n t\u1eed",
  "exchange": "\u0110\u1ed5i",
  "order_has_been_canceled": "\u0110\u01a1n h\u00e0ng \u0111\u00e3 b\u1ecb h\u1ee7y",
  "order_has_been_proceeded": "\u0110\u01a1n h\u00e0ng \u0111\u00e3 \u0111\u01b0\u1ee3c ti\u1ebfn h\u00e0nh",
  "order_has_been_suspended": "\u0110\u01a1n h\u00e0ng \u0111\u00e3 b\u1ecb \u0111\u00ecnh ch\u1ec9",
  "order_status_history": "L\u1ecbch s\u1eed tr\u1ea1ng th\u00e1i \u0111\u01a1n h\u00e0ng",
  "orders": "\u0110\u01a1n h\u00e0ng",
  "payment_information": "Th\u00f4ng tin thanh to\u00e1n",
  "proceed": "Ti\u1ebfn h\u00e0nh",
  "rates": "Gi\u00e1",
  "return": "Tr\u1edf v\u1ec1",
  "setting_language_preference_text": "Ch\u1ecdn ng\u00f4n ng\u1eef m\u00e0 b\u1ea1n th\u00edch hi\u1ec3n th\u1ecb trang web v\u00e0 nh\u1eadn email.",
  "setting_send_email_after_login": "G\u1eedi email sau m\u1ed7i l\u1ea7n \u0111\u0103ng nh\u1eadp.",
  "suspend": "\u0110\u00ecnh ch\u1ec9",
  "wallet_updated_successfully": "V\u00ed \u0111\u01b0\u1ee3c c\u1eadp nh\u1eadt th\u00e0nh c\u00f4ng",
  "we_will_send_you_money_back_shortly": "Ch\u00fang t\u00f4i s\u1ebd g\u1eedi l\u1ea1i cho b\u1ea1n ti\u1ec1n ngay",
  "guest_login_and_signup_order_separation": "ho\u1eb7c l\u00e0",
  "order_button_create_order": "T\u1ea1o \u0111\u01a1n h\u00e0ng",
  "guest_login": "\u0110\u0103ng nh\u1eadp",
  "guest_signup": "\u0110\u0103ng k\u00fd",
  "menu_guest_link_about_us": "V\u1ec1 ch\u00fang t\u00f4i",
  "menu_guest_link_help": "C\u1ee9u gi\u00fap",
  "menu_guest_link_terms": "\u0110i\u1ec1u kho\u1ea3n d\u1ecbch v\u1ee5",
  "order_form_caption": "T\u1ea1o \u0111\u01a1n h\u00e0ng c\u1ee7a b\u1ea1n ngay t\u1ea1i \u0111\u00e2y",
  "order_form_email": "E-mail",
  "forgot_password": "Qu\u00ean m\u1eadt kh\u1ea9u?",
  "login_form_submit_button": "\u0111\u0103ng nh\u1eadp",
  "page_login_title": "\u0111\u0103ng nh\u1eadp v\u00e0o {{brandName}}",
  "page_signup_title": "\u0110\u0103ng k\u00fd",
  "signup_form_please_fill": "Vui l\u00f2ng \u0111i\u1ec1n v\u00e0o c\u00e1c tr\u01b0\u1eddng sau \u0111\u1ec3 \u0111\u0103ng k\u00fd:",
  "signup_form_submit_button": "\u0110\u0103ng k\u00fd",
  "order_form_placeholder_currency": "Ti\u1ec1n t\u1ec7",
  "refund_widget_add_new_refund_address": "Th\u00eam v\u00ed m\u1edbi",
  "refund_address_select_label": "\u0110\u1ecba ch\u1ec9 ho\u00e0n tr\u1ea3 ti\u1ec1n",
  "copied": "Sao ch\u00e9p",
  "suspended_widget_return_money_back": "Tr\u1ea3 l\u1ea1i ti\u1ec1n",
  "suspended_widget_top_alert_message": "\u0110\u01a1n h\u00e0ng b\u1ecb \u0111\u00ecnh ch\u1ec9, b\u00e2y gi\u1edd ch\u1edd quy\u1ebft \u0111\u1ecbnh c\u1ee7a b\u1ea1n.",
  "add_order": "Th\u00eam \u0111\u01a1n h\u00e0ng m\u1edbi",
  "language": "Ng\u00f4n ng\u1eef",
  "logged_in": "\u0110\u0103ng nh\u1eadp",
  "search": "T\u00ecm ki\u1ebfm",
  "status_direction": "H\u01b0\u1edbng tr\u1ea1ng th\u00e1i",
  "status_direction_description_cancelled": "H\u1ee7y \u0111\u01a1n h\u00e0ng",
  "status_direction_description_proceed": "Ti\u1ebfn h\u00e0nh \u0111\u1eb7t h\u00e0ng",
  "status_direction_description_return_to_client": "Tr\u1ea3 l\u1ea1i ti\u1ec1n/ti\u1ec1n \u0111i\u1ec7n t\u1eed c\u1ee7a t\u00f4i",
  "status_direction_description_suspended": "\u0110\u00ecnh ch\u1ec9 \u0111\u01a1n h\u00e0ng",
  "status_final": "Tr\u1ea1ng th\u00e1i cu\u1ed1i c\u00f9ng",
  "status_name_cancelled": "\u0110\u00e3 h\u1ee7y",
  "status_name_confirming_incoming": "X\u00e1c nh\u1eadn \u0111\u1ebfn",
  "status_name_confirming_outgoing": "X\u00e1c nh\u1eadn \u0111i",
  "status_name_delivered": "\u0110\u00e3 giao h\u00e0ng",
  "status_name_done": "Xong",
  "status_name_new": "M\u1edbi",
  "status_name_proceed": "Ti\u1ebfn h\u00e0nh",
  "status_name_received": "Nh\u1eadn",
  "status_name_return_to_client": "Quay tr\u1edf l\u1ea1i kh\u00e1ch h\u00e0ng",
  "status_name_returned": "Tr\u1ea3 l\u1ea1i",
  "status_name_suspended": "\u0110\u00ecnh ch\u1ec9",
  "suspended_widget_table_change_percent": "Thay \u0111\u1ed5i",
  "client_bank_account_list_add_new_account": "Th\u00eam t\u00e0i kho\u1ea3n m\u1edbi",
  "account_form_create_button": "Th\u00eam t\u00e0i kho\u1ea3n ng\u00e2n h\u00e0ng",
  "account_form_save_button": "L\u01b0u l\u1ea1i",
  "create_new_wallet_header": "Th\u00eam v\u00ed m\u1edbi",
  "delete_account": "X\u00f3a t\u00e0i kho\u1ea3n",
  "delete_wallet": "X\u00f3a v\u00ed",
  "update_account": "C\u1eadp nh\u1eadt t\u00e0i kho\u1ea3n",
  "update_wallet": "C\u1eadp nh\u1eadt v\u00ed",
  "wallet_form_create_button": "Th\u00eam v\u00ed",
  "header_what_now": "G\u00ec b\u00e2y gi\u1edd",
  "profile_page_header_my_documents": "T\u1eadp tin \u0111\u00ednh k\u00e8m",
  "profile_page_header_my_profile": "Th\u00f4ng tin c\u1ee7a t\u00f4i",
  "profile_page_ready_for_verification_we_are_checking_your_profile": "H\u1ed3 s\u01a1 c\u1ee7a b\u1ea1n \u0111ang \u0111\u01b0\u1ee3c ki\u1ec3m tra.",
  "profile_page_save_button": "L\u01b0u ",
  "profile_page_tab_level1": "C\u1ea5p \u0111\u1ed9 1",
  "profile_page_tab_level2": "C\u1ea5p \u0111\u1ed9 2",
  "profile_page_tab_this_level_is_accepted": "C\u1ea5p \u0111\u1ed9 n\u00e0y \u0111\u01b0\u1ee3c ch\u1ea5p nh\u1eadn",
  "refund_address_set_successfully": "\u0110\u1ecba ch\u1ec9 ho\u00e0n ti\u1ec1n \u0111\u01b0\u1ee3c \u0111\u1eb7t th\u00e0nh c\u00f4ng",
  "profile_level_checkbox_ready_for_verification_you_would_not_be_able_to_modify_your_information_it_sent_to_admin": "N\u1ebfu b\u1ea1n nh\u1ea5p v\u00e0o \u00f4 b\u00ean d\u01b0\u1edbi v\u00e0 g\u1eedi bi\u1ec3u m\u1eabu, b\u1ea1n s\u1ebd kh\u00f4ng th\u1ec3 s\u1eeda \u0111\u1ed5i v\u00e0 x\u00f3a c\u00e1c t\u1ec7p c\u1ee7a m\u00ecnh. D\u1eef li\u1ec7u s\u1ebd \u0111\u01b0\u1ee3c g\u1eedi \u0111\u1ec3 \u0111\u00e1nh gi\u00e1 cho ng\u01b0\u1eddi \u0111i\u1ec1u h\u00e0nh c\u1ee7a ch\u00fang t\u00f4i.",
  "client_sidebar_avatar_hash_description": "Nh\u1edb h\u00ecnh \u1ea3nh n\u00e0y. N\u00f3 \u0111\u01b0\u1ee3c t\u1ea1o ra duy nh\u1ea5t cho t\u00e0i kho\u1ea3n c\u1ee7a b\u1ea1n. N\u1ebfu n\u00f3 \u0111\u00e3 thay \u0111\u1ed5i, c\u00f3 th\u1ec3 ai \u0111\u00f3 \u0111ang c\u1ed1 g\u1eafng gi\u1ea3 m\u1ea1o trang web c\u1ee7a ng\u01b0\u1eddi kh\u00e1c. Ki\u1ec3m tra \u0111\u1ecba ch\u1ec9 trong tr\u00ecnh duy\u1ec7t c\u1ee7a b\u1ea1n \u0111\u1ec3 \u0111\u1ea3m b\u1ea3o \u0111\u1ecba ch\u1ec9 ch\u00ednh x\u00e1c v\u00e0 \u1ed5 kh\u00f3a m\u00e0u xanh l\u00e1 c\u00e2y \u0111\u01b0\u1ee3c hi\u1ec3n th\u1ecb \u1edf b\u00ean tr\u00e1i c\u1ee7a n\u00f3.",
  "navigation_link_logout": "\u0110\u0103ng xu\u1ea5t",
  "navigation_link_rates": "Gi\u00e1",
  "company_about": "Simplecoin l\u00e0 v\u0103n ph\u00f2ng trao \u0111\u1ed5i ti\u1ec1n t\u1ec7 c\u1ee7a S\u00e9c. M\u1ee5c ti\u00eau c\u1ee7a ch\u00fang t\u00f4i l\u00e0 l\u00e0m cho giao d\u1ecbch v\u1edbi Bitcoin v\u00e0 c\u00e1c lo\u1ea1i ti\u1ec1n k\u1ef9 thu\u1eadt s\u1ed1 kh\u00e1c \u0111\u01a1n gi\u1ea3n nh\u1ea5t c\u00f3 th\u1ec3. V\u1edbi Simplecoin b\u1ea1n c\u00f3 th\u1ec3 mua, b\u00e1n v\u00e0 trao \u0111\u1ed5i ti\u1ec1n \u0111i\u1ec7n t\u1eed r\u1ea5t d\u1ec5 d\u00e0ng.",
  "sidebar_navigation_orders": "Nh\u1eefng \u0111\u01a1n h\u00e0ng",
  "sidebar_navigation_profile": "H\u1ed3 s\u01a1",
  "sidebar_navigation_security": "B\u1ea3o v\u1ec7",
  "sidebar_navigation_settings": "C\u00e0i \u0111\u1eb7t",
  "company_header": "V\u1ec1 c\u00f4ng ty",
  "company_header_contacts": "Li\u00ean h\u1ec7",
  "company_header_social": "M\u1ea1ng x\u00e3 h\u1ed9i",
  "company_subheader": "TRAO \u0110\u1ed4I TI\u1ec0N T\u1ec6 \u0110\u01a0N GI\u1ea2N",
  "company_social_group": "Nh\u00f3m {{brandName}}",
  "company_header_billing_address": "\u0110\u1ecba ch\u1ec9 thanh to\u00e1n",
  "page_header_company": "Trao \u0111\u1ed5i Bitcoin & ti\u1ec1n \u0111i\u1ec7n t\u1eed {{brandName}} | BTC LTC ETH BCH XRP",
  "footer_sidebar_link_about": "V\u1ec1 ch\u00fang t\u00f4i",
  "client_order_reset_all_label": "\u0110\u1eb7t l\u1ea1i m\u1ecdi th\u1ee9",
  "client_order_search_btn": "T\u00ecm ki\u1ebfm \u0111\u01a1n h\u00e0ng",
  "transaction_accepted_by_banks_now_waiting_for_transaction_being_listed_on_bank_record": "Y\u00eau c\u1ea7u g\u1eedi ti\u1ec1n \u0111\u00e3 \u0111\u01b0\u1ee3c ng\u00e2n h\u00e0ng ch\u1ea5p nh\u1eadn v\u00e0 b\u00e2y gi\u1edd ch\u00fang t\u00f4i \u0111ang ch\u1edd giao d\u1ecbch \u0111\u01b0\u1ee3c x\u00e1c nh\u1eadn trong danh s\u00e1ch c\u00e1c giao d\u1ecbch ng\u00e2n h\u00e0ng g\u1eedi \u0111i.",
  "error": "l\u1ed7i",
  "client_bank_accounts_widget_header": "T\u00e0i li\u1ec7u t\u00e0i kho\u1ea3n ng\u00e2n h\u00e0ng",
  "new_return_to_client_waiting_for_transaction_to_be_matched_and_return_your_funds": "Ch\u00fang t\u00f4i \u0111ang ch\u1edd giao d\u1ecbch \u0111\u01b0\u1ee3c kh\u1edbp. Ch\u00fang t\u00f4i g\u1eedi l\u1ea1i khi c\u00f3 th\u00f4ng b\u00e1o v\u00ec h\u01b0\u1edbng \u0111\u1eb7t h\u00e0ng l\u00e0 \"tr\u1ea3 l\u1ea1i cho kh\u00e1ch h\u00e0ng\".",
  "page_subheader_settings_order_emails": "Email \u0111\u1eb7t h\u00e0ng",
  "page_subheader_settings_security_emails": "Email b\u1ea3o m\u1eadt",
  "client_setting_hint_send_email_when_order_confirming_incoming": "G\u1eedi email khi \u0111\u01a1n h\u00e0ng \u0111\u01b0\u1ee3c gh\u00e9p n\u1ed1i v\u1edbi giao d\u1ecbch ti\u1ec1n \u0111i\u1ec7n t\u1eed \u0111ang ch\u1edd x\u00e1c nh\u1eadn.",
  "client_setting_hint_send_email_when_order_confirming_outgoing": "G\u1eedi email khi ng\u00e2n h\u00e0ng ho\u1eb7c giao d\u1ecbch ti\u1ec1n \u0111i\u1ec7n t\u1eed \u0111ang ch\u1edd x\u00e1c nh\u1eadn",
  "client_setting_hint_send_email_when_order_done": "G\u1eedi email khi tr\u1ea1ng th\u00e1i \u0111\u01a1n h\u00e0ng thay \u0111\u1ed5i \u0111\u1ec3 th\u1ef1c hi\u1ec7n.",
  "client_setting_hint_send_email_when_order_proceed": "G\u1eedi email khi tr\u1ea1ng th\u00e1i \u0111\u01a1n h\u00e0ng thay \u0111\u1ed5i \u0111\u1ec3 ti\u1ebfn h\u00e0nh.",
  "client_setting_hint_send_email_when_order_received": "G\u1eedi email khi {{brandName}} nh\u1eadn \u0111\u01b0\u1ee3c thanh to\u00e1n ng\u00e2n h\u00e0ng cho \u0111\u01a1n \u0111\u1eb7t h\u00e0ng ho\u1eb7c thanh to\u00e1n b\u1eb1ng ti\u1ec1n \u0111i\u1ec7n t\u1eed \u0111\u01b0\u1ee3c x\u00e1c nh\u1eadn",
  "client_setting_hint_send_email_when_order_return_to_client": "G\u1eedi cho email khi h\u01b0\u1edbng c\u1ee7a m\u1ed9t s\u1ed1 \u0111\u01a1n \u0111\u1eb7t h\u00e0ng thay \u0111\u1ed5i th\u00e0nh tr\u1ea3 l\u1ea1i.",
  "client_setting_hint_send_email_when_order_suspended": "G\u1eedi email khi h\u01b0\u1edbng \u0111\u1eb7t h\u00e0ng \u0111\u01b0\u1ee3c thay \u0111\u1ed5i th\u00e0nh b\u1ecb \u0111\u00ecnh ch\u1ec9.",
  "client_settings_label_order_confirming_incoming": "X\u00e1c nh\u1eadn \u0111\u1ebfn",
  "client_settings_label_order_confirming_outgoing": "X\u00e1ch nh\u1eadn giao d\u1ecbch",
  "client_settings_label_order_done": "Xong",
  "client_settings_label_order_proceed": "Ti\u1ebfn h\u00e0nh",
  "client_settings_label_order_received": "Nh\u1eadn",
  "client_settings_label_order_return_to_client": "Tr\u1edf v\u1ec1 kh\u00e1ch h\u00e0ng",
  "client_settings_label_order_suspended": "\u0110\u00ecnh ch\u1ec9",
  "page_subheader_settings_general": "Chung",
  "profile_page_level1_my_document_subheader_paragraph": "B\u1ea5t k\u1ef3 t\u00e0i li\u1ec7u n\u00e0o kh\u00e1c c\u00f3 th\u1ec3 \u0111\u01b0\u1ee3c s\u1eed d\u1ee5ng \u0111\u1ec3 nh\u1eadn d\u1ea1ng tr\u1ef1c tuy\u1ebfn c\u1ee7a b\u1ea1n. B\u1ea1n c\u00f3 th\u1ec3 t\u1ea3i l\u00ean nhi\u1ec1u t\u1eadp tin \u1edf \u0111\u00e2y.",
  "profile_page_level2_my_document_subheader_paragraph": "B\u1ea1n c\u00f3 th\u1ec3 t\u1ea3i l\u00ean nhi\u1ec1u t\u1eadp tin \u1edf \u0111\u00e2y",
  "profile_page_level2_note_description": "N\u1ebfu \u0111\u0103ng k\u00fd l\u00e0 m\u1ed9t c\u00f4ng ty, h\u00e3y n\u00eau v\u1ecb tr\u00ed c\u1ee7a b\u1ea1n \u1edf \u0111\u00f3, t\u00ean c\u00f4ng ty v\u00e0 s\u1ed1 \u0111\u0103ng k\u00fd. Gi\u1ea3i th\u00edch m\u1ecdi t\u00ecnh hu\u1ed1ng kh\u00f4ng chu\u1ea9n nh\u01b0 nh\u1eadn thanh to\u00e1n t\u1eeb b\u00ean th\u1ee9 ba, \u0111\u1eb7t h\u00e0ng cho ng\u01b0\u1eddi kh\u00e1c, v.v.",
  "profile_page_level2_trading_purpose_description": "Vui l\u00f2ng cho bi\u1ebft m\u1ee5c \u0111\u00edch trao \u0111\u1ed5i c\u1ee7a b\u1ea1n l\u00e0 g\u00ec. Ch\u00fang t\u00f4i \u0111\u01b0\u1ee3c y\u00eau c\u1ea7u \u0111i\u1ec1u n\u00e0y b\u1edfi c\u00e1c nh\u00e0 qu\u1ea3n l\u00fd S\u00e9c.",
  "profile_page_level2_trading_source_of_income_description": "Vui l\u00f2ng m\u00f4 t\u1ea3 c\u00e1ch b\u1ea1n ki\u1ebfm \u0111\u01b0\u1ee3c ti\u1ec1n cho c\u00e1c giao d\u1ecbch c\u1ee7a b\u1ea1n. \u0110\u1ed1i v\u1edbi s\u1ed1 ti\u1ec1n r\u1ea5t l\u1edbn, ch\u00fang t\u00f4i s\u1ebd y\u00eau c\u1ea7u b\u1ea5t k\u1ef3 lo\u1ea1i t\u00e0i li\u1ec7u n\u00e0o c\u00f3 th\u1ec3 h\u1ed7 tr\u1ee3 cho tuy\u00ean b\u1ed1 c\u1ee7a b\u1ea1n. N\u1ebfu b\u1ea1n kh\u00f4ng th\u1ec3 t\u1ea3i l\u00ean b\u1ea5t k\u1ef3, vi\u1ebft r\u00f5 r\u00e0ng r\u1eb1ng kh\u00f4ng c\u00f3 b\u1eb1ng ch\u1ee9ng nh\u01b0 v\u1eady",
  "action_reset_password_error_email_not_sent": "Xin l\u1ed7i, ch\u00fang t\u00f4i kh\u00f4ng th\u1ec3 \u0111\u1eb7t l\u1ea1i m\u1eadt kh\u1ea9u cho email b\u1ea1n \u0111\u00e3 nh\u1eadp.",
  "action_reset_password_success": "H\u01b0\u1edbng d\u1eabn ti\u1ebfp theo \u0111\u00e3 \u0111\u01b0\u1ee3c g\u1eedi \u0111\u1ebfn email c\u1ee7a b\u1ea1n.",
  "address_was_deleted": "\u0110\u1ecba ch\u1ec9 \u0111\u00e3 b\u1ecb x\u00f3a.",
  "bank_account_was_added": "T\u00e0i kho\u1ea3n ng\u00e2n h\u00e0ng \u0111\u00e3 \u0111\u01b0\u1ee3c th\u00eam v\u00e0o.",
  "bank_account_was_deleted": "T\u00e0i kho\u1ea3n ng\u00e2n h\u00e0ng \u0111\u00e3 b\u1ecb x\u00f3a.",
  "bank_account_was_updated": "T\u00e0i kho\u1ea3n ng\u00e2n h\u00e0ng \u0111\u00e3 \u0111\u01b0\u1ee3c c\u1eadp nh\u1eadt.",
  "change": "Thay \u0111\u1ed5i",
  "change_password": "Thay \u0111\u1ed5i m\u1eadt kh\u1ea9u",
  "delete": "X\u00f3a",
  "email_confirmed": "Email c\u1ee7a b\u1ea1n \u0111\u00e3 \u0111\u01b0\u1ee3c x\u00e1c nh\u1eadn.",
  "enter_email_address_to_recover_account": "Vui l\u00f2ng \u0111i\u1ec1n email c\u1ee7a b\u1ea1n. M\u1ed9t link \u0111\u1ec3 thi\u1ebft l\u1eadp l\u1ea1i m\u1eadt kh\u1ea9u s\u1ebd \u0111\u01b0\u1ee3c g\u1eedi \u0111\u1ebfn \u0111\u00f3.",
  "file_was_deleted": "T\u1eadp tin \u0111\u00e3 b\u1ecb x\u00f3a ",
  "information_successfully_saved": "Th\u00f4ng tin c\u1ee7a b\u1ea1n \u0111\u00e3 \u0111\u01b0\u1ee3c l\u01b0u th\u00e0nh c\u00f4ng.",
  "new_address_was_added": "\u0110\u1ecba ch\u1ec9 m\u1edbi \u0111\u00e3 \u0111\u01b0\u1ee3c th\u00eam v\u00e0o.",
  "new_password_saved": "M\u1eadt kh\u1ea9u m\u1edbi \u0111\u01b0\u1ee3c l\u01b0u.",
  "order_has_been_created_guest": "\u0110\u01a1n h\u00e0ng \u0111\u00e3 \u0111\u01b0\u1ee3c t\u1ea1o ra. Vui l\u00f2ng ki\u1ec3m tra email c\u1ee7a b\u1ea1n \u0111\u1ec3 bi\u1ebft th\u00eam th\u00f4ng tin v\u1ec1 \u0111\u01a1n \u0111\u1eb7t h\u00e0ng n\u00e0y.",
  "pls_choose_new_password": "Vui l\u00f2ng ch\u1ecdn m\u1eadt kh\u1ea9u m\u1edbi c\u1ee7a b\u1ea1n:",
  "request_password_reset": "Y\u00eau c\u1ea7u \u0111\u1eb7t l\u1ea1i m\u1eadt kh\u1ea9u",
  "reset_password": "\u0110\u1eb7t l\u1ea1i m\u1eadt kh\u1ea9u",
  "save": "L\u01b0u l\u1ea1i",
  "security": "B\u1ea3o v\u1ec7",
  "send": "G\u1eedi",
  "signup": "\u0110\u0103ng k\u00fd",
  "update": "C\u1eadp nh\u1eadt",
  "are_you_sure_you_want_to_delete_this_file": "B\u1ea1n c\u00f3 ch\u1eafc ch\u1eafn mu\u1ed1n x\u00f3a t\u1eadp tin n\u00e0y?",
  "client_bank_account_message_warning_about_symbol": "* Bi\u1ec3u t\u01b0\u1ee3ng Simplecoin s\u1ebd \u0111\u01b0\u1ee3c th\u00eam v\u00e0o tin nh\u1eafn khi ch\u00fang t\u00f4i g\u1eedi ti\u1ec1n cho b\u1ea1n.",
  "from_amount_currency_label": "T\u1eeb s\u1ed1 l\u01b0\u1ee3ng",
  "to_amount_currency_label": "V\u1ec1 s\u1ed1 l\u01b0\u1ee3ng",
  "menu_guest_link_rates": "Gi\u00e1",
  "is_automatic_proceed_on_duplicate_payment": "T\u1ef1 \u0111\u1ed9ng \u0111\u1eb7t tr\u1ea1ng th\u00e1i \"Ti\u1ebfn h\u00e0nh\" cho c\u00e1c \u0111\u01a1n h\u00e0ng tr\u00f9ng l\u1eb7p",
  "is_automatic_proceed_on_late_payment": "T\u1ef1 \u0111\u1ed9ng x\u00e1c nh\u1eadn m\u1ee9c gi\u00e1 hi\u1ec7n t\u1ea1i cho c\u00e1c kho\u1ea3n thanh to\u00e1n tr\u1ec5",
  "setting_hint_is_automatic_proceed_on_duplicate_payment": "Khi c\u00f3 thanh to\u00e1n tr\u00f9ng l\u1eb7p m\u1edbi (thanh to\u00e1n kh\u1edbp v\u1edbi \u0111\u01a1n h\u00e0ng \u0111\u00e3 \u0111\u01b0\u1ee3c thanh to\u00e1n), h\u1ec7 th\u1ed1ng s\u1ebd t\u1ef1 \u0111\u1ed9ng sao ch\u00e9p \u0111\u01a1n h\u00e0ng \u0111\u00e3 thanh to\u00e1n.  Th\u00f4ng th\u01b0\u1eddng, \u0111\u01a1n h\u00e0ng \u0111\u00e3 sao ch\u00e9p \u0111\u00f3 b\u1ecb t\u1ea1m ng\u01b0ng v\u00e0 s\u1ebd ch\u1edd quy\u1ebft \u0111\u1ecbnh c\u1ee7a b\u1ea1n.  N\u1ebfu \u00f4 n\u00e0y \u0111\u01b0\u1ee3c ch\u1ecdn, \u0111\u01a1n \u0111\u1eb7t h\u00e0ng \u0111\u00e3 sao ch\u00e9p s\u1ebd \u0111\u01b0\u1ee3c c\u1eadp nh\u1eadt th\u00e0nh gi\u00e1 hi\u1ec7n t\u1ea1i v\u00e0 tr\u1ea1ng th\u00e1i \u0111\u01b0\u1ee3c \u0111\u1eb7t th\u00e0nh \"ti\u1ebfp t\u1ee5c\".  B\u1eb1ng c\u00e1ch n\u00e0y, b\u1ea1n c\u00f3 th\u1ec3 trao \u0111\u1ed5i ti\u1ec1n t\u1ec7 ch\u1ec9 b\u1eb1ng c\u00e1ch g\u1eedi thanh to\u00e1n.  Kh\u00f4ng c\u1ea7n t\u01b0\u01a1ng t\u00e1c v\u1edbi trang web c\u1ee7a ch\u00fang t\u00f4i.",
  "setting_hint_is_automatic_proceed_on_late_payment": "Khi \u0111\u1ebfn h\u1ea1n thanh to\u00e1n, h\u1ec7 th\u1ed1ng s\u1ebd t\u1ef1 \u0111\u1ed9ng ch\u1ea5p nh\u1eadn gi\u00e1 th\u1ecb tr\u01b0\u1eddng m\u1edbi v\u00e0 ti\u1ebfn h\u00e0nh \u0111\u1eb7t h\u00e0ng cho b\u1ea1n.  Theo m\u1eb7c \u0111\u1ecbnh, \u0111\u01a1n h\u00e0ng s\u1ebd b\u1ecb t\u1ea1m ng\u01b0ng v\u00e0 ch\u1edd b\u1ea1n quy\u1ebft \u0111\u1ecbnh.",
  "no_camera_found": "Kh\u00f4ng t\u00ecm th\u1ea5y m\u00e1y \u1ea3nh ho\u1eb7c quy\u1ec1n truy c\u1eadp kh\u00f4ng \u0111\u01b0\u1ee3c cung c\u1ea5p.",
  "scan_address": "Scan QR",
  "button_disable": "T\u1eaft",
  "confirm": "X\u00e1c nh\u1eadn",
  "header_disable_google_authenticator": "T\u1eaft x\u00e1c minh 2 b\u01b0\u1edbc (2FA)",
  "header_enable_google_authenticator": "B\u1eadt x\u00e1c minh 2 b\u01b0\u1edbc (2FA)",
  "label_2fa_password": "M\u1eadt kh\u1ea9u",
  "label_2fa_secret": "B\u00ed m\u1eadt",
  "2fa_secret_description": "C\u1ea3nh b\u00e1o: L\u01b0u m\u00e3 n\u00e0y \u1edf n\u01a1i an to\u00e0n. N\u00f3 c\u00f3 th\u1ec3 \u0111\u01b0\u1ee3c s\u1eed d\u1ee5ng \u0111\u1ec3 kh\u00f4i ph\u1ee5c quy\u1ec1n truy c\u1eadp v\u00e0o x\u00e1c th\u1ef1c 2 y\u1ebfu t\u1ed1 trong tr\u01b0\u1eddng h\u1ee3p \u0111i\u1ec7n tho\u1ea1i c\u1ee7a b\u1ea1n b\u1ecb m\u1ea5t.",
  "btn_disable": "t\u1eaft",
  "btn_enable": "K\u00edch ho\u1ea1t",
  "two_factor_authentication": "X\u00e1c th\u1ef1c hai y\u1ebfu t\u1ed1 (2FA)",
  "fill_information_below_to_disable_two_factor_authentication": "\u0110i\u1ec1n th\u00f4ng tin b\u00ean d\u01b0\u1edbi \u0111\u1ec3 t\u1eaft x\u00e1c th\u1ef1c hai y\u1ebfu t\u1ed1:",
  "alert_success_email_link_was_resent_again": "M\u1ed9t email m\u1edbi \u0111\u1ec3 x\u00e1c nh\u1eadn \u0111\u1ecba ch\u1ec9 email \u0111\u00e3 \u0111\u01b0\u1ee3c g\u1eedi.",
  "hint_login_form_2fa_code": "M\u00e3 kh\u00f4ng b\u1eaft bu\u1ed9c khi x\u00e1c th\u1ef1c hai y\u1ebfu t\u1ed1 kh\u00f4ng \u0111\u01b0\u1ee3c b\u1eadt tr\u00ean t\u00e0i kho\u1ea3n.",
  "footer_sidebar_link_terms": "\u0110i\u1ec1u kho\u1ea3n d\u1ecbch v\u1ee5",
  "button_download_order_pdf": "T\u1ea3i b\u1ea3n PDF",
  "button_hint_download_order_pdf": "T\u1ea3i th\u00f4ng tin \u0111\u1eb7t h\u00e0ng PDF",
  "alert_success_affiliate_payout_request_successfully_created": "Y\u00eau c\u1ea7u r\u00fat ti\u1ec1n li\u00ean k\u1ebft th\u00e0nh c\u00f4ng.",
  "alert_success_you_have_become_affiliate": "T\u00e0i kho\u1ea3n li\u00ean k\u1ebft c\u1ee7a b\u1ea1n v\u1eeba \u0111\u01b0\u1ee3c k\u00edch ho\u1ea1t. ",
  "button_create": "T\u1ea1o",
  "button_save": "L\u01b0u",
  "page_affiliate_dashboard_copy_url_header": "URL li\u00ean k\u1ebft",
  "page_affiliate_dashboard_overview_header": "t\u1ed5ng quan v\u1ec1 li\u00ean k\u1ebft",
  "page_affiliate_dashboard_overview_paragraph": "D\u01b0\u1edbi \u0111\u00e2y b\u1ea1n c\u00f3 th\u1ec3 xem m\u1ed9t b\u1ea3n t\u00f3m t\u1eaft c\u1ee7a t\u00e0i kho\u1ea3n gi\u1edbi thi\u1ec7u c\u1ee7a b\u1ea1n.",
  "page_affiliate_dashboard_paragraph": "Tr\u00ean trang n\u00e0y, b\u1ea1n c\u00f3 th\u1ec3 xem t\u00f3m t\u1eaft t\u1ed5ng th\u1ec3 v\u1ec1 t\u00e0i kho\u1ea3n gi\u1edbi thi\u1ec7u c\u1ee7a m\u00ecnh, s\u1ed1 li\u1ec7u th\u1ed1ng k\u00ea chi ti\u1ebft v\u00e0 y\u00eau c\u1ea7u r\u00fat ti\u1ec1n b\u1eb1ng b\u1ea5t k\u1ef3 lo\u1ea1i ti\u1ec1n n\u00e0o \u0111\u01b0\u1ee3c {{brandName}} h\u1ed7 tr\u1ee3.",
  "page_affiliate_statistics_button": "Th\u1ed1ng k\u00ea",
  "page_affiliate_stats_first_paragraph": "Tr\u00ean trang n\u00e0y, b\u1ea1n c\u00f3 th\u1ec3 xem s\u1ed1 li\u1ec7u th\u1ed1ng k\u00ea cho t\u00e0i kho\u1ea3n gi\u1edbi thi\u1ec7u c\u1ee7a b\u1ea1n. N\u00f3 \u0111\u00e3 \u0111\u01b0\u1ee3c s\u1eed d\u1ee5ng bao nhi\u00eau l\u1ea7n b\u1edfi kh\u00e1ch h\u00e0ng v\u00e0 khi n\u00e0o.",
  "page_affiliate_stats_label_conversion": "S\u1ed1 l\u1ea7n nh\u1ea5p v\u00e0o bi\u1ec3u ng\u1eef",
  "page_affiliate_stats_label_period": "Ng\u00e0y",
  "page_affiliate_withdraw_button": "r\u00fat ",
  "page_become_affiliate_first_paragraph": "N\u1ebfu b\u1ea1n mu\u1ed1n tr\u1edf th\u00e0nh \u0111\u01a1n v\u1ecb li\u00ean k\u1ebft c\u1ee7a ch\u00fang t\u00f4i, vui l\u00f2ng ch\u1ecdn h\u1ed9p ki\u1ec3m b\u00ean d\u01b0\u1edbi \u0111\u1ec3 x\u00e1c nh\u1eadn r\u1eb1ng b\u1ea1n \u0111\u1ed3ng \u00fd v\u1edbi c\u00e1c \u0111i\u1ec1u ki\u1ec7n li\u00ean k\u1ebft c\u1ee7a ch\u00fang t\u00f4i v\u00e0 g\u1eedi bi\u1ec3u m\u1eabu \u0111\u1ec3 ti\u1ebfp t\u1ee5c.",
  "page_header_affiliate_dashboard": "Trang t\u1ed5ng quan li\u00ean k\u1ebft",
  "page_header_become_affiliate": "Ch\u1edf th\u00e0nh ng\u01b0\u1eddi li\u00ean k\u1ebft",
  "sidebar_navigation_affiliate": "Li\u00ean k\u1ebft\n",
  "label_agree_with_affiliate_conditions": "T\u00f4i \u0111\u1ed3ng \u00fd v\u1edbi \u0111i\u1ec1u ki\u1ec7n li\u00ean k\u1ebft",
  "page_affiliate_withdraw_request_paragraph": "Ch\u1ecdn t\u00e0i kho\u1ea3n ho\u1eb7c \u0111\u1ecba ch\u1ec9 ng\u00e2n h\u00e0ng \u0111\u1ec3 r\u00fat ti\u1ec1n t\u1eeb s\u1ed1 d\u01b0 li\u00ean k\u1ebft c\u1ee7a b\u1ea1n.",
  "page_affiliate_finance_first_paragraph": "Tr\u00ean trang n\u00e0y, b\u1ea1n s\u1ebd th\u1ea5y danh s\u00e1ch c\u00e1c \u0111\u01a1n h\u00e0ng \u0111\u01b0\u1ee3c li\u00ean k\u1ebft v\u1edbi t\u00e0i kho\u1ea3n c\u1ee7a b\u1ea1n v\u00e0 l\u1ee3i nhu\u1eadn b\u1ea1n nh\u1eadn \u0111\u01b0\u1ee3c cho m\u1ed7i \u0111\u01a1n h\u00e0ng b\u1eb1ng EUR.",
  "page_affiliate_finance_label_order": "\u0110\u01a1n \u0111\u1eb7t h\u00e0ng",
  "page_affiliate_finance_button": "Thu nh\u1eadp",
  "page_affiliate_finance_label_profit_in_eur": "L\u1ee3i nhu\u1eadn (EUR)",
  "page_affiliate_asset_banners_paragraph": "B\u1ea1n bao g\u1ed3m c\u00e1c bi\u1ec3u ng\u1eef t\u01b0\u01a1ng t\u00e1c b\u00ean d\u01b0\u1edbi tr\u00ean trang web c\u1ee7a b\u1ea1n.  Sao ch\u00e9p b\u1ea5t k\u1ef3 m\u00e3 n\u00e0o b\u00ean d\u01b0\u1edbi v\u00e0 ch\u00e8n n\u00f3 v\u00e0o v\u1ecb tr\u00ed b\u1ea1n mu\u1ed1n n\u00f3 \u0111\u01b0\u1ee3c hi\u1ec3n th\u1ecb.",
  "page_affiliate_assets_button": "T\u00e0i li\u1ec7u qu\u1ea3ng c\u00e1o",
  "api_order_unsupported_currency_pair": "Ch\u00fang t\u00f4i kh\u00f4ng cho ph\u00e9p s\u1ef1 thay \u0111\u1ed5i n\u00e0y",
  "footer_sidebar_link_data_processing": "GDPR",
  "alert_success_ticket_created": "Y\u00eau c\u1ea7u \u0111\u00e3 \u0111\u01b0\u1ee3c t\u1ea1o th\u00e0nh c\u00f4ng.",
  "alert_success_ticket_reply_sent": "Tr\u1ea3 l\u1eddi cho y\u00eau c\u1ea7u th\u00e0nh c\u00f4ng.",
  "button_send": "G\u1eedi",
  "page_header_ticket_create": "Y\u00eau c\u1ea7u m\u1edbi",
  "page_header_ticket_list": "Y\u00eau c\u1ea7u",
  "page_header_ticket_reply": "Tr\u1ea3 l\u1eddi y\u00eau c\u1ea7u",
  "page_ticket_reply_you": "B\u1ea1n",
  "sidebar_navigation_ticket": "h\u1ed7 tr\u1ee3",
  "alert_success_ticket_closed": "Y\u00eau c\u1ea7u \u0111\u00e3 \u0111\u01b0\u1ee3c \u0111\u00e1nh d\u1ea5u l\u00e0 \u0111\u00e3 \u0111\u01b0\u1ee3c x\u1eed l\u00fd.",
  "button_create_support_ticket": "H\u1ed7 tr\u1ee3",
  "button_hint_create_support_ticket": "N\u1ebfu b\u1ea1n c\u00f3 b\u1ea5t k\u1ef3 c\u00e2u h\u1ecfi ho\u1eb7c v\u1ea5n \u0111\u1ec1 n\u00e0o v\u1edbi \u0111\u01a1n \u0111\u1eb7t h\u00e0ng n\u00e0y, b\u1ea1n c\u00f3 th\u1ec3 t\u1ea1o m\u1ed9t y\u00eau c\u1ea7u h\u1ed7 tr\u1ee3.",
  "page_tickets_pending_tickets": "Y\u00eau c\u1ea7u ch\u1edd x\u1eed l\u00fd",
  "page_tickets_solved_tickets": "Y\u00eau c\u1ea7u \u0111\u00e3 \u0111\u01b0\u1ee3c gi\u1ea3i quy\u1ebft",
  "page_tickets_no_pending_tickets": "Kh\u00f4ng c\u00f3 y\u00eau c\u1ea7u \u0111\u01b0\u1ee3c t\u1ea1o ho\u1eb7c \u0111ang ch\u1edd x\u1eed l\u00fd n\u00e0o.",
  "page_tickets_no_solved_tickets": "Ch\u01b0a c\u00f3 y\u00eau c\u1ea7u n\u00e0o \u0111\u01b0\u1ee3c gi\u1ea3i quy\u1ebft.",
  "take_selfie_button": "Ch\u1ee5p \u1ea3nh t\u1ef1 s\u01b0\u1edbng",
  "no_camera_access_provided": "Kh\u00f4ng c\u00f3 quy\u1ec1n truy c\u1eadp m\u00e1y \u1ea3nh \u0111\u01b0\u1ee3c cung c\u1ea5p. Vui l\u00f2ng ki\u1ec3m tra quy\u1ec1n c\u1ee7a m\u00e1y \u1ea3nh v\u00e0 \u0111\u1ea3m b\u1ea3o ch\u00fang kh\u00f4ng b\u1ecb t\u1eaft.",
  "menu_guest_link_privacy_policy": "Ch\u00ednh s\u00e1ch b\u1ea3o m\u1eadt",
  "change_password_social_reset_password_claim": "Vui l\u00f2ng nh\u1ea5n n\u00fat b\u00ean d\u01b0\u1edbi. Sau \u0111\u00f3 ch\u00fang t\u00f4i s\u1ebd g\u1eedi cho b\u1ea1n m\u1ed9t email c\u00f3 li\u00ean k\u1ebft cho ph\u00e9p b\u1ea1n thay \u0111\u1ed5i m\u1eadt kh\u1ea9u c\u1ee7a m\u00ecnh.",
  "alert_change_password_email_was_sent": "Vui l\u00f2ng ki\u1ec3m tra email c\u1ee7a b\u1ea1n, n\u01a1i b\u1ea1n c\u00f3 th\u1ec3 t\u00ecm th\u1ea5y li\u00ean k\u1ebft \u0111\u1ec3 \u0111\u1eb7t m\u1eadt kh\u1ea9u c\u1ee7a b\u1ea1n.",
  "page_confirm_two_factor": "X\u00e1c nh\u1eadn x\u00e1c minh 2 y\u1ebfu t\u1ed1",
  "page_confirm_two_factor_paragraph": "Vui l\u00f2ng nh\u1eadp m\u00e3 t\u1eeb \u1ee9ng d\u1ee5ng x\u00e1c minh 2 y\u1ebfu t\u1ed1 c\u1ee7a b\u1ea1n:",
  "cookie_consent_i_agree": "T\u00f4i hi\u1ec3u\n",
  "cookie_consent_message": "Trang web n\u00e0y s\u1eed d\u1ee5ng cookie \u0111\u1ec3 h\u1ed7 tr\u1ee3 \u0111i\u1ec1u h\u01b0\u1edbng v\u00e0 kh\u1ea3 n\u0103ng cung c\u1ea5p ph\u1ea3n h\u1ed3i v\u00e0 ph\u00e2n t\u00edch vi\u1ec7c s\u1eed d\u1ee5ng c\u1ee7a b\u1ea1n v\u1edbi c\u00e1c s\u1ea3n ph\u1ea9m v\u00e0 d\u1ecbch v\u1ee5 c\u1ee7a ch\u00fang t\u00f4i",
  "try_again_button": "Th\u1eed l\u1ea1i",
  "dropable_or_pastable_from_clipboard": "K\u00e9o t\u1eadp tin c\u1ee7a b\u1ea1n ho\u1eb7c d\u00e1n h\u00ecnh \u1ea3nh clipboard v\u00e0o khu v\u1ef1c n\u00e0y b\u1eb1ng c\u00e1ch nh\u1ea5n CTRL-V",
  "hint_enter_two_factor_code_to_confirm_action": "Nh\u1eadp m\u00e3 Google Authenticator.",
  "add_new_ticket": "th\u00eam y\u00eau c\u1ea7u m\u1edbi",
  "close_ticket": "\u0110\u00e1nh d\u1ea5u l\u00e0 \u0111\u00e3 gi\u1ea3i quy\u1ebft",
  "client_setting_hint_send_email_limit_price_changed": "G\u1eedi cho t\u00f4i m\u1ed9t email khi gi\u00e1 gi\u1edbi h\u1ea1n c\u1ee7a \u0111\u01a1n h\u00e0ng \u0111\u01b0\u1ee3c \u0111\u1eb7t ho\u1eb7c thay \u0111\u1ed5i.",
  "client_settings_label_order_limit_price_changed": "Gi\u00e1 gi\u1edbi h\u1ea1n",
  "button_set_limit": "\u0110\u1eb7t gi\u1edbi h\u1ea1n",
  "suspended_widget_header_limit_rate": "T\u1ef7 l\u1ec7 gi\u1edbi h\u1ea1n",
  "suspended_widget_header_limit_rate_hint": "B\u1eb1ng c\u00e1ch \u0111\u1eb7t t\u1ef7 l\u1ec7 gi\u1edbi h\u1ea1n, b\u1ea1n s\u1ebd t\u1ef1 \u0111\u1ed9ng ch\u1ea5p nh\u1eadn \u0111\u01a1n \u0111\u1eb7t h\u00e0ng khi t\u1ef7 l\u1ec7 th\u1ecb tr\u01b0\u1eddng s\u1ebd \u0111\u1ea1t \u0111\u1ebfn t\u1ef7 l\u1ec7 gi\u1edbi h\u1ea1n do b\u1ea1n \u0111\u1eb7t.",
  "suspended_widget_header_market_rate": "T\u1ec9 l\u1ec7 th\u1ecb tr\u01b0\u1eddng",
  "suspended_widget_header_market_rate_hint": "T\u1ef7 l\u1ec7 th\u1ecb tr\u01b0\u1eddng cho \u0111\u01a1n \u0111\u1eb7t h\u00e0ng c\u1ee7a b\u1ea1n m\u00e0 ch\u00fang t\u00f4i c\u00f3 th\u1ec3 cung c\u1ea5p cho b\u1ea1n. T\u1ea5t c\u1ea3 c\u00e1c kho\u1ea3n ph\u00ed \u0111\u00e3 \u0111\u01b0\u1ee3c bao g\u1ed3m, v\u00ec v\u1eady \u0111\u00e2y l\u00e0 t\u1ef7 l\u1ec7 ch\u00ednh x\u00e1c m\u00e0 b\u1ea1n s\u1ebd nh\u1eadn \u0111\u01b0\u1ee3c.",
  "suspended_widget_header_order_rate": "t\u1ef7 l\u1ec7 \u0111\u1eb7t h\u00e0ng",
  "suspended_widget_header_order_rate_hint": "\u0110\u00e2y l\u00e0 t\u1ef7 l\u1ec7 ch\u00fang t\u00f4i thi\u1ebft l\u1eadp cho b\u1ea1n v\u00e0o th\u1eddi \u0111i\u1ec3m b\u1ea1n t\u1ea1o \u0111\u01a1n h\u00e0ng m\u1edbi. N\u00f3 \u0111\u00e3 bao g\u1ed3m t\u1ea5t c\u1ea3 c\u00e1c kho\u1ea3n ph\u00ed.",
  "button_change_limit": "Thay \u0111\u1ed5i",
  "button_limit_cancel": "H\u1ee7y",
  "suspended_widget_limit_form_paragraph": "Hai l\u0129nh v\u1ef1c n\u00e0y \u0111\u1ea1i di\u1ec7n cho c\u00f9ng m\u1ed9t t\u1ef7 l\u1ec7. Gi\u00e1 tr\u1ecb c\u1ee7a tr\u01b0\u1eddng th\u1ee9 hai ch\u1ee9a gi\u00e1 tr\u1ecb ngh\u1ecbch \u0111\u1ea3o c\u1ee7a tr\u01b0\u1eddng th\u1ee9 nh\u1ea5t v\u00e0 ch\u00fang \u0111\u01b0\u1ee3c c\u1eadp nh\u1eadt t\u01b0\u01a1ng \u1ee9ng. N\u00f3 cho ph\u00e9p b\u1ea1n nh\u1eadp t\u1ef7 l\u1ec7 theo c\u00e1ch b\u1ea1n \u0111\u00e3 quen.",
  "hint_close_ticket_button": "N\u1ebfu b\u1ea1n b\u1ea5m v\u00e0o n\u00fat n\u00e0y, y\u00eau c\u1ea7u s\u1ebd \u0111\u01b0\u1ee3c \u0111\u00e1nh d\u1ea5u l\u00e0 \u0111\u00e3 gi\u1ea3i quy\u1ebft v\u00e0 b\u1ea1n s\u1ebd c\u1ea7n t\u1ea1o m\u1ed9t y\u00eau c\u1ea7u m\u1edbi n\u1ebfu b\u1ea1n s\u1ebd mu\u1ed1n li\u00ean l\u1ea1c th\u00eam.",
  "heading_chart": "\u0110\u1ed3 th\u1ecb",
  "button_close": "\u0110\u00f3ng",
  "boolean_option_no": "Kh\u00f4ng",
  "boolean_option_yes": "C\u00f3 ",
  "generic_link_learn_more": "T\u00ecm hi\u1ec3u th\u00eam",
  "profile_page_header_my_documents_level0_paragraph": "<strong> Vui l\u00f2ng \u0111\u00ednh k\u00e8m c\u00e1c t\u00e0i li\u1ec7u \u0111\u1ec3 nh\u1eadn d\u1ea1ng c\u1ee7a b\u1ea1n </strong> <br>\n Ch\u00fang t\u00f4i c\u1ea7n b\u1ea3n qu\u00e9t ch\u1ea5t l\u01b0\u1ee3ng t\u1ed1t (300dpi) ho\u1eb7c \u1ea3nh c\u00f3 th\u1ec3 \u0111\u1ecdc \u0111\u01b0\u1ee3c ho\u00e0n to\u00e0n, kh\u00f4ng th\u1ec3 ch\u1ec9nh s\u1eeda hay x\u00f3a ph\u1ea7n n\u00e0o c\u1ee7a b\u1ea5t k\u1ef3 t\u00e0i li\u1ec7u n\u00e0o\n \"T\u00e0i li\u1ec7u kh\u00e1c\" c\u00f3 th\u1ec3 l\u00e0 b\u1ea5t k\u1ef3 t\u00e0i li\u1ec7u n\u00e0o hi\u1ec3n th\u1ecb th\u00f4ng tin nh\u1eadn d\u1ea1ng c\u1ee7a con ng\u01b0\u1eddi, lo\u1ea1i v\u00e0 s\u1ed1 l\u01b0\u1ee3ng t\u00e0i li\u1ec7u id, qu\u1ed1c gia c\u1ea5p v\u00e0 c\u01a1 quan c\u00f3 th\u1ea9m quy\u1ec1n v\u00e0 th\u1eddi h\u1ea1n hi\u1ec7u l\u1ef1c.",
  "profile_page_header_my_documents_level1_paragraph": "<strong>Vui l\u00f2ng \u0111\u00ednh k\u00e8m c\u00e1c t\u00e0i li\u1ec7u nh\u01b0 sau:</strong><br>\n- b\u1ea5t k\u1ef3 t\u00e0i li\u1ec7u n\u00e0o c\u00f3 th\u1ec3 gi\u00fap ch\u1ee9ng minh ngu\u1ed3n thu nh\u1eadp c\u1ee7a b\u1ea1n<br>\n- \u1ea3nh b\u1ea1n \u0111ang gi\u1eef ID c\u1ee7a b\u1ea1n<br>\n- h\u1ed3 s\u01a1 \u0111\u0103ng k\u00fd c\u00f4ng ty, n\u1ebfu b\u1ea1n c\u00f3<br>\n- B\u1ea5t k\u1ef3 t\u00e0i li\u1ec7u kh\u00e1c c\u1ee7a b\u1ea1n m\u00e0 nh\u00e2n vi\u00ean c\u1ee7a ch\u00fang t\u00f4i y\u00eau c\u1ea7u",
  "chart_module_market_price_inverse": "\u0110\u1ea3o ng\u01b0\u1ee3c",
  "chart_module_no_data_available": "Kh\u00f4ng c\u00f3 d\u1eef li\u1ec7u",
  "bank_account_verified": "X\u00e1c minh",
  "profile_page_bank_account_header": "T\u00e0i kho\u1ea3n ng\u00e2n h\u00e0ng c\u1ee7a b\u1ea1n",
  "profile_page_bank_accounts_paragraph": "\u0110\u1ec3 nh\u1eadn d\u1ea1ng th\u00e0nh c\u00f4ng qua internet, ch\u00fang t\u00f4i c\u1ea7n x\u00e1c minh \u00edt nh\u1ea5t m\u1ed9t t\u00e0i kho\u1ea3n ng\u00e2n h\u00e0ng. Tr\u00ean trang t\u00e0i kho\u1ea3n thi\u1ebft l\u1eadp t\u00e0i kho\u1ea3n c\u1ee7a b\u1ea1n sau \u0111\u00f3 \u0111\u00ednh k\u00e8m t\u00e0i li\u1ec7u \u0111\u01b0\u1ee3c y\u00eau c\u1ea7u theo h\u01b0\u1edbng d\u1eabn ho\u1eb7c g\u1eedi m\u1ed9t kho\u1ea3n thanh to\u00e1n \u0111\u1ec3 x\u00e1c minh.",
  "bank_account_file_upload_unverified_level0_paragraph": "\u0110\u1ec3 ho\u00e0n th\u00e0nh nh\u1eadn d\u1ea1ng t\u1eeb xa c\u1ee7a b\u1ea1n (x\u00e1c minh h\u1ed3 s\u01a1 c\u1ea5p 1), ch\u00fang t\u00f4i s\u1ebd c\u1ea7n ch\u1ee9ng minh s\u1ef1 t\u1ed3n t\u1ea1i c\u1ee7a t\u00e0i kho\u1ea3n ng\u00e2n h\u00e0ng d\u01b0\u1edbi t\u00ean c\u1ee7a b\u1ea1n.\nVui l\u00f2ng \u0111\u00ednh k\u00e8m t\u00e0i li\u1ec7u do ng\u00e2n h\u00e0ng ph\u00e1t h\u00e0nh hi\u1ec3n th\u1ecb s\u1ed1 t\u00e0i kho\u1ea3n v\u00e0 t\u00ean ch\u1ee7 s\u1edf h\u1eefu. N\u00f3 c\u00f3 th\u1ec3 l\u00e0 m\u1ed9t b\u00e1o c\u00e1o t\u00e0i kho\u1ea3n ho\u1eb7c h\u1ee3p \u0111\u1ed3ng b\u1ea3o tr\u00ec t\u00e0i kho\u1ea3n. B\u1ea3n sao c\u1ee7a t\u00e0i li\u1ec7u gi\u1ea5y ho\u1eb7c t\u1eadp tin PDF \u0111\u01b0\u1ee3c ch\u1ea5p nh\u1eadn. Vui l\u00f2ng \u0111\u00ednh k\u00e8m t\u00e0i li\u1ec7u \u1edf \u0111\u00e2y.",
  "guest_market_table": "B\u1ea3ng gi\u00e1",
  "validation_error_not_matching_passwords": "M\u1eadt kh\u1ea9u kh\u00f4ng kh\u1edbp",
  "react_send_from_amount_here": "G\u1eedi {{amount}} <0>{{currency}}</0> \u0111\u1ebfn \u0111\u1ecba ch\u1ec9 n\u00e0y:",
  "react_we_received_your_payment_but_you_decided_to_get_it_we_will_send_it_shortly": "Ch\u00fang t\u00f4i \u0111\u00e3 nh\u1eadn \u0111\u01b0\u1ee3c kho\u1ea3n thanh to\u00e1n c\u1ee7a b\u1ea1n, nh\u01b0ng b\u1ea1n \u0111\u00e3 quy\u1ebft \u0111\u1ecbnh tr\u1ea3 n\u00f3. S\u1ed1 ti\u1ec1n {{amount}} <0>{{currency}}</0> ch\u00fang t\u00f4i s\u1ebd g\u1eedi ngay l\u1eadp t\u1ee9c.",
  "react_we_received_currency_from_your_order_and_we_will_send_you_currency_to": "Ch\u00fang t\u00f4i \u0111\u00e3 nh\u1eadn \u0111\u01b0\u1ee3c {{amountFrom}} <0>{{currencyFrom}}</0> cho \u0111\u01a1n \u0111\u1eb7t h\u00e0ng c\u1ee7a b\u1ea1n v\u00e0 ch\u00fang t\u00f4i s\u1ebd g\u1eedi cho b\u1ea1n {{amountTo}} <1>{{currencyTo}}</1>",
  "react_confirming_incoming_crypto_transaction_waiting_for_more_confirmations": "Ch\u00fang t\u00f4i c\u00f3 m\u1ed9t giao d\u1ecbch ti\u1ec1n \u0111i\u1ec7n t\u1eed \u0111\u1ebfn cho {{amount}} <0>{{currency}}</0>. Ch\u00fang t\u00f4i hi\u1ec7n \u0111ang ch\u1edd x\u00e1c nh\u1eadn.",
  "react_confirming_outgoing_crypto_transaction_waiting_for_more_confirmations": "Ch\u00fang t\u00f4i \u0111\u00e3 g\u1eedi m\u1ed9t giao d\u1ecbch ti\u1ec1n \u0111i\u1ec7n t\u1eed cho {{amount}} <0>{{currency}}</0>. Ch\u00fang t\u00f4i hi\u1ec7n \u0111ang ch\u1edd x\u00e1c nh\u1eadn th\u00eam v\u1ec1 c\u00e1c giao d\u1ecbch g\u1eedi \u0111i.",
  "react_suspended_widget_accept_btn": "Nh\u1eadn {{amount}} {{currency}}",
  "react_suspended_widget_return_btn": "Tr\u1ea3 l\u1ea1i {{amount}} {{currency}}",
  "react_order_created_high_market_volatility_message": "Do t\u00ednh bi\u1ebfn \u0111\u1ed9ng c\u1ee7a th\u1ecb tr\u01b0\u1eddng cao, ch\u00fang t\u00f4i ch\u1ec9 c\u00f3 th\u1ec3 gi\u1eef t\u1ef7 l\u1ec7 n\u00e0y trong {{th\u1eddi gian}} ph\u00fat. T\u1ed1c \u0111\u1ed9 giao d\u1ecbch ti\u1ec1n \u0111i\u1ec7n t\u1eed c\u1ee7a b\u1ea1n ph\u1ee5 thu\u1ed9c v\u00e0o ph\u00ed giao d\u1ecbch, v\u00ec v\u1eady \u0111i\u1ec1u quan tr\u1ecdng l\u00e0 ph\u1ea3i s\u1eed d\u1ee5ng ph\u00ed giao d\u1ecbch ch\u00ednh x\u00e1c \u0111\u1ec3 giao d\u1ecbch \u0111\u01b0\u1ee3c x\u00e1c minh s\u1edbm. N\u1ebfu kho\u1ea3n thanh to\u00e1n c\u1ee7a b\u1ea1n \u0111\u1ebfn sau th\u1eddi gian n\u00e0y v\u00e0 \u0111\u1ed3ng th\u1eddi, t\u1ef7 l\u1ec7 thay \u0111\u1ed5i \u0111\u00e1ng ch\u00fa \u00fd, ch\u00fang t\u00f4i s\u1ebd li\u00ean h\u1ec7 v\u1edbi b\u1ea1n qua email \u0111\u1ec3 cung c\u1ea5p c\u00e1c t\u00f9y ch\u1ecdn n\u00e0y - ch\u1ea5p nh\u1eadn t\u1ef7 gi\u00e1 h\u1ed1i \u0111o\u00e1i m\u1edbi v\u00e0 ti\u1ebfp t\u1ee5c \u0111\u1eb7t h\u00e0ng ho\u1eb7c tr\u1ea3 l\u1ea1i ti\u1ec1n c\u1ee7a b\u1ea1n.",
  "react_order_suspend_over_limit_message": "\u0110\u01a1n \u0111\u1eb7t h\u00e0ng c\u1ee7a b\u1ea1n \u0111\u00e3  b\u1ecb t\u1ef1 \u0111\u1ed9ng gi\u1eef v\u00ec b\u1ea1n \u0111\u00e3 \u0111\u1ea1t \u0111\u1ebfn gi\u1edbi h\u1ea1n giao d\u1ecbch h\u00e0ng th\u00e1ng t\u1ed1i \u0111a c\u1ee7a m\u00ecnh. Vui l\u00f2ng x\u00e1c minh c\u1ea5p t\u00e0i kho\u1ea3n c\u1ee7a b\u1ea1n {{level}}.",
  "chart_timeframe_option_all": "t\u1ea5t c\u1ea3",
  "react_bank_account_file_upload_unverified_paragraph": "Ch\u00fang t\u00f4i c\u0169ng s\u1ebd c\u1ea7n b\u1ea5t k\u1ef3 giao d\u1ecbch \u0111\u1ebfn t\u1eeb t\u00e0i kho\u1ea3n n\u00e0y \u0111\u1ec3 xem t\u00ean c\u1ee7a ch\u1ee7 s\u1edf h\u1eefu. N\u1ebfu b\u1ea1n g\u1eedi ti\u1ec1n t\u1eeb t\u00e0i kho\u1ea3n n\u00e0y cho ch\u00fang t\u00f4i, b\u1ea1n kh\u00f4ng c\u1ea7n ph\u1ea3i l\u00e0m g\u00ec c\u1ea3. Nh\u01b0ng n\u1ebfu b\u1ea1n b\u00e1n ti\u1ec1n \u0111i\u1ec7n t\u1eed v\u00e0 nh\u1eadn ti\u1ec1n trong t\u00e0i kho\u1ea3n n\u00e0y, h\u00e3y g\u1eedi cho ch\u00fang t\u00f4i m\u1ed9t \u0111\u1ed3ng xu koruna ho\u1eb7c \u0111\u1ed3ng xu euro cho m\u1ed9t trong nh\u1eefng <0>t\u00e0i kho\u1ea3n ng\u00e2n h\u00e0ng c\u1ee7a ch\u00fang t\u00f4i</0>.",
  "react_2fa_qr_code_description": "\u0110\u1ec3 b\u1ea3o m\u1eadt t\u1ed1t h\u01a1n, b\u1ea1n c\u00f3 th\u1ec3 b\u1eadt x\u00e1c minh 2 b\u01b0\u1edbc. B\u1ea1n ph\u1ea3i c\u00e0i \u0111\u1eb7t <0>Google Authenticator</0>. \u0110\u1ec3 k\u00edch ho\u1ea1t d\u1ecbch v\u1ee5, b\u1ea1n s\u1ebd d\u00f9ng \u1ee9ng d\u1ee5ng n\u00e0y \u0111\u1ec3 qu\u00e9t m\u00e3 QR t\u1eeb trang n\u00e0y, nh\u1eadp m\u1eadt kh\u1ea9u v\u00e0 m\u00e3 \u1ee9ng d\u1ee5ng \u0111\u00e3 t\u1ea1o cho b\u1ea1n.",
  "chart_timeframe_option_day": "Ng\u00e0y",
  "chart_timeframe_option_month": "Th\u00e1ng",
  "chart_timeframe_option_week": "Tu\u1ea7n",
  "chart_timeframe_option_year": "N\u0103m",
  "chart_timeframe_option_year_to_date": "N\u0103m n\u00e0y",
  "react_profile_page_level1_paragraph_before_form": "C\u1ea5p 1 cho ph\u00e9p giao d\u1ecbch h\u00e0ng th\u00e1ng l\u00ean t\u1edbi {{limit_level_2}} EUR trong {{tradeLimitDays}} ng\u00e0y.",
  "react_profile_page_level2_paragraph_before_form": "C\u1ea5p \u0111\u1ed9 2 cho ph\u00e9p kh\u00e1ch h\u00e0ng giao d\u1ecbch kh\u1ed1i l\u01b0\u1ee3ng t\u00edch l\u0169y tr\u00ean {{limit_level_2}} EUR trong {{tradeLimitDays}} ng\u00e0y. Trong m\u1ed9t s\u1ed1 tr\u01b0\u1eddng h\u1ee3p, c\u00e2u h\u1ecfi AML b\u1ed5 sung c\u00f3 th\u1ec3 \u0111\u01b0\u1ee3c ti\u1ebfn h\u00e0nh.",
  "react_page_header_posts_by_category": "Lo\u1ea1i: {{name}}",
  "chart_timeframe_option_3months": "3 th\u00e1ng",
  "ticket_load_more": "T\u1ea3i th\u00eam",
  "ticket_type_your_message": "Vi\u1ebft tin nh\u1eafn v\u00e0o \u0111\u00e2y...",
  "no_results_found": "Kh\u00f4ng c\u00f3 k\u1ebft qu\u1ea3.",
  "validation_field_required": "\u00d4 n\u00e0y b\u1eaft bu\u1ed9c",
  "validation_min_characters": "\u00cdt nh\u1ea5t ${min} ch\u1eef \u1edf trong \u00f4",
  "uploaded_file_information_complete": "Th\u00f4ng tin v\u1ec1 t\u00e0i li\u1ec7u n\u00e0y \u0111\u00e3 \u0111\u1ea7y \u0111\u1ee7",
  "uploaded_file_click_to_fill_information": "B\u1ea5m v\u00e0o \u0111\u00e2y \u0111\u1ec3 b\u1ed5 sung th\u00eam th\u00f4ng tin",
  "react_order_header": "\u0110\u01a1n h\u00e0ng {{id}}",
  "profile_page_header_my_personal_data": "Th\u00f4ng tin c\u00e1 nh\u00e2n",
  "validation_error_incorrect_phone_number": "S\u1ed1 \u0111i\u1ec7n tho\u1ea1i sai",
  "react_file_upload_extension_error": "Chi co nhung loai nay ban co the chiu cap:  {{extensions}}",
  "qr_code_scan_success": "QR code \u0111\u00e3 scan th\u00e0nh c\u00f4ng",
  "upload_success": "T\u1ea3i l\u00ean \u0111\u00e3 th\u00e0nh c\u00f4ng",
  "button_open_form": "M\u1edf m\u1eabu",
  "client_bank_trade_volume_label": "Gi\u00e1 tr\u1ecb \u0111\u01a1n h\u00e0ng cho t\u00e0i kho\u1ea3n ng\u00e2n h\u00e0ng",
  "qr_payment_hint": "B\u1ea1n c\u00f3 th\u1ec3 s\u1eed d\u1ee5ng QR d\u00e8 thanh to\u00e1n. Qu\u00e9t m\u00e3 b\u00ean d\u01b0\u1edbi b\u1eb1ng \u1ee9ng d\u1ee5ng ng\u00e2n h\u00e0ng th\u00f4ng minh trong \u0111i\u1ec7n tho\u1ea1i c\u1ee7a b\u1ea1n",
  "react_signup_to_get_trading_volume_or_personal_discount": "<0>\u0110\u0103ng nh\u1eadp</0> \u0111\u1ec3 nh\u1eadn kh\u1ed1i l\u01b0\u1ee3ng v\u00e0 gi\u1ea3m gi\u00e1 c\u00e1 nh\u00e2n",
  "sidebar_user_traded_volume": "{{tradeLimitDays}} ng\u00e0y: {{traded}}/{{limit}}EUR\n",
  "trading_limits_info_message": "Gi\u1edbi h\u1ea1n cho kh\u00e1ch h\u00e0ng m\u00e0 ch\u01b0a c\u00f3 x\u00e1c minh: {{limitLevel1}} EUR/{{level1TradeLimitDays}} ng\u00e0y <br>\nC\u1ea5p 1: {{limitLevel2}}  EUR/{{level2TradeLimitDays}} ng\u00e0y <br/>\nC\u1ea5p 2: kh\u00f4ng c\u00f3 gi\u1edbi h\u1ea1n",
  "sidebar_user_traded_volume_sum": "Kh\u1ed1i l\u01b0\u1ee3ng giao d\u1ecbch: {{giao d\u1ecbch}} EUR",
  "react_refund_widget_already_chosen_refund_address": "B\u1ea1n \u0111\u00e3 ch\u1ecdn {{refundAddress}} l\u00e0m \u0111\u1ecba ch\u1ec9 ho\u00e0n tr\u1ea3.",
  "react_we_received_your_crypto_payment_but_you_decided_to_get_it_we_will_send_it_shortly": "Ch\u00fang t\u00f4i \u0111\u00e3 nh\u1eadn \u0111\u01b0\u1ee3c ti\u1ec1n c\u1ee7a b\u1ea1n, nh\u01b0ng b\u1ea1n \u0111\u00e3 quy\u1ebft \u0111\u1ecbnh l\u1ea5y l\u1ea1i. T\u00f9y ch\u1ecdn tr\u1ea3 l\u1ea1i c\u00f3 ph\u00ed b\u1ed5 sung, b\u1ea1n s\u1ebd nh\u1eadn \u0111\u01b0\u1ee3c {{amount}} <0>{{currency}}</0> tr\u1edf l\u1ea1i \u0111\u1ecba ch\u1ec9 ho\u00e0n tr\u1ea3 c\u1ee7a b\u1ea1n.",
  "refund_widget_btn_change_refund_address": "Thay \u0111\u1ed5i \u0111\u1ecba ch\u1ec9 ho\u00e0n tr\u1ea3",
  "react_order_crypto_return_fee_info_message": "T\u00f9y ch\u1ecdn tr\u1ea3 l\u1ea1i c\u00f3 ph\u00ed b\u1ed5 sung, b\u1ea1n s\u1ebd nh\u1eadn \u0111\u01b0\u1ee3c {{amount}} <0>{{currency}}</0> tr\u1edf l\u1ea1i \u0111\u1ecba ch\u1ec9 ho\u00e0n tr\u1ea3 c\u1ee7a b\u1ea1n.",
  "react_suspended_widget_return_currency_btn": "Tr\u1ea3 l\u1ea1i {{amount}} {{currency}} cho v\u00ed n\u00e0y",
  "we_will_send_you_crypto_back_shortly": "Ch\u00fang t\u00f4i s\u1ebd g\u1eedi l\u1ea1i cho b\u1ea1n ti\u1ec1n ngay.",
  "button_remove_limit": "X\u00f3a gi\u1edbi h\u1ea1n",
  "react_alert_limit_removed": "\u0110\u00e3 lo\u1ea1i b\u1ecf gi\u1edbi h\u1ea1n th\u00e0nh c\u00f4ng",
  "unconfirmed_email_please_confirm_or_resend": "Vui l\u00f2ng x\u00e1c nh\u1eadn \u0111\u1ecba ch\u1ec9 email c\u1ee7a b\u1ea1n tr\u01b0\u1edbc ho\u1eb7c <0>g\u1eedi l\u1ea1i email k\u00edch ho\u1ea1t</0>",
  "button_back": "Tr\u1edf l\u1ea1i",
  "button_next": "Ti\u1ebfp theo",
  "button_submit_profile": "G\u1eedi h\u1ed3 s\u01a1",
  "you_need_to_log_out_before_seeing_anonymous_order": "B\u1ea1n c\u1ea7n ph\u1ea3i \u0111\u0103ng xu\u1ea5t tr\u01b0\u1edbc khi th\u1ea5y \u0111\u01a1n h\u00e0ng \u1ea9n danh",
  "react_form_rate_explenation": "Gi\u00e1 c\u1ee7a b\u1ea1n \u0111\u01b0\u1ee3c t\u00ednh nh\u01b0 th\u1ebf n\u00e0o?",
  "sign_up_to_return_guest_order": "\u0110\u1ec3 tr\u1ea3 l\u1ea1i \u0111\u01a1n \u0111\u1eb7t h\u00e0ng c\u1ee7a b\u1ea1n xin vui l\u00f2ng \u0111\u0103ng k\u00fd v\u00e0 th\u00eam v\u00ed.",
  "error_page_not_found": "Kh\u00f4ng t\u00ecm th\u1ea5y trang",
  "react_page_affiliate_withdraw_request_button": "R\u00fat {{amount}} {{currency}}",
  "affiliate_withdraw_history_table_converted_amount": "S\u1ed1 ti\u1ec1n \u0111\u00e3 chuy\u1ec3n \u0111\u1ed5i",
  "react_page_affiliate_dashboard_copy_url_paragraph": "Sao ch\u00e9p URL b\u00ean d\u01b0\u1edbi v\u00e0 chia s\u1ebb n\u00f3 tr\u00ean blog, ph\u01b0\u01a1ng ti\u1ec7n truy\u1ec1n th\u00f4ng x\u00e3 h\u1ed9i ho\u1eb7c v\u1edbi b\u1ea1n b\u00e8 c\u1ee7a b\u1ea1n.  B\u1ea5t k\u1ef3 ai nh\u1ea5p v\u00e0o li\u00ean k\u1ebft n\u00e0y v\u00e0 \u0111\u0103ng k\u00fd ho\u1eb7c t\u1ea1o \u0111\u01a1n h\u00e0ng trong v\u00f2ng {{duration}} ng\u00e0y s\u1ebd \u0111\u01b0\u1ee3c li\u00ean k\u1ebft v\u1edbi t\u00e0i kho\u1ea3n li\u00ean k\u1ebft c\u1ee7a b\u1ea1n.  B\u1ea1n s\u1ebd nh\u1eadn \u0111\u01b0\u1ee3c \u0111i\u1ec1u kho\u1ea3n t\u1eeb m\u1ecdi trao \u0111\u1ed5i \u0111\u01b0\u1ee3c th\u1ef1c hi\u1ec7n b\u1edfi ng\u01b0\u1eddi d\u00f9ng b\u1ea1n \u0111\u00e3 m\u1eddi.",
  "react_affiliaite_balance_information": "S\u1ed1 d\u01b0: {{balance}}<br/>T\u1ed5ng l\u1ee3i nhu\u1eadn: {{totalProfit}}",
  "react_affiliaite_balance_information_tooltip": "S\u1ed1 d\u01b0 l\u00e0 s\u1ed1 ti\u1ec1n hi\u1ec7n t\u1ea1i c\u1ee7a b\u1ea1n m\u00e0 b\u1ea1n c\u00f3 th\u1ec3 r\u00fat. T\u1ed5ng l\u1ee3i nhu\u1eadn l\u00e0 nh\u1eefng g\u00ec b\u1ea1n ki\u1ebfm \u0111\u01b0\u1ee3c k\u1ec3 t\u1eeb khi b\u1ea1n tham gia ch\u01b0\u01a1ng tr\u00ecnh li\u00ean k\u1ebft.",
  "label_affiliate_overview_joined_at": "Tham gia",
  "label_repeat_password": "L\u1eb7p l\u1ea1i m\u1eadt kh\u1ea9u",
  "react_consent_to_tos": "B\u1eb1ng c\u00e1ch ti\u1ebfp t\u1ee5c, t\u00f4i tuy\u00ean b\u1ed1 r\u1eb1ng t\u00f4i \u0111\u00e3 \u0111\u1ecdc v\u00e0 \u0111\u1ed3ng \u00fd v\u1edbi <0>\u0110i\u1ec1u kho\u1ea3n d\u1ecbch v\u1ee5</0> v\u00e0 <1>Ch\u00ednh s\u00e1ch quy\u1ec1n ri\u00eang t\u01b0</1> c\u1ee7a {{companyName}}.",
  "label_delete_account": "X\u00f3a t\u00e0i kho\u1ea3n",
  "button_delete_account": "X\u00f3a t\u00e0i kho\u1ea3n",
  "delete_account_warning_paragraph": "N\u1ebfu b\u1ea1n ti\u1ebfp t\u1ee5c, d\u1eef li\u1ec7u c\u1ee7a t\u00e0i kho\u1ea3n c\u1ee7a b\u1ea1n s\u1ebd b\u1ecb x\u00f3a v\u0129nh vi\u1ec5n.  Ch\u00fang t\u00f4i r\u1ea5t ti\u1ebfc khi th\u1ea5y b\u1ea1n \u0111i.  B\u1ea1n c\u00f3 th\u1ec3 \u0111\u0103ng k\u00fd l\u1ea1i b\u1ea5t c\u1ee9 l\u00fac n\u00e0o v\u1edbi c\u00f9ng m\u1ed9t \u0111\u1ecba ch\u1ec9 email nh\u01b0ng t\u00e0i kho\u1ea3n c\u1ee7a b\u1ea1n s\u1ebd tr\u1ed1ng.",
  "account_delete_success": "T\u00e0i kho\u1ea3n c\u1ee7a b\u1ea1n \u0111\u00e3 b\u1ecb x\u00f3a",
  "log_out_success": "B\u1ea1n \u0111\u00e3 \u0111\u01b0\u1ee3c \u0111\u0103ng xu\u1ea5t.",
  "session_time_remaining_label": "Th\u1eddi gian phi\u00ean c\u00f2n l\u1ea1i",
  "session_expire_in_label": "Phi\u00ean c\u1ee7a b\u1ea1n s\u1ebd h\u1ebft h\u1ea1n",
  "order_filter_label": "L\u1ecdc \u0111\u01a1n h\u00e0ng",
  "order_filter_button_all": "T\u1ea5t c\u1ea3",
  "to_currency_amount_field_label": "T\u00f4i mu\u1ed1n nh\u1eadn",
  "status_name_unfinished": "Ch\u01b0a xong",
  "status_description_unfinished": "\u0110\u01a1n h\u00e0ng ch\u01b0a c\u00f3 tr\u1ea1ng th\u00e1i cu\u1ed1i c\u00f9ng",
  "filter_drawer_incomplete_filters_warning": "Vui l\u00f2ng ch\u1ecdn \u00edt nh\u1ea5t m\u1ed9t tr\u1ea1ng th\u00e1i t\u1eeb m\u1ed7i danh m\u1ee5c",
  "help_pages_label": "Trung t\u00e2m tr\u1ee3 gi\u00fap",
  "react_file_upload_size_error": "C\u00e1c t\u1eadp tin b\u1ea1n \u0111\u00e3 c\u1ed1 g\u1eafng \u0111\u1ec3 t\u1ea3i l\u00ean l\u00e0 qu\u00e1 l\u1edbn. K\u00edch th\u01b0\u1edbc t\u1ec7p t\u1ed1i \u0111a l\u00e0 {{size}} MB",
  "alert_submit_previous_level_first": "Vui l\u00f2ng g\u1eedi c\u1ea5p x\u00e1c minh h\u1ed3 s\u01a1 tr\u01b0\u1edbc.",
  "wrong_vs_format_message": "Bi\u1ec3u t\u01b0\u1ee3ng variable n\u00ean ch\u1ee9a ch\u1eef s\u1ed1 (t\u1ed1i \u0111a 10)",
  "wrong_ss_format_message": "Bi\u1ec3u t\u01b0\u1ee3ng specific ch\u1ec9 n\u00ean ch\u1ee9a ch\u1eef s\u1ed1 (t\u1ed1i \u0111a 10)",
  "wrong_ks_format_message": "Bi\u1ec3u t\u01b0\u1ee3ng constant  n\u00ean ch\u1ee9a c\u00e1c ch\u1eef s\u1ed1 (t\u1ed1i \u0111a 4)",
  "button_extend_session": "Gia h\u1ea1n phi\u00ean",
  "fiat_payment_recipient_name": "T\u00ean ng\u01b0\u1eddi nh\u1eadn",
  "fiat_payment_recipient_street": "\u0111\u01b0\u1eddng ph\u1ed1 c\u1ee7a ng\u01b0\u1eddi nh\u1eadn",
  "fiat_payment_recipient_city": "Th\u00e0nh ph\u1ed1 ng\u01b0\u1eddi nh\u1eadn",
  "fiat_payment_recipient_country": "N\u01b0\u1edbc nh\u1eadn",
  "react_you_may_send_money_on_one_of_the_following_bank_accounts_singular": "G\u1eedi {{amount}} <0> {{curency}} </ 0> v\u00e0o t\u00e0i kho\u1ea3n ng\u00e2n h\u00e0ng sau",
  "crypto_qr_hint": "B\u1ea1n c\u00f3 th\u1ec3 qu\u00e9t m\u00e3 b\u00ean d\u01b0\u1edbi ho\u1eb7c nh\u1ea5p v\u00e0o n\u00f3 \u0111\u1ec3 m\u1edf v\u00ed tr\u00ean thi\u1ebft b\u1ecb c\u1ee7a b\u1ea1n.",
  "react_validation_error_insufficient_funds": "Kh\u00f4ng \u0111\u1ee7 ti\u1ec1n. B\u1ea1n ch\u1ec9 c\u00f3 {{amount}} trong s\u1ed1 {{currencyName}}.",
  "client_settings_label_affiliate_new_income": "Ti\u1ec1n m\u1edbi \u0111\u01b0\u1ee3c ghi c\u00f3",
  "client_setting_hint_affiliate_new_income": "G\u1eedi cho t\u00f4i m\u1ed9t email khi t\u00f4i nh\u1eadn \u0111\u01b0\u1ee3c t\u00edn d\u1ee5ng m\u1edbi v\u00e0o t\u00e0i kho\u1ea3n li\u00ean k\u1ebft c\u1ee7a m\u00ecnh.",
  "page_subheader_settings_affiliate": "li\u00ean k\u1ebft",
  "check_address_on_trezor_header": "Ki\u1ec3m tra \u0111\u1ecba ch\u1ec9 tr\u00ean Trezor/k\u00e9t",
  "check_address_on_trezor_body": "V\u00e0 nh\u1ea5n n\u00fat ti\u1ebfp t\u1ee5c tr\u00ean n\u00f3",
  "show_address_on_trezor_button_label": "Hi\u1ec3n th\u1ecb \u0111\u1ecba ch\u1ec9 tr\u00ean Trezor/k\u00e9t",
  "send_by_trezor_button": "G\u1eedi t\u1eeb trezor/k\u00e9t",
  "send_by_trezor_description_tooltip": "B\u1ea1n c\u00f3 th\u1ec3 s\u1eed d\u1ee5ng t\u00ednh n\u0103ng n\u00e0y \u0111\u1ec3 g\u1eedi thanh to\u00e1n m\u1ed9t c\u00e1ch an to\u00e0n t\u1eeb trezor/k\u00e9t \u0111\u00e3 \u0111\u01b0\u1ee3c k\u1ebft n\u1ed1i.",
  "trezor_address_import_dialog_header": "C\u00e1c \u0111\u1ecba ch\u1ec9 sau thu\u1ed9c v\u1ec1 t\u00e0i kho\u1ea3n trezor/k\u00e9t \u0111\u00e3 ch\u1ecdn. Vui l\u00f2ng ch\u1ecdn m\u1ed9t.",
  "import_from_trezor_button": "Nh\u1eadp kh\u1ea9u t\u1eeb trezor/k\u00e9t",
  "address_used": "\u0111\u00e3 s\u1eed d\u1ee5ng",
  "trezor_transaction_sent_dialog_title": "Giao d\u1ecbch \u0111\u00e3 \u0111\u01b0\u1ee3c g\u1eedi th\u00e0nh c\u00f4ng.",
  "trezor_transaction_sent_dialog_content": "Id giao d\u1ecbch l\u00e0: {{txid}} Vui l\u00f2ng \u0111\u1ee3i trong gi\u00e2y l\u00e1t cho \u0111\u1ebfn khi giao d\u1ecbch \u0111\u01b0\u1ee3c gh\u00e9p n\u1ed1i v\u1edbi \u0111\u01a1n \u0111\u1eb7t h\u00e0ng c\u1ee7a b\u1ea1n. B\u1ea1n c\u00f3 th\u1ec3 s\u1eed d\u1ee5ng bi\u1ec3u t\u01b0\u1ee3ng t\u1ea3i l\u1ea1i \u0111\u1ec3 l\u00e0m m\u1edbi \u0111\u01a1n h\u00e0ng.",
  "trezor_address_import_available_for_currencies": "Nh\u1eadp \u0111\u1ecba ch\u1ec9 trezor/k\u00e9t c\u00f3 s\u1eb5n cho: {{curencies}}",
  "trezor_link_learn_more": "T\u00ecm hi\u1ec3u th\u00eam v\u1ec1 c\u00e1ch s\u1eed d\u1ee5ng trezor/h\u1ea7m tr\u00ean {{brandName}}",
  "page_refresh_button_label": "L\u00e0m m\u1edbi",
  "profile_level_one_send_transfer_instead_of_document_upload": "T\u00f4i s\u1ebd g\u1eedi chuy\u1ec3n kho\u1ea3n ng\u00e2n h\u00e0ng t\u1eeb m\u1ed9t t\u00e0i kho\u1ea3n \u0111\u1ee9ng t\u00ean t\u00f4i thay v\u00ec t\u1ea3i l\u00ean m\u1ed9t t\u00e0i li\u1ec7u.",
  "profile_level1_primary_document_front_label": "M\u1eb7t tr\u01b0\u1edbc c\u1ee7a t\u00e0i li\u1ec7u ch\u00ednh",
  "profile_level1_primary_document_reverse_label": "M\u1eb7t sau c\u1ee7a t\u00e0i li\u1ec7u ch\u00ednh",
  "profile_level1_secondary_document_front_label": "M\u1eb7t tr\u01b0\u1edbc c\u1ee7a t\u00e0i li\u1ec7u ph\u1ee5",
  "profile_level1_secondary_document_reverse_label": "M\u1eb7t sau c\u1ee7a t\u00e0i li\u1ec7u ph\u1ee5",
  "profile_page_level1_primary_document_label": "T\u00e0i li\u1ec7u ch\u00ednh",
  "profile_page_level1_secondary_document_label": "T\u00e0i li\u1ec7u ph\u1ee5",
  "profile_page_level1_additional_documents_label": "T\u00e0i li\u1ec7u b\u1ed5 sung",
  "profile_page_level1_document_passport": "H\u1ed9 chi\u1ebfu",
  "profile_page_level1_document_national_id": "ID qu\u1ed1c gia",
  "profile_page_level1_document_other": "T\u00e0i li\u1ec7u kh\u00e1c",
  "profile_page_level1_document_driving_license": "B\u1eb1ng l\u00e1i xe",
  "profile_page_level1_primary_document_type_select_information": "Ch\u1ecdn lo\u1ea1i t\u00e0i li\u1ec7u ch\u00ednh c\u1ee7a b\u1ea1n",
  "profile_page_level1_secondary_document_type_select_information": "Ch\u1ecdn lo\u1ea1i t\u00e0i li\u1ec7u ph\u1ee5 c\u1ee7a b\u1ea1n",
  "profile_page_level1_additional_document_type_select_information": "Th\u00eam t\u00e0i li\u1ec7u b\u1ed5 sung",
  "profile_page_level1_additional_document_skip_label": "T\u00f4i kh\u00f4ng c\u00f3 b\u1ea5t k\u1ef3 t\u00e0i li\u1ec7u b\u1ed5 sung \u0111\u1ec3 t\u1ea3i l\u00ean",
  "address_format_bech32_wallet_support_warning": "\u0110\u1ecba ch\u1ec9 n\u00e0y l\u00e0 \u1edf \u0111\u1ecbnh d\u1ea1ng bech32 m\u00e0 kh\u00f4ng \u0111\u01b0\u1ee3c h\u1ed7 tr\u1ee3 \u0111\u1ea7y \u0111\u1ee7 b\u1edfi Trezor Wallet. \u0110\u1ed1i v\u1edbi \u0111\u1ea7y \u0111\u1ee7 ch\u1ee9c n\u0103ng vui l\u00f2ng ch\u1ecdn lo\u1ea1i  \"Segwit t\u00e0i kho\u1ea3n \" ho\u1eb7c  \"k\u1ebf th\u1eeba t\u00e0i kho\u1ea3n \". \u0110\u1ecba ch\u1ec9 \u1edf \u0111\u1ecbnh d\u1ea1ng bech32 b\u00e2y gi\u1edd c\u00f3 th\u1ec3 \u0111\u01b0\u1ee3c s\u1eed d\u1ee5ng trong v\u00ed electrum.org.",
  "label_document_title": "T\u00ean t\u00e0i li\u1ec7u",
  "react_new_app_version_available_dialog_title": "Phi\u00ean b\u1ea3n m\u1edbi c\u1ee7a {{brandName}} c\u00f3 s\u1eb5n",
  "update_application_version": "C\u1eadp nh\u1eadt",
  "third_party_guest_order_provide_email_paragraph": "\u0110\u1ec3 ti\u1ebfp t\u1ee5c vui l\u00f2ng cung c\u1ea5p \u0111\u1ecba ch\u1ec9 email.",
  "third_party_order_claim_email_verification_message": "Vui l\u00f2ng ki\u1ec3m tra email c\u1ee7a b\u1ea1n n\u01a1i b\u1ea1n c\u00f3 th\u1ec3 t\u00ecm th\u1ea5y m\u1ed9t li\u00ean k\u1ebft x\u00e1c nh\u1eadn.",
  "third_party_guest_order_confirm_or_resend_email": "Vui l\u00f2ng x\u00e1c nh\u1eadn \u0111\u1ecba ch\u1ec9 email c\u1ee7a b\u1ea1n ho\u1eb7c s\u1eed d\u1ee5ng n\u00fat b\u00ean d\u01b0\u1edbi \u0111\u1ec3 g\u1eedi l\u1ea1i.",
  "log_in_to_see_order_registered_client": "\u0110\u0103ng nh\u1eadp \u0111\u1ec3 xem \u0111\u01a1n h\u00e0ng",
  "client_recipients_list_header": "Ng\u01b0\u1eddi nh\u1eadn",
  "sidebar_navigation_recipients": "Ng\u01b0\u1eddi nh\u1eadn \n",
  "recipients_filter_label": "B\u1ed9 l\u1ecdc ng\u01b0\u1eddi nh\u1eadn",
  "client_add_new_recipient_button": "Th\u00eam ng\u01b0\u1eddi nh\u1eadn",
  "reset_filters_button": "Kh\u1edfi \u0111\u1ed9ng l\u1ea1i",
  "create_new_recipient_header": "Th\u00eam ng\u01b0\u1eddi nh\u1eadn m\u1edbi",
  "disable_social_login_label": "H\u1ee7y li\u00ean k\u1ebft t\u00e0i kho\u1ea3n m\u1ea1ng x\u00e3 h\u1ed9i",
  "btn_unlink": "H\u1ee7y li\u00ean k\u1ebft",
  "alert_error_please_set_password_to_disable_social_login": "Vui l\u00f2ng \u0111\u1eb7t m\u1eadt kh\u1ea9u t\u00e0i kho\u1ea3n tr\u01b0\u1edbc \u0111\u1ec3 h\u1ee7y li\u00ean k\u1ebft \u0111\u0103ng nh\u1eadp t\u00e0i kho\u1ea3n truy\u1ec1n th\u00f4ng x\u00e3 h\u1ed9i.",
  "disable_social_login_warning_paragraph": "N\u1ebfu b\u1ea1n ti\u1ebfp t\u1ee5c, t\u00e0i kho\u1ea3n x\u00e3 h\u1ed9i c\u1ee7a b\u1ea1n s\u1ebd b\u1ecb h\u1ee7y li\u00ean k\u1ebft. B\u1ea1n s\u1ebd ch\u1ec9 c\u00f3 th\u1ec3 \u0111\u0103ng nh\u1eadp b\u1eb1ng m\u1eadt kh\u1ea9u t\u00e0i kho\u1ea3n {{brandName}} c\u1ee7a b\u1ea1n.",
  "social_login_disable_success": "\u0110\u0103ng nh\u1eadp x\u00e3 h\u1ed9i v\u00f4 hi\u1ec7u ho\u00e1 th\u00e0nh c\u00f4ng",
  "file_attached_to_ticket_auto_message": "T\u1eadp tin \u0111\u00ednh k\u00e8m: {{fileNames}}",
  "network_error_warning": "R\u1ea5t ti\u1ebfc, c\u00f3 v\u1ebb nh\u01b0 b\u1ea1n \u0111ang offline",
  "password_strength_very_weak": "M\u1eadt kh\u1ea9u r\u1ea5t y\u1ebfu",
  "password_strength_weak": "M\u1eadt kh\u1ea9u y\u1ebfu",
  "password_strength_good": "M\u1eadt kh\u1ea9u \u0111\u01b0\u1ee3c ch\u1ea5p nh\u1eadn",
  "password_strength_great": "M\u1eadt kh\u1ea9u m\u1ea1nh",
  "status_description_on_hold": "\u0110\u1eb7t h\u00e0ng b\u1ecb t\u1ea1m gi\u1eef",
  "status_direction_description_on_hold": "\u0110\u1eb7t h\u00e0ng b\u1ecb t\u1ea1m gi\u1eef",
  "exchange_form_fee_included_tooltip_title": "T\u1ef7 l\u1ec7 b\u1ea1n th\u1ea5y bao g\u1ed3m ph\u00ed giao d\u1ecbch sau",
  "exchange_form_fee_not_included_tooltip_title": "Ph\u00ed giao d\u1ecbch sau kh\u00f4ng \u0111\u01b0\u1ee3c bao g\u1ed3m trong gi\u00e1 Hi\u1ec3n th\u1ecb. Ch\u1ec9 \u0111\u1ecbnh l\u01b0\u1ee3ng \u0111\u1ec3 xem t\u1ef7 l\u1ec7 cu\u1ed1i c\u00f9ng.",
  "exchange_form_total_fee_eur": "T\u1ed5ng ph\u00ed: ~{{totalFeeEur}} EUR",
  "exchange_form_fee_tooltip_fee_in": "Ph\u00ed giao d\u1ecbch \u0111\u1ebfn: {{amount}} {{Currency}}",
  "exchange_form_fee_tooltip_fee_out": "Ph\u00ed giao d\u1ecbch \u0111i: {{amount}} {{Currency}}",
  "exchange_form_fee_label_no_fee": "Mi\u1ec5n ph\u00ed",
  "exchange_form_fee_label_no_fee_explanation": "Giao d\u1ecbch n\u00e0y s\u1ebd kh\u00f4ng c\u00f3 ph\u00ed giao d\u1ecbch b\u1ed5 sung",
  "exchange_form_fee_label_included": "Bao g\u1ed3m ph\u00ed giao d\u1ecbch",
  "exchange_form_fee_label_excluded": "Kh\u00f4ng bao g\u1ed3m ph\u00ed giao d\u1ecbch",
  "order_view_back_to_vendor_button_label": "Quay l\u1ea1i {{providerName}}",
  "table_sort_label": "S\u1eafp x\u1ebfp",
  "pagination_next_page": "Trang ti\u1ebfp theo",
  "pagination_previous_page": "Trang tr\u01b0\u1edbc",
  "pagination_rows_per_page": "H\u00e0ng tr\u00ean m\u1ed7i trang",
  "pagination_display_rows": "c\u1ee7a",
  "button_download_csv": "T\u1ea3i  CSV",
  "button_print": "In",
  "table_view_columns": "Xem c\u00e1c c\u1ed9t",
  "table_filter": "B\u1ea3ng l\u1ecdc",
  "table_filters_label": "B\u1ed9 l\u1ecdc",
  "show_columns_label": "Hi\u1ec3n th\u1ecb c\u00e1c c\u1ed9t",
  "table_rows_selected": "(c\u00e1c) h\u00e0ng \u0111\u00e3 ch\u1ecdn",
  "add_account": "Th\u00eam t\u00e0i kho\u1ea3n",
  "account_balance": "s\u1ed1 d\u01b0",
  "account_minimal_balance_label": "S\u1ed1 d\u01b0 t\u1ed1i thi\u1ec3u:",
  "page_header_accounts": "Nh\u1eefng t\u00e0i kho\u1ea3n",
  "sidebar_navigation_accounts": "Nh\u1eefng t\u00e0i kho\u1ea3n",
  "sidebar_navigation_trade": "giao d\u1ecbch",
  "landing_page_chart_widget_buy_button": "Mua",
  "exchange_form_from_amount_label": "B\u1ea1n g\u1eedi",
  "exchange_form_to_amount_label": "B\u1ea1n l\u1ea5y",
  "footer_trading_since": "Giao d\u1ecbch ti\u1ec1n \u0111i\u1ec7n t\u1eed t\u1eeb n\u0103m 2013",
  "footer_headers_subscribe_to_newsletter": "Theo d\u00f5i b\u1ea3n tin c\u1ee7a ch\u00fang t\u00f4i",
  "footer_rights_reserved": "\u00a9 {{year}} {{companyName}}  \u0110\u00e3 \u0111\u0103ng k\u00fd B\u1ea3n quy\u1ec1n.",
  "landing_header": "Trao \u0111\u1ed5i ti\u1ec1n \u0111i\u1ec7n t\u1eed \u0111\u01b0\u1ee3c tin c\u1eady t\u1eeb n\u0103m 2013",
  "landing_subheader": "Giao d\u1ecbch d\u1ec5 d\u00e0ng.  <br /> Ho\u1ea1t \u0111\u1ed9ng tr\u00ean 30 c\u1eb7p ti\u1ec1n t\u1ec7.",
  "landing_button_join": "T\u00f4i \u0111\u1ed3ng \u00fd v\u1edbi t\u1ea5t c\u1ea3",
  "landing_button_about_us": "V\u1ec1 ch\u00fang t\u00f4i",
  "landing_recent_orders_header": "Nh\u1eefng \u0111\u01a1n \u0111\u1eb7t h\u00e0ng g\u1ea7n \u0111\u00e2y",
  "landing_statistics_30_day_order_count_label": "S\u1ed1 l\u01b0\u1ee3ng trao \u0111\u1ed5i \u0111\u01b0\u1ee3c th\u1ef1c hi\u1ec7n trong 30 ng\u00e0y qua",
  "landing_statistics_verified_clients_count": "nh\u1eefng kh\u00e1ch h\u00e0ng \u0111\u00e3 x\u00e1c minh",
  "landing_statistics_volume_in_currency": "Kh\u1ed1i l\u01b0\u1ee3ng 30 ng\u00e0y b\u1eb1ng {{currency}}",
  "landing_header_help": "Tr\u1ee3 gi\u00fap",
  "button_buy_now": "Mua ngay",
  "footer_header_support": "S\u01b0\u0323 giu\u0341p \u0111\u01a1\u0309",
  "footer_link_help": "Tr\u1ee3 gi\u00fap",
  "footer_header_company": "C\u00f4ng ty",
  "footer_header_features": "\u0110\u1eb7c tr\u01b0ng",
  "footer_features_trezor_support": "H\u1ed7 tr\u1ee3 trezor/k\u00e9t",
  "footer_features_limit_order": "Gi\u1edbi h\u1ea1n \u0111\u01a1n h\u00e0ng",
  "footer_email_newsletter_form_text": "Tham gia b\u1ea3n tin c\u1ee7a ch\u00fang t\u00f4i v\u00e0 b\u1ea1n s\u1ebd kh\u00f4ng bao gi\u1edd b\u1ecf l\u1ee1 b\u1ea5t k\u1ef3 \u0111i\u1ec1u g\u00ec quan tr\u1ecdng x\u1ea3y ra tr\u00ean {{brandName}}.",
  "landing_email_newsletter_subscribe_button": "\u0110\u0103ng k\u00fd",
  "landing_email_newsletter_subscribe_field_placeholder": "Vui l\u00f2ng nh\u1eadp \u0111\u1ecba ch\u1ec9 email t\u1ea1i \u0111\u00e2y",
  "landing_email_subscribe_consent_warning": "B\u1eb1ng c\u00e1ch nh\u1eadp email c\u1ee7a b\u1ea1n, b\u1ea1n \u0111\u1ed3ng \u00fd v\u1edbi <0>\u0110i\u1ec1u kho\u1ea3n d\u1ecbch v\u1ee5</0> v\u00e0 <1>Ch\u00ednh s\u00e1ch quy\u1ec1n ri\u00eang t\u01b0</1> c\u1ee7a ch\u00fang t\u00f4i",
  "footer_link_transaction_fees": "Ph\u00ed giao d\u1ecbch",
  "footer_link_trade_limits": "Gi\u1edbi h\u1ea1n giao d\u1ecbch",
  "add_the_following_comment_to_payment": "Th\u00eam nh\u1eadn x\u00e9t sau v\u00e0o thanh to\u00e1n",
  "alert_error_please_set_password_to_enable_two_factor": "Vui l\u00f2ng \u0111\u1eb7t m\u1eadt kh\u1ea9u t\u00e0i kho\u1ea3n tr\u01b0\u1edbc \u0111\u1ec3 k\u00edch ho\u1ea1t x\u00e1c th\u1ef1c hai y\u1ebfu t\u1ed1",
  "alert_success_google_authenticator_disabled_successfully": "\u0110\u00e3 t\u1eaft x\u00e1c th\u1ef1c hai y\u1ebfu t\u1ed1 th\u00e0nh c\u00f4ng.",
  "alert_success_google_authenticator_enabled_successfully": "X\u00e1c minh 2 b\u01b0\u1edbc \u0111\u00e3 \u0111\u01b0\u1ee3c b\u1eadt th\u00e0nh c\u00f4ng.",
  "confirmed": "\u0110\u00e3 x\u00e1c nh\u1eadn",
  "created_at": "T\u1ea1o t\u1ea1i",
  "enter_form_email_placeholder": "Email",
  "enter_form_password_placeholder": "M\u1eadt kh\u1ea9u",
  "fiat_payments_without_note_cause_delay_warning": "T\u1ea5t c\u1ea3 c\u00e1c kho\u1ea3n thanh to\u00e1n kh\u00f4ng c\u00f3 v\u0103n b\u1ea3n n\u00e0y s\u1ebd \u0111\u01b0\u1ee3c ki\u1ec3m tra th\u1ee7 c\u00f4ng c\u00f3 th\u1ec3 g\u00e2y ra s\u1ef1 ch\u1eadm tr\u1ec5 trong vi\u1ec7c x\u1eed l\u00fd c\u00e1c \u0111\u01a1n \u0111\u1eb7t h\u00e0ng!",
  "form_label_new_password": "M\u1eadt kh\u1ea9u m\u1edbi",
  "incoming_bank_transaction": "Giao d\u1ecbch ng\u00e2n h\u00e0ng m\u1edbi \u0111\u1ebfn",
  "incoming_crypto_transaction": "Giao d\u1ecbch ti\u1ec1n \u0111i\u1ec7n t\u1eed s\u1eafp t\u1edbi",
  "label_2fa_code": "M\u00e3 2FA",
  "label_account_constant": "S\u1ed1 constant",
  "label_account_label": "Nh\u00e3n hi\u1ec7u",
  "label_account_number": "S\u1ed1 t\u00e0i kho\u1ea3n",
  "label_account_specific": "S\u1ed1 specific",
  "label_account_vs": "S\u1ed1 variable",
  "label_address": "\u0110\u1ecba ch\u1ec9",
  "label_address_label": "Nh\u00e3n hi\u1ec7u",
  "label_addresses": "\u0110\u1ecba ch\u1ec9",
  "label_affiliate_overview_affiliate_id": "ID ng\u01b0\u1eddi gi\u1edbi thi\u1ec7u",
  "label_affiliate_overview_estimated_profit": "\u0111\u01a1n \u0111\u1eb7t h\u00e0ng \u0111ang \u0111\u01b0\u1ee3c ti\u1ebfn h\u00e0nh",
  "label_affiliate_overview_referrals_count": "Ng\u01b0\u1eddi d\u00f9ng \u0111\u01b0\u1ee3c m\u1eddi",
  "label_affiliate_overview_url_hits_count": "Nh\u1ea5p chu\u1ed9t bi\u1ec3u ng\u1eef",
  "label_amount": "S\u1ed1 l\u01b0\u1ee3ng",
  "label_arbitrary_data": "Th\u1ebb \u0111\u00edch",
  "label_bank_account": "T\u00e0i kho\u1ea3n ng\u00e2n h\u00e0ng",
  "label_bank_account_id": "T\u00e0i kho\u1ea3n ng\u00e2n h\u00e0ng",
  "label_bank_accounts": "T\u00e0i kho\u1ea3n ng\u00e2n h\u00e0ng",
  "label_birthday": "Ng\u00e0y sinh",
  "label_city": "Th\u00e0nh ph\u1ed1",
  "label_confirmations": "X\u00e1c nh\u1eadn",
  "label_country": "\u0110\u1ea5t n\u01b0\u1edbc",
  "label_created_at": "T\u1ea1o t\u1ea1i",
  "label_currency": "Ti\u1ec1n t\u1ec7",
  "label_current_password": "M\u1eadt kh\u1ea9u hi\u1ec7n t\u1ea1i",
  "label_document_number": "S\u1ed1 t\u00e0i li\u1ec7u",
  "label_expires_at": "C\u00f3 gi\u00e1 tr\u1ecb \u0111\u1ebfn",
  "label_first_name": "T\u00ean",
  "label_from_amount_currency": "T\u1eeb",
  "label_hash": "hash",
  "label_id": "ID",
  "label_in_block_id": "ID ch\u1eb7n",
  "label_is_politician": "Ng\u01b0\u1eddi ti\u1ebfp x\u00fac v\u1edbi ch\u00ednh tr\u1ecb",
  "label_issued_by": "Ph\u00e1t h\u00e0nh b\u1edfi",
  "label_issued_by_country": "N\u01b0\u1edbc xu\u1ea5t b\u1ea3n",
  "label_last_name": "H\u1ecd",
  "label_limit_price": "Gi\u00e1 gi\u1edbi h\u1ea1n",
  "label_message": "Tin nh\u1eafn",
  "label_monthly_volume": "Kh\u1ed1i l\u01b0\u1ee3ng h\u00e0ng th\u00e1ng",
  "label_new_password_again": "M\u1eadt kh\u1ea9u m\u1edbi m\u1ed9t l\u1ea7n n\u1eefa",
  "label_note": "L\u01b0u \u00fd",
  "label_other_account_number": "T\u00e0i kho\u1ea3n ng\u00e2n h\u00e0ng",
  "label_our_bank_account_id": "T\u00e0i kho\u1ea3n ng\u00e2n h\u00e0ng {{brandName}}",
  "label_owner_address": "\u0110\u1ecba ch\u1ec9 c\u1ee7a ch\u1ee7 t\u00e0i kho\u1ea3n",
  "label_owner_city": "Th\u00e0nh ph\u1ed1 c\u1ee7a ch\u1ee7 t\u00e0i kho\u1ea3n",
  "label_owner_country": "N\u01b0\u1edbc s\u1edf h\u1eefu",
  "label_owner_name": "T\u00ean ch\u1ee7 s\u1edf h\u1eefu",
  "label_paid_at": "\u0110\u00e3 tr\u1ea3 ti\u1ec1n",
  "label_personal_id": "S\u1ed1 sinh",
  "label_phone": "S\u1ed1 \u0111i\u1ec7n tho\u1ea1i",
  "label_rate": "T\u1ef7 l\u1ec7",
  "label_ready_for_verification": "S\u1eb5n s\u00e0ng \u0111\u1ec3 x\u00e1c minh",
  "label_receive_address": "\u0110\u1ecba ch\u1ec9 c\u1ee7a ng\u01b0\u1eddi nh\u1eadn",
  "label_status": "Tr\u1ea1ng th\u00e1i",
  "label_status_direction": "H\u01b0\u1edbng tr\u1ea1ng th\u00e1i",
  "label_status_final": "Tr\u1ea1ng th\u00e1i cu\u1ed1i c\u00f9ng",
  "label_street": "Ph\u1ed1",
  "label_ticket_body": "M\u00f4 t\u1ea3 v\u00e9",
  "label_ticket_category": "Lo\u1ea1i",
  "label_ticket_title": "Ch\u1ee7 \u0111\u1ec1 ",
  "label_to_amount_currency": "\u0110\u1ebfn",
  "label_trading_purpose": "M\u1ee5c \u0111\u00edch giao d\u1ecbch",
  "label_trading_source_of_income": "Ngu\u1ed3n thu nh\u1eadp",
  "label_zipcode": "M\u00e3 b\u01b0u \u0111i\u1ec7n",
  "mail_client_identification_bullet_point_fill_personal_information": "\u0110i\u1ec1n th\u00f4ng tin c\u00e1 nh\u00e2n",
  "oops_something_went_wrong": "R\u1ea5t ti\u1ebfc, \u0111\u00e3 x\u1ea3y ra l\u1ed7i. Vui l\u00f2ng th\u1eed l\u1ea1i sau.",
  "order_id": "ID \u0111\u01a1n h\u00e0ng",
  "outgoing_bank_transaction": "Giao d\u1ecbch ng\u00e2n h\u00e0ng g\u1eedi \u0111i",
  "outgoing_crypto_transaction": "Giao d\u1ecbch ti\u1ec1n \u0111i\u1ec7n t\u1eed g\u1eedi \u0111i",
  "page_header_settings": "C\u00e0i \u0111\u1eb7t",
  "password_was_changed": "M\u1eadt kh\u1ea9u \u0111\u00e3 \u0111\u01b0\u1ee3c thay \u0111\u1ed5i",
  "settings_updated": "C\u00e0i \u0111\u1eb7t \u0111\u00e3 \u0111\u01b0\u1ee3c c\u1eadp nh\u1eadt",
  "social_login_with_facebook": "\u0110\u0103ng nh\u1eadp vao facebook",
  "social_login_with_vkontakte": "\u0110\u0103ng nh\u1eadp vao Vkontakte",
  "social_signin_with_google": "\u0110\u0103ng nh\u1eadp vao Google",
  "social_signin_with_linkedin": "\u0110\u0103ng nh\u1eadp b\u1eb1ng LinkedIn",
  "status": "Tr\u1ea1ng th\u00e1i",
  "status_description_cancelled": "\u0110\u01a1n h\u00e0ng b\u1ecb h\u1ee7y",
  "status_description_confirming_incoming": "\u0110\u01a1n h\u00e0ng \u0111ang x\u00e1c nh\u1eadn giao d\u1ecbch m\u1edbi",
  "status_description_confirming_outgoing": "\u0110\u01a1n h\u00e0ng \u0111ang x\u00e1c nh\u1eadn giao d\u1ecbch g\u1eedi \u0111i m\u1edbi",
  "status_description_delivered": "\u0110\u1eb7t h\u00e0ng \u0111\u01b0\u1ee3c giao",
  "status_description_done": "\u0110\u01a1n h\u00e0ng \u0111\u00e3 ho\u00e0n th\u00e0nh",
  "status_description_new": "\u0110\u01a1n h\u00e0ng \u0111\u01b0\u1ee3c t\u1ea1o, ch\u01b0a c\u00f3 kho\u1ea3n thanh to\u00e1n n\u00e0o nh\u1eadn \u0111\u01b0\u1ee3c",
  "status_description_proceed": "\u0110\u01a1n h\u00e0ng s\u1ebd \u0111\u01b0\u1ee3c th\u1ef1c hi\u1ec7n nh\u01b0 d\u1ef1 \u0111\u1ecbnh. B\u1ea1n s\u1ebd nh\u1eadn \u0111\u01b0\u1ee3c m\u1ed9t kho\u1ea3n thanh to\u00e1n b\u1eb1ng lo\u1ea1i ti\u1ec1n b\u1ea1n \u0111\u00e3 ch\u1ecdn",
  "status_description_received": "\u0110\u01a1n h\u00e0ng \u0111\u00e3 nh\u1eadn \u0111\u01b0\u1ee3c giao d\u1ecbch ng\u00e2n h\u00e0ng/ti\u1ec1n \u0111i\u1ec7n t\u1eed v\u00e0 s\u1eb5n s\u00e0ng ti\u1ebfp t\u1ee5c",
  "status_description_return_to_client": "\u0110\u01a1n h\u00e0ng \u0111\u01b0\u1ee3c thi\u1ebft l\u1eadp \u0111\u1ec3 \u0111\u01b0\u1ee3c tr\u1ea3 l\u1ea1i cho kh\u00e1ch h\u00e0ng",
  "status_description_returned": "Giao d\u1ecbch c\u1ee7a \u0111\u01a1n h\u00e0ng \u0111\u00e3 \u0111\u01b0\u1ee3c tr\u1ea3 l\u1ea1i cho kh\u00e1ch h\u00e0ng",
  "status_description_suspended": "\u0110\u01a1n h\u00e0ng b\u1ecb \u0111\u00ecnh ch\u1ec9.  M\u1ed9t s\u1ed1 l\u00fd do c\u00f3 th\u1ec3 x\u1ea3y ra: v\u01b0\u1ee3t qu\u00e1 th\u1eddi gian thanh to\u00e1n, ghi ch\u00fa giao d\u1ecbch ng\u00e2n h\u00e0ng kh\u00f4ng ch\u00ednh x\u00e1c, v\u01b0\u1ee3t qu\u00e1 kh\u1ed1i l\u01b0\u1ee3ng h\u00e0ng th\u00e1ng ho\u1eb7c y\u00eau c\u1ea7u x\u00e1c minh ho\u1eb7c b\u1ea1n \u0111\u00e3 t\u1ea1m ng\u01b0ng \u0111\u01a1n h\u00e0ng theo c\u00e1ch th\u1ee7 c\u00f4ng",
  "ticket_category_affiliate": "Li\u00ean k\u1ebft",
  "ticket_category_api": "API",
  "ticket_category_business_verification": "X\u00e1c minh kinh doanh",
  "ticket_category_order": "\u0110\u01a1n h\u00e0ng",
  "ticket_category_other": "Kh\u00e1c",
  "ticket_category_security": "B\u1ea3o v\u1ec7",
  "ticket_category_suggestion": "G\u1ee3i \u00fd",
  "ticket_category_verification": "X\u00e1c minh",
  "validation_error_incorrect_email_format": "\u0110\u1ecbnh d\u1ea1ng email kh\u00f4ng ch\u00ednh x\u00e1c",
  "you_have_accepted_current_suspended_deal": "B\u1ea1n \u0111\u00e3 ch\u1ea5p nh\u1eadn th\u1ecfa thu\u1eadn b\u1ecb \u0111\u00ecnh ch\u1ec9 hi\u1ec7n t\u1ea1i",
  "close": "\u0111\u00f3ng",
  "status_on_hold": "Ch\u1edd",
  "submit": "G\u1eedi \u0111i",
  "resend": "g\u1eedi l\u1ea1i",
  "reviews": "\u0111\u00e1nh gi\u00e1",
  "label_counter_address": "\u0110\u1ecba ch\u1ec9 qu\u1ea7y",
  "react_you_may_send_money_on_one_of_the_following_bank_accounts_plural": "G\u1eedi {{amount}} <0>{{currency}}</0> v\u00e0o m\u1ed9t trong c\u00e1c t\u00e0i kho\u1ea3n ng\u00e2n h\u00e0ng sau",
  "order_filter_button_new": "M\u1edbi",
  "order_filter_button_in_progress": "Trong ti\u1ebfn tr\u00ecnh",
  "order_filter_button_finished": "\u0110\u00e3 k\u1ebft th\u00fac",
  "order_filter_button_custom": "T\u00f9y ch\u1ec9nh",
  "client_order_status_base_label": "Tr\u1ea1ng th\u00e1i",
  "client_order_status_direction_label": "H\u01b0\u1edbng",
  "client_order_status_final_label": "Tr\u1ea1ng th\u00e1i cu\u1ed1i c\u00f9ng",
  "ticket_status_solved": "\u0110\u00e3 gi\u1ea3i quy\u1ebft",
  "ticket_status_in_progress": "Trong ti\u1ebfn tr\u00ecnh",
  "ticket_status_waiting_for_admin": "\u0110ang ch\u1edd c\u00e2u tr\u1ea3 l\u1eddi t\u1eeb Simplecoin",
  "ticket_status_waiting_for_client": "\u0110ang ch\u1edd \u00fd ki\u1ebfn c\u1ee7a kh\u00e1ch h\u00e0ng",
  "member_of": "Th\u00e0nh vi\u00ean c\u1ee7a",
  "level_one_verification_warning_back_transfer_instead_of_statement": "Vui l\u00f2ng tham kh\u1ea3o <0>b\u00e0i vi\u1ebft n\u00e0y</0> \u0111\u1ec3 bi\u1ebft th\u00eam th\u00f4ng tin v\u1ec1 x\u00e1c minh t\u00e0i kho\u1ea3n ng\u00e2n h\u00e0ng",
  "label_bank_account_number": "Bank account number",
  "label_bank_account_details": "Bank account information",
  "validation_max_characters": "Field may only contain up to ${max} characters",
  "verification_required_if_order_made_warning_for_registered_clients": "In order to proceed with this order you will have to increase your verification level. Your order will be suspended automatically and you will have to accept new rate after the verification is done. For information about limits and verification visit <0>this page</0>.",
  "verification_required_if_order_made_warning_for_guests": "In order to proceed with this order you will have to sign up and then increase your verification level. Your order will be suspended automatically and you will have to accept new rate after the verification is done. For information about limits and verification visit <0>this page</0>.",
  "dialog_text_anonymous_trading_disabled_please_log_in_or_register": "Please log in or sign up to create an order",
  "get_verified_to_start_trading_warning_message": "You need to increase your verification level in order to start trading. You currently have <strong>level {{currentVerificationLevel}}</strong> and need <strong>level {{requiredVerificationLevel}}</strong> to start trading. Please go to the profile tab and fill in the verification form.",
  "offer_will_expire_in": "This offer expires",
  "offer_has_expired": "This offer has expired. Please create a new one on our partner's site or directly on <0>{{company}}</0>.",
  "order_offer_header": "{{companyName}} exchange offer",
  "verification_required_for_order_creation_partner_order": "It is now required to reach verification level {{level}} on {{brandName}} before processing your order. Please sign up and verify your profile in order to continue.",
  "label_specify_destination_tag": "Important: enter {{destinationTag}} as destination tag.",
  "validation_error_required_characters_exactly": "Field ${path} must be exactly ${length} characters",
  "csv_export_column_from_currency": "From currency",
  "csv_export_column_to_currency": "To currency",
  "react_order_crypto_no_fee_info_message": "We do not charge any fees for this order. You will receive full refund.",
  "login_confirmation_form_check_email_paragraph": "A verification code has been sent to your email. Please check your inbox and paste the code below to confirm your login.",
  "login_confirmation_title": "Email verification",
  "login_confirmation_email_resent": "Confirmation email send successfully",
  "login_confirmation_email_not_received": "Did not receive verification email? Please check your spam folder. It can take several minutes.",
  "login_confirmation_code_input_placeholder": "6 digit code",
  "lost_2fa_device_contact_support": "Lost your device? Contact support at {{companyEmail}} or {{companyPhone}} ",
  "higher_verification_limit_required_warning": "Please increase your verification level to see payment instructions",
  "verification_through_onfido_disclaimer": "Identity verification will be performed by Onfido.  ",
  "signup_and_verification_required_warning": "Please <0>sign up</0> and verify your account in order to proceed with your order.",
  "go_to_profile_button": "Go to profile",
  "profile_page_divex_address_ownership_disclaimer_heading": "Wallet security statement",
  "profile_page_divex_address_ownership_disclaimer_message": "Please attach a scan or a photocopy of the declaration of wallet ownership which shows that you are the only one with access to the cryptocurrency wallet that you will use within Divex.",
  "profile_page_divex_address_ownership_disclaimer_warning": "The statement is required to ensure that your advisor does not have access to your cryptocurrency wallet and therefore can not steal your funds in future. </br> If you would like to know more about recommended wallet security, <0>click here</0>.",
  "signup_for_invited_only_divex_message": "Registration is only for Divex exchange clients. </br>\nDo you want to become a client? Contact one of our partners to help you register and set up the entire process. </br>\nThe list of all partners is available <0>here</0>.",
  "divex_address_ownership_disclaimer_upload_label": "Attach document here",
  "email_verification_token_expired_log_in_again": "This verification link is incorrect or has expired. Please log in again",
  "pagination_total_visible_rows": "{visibleCount} of {totalCount}",
  "phone_hint_include_prefix": "Please provide the number with prefix",
  "sidebar_navigation_transactions": "Transactions",
  "account_balance_formatted": "Balance: {{balanceWithCurrency}}",
  "transfer_sent_successfully": "Transfer sent successfully",
  "cookie_consent_i_agree_to_all": "Enable all",
  "cookie_consent_i_agree_to_required": "Enable functional cookies only",
  "divex_affil_find_more": "Find out more about affiliate program in <0>this article.<0/>",
  "button_i_confirm": "I confirm",
  "wallet_ownership_declaration_paragraph_1": "I have been advised to use the Trezor hardware wallet for storage of digital assets and use it with PIN code.",
  "wallet_ownership_declaration_paragraph_2": "I am the owner of the master seed (12-24 words) that I have stored securely. I am aware that the cryptocurrencies in the wallet can be spent using the master seed, even without the device itself.",
  "wallet_ownership_declaration_paragraph_3": "The advisor who was helping me set up my wallet did not have access to my master seed and could not make a copy of it.",
  "page_header_exchanges": "Exchange currencies",
  "sidebar_navigation_dashboard": "Dashboard",
  "dashboard_header": "Dashboard",
  "page_header_transfers": "Transfers",
  "settings_button_general_settings": "General",
  "settings_button_security_settings": "Security",
  "settings_button_email_settings": "Rules for sending emails",
  "settings_button_profile_verification": "Profile verification",
  "action_activate_account_email_sent": "We have sent you an e-mail with link for activation of your password to the e-mail address.",
  "action_activate_account_error_email_not_sent": "The e-mail you have provided does not match account that may be activated this way. Please choose another e-mail.",
  "request_account_activation": "Account activation",
  "account_activation_enter_email_provided_to_support": "Activate your Divex account by entering the email you used to register for the Introduction to Bitcoin or Diverzo Academy course.",
  "account_activate_button": "Activate account",
  "account_activation_password_saved": "Your password has been saved. You can now log in.",
  "breadcrumb_category_order": "Order",
  "breadcrumb_subcategory_order_id": "Order #{{id}}",
  "page_header_general_settings": "General settings",
  "page_header_email_settings": "Email rules",
  "dashboard_order_table_last_orders": "Last orders",
  "dashboard_order_table_all_orders": "All orders",
  "user_discount": "Your discount",
  "get_discount": "Get discount",
  "volume_discount": "Volume discount",
  "personal_discount": "Personal discount",
  "ticket_status_all": "All",
  "id": "ID",
  "exchange_form_total_fee": "Total fees",
  "exchange_form_out_fee_crypto": "Outgoing transaction fee",
  "exchange_form_out_fee_fiat": "Outgoing bank transfer fee",
  "exchange_form_in_fee_crypto": "Incoming network fee",
  "exchange_form_in_fee_fiat": "Incoming bank transfer fee",
  "support": "Support",
  "tickets": "Tickets",
  "ticket": "Ticket",
  "message": "Message",
  "upload_file": "Upload file",
  "you": "You",
  "page_become_affiliate_partner_header": "Become Simplecoin <0>affiliate</0> partner",
  "page_become_affiliate_partner_bonus": "Recommend our exchange office to people and get a <strong>  commission of <br> up to {{affiliatePercent}}%</strong> of each exchange they make.",
  "page_become_affiliate_partner_involved": "I want to get involved",
  "page_become_affiliate_partner_information": "More information",
  "page_affiliate_dashboard_header": "Affiliate program",
  "page_affiliate_dashboard_current_balance_header": "Current balance",
  "page_affiliate_dashboard_current_balance_withdraw": "Withdraw balance",
  "order_related_support": "Support related to order",
  "page_affiliate_dashboard_total_profit": "Total profit",
  "page_become_affiliate_partner_copy_link_header": "Your affiliate link",
  "page_become_affiliate_partner_bonus_start": "Start of cooperation:",
  "page_become_affiliate_partner_bonus_tip": "<strong> Tip! </strong> Also use pre-made promotional banners in various sizes and motifs.",
  "page_become_affiliate_partner_promotional": "Show promotional materials",
  "document_issued_by_government": "Document issued by government",
  "document_back_side_scan": "Document back side scan",
  "document_front_side_scan": "Document front side scan",
  "document_type": "Document type",
  "scan_quality_warning": "Good quality scans (300dpi) or photos which are fully readable are required. No parts of any document can be edited nor removed.",
  " page_become_affiliate_partner_income_header": "Overview of income",
  "page_become_affiliate_partner_withdrawal_header": "Overview of withdrawal",
  "page_become_affiliate_partner_statistics_header": "Overwiew of statistics",
  "secondary_document": "Secondary document",
  "selfie_with_id": "Selfie with ID card",
  "affiliate_withdraw_breadcrumb": "Withdraw",
  "affiliate_withdraw_header": "Withdraw affiliate balance",
  "affiliate_withdraw_info_alert": "Your affiliate balance is <strong>{{balanceCurrencyEur}}</strong>. You may withdraw it in any of the supported currencies. You can see the converted amount after selecting the currency and recipient. ",
  "bank_account_create_form_header": "Add a new {{currencyCode}} bank account",
  "crypto_address_create_form_header": "Add a new {{currencyCode}} wallet",
  "settings_social_create_password_button": "Set password",
  "recipient_page_table_name": "Name",
  "recipient_page_table_address": "Address / Bank account no.",
  "recipient_page_table_currency": "Currency",
  "recipient_page_table_header": "List",
  "wallet_welcome_page": "You are about to go to <br /> Simplecoin Wallet",
  "wallet_welcome_page_condions": "I agree with the terms and <0>conditions</0> of Simplecoin Wallet",
  "wallet_welcome_page_button": "Continue to Simplecoin Wallet",
  "wallet_welcome_page_benefits_1": "Create a portfolio at Simplecoin",
  "wallet_welcome_page_benefits_2": "Easy deposit of funds",
  "wallet_welcome_page_benefits_3": "Trade cryptocurrencies easier and faster",
  "wallet_welcome_page_benefits_4": "More advantageous shift fees",
  "wallet_welcome_page_benefits_5": "Priority support",
  "verification_transaction": "Verification transaction",
  "verification_call_info": "As part of the verification process, we also need to verify your phone number, so we will call you to confirm that the verification was completed without any problems. You can choose the approximate time that is most convenient for you for the final phone call.",
  "verification_call": "Verification call",
  "when_should_we_call": "When should we call you",
  "asap": "As soon as possible",
  "email": "E-mail",
  "verification_and_limits": "Verifications and limits",
  "profile_verification": "Profile verification",
  "limits": "Limits",
  "start_with_verification": "Start with verification",
  "verification_in_progress": "Verification in progress",
  "level1": "Level 1",
  "done": "Done",
  "ok": "OK",
  "profile_verification_done_info": "We've emailed you the verification payment information in case you want to get back to them. We will let you know by email that you have been verified.",
  "sidebar_navigation_portfolios": "Porfolio",
  "page_dashboard_insert_deposit": "Deposit funds",
  "page_trade_head_title": "Trade",
  "transaction_tabs_title_exchange": "Exchange",
  "transaction_tabs_title_deposit": "Deposit",
  "transaction_tabs_title_withdraw": "Withdraw",
  "transaction_tabs_title_transfer": "Transfer",
  "label_summary": "Summary",
  "increase_limit": "Increase limit",
  "read_only": "Read-only",
  "page_portfolio_header": "Portfolio",
  "amount": "Amount",
  "purpose_of_crypto_trading": "Purpose of trading with cryptocurrencies",
  "what_is_purpose_of_crypto_trading": "What is your purpose of trading with cryptocurencies",
  "get_familiar_with_cryptocurrencies": "Get familiar with cryptocurencies",
  "occasional_buing_selling": "Occasional buying/selling",
  "one_off_investment": "One-off investment",
  "regular_savings": "Regular savings",
  "other": "Other",
  "speculations": "Speculations",
  "describe_your_purpose_of_cryptotrading": "Describe your purpose of trading with cryptocurrencies",
  "today": "today",
  "morning": "morning",
  "evening": "evening",
  "afternoon": "afternoon",
  "tomorrow": "tomorrow",
  "proof_of_income": "Proof of income",
  "proof_of_income_info": "The Anti-Money Laundering and Anti-Terrorism Financing Act (AML Act) requires to verify the source of your income if you want to trade with higher amounts. We therefore need to ask you some questions about the origin of the money you want to trade with us.",
  "income": "Income",
  "one-off_income": "one-off income",
  "monthly_income": "monthly income",
  "quarterly_income": "quarterly income",
  "yearly_income": "yearly income",
  "frequency": "Frequency",
  "source_of_income": "Source of income",
  "employment": "Employment",
  "position_in_company": "Position in company",
  "company_name": "Company name",
  "running_own_business": "Running own business",
  "rental": "Rental",
  "share_of_profit": "Share of profit",
  "pension": "Pension",
  "documents_supporting_the_income": "Documents supporting the income",
  "income_source_description": "Description",
  "add_income": "Add next income",
  "footer_sidebar_link_blog": "Blog",
  "proof_of_basic_income": "Proof of basic income",
  "proof_of_basic_income_info": "In order to set at least a basic trading and deposit limit, we need to know if you have at least a minimum income. There is no problem to increase this basic limit later if you want to trade \"at full throttle\". Even if you don't have that income, it is still OK. Later you will describe the source of the funds you want to trade with and we will set the limit accordingly.",
  "step": "Step",
  "basic_income_agreement": "I confirm that my income corresponds to at least 600 EUR per month",
  "semi-yearly_income": "semi-yearly income",
  "incomes": "Incomes",
  "income_state_submited": "awaits approval",
  "income_state_approved": "approved",
  "income_state_new": "new",
  "updating_incomes_info": "Any modification of your incomes (adding a new income or deleting an existing one) must be assessed by us and we will adjust your trading limit accordingly.",
  "submit_for_approval": "Submit for approval",
  "income_state_unapproved_change": "unapproved change",
  "prooved_incomes": "Prooved incomes",
  "verification_and_incomes": "Verification and incomes",
  "trading_limits": "Trading limits",
  "current_allowed_trading_amount": "Allowed amount for trading",
  "current_allowed_trading_amount_info": "This is the maximum amount you can right now deposit into Simplecoin Wallet or exchange in Simplecoin Exchange. This amount changes over time depending on how often you trade. It can gradually decrease to zero or increase back to your original limit. Only paid orders and confirmed deposits counts.",
  "individual_limits": "Individual limits",
  "time_period": "Time period",
  "limit_extension": "Limit extension",
  "remaining_limit_amount": "Remaining amount you can still draw down",
  "state_of_limit_execution": "State of limit execution",
  "in_time_period": "in",
  "individual_trading_limits_info": "If you have more individual limits, you must meet all of them. The one that is currently the most restrictive is always applied.",
  "trading_limit_extension_info": "If the standard limit is not enough for you, we can increase your limit even further by providing proof of one-off income. We will gradually reduce this limit whenever you trade above the standard limit.",
  "income_state_deleted": "deleted",
  "account_update_success": "Account was updated",
  "account_label_edit": "Edit account",
  "account_action_archive": "Archive account",
  "waiting_for_payment": "waiting for payment",
  "waiting_for_payment_approval": "waiting for payment approval",
  "verification_transaction_info": "For successful remote identification, you will need to verify at least one of your bank accounts (in your name). For this we only need to receive a single euro from this account.",
  "verification_transaction_received_info": "The verification transaction has been received. All we have to do is verify that it came from your bank account.",
  "verification_transaction_approved_info": "Your bank account has been verified. So we can skip this step.",
  "export_to_csv": "Export to CSV",
  "new_account_button": "Create new Account",
  "remove_recipient": "Remove recipient",
  "recipient_detail": "Recipient detail",
  "account_label": "Account label",
  "veriffied": "veriffied",
  "not_verified": "not verified",
  "label_create_trade": "Create trade",
  "remove_income": "Remove income",
  "transaction_detail_header": "Transaction detail",
  "transaction_type": "Type",
  "trade": "Trade",
  "withdraw_label_about": "You are about to withdraw",
  "withdraw_summary_fees": "Summary and fees",
  "Choose-destination-bank-account": "Choose destination bank account",
  "wallet_account_destination": "Chose destination {{currencyName}} address",
  "account_unique_name_label": "Provide a unique name of this account",
  "account_note_label": "You can add any additional note here",
  "account_detail_suspend_alert": "Your account is suspended. Please email us for support.",
  "current_balance_label": "Current balance",
  "balance_blocked_label": "Blocked balance",
  "label_not_choosen_account": "You do not have an account selected",
  "label_not_enough_money": "You have insufficient funds",
  "label_set_all_funds": "Set all funds",
  "label_available_balance": "Available balance",
  "label_withdraw": "Withdraw",
  "account_label_description": "Account description",
  "label_balance_after_transaction": "Balance after transaction",
  "label_fee": "Fee",
  "label_counter_party": "Counterparty",
  "label_operation_confirm_withdraw": "Confirm your withdrawal",
  "label_operation_confirm_transfer": "Confirm your transfer",
  "label_operation_confirm_exchange": "Confirm your exchange",
  "label_no_account_this_currency": "You do not have an account in this currency",
  "transaction_info_acc_head": "Amount and currency",
  "label_from_account": "From account",
  "label_target_account": "Target account",
  "exchanges_balances_alert_fiat": "Conversion between non-crypto is currently not possible",
  "account_create_success": "Account was created",
  "withdraw_label_requests": "Withdraw requests",
  "operations_label_dashboard_header": "Operations",
  "label_currency_general": "Base currency",
  "income_state_draft": "unverified",
  "label_client_currency_balance": "Balance in base currency",
  "label_show_all": "Show all",
  "dashboard_balances_last_transactions": "Latest transactions",
  "dashboard_balances_no_accounts": "You don't have an account yet",
  "dashboard_balances_no_withdrawal_requests": "No withdrawal requests",
  "dashboard_balances_no_transaction": "You don't have transactions yet",
  "general_settings_label_base_currency": "This currency will be used as default throughout the app",
  "general_settings_header_base_currency": "Base currency",
  "page_portfolio_closed_suspend_accounts_header": "Suspended and canceled Accounts",
  "confirm_withdraw_button": "Withdraw",
  "button_withdraw_continue_to_confirmation": "Continue",
  "recipient_name": "Recipient name",
  "button_create_transfer": "Create transfer",
  "button_transfer_continue_to_confirmation": "Continue",
  "button_exchange_continue_to_confirmation": "Continue",
  "total_balance": "Total balance",
  "no_trading_limit_extension": "There is no extension of the trading limit.",
  "deposit_component_automatic_create_acc_label": "You do not have an account at Simplecoin in this currency yet. We'll set it up for you automatically.",
  "deposit": "Deposit",
  "withdraw": "Withdraw",
  "create_default_account_label": "Default account for",
  "create_default_account_description": "Automatically created account when depositing funds",
  "create_default_account_button": "Create default {{currencyCode}} account",
  "dashboard_blog_posts_header": "Last blog posts",
  "dashboard_link_to_blog": "Blog",
  "credited_amount": "Credited amount",
  "exchange_rate": "Exchange rate",
  "accounting_time": "Accounting time",
  "date": "Date",
  "amounts": "Amounts",
  "success_order_header": "What's next? Pay for your order to complete.",
  "success_order_email": "We have sent you payment instructions by email <0>{{email}}</0>.",
  "finop_type_DEPOSIT": "deposit",
  "finop_type_EXCHANGE": "exchange",
  "finop_type_TRANSFER": "transfer",
  "finop_type_WITHDRAWAL": "withdraw",
  "exchange_made": "Exchange successful",
  "from_account": "From account",
  "to_account": "To account",
  "accounts": "Accounts",
  "portfolio_balance_summary_fiat_header": "Fiat:",
  "portfolio_balance_summary_crypto_header": "Cryptocurrency:",
  "order_success_check_crypto": "Please check if your wallet address is correct.",
  "success_order_label_id": "Your order number is <0>{{order_id}}</0> created. Thank you!",
  "exchange_form_crypto_address_field_label": "Cryptocurrency address",
  "order_success_check_bank": "Please check if the bank account is correct.",
  "other_currencies": "Other currencies",
  "no_funds_in_portfolio": "You have no funds in your portfolio now. Deposit fiat or crypto funds into your accounts and then you can start trading immediately.",
  "exchange_form_register": "for higher limits.",
  "list": "List",
  "withdrawal_request_status_new": "Pending",
  "withdrawal_request_status_confirming_outgoing": "Dispatched",
  "withdrawal_request_status_done": "Done",
  "withdrawal_request_status_canceled": "Cancelled",
  "landing_main_header": "<0>The biggest</0> cryptocurrency exchange in Czechia",
  "next_landing_subheader": "Exchanging cryptocurrencies <0>since 2013</0>.",
  "header_link_about_us": "About us",
  "header_link_get_started": "Get started",
  "header_link_blog": "Blog",
  "header_service_hours": "Mo-Su 9-21",
  "buy_now_button": "Buy now",
  "chart_header": "Buy and sell",
  "exchange_form_label": "Buy and sell",
  "how_it_works_paragraph_1_content": "It only takes a minute to place an order. Up to 200 \u20ac / 10 days, all we need is your email and cryptocurrency wallet address. From higher amounts you will need to register and get verified.",
  "how_it_works_paragraph_1_heading": "Order",
  "how_it_works_paragraph_2_heading": "Pay",
  "how_it_works_paragraph_3_heading": "Receive cryptocurrency",
  "how_it_works_paragraph_2_content": "Once your order is complete, we will provide you with payment instructions.",
  "how_it_works_paragraph_3_content": "Upon receiving of your payment, we will send the cryptocurrency to your wallet. We can usually do it within 30 minutes!",
  "landing_support_section_paragraph": "Need help with exchanging cryptocurrencies or using the Trezor hardware wallet? Contact us anytime via email, phone, or visit our client center for personalized assistance.",
  "landing_support_section_heading": "Genuine people, real customer support",
  "contact_us_button": "Contact",
  "landing_advantages_1_heading": "Simple and safe cryptocurrency trade",
  "landing_advantages_1_text": "In only takes a minute to create an order. And once the funds are credited to our account, we will manually verify the transaction and send you the cryptocurrency within 30 minutes on average.",
  "landing_advantages_2_heading": "Real people, human customer support",
  "landing_advantages_3_heading": "Biggest and oldest exchange in Czechia",
  "landing_advantages_2_text": "Need advice on exchanging cryptocurrencies? You can always write, call or send us a ticket. We handle most requests promptly.",
  "landing_advantages_3_text": "We have been exchanging cryptocurrencies since 2013. We have exchanged cryptocurrencies for more than 21 thousand people with a total value of over 8 billion CZK. We are one of the founding members of <0>\u010cKMA</0>.",
  "ckma_founder": "Founding member of",
  "newsletter_field_placeholder": "Your e-mail",
  "footer_sub_header_newsletter_subscription": "Sign up for news",
  "footer_link_career": "Career",
  "footer_link_affiliate_program": "Affiliate program",
  "footer_link_privacy_notice": "Privacy",
  "footer_link_terms_and_conditions": "Terms and conditions",
  "footer_link_contact": "Contact",
  "footer_link_limits_and_verification": "Limits and account verification",
  "footer_link_faq": "Frequently asked questions",
  "footer_link_blog": "Blog",
  "footer_header_company_info": "We have exchanged over 8 billion Czech Crowns since 2013.",
  "landing_bottom_section_header_trade_easily": "Trade coins with ease",
  "landing_bottom_section_button_buy_coins": "Buy cryptocurrency",
  "how_it_works_header": "How does it work?",
  "landing_wrote_about_us_header": "Wrote about us",
  "landing_last_orders_header": "Last transactions",
  "header_faq": "Frequently asked questions",
  "faq_general_questions_header": "General",
  "log_in_button": "Log in",
  "header_button_buy_crypto": "Buy cryptocurrency",
  "header_short_button_buy": "Buy",
  "search_button": "Search",
  "header_support": "Support",
  "send_button": "Send",
  "email_subscribe_header": "Subscribe for news from the cryptocurrency world",
  "affiliate_advantage_payout_anytime": "Payout possible at any moment",
  "affiliate_advantage_banner": "Banners for your website or blog",
  "affiliate_advantage_referral_link": "Own referral link",
  "affiliate_advantage_fiat_or_crypto": "Get paid in crypto or fiat currency",
  "affiliate_advantage_cookie": "Cookies for {{affiliateCookieValidityDays}} days",
  "affiliate_advantage_reward_from_margin": "{{affiliateMarginReward}}% reward from our margin",
  "affiliate_header": "Affiliate program",
  "affiliate_subheader": "Become a Simplecoin affiliate partner and get commission from every exchange done by people you brought.",
  "header_earn_with_us": "Earn with {{brand}}",
  "create_account_button": "Sign up",
  "affiliate_how_it_works_header": "How does it work?",
  "affiliate_how_it_works_step_1_header": "Create an account and sign up for our affiliate program",
  "affiliate_how_it_works_step_2_header": "A unique link will be created for you",
  "affiliate_how_it_works_step_3_text": "Every person who comes to the Simplecoin site via your link receives a cookie. If they create an order within 7 days, you will earn a profit of {{affiliateMarginReward}}% of our margin. You will earn these returns for every order he trades with us. For orders created after the 7 day limit, the link is no longer active.",
  "affiliate_how_it_works_step_2_text": "You have a URL and banners that you can share on your website, blog, social media or with your friends. Anyone who uses this link and then signs up or creates an order within 7 days will be entered into your affiliate program for two years.",
  "affiliate_how_it_works_step_1_text": "Both registration and verification are required to join the affiliate program. Registration allows you to access the administration and manage your account. Verification is required for payment of rewards. We follow good manners and do not encourage unfair practices or dealings. We expect the same from our partners.",
  "affiliate_how_it_works_step_4_text": "You can view how much you have earned at any time in your affiliate program administration. When you feel like it, you can choose the amount in crypto or fiat currency. We will send the payment to your bank account or crypto wallet.",
  "affiliate_how_it_works_step_3_header": "You will receive {{affiliateMarginReward}}% from our profit margin for every customer you bring",
  "affiliate_how_it_works_step_4_header": "You can withdraw your earnings at any time",
  "limits_and_verification_header": "Limits and verification",
  "limits_and_verification_subheader": "For legal reasons, like all other exchanges, we must <0>verify every transaction above a certain limit.</0> If this applies to you, you can easily register and have your account verified. See the boxes below for information on limits and verification.",
  "limits_table_level_0_header": "up to {{limitLevel0Eur}} \u20ac",
  "limits_table_level_0_subheader": "per {{limitLevel0DurationDays}} days",
  "limits_table_registration": "Compulsory registration and identification",
  "limits_table_row_2_documents_photo": "Photo of 2 personal documents",
  "limits_table_row_selfie": "Selfie with document",
  "limits_table_row_verification_bank_transfer": "Verification bank transfer",
  "limits_table_row_verification_phone_call": "Verification phone call",
  "limits_table_row_purpose_od_crypto_purchase": "Purpose of cryptocurrency purchase",
  "limits_table_row_source_of_income": "Source of income",
  "limits_table_row_individual_limits_base_on_income_source": "Individual limits based of source of income",
  "limits_table_level_1_header": "{{limitLevel0Eur}} \u2014 {{limitLevel1Eur}} \u20ac",
  "limits_table_level_1_subheader": "per {{limitLevel1DurationDays}} days",
  "limits_table_level_2_subheader": "per {{limitLevel2DurationDays}} days",
  "limits_table_level_2_header": "over {{limitLevel2EurStart}} \u20ac ",
  "footer_mobile_navigation_expand_label": "Site navigation",
  "support_search_placeholder": "What would you like to know?",
  "error_404_header": "We couldn't find that page",
  "error_404_subheader": "But you can continue to...",
  "error_page_contact_us": "If you think this is a mistake, write us on {{supportEmail}}. Thank you!",
  "link_blog_label": "What's new on Simplecoin",
  "link_contact_label": "Contact us",
  "link_faq_label": "Find answers here",
  "about_page_recommendations_section_header": "Recommended by",
  "cookie_dialog_text_content": "{{brand}} uses cookies to make using our services more comfortable and to constantly improve user experience. You can can choose which types of cookies will be used below. For more information read our <0>cookie policy</0>",
  "cookie_dialog_necessary_cookies_header": "Necessary cookies",
  "cookie_dialog_necessary_cookies_text": "Cookies which are needed for the functioning of the website",
  "cookie_dialog_necessary_cookies_always_active": "Always active",
  "cookie_dialog_statistical_cookies_header": "Statistical cookies",
  "cookie_dialog_statistical_cookies_text": "Cookies which help us measure and understand how you use our services",
  "cookie_dialog_marketing_cookies_header": "Marketing cookies",
  "cookie_dialog_marketing_cookies_text": "Cookies that help us in some way",
  "cookie_dialog_personalization_cookies_header": "Personalization cookies",
  "cookie_dialog_personalization_cookies_text": "Cookies thanks to which you will not need to set some things twice",
  "cookie_dialog_accept_all_button": "Accept all",
  "cookie_dialog_confirm_choices_button": "Confirm my choices",
  "cookie_dialog_header": "Your cookie preferences",
  "support_label_breadcrumbs_navigation": "Help",
  "buy_currency_button": "Buy {{currencyName}}",
  "exchange_form_get_wallet_help": "No wallet?",
  "scan_address_qr_code": "Scan QR",
  "exchange_form_crypto_address_field_placeholder": "bc1...",
  "order_form_email_and_account_step_label": "Order information",
  "order_form_amounts_step_label": "Amount and currency",
  "order_form_header": "Order",
  "landing_login_dialog_title": "Introducing Simplecoin Pro. A new platform with lower fees and instant currency exchanges. No need for a crypto wallet.\n",
  "landing_login_dialog_pro_header": "Try Simplecoin Pro",
  "landing_login_dialog_exchange_header": "Go to Simplecoin Exchange",
  "landing_login_dialog_exchange_text": "Simple crypto exchange service you know and love",
  "landing_login_dialog_pro_text": "Try Pro for even better trading experience",
  "landing_login_dialog_pro_login_button": "Log in to Pro",
  "landing_login_dialog_exchange_login_button": "Log in to Exchange",
  "about_us_section_small_header": "About us",
  "about_us_section_1_header": "We make the world of buying or selling <0>Bitcoin</0> and other digital currencies <0>easier</0>.",
  "about_us_section_1_subheader": "The world of cryptocurrencies erases boundaries and brings you real freedom and a new perspective on finance.",
  "about_us_section_2_header": "We are cryptocurrency experts",
  "about_us_section_2_subheader": "We help put money back to regular people's hands",
  "about_us_section_2_content": "Simplecoin is first and foremost a technology company that relies on the expertise of our programmers. We invest time and attention specifically in a high standard of security for your client accounts and data.",
  "about_us_section_3_header": "We've been making it easy for people to buy cryptocurrencies <0>since 2013</0>",
  "about_us_section_3_text": "From the very beginning, we have been a purely Czech company with a clear ownership structure. Anyone can trade cryptocurrencies. We bring another possibility of working with finances that are not managed by banks and the state. We bring you options that you manage yourself. We show people that the cryptocurrency world is simple.",
  "about_us_section_4_header": "Modern technology and security",
  "about_us_section_4_subheader": "We take care of the high security of user accounts",
  "about_us_section_4_text": "Simplecoin is primarily a technology company. We base our experience on the expert knowledge of our programmers. We invest time and attention in a high level of security for our clients' accounts and data.",
  "contact_header": "Get in touch",
  "contact_page_email_label": "Email",
  "contact_page_phone_label": "Phone",
  "contact_page_customer_center": "Customer center",
  "contact_page_billing_info": "Billing information",
  "support_search_result_count": "{{resultsCount}} results",
  "limits_table_item_not_needed": "No",
  "limits_table_item_required": "Required",
  "how_to_get_started_step_1_name": "Step 1",
  "how_to_get_started_step_1_header": "You need a crypto wallet",
  "how_to_get_started_step_1_cta": "How to set up a crypto wallet",
  "how_to_get_started_step_1_text": "The wallet is used to store cryptocurrencies. You will need it when making a purchase. If you don't have a wallet, you can download it as an app on your mobile phone or computer. We'll show you how. ",
  "how_to_get_started_step_2_name": "Step 2",
  "how_to_get_started_step_2_header": "Sign up",
  "how_to_get_started_step_2_subheader": "It has its advantages",
  "how_to_get_started_step_2_text": "You will need an email address and a secure password. You can place an order without registering, but you will lose the volume discount and the ability to manage your order in your user account.",
  "how_to_get_started_step_3_name": "Step 3",
  "how_to_get_started_step_3_header": "Create an order",
  "how_to_get_started_step_3_subheader": "Now it only takes a minute",
  "how_to_get_started_step_3_text": "Fill out the order form - you already have everything you need (email address and crypto wallet address).  We will send you payment instructions by email. Once we have verified the funds have been credited, we will send you the desired currency. We can usually do this within 30 min.",
  "how_to_get_started_step_3_hint": "Pay attention to payment instructions. Remember to insert the text in the message to the recipient as instructed. This will help us process your order faster.",
  "how_to_get_started_step_4_name": "",
  "how_to_get_started_step_4_header": "",
  "how_to_get_started_step_4_subheader": "",
  "how_to_get_started_step_4_text": "",
  "how_to_get_started_step_4_cta": "",
  "landing_clients_since_2013": "clients since 2013",
  "landing_monthly_volume": "of volume in the last 30 days ",
  "landing_exchanges_per_month": "exchanges in the last 30 days",
  "cookie_bar_header": "<0>Simplecoin</0> uses cookies to function correctly",
  "cookie_bar_text": "We use cookies to for login as well as navigation and traffic analysis. Thank you for your consent to their use. Select your cookies.",
  "cookie_bar_agree_and_continue_button": "Agree and continue",
  "cookie_bar_manage_cookie_settings_button": "Cookie preferences",
  "order_detail_info_header": "Order details",
  "order_detail_order_id": "Order number",
  "order_detail_created_at": "Created at",
  "order_detail_status": "Status",
  "order_detail_rate": "Order rate",
  "order_detail_button_cancel": "Cancel",
  "order_detail_button_suspend": "Suspend",
  "order_detail_button_download": "Download in PDF",
  "order_detail_button_new_ticket": "Create a ticket",
  "order_detail_history_table_header": "Order history",
  "order_history_datetime_column_name": "Date and time",
  "order_history_status_column_name": "Status",
  "order_detail_outgoing_payment_label": "Outgoing payment",
  "order_detail_incoming_payment_label": "Your payment",
  "order_detail_incoming_payment_waiting_for_transaction": "Your transaction has not been detected yet",
  "order_detail_incoming_payment_waiting_for_outgoing_transaction": "We are waiting for your payment",
  "order_payment_instructions_header": "Send payment with the following details",
  "order_form_payment_instructions_bank_account_number_label": "Bank account number",
  "order_form_payment_instructions_bank_amount_label": "Amount",
  "order_form_payment_instructions_bank_message_label": "Payment message",
  "order_form_payment_instructions_use_qr": "Or scan the following QR code",
  "order_form_payment_instructions_choose_one_of_company_accounts": "Company account",
  "order_status_done": "Done",
  "order_status_delivered": "Delivered",
  "order_status_canceled": "Canceled",
  "order_status_returned": "Returned",
  "order_status_in_progress": "In progress",
  "order_status_confirming_incoming": "Confirming incoming",
  "order_status_confirming_outgoing": "Confirming outgoing",
  "order_status_new": "New",
  "order_status_suspended": "Suspended",
  "order_status_received": "Received",
  "order_detail_outgoing_payment_waiting_for_incoming_transaction": "Your transaction has not been detected yet",
  "show_payment_instructions_dialog_opener_button": "Show payment instructions",
  "support_section_header": "Did not find the answer?",
  "support_section_text": "The customer service line can be reached Monday to Sunday between 9:00 and 21:00. Or send us an email. Our colleagues will always be happy to advise you and guide you safely through your purchase.",
  "label_crypto_sent_to_address": "Address",
  "label_transaction_id": "Transaction ID",
  "order_detail_button_proceed": "Proceed",
  "label_amount_and_currency": "Amount",
  "label_payment_sent_at": "Created",
  "order_detail_outgoing_payment_waiting_to_confirm_incoming_transaction": "Incoming transaction is being confirmed",
  "order_detail_outgoing_payment_order_suspended": "Order is suspended, now waiting for your decision. ",
  "order_detail_outgoing_payment_order_return_to_client": "Your payment will be returned",
  "order_form_payment_instructions_company_billing_details": "Billing information",
  "order_has_been_created": "Order successfully created",
  "fiat_payment_instructions_amount_label": "Amount",
  "order_detail_button_return": "Return",
  "order_view_original_price_label": "Previous offer",
  "order_view_limit_price_label": "Limit price",
  "order_view_current_price_label": "Current offer",
  "cryypto_payment_instructions_amount_label": "Amount",
  "payment_instruction_hint_fill_amount_to_show_qr": "Fill amount filed to generate QR code",
  "deposit_payment_instruction_amount_label": "Amount to deposit",
  "form_email_field": "Email",
  "confirm_button": "Confirm",
  "email_placeholder": "Email",
  "change_in_24hours": "Change in the last 24 hours",
  "order_offer_amounts_label": "Your offer",
  "order_offer_your_crypto_address": "Your cryptocurrency address",
  "order_offer_continue_to_login": "Continue with {{companyName}} account",
  "order_offer_or_separator": "or",
  "order_offer_continue_without_registration": "Continue without registration",
  "order_offer_validity_notice": "This offer will expire in <strong>{{timeRemaining}}</strong>. Please log in or continue with email. ",
  "order_offer_continue_as_guest": "Continue as guest",
  "order_offer_existing_email_please_click_continue_button": "This email is already registered. Please choose the \"Continue with {{companyName}} account\" option",
  "landing_header_references": "References",
  "crypto_payment_instructions_amount_label": "Amount",
  "crypto_payment_instructions_destination_tag_field_label": "Destination tag",
  "order_form_mail_help_text": "We will send you instructions for completing the order to this e-mail",
  "validation_required_email": "Enter your e-mail address",
  "validation_required_crypto_account": "Enter your crypto account",
  "landing_help_section_header": "Here you will find answers to the most common questions. In addition to written instructions, we can also help you on our customer service line at +420 608 746 753 or by email at info@simplecoin.eu, Monday to Sunday from 9 a.m. to 9 p.m. We can guide you through your first purchase, help you order or register, advise you on how to set up your wallet, or answer your questions. Feel free to ask us anything.",
  "guest_exchange_form_limit_alert": "Purchase without registration is possible up to {{code}} {{limit}}. For higher amounts, please log in or register.",
  "exchange_form_email_label": "Your e-mail",
  "exchange_form_alert_change": "The exchange rate between these currencies cannot be traded. Please adjust your currency selection.",
  "welcome_in_wallet": "Welcome in Wallet!",
  "start_with_funding_text_2": "You have been verified. Now you can deposit funds and start trading.",
  "start_with_funding_text_1": "Well done!",
  "amount_in_fiat_payment_instructions_info": "The amount is required to generate the QR code",
  "limit_table_level_0_text": "You can buy up to \u20ac{{limitLevel0Eur}} within 10 days without registration and without he need to verify personal documents.",
  "label_go_to_order": "Create Order",
  "label_register": "Register",
  "account_detail": "Account detail",
  "signup_form_subtitle": "You will get a better rate and higher trading limits. You will have an overview of your transactions.",
  "label_guest_login": "If you already have an account <0>Login</0>",
  "label_guest_signup": "If you don't have an account yet, <0>sign up</0>",
  "label_register_success": "Registration was successful",
  "label_register_success_verify": "Please activate your account now",
  "label_register_success_sended_verify": "We have sent you a link to activate your account to the e-mail {{mail}}.",
  "label_account_notes": "Account notes",
  "deposit_request_card_head": "Deposit requests",
  "dashboard_balances_no_deposit_requests": "No deposit requests",
  "deposit_request_status_pending": "Pending",
  "exchange_form_terms_must_be_accepted": "Terms and conditions should be accepted",
  "validation_required_bank_account": "Enter your bank account number",
  "order_status_dispatched": "Dispatched",
  "order_status_paid": "Paid",
  "order_status_paidUnconfirmed": "Paid (1/2)",
  "order_status_tooltip_new": "The order is waiting to be paid.",
  "order_status_tooltip_paidUnconfirmed": "We see an incoming transaction. It is waiting to be confirmed on the block-chain.",
  "order_status_tooltip_paid": "The order has been paid. We're working on dispatching the ordered currency.",
  "order_status_tooltip_paid_suspended": "The order has been paid.",
  "order_status_tooltip_dispatched": "We have dispatched the ordered currency to the target address/account. We are waiting for the confirmation on the block-chain/bank.",
  "order_status_tooltip_done": "The order is successfully processed.",
  "order_status_tooltip_canceled": "The order has been canceled.",
  "suspended_order_tooltip": "Order processing is suspended.",
  "order_detail_outgoing_payment_waiting_to_be_sent": "We are working to dispatch the ordered funds as soon as possible.",
  "exchange_limit_for_verification_level_2_text": "Your trading limit is not formally set. For large amounts, it will be assessed individually according to your <0>documented income</0>.",
  "your_limit_is": "Your limit is",
  "trading_limit": "Trading limit",
  "exchange_trading_limit_info": "The limit is automatically adjusted after we receive payment for your order. New unpaid orders do not affect the limit.",
  "you_have_no_suitable_wallet_among_recipients": "You have no wallet for {{currencyCode}} currency among your recipients.",
  "you_have_no_suitable_account_among_recipients": "You have no bank account for {{currencyCode}} currency among your recipients.",
  "rate_when_order_created": "Rate when order created",
  "order_status_proceed": "Proceed processing",
  "waiting_for_limit": "Waiting for limit",
  "button_more": "More ...",
  "exchange_form_expand_optional_fields": "Expand optional fields",
  "return_fiat_payment_info": "We have received your payment already. You have decided to get it back. But return payment has additional fee. You can get {{amount}} {{currency}} back to original bank account.",
  "cancel_order_and_return_payment": "Cancel order and return payment",
  "remaining_limit": "Remaining",
  "trading_limit_reached": "You have reached your trading limit for given period.",
  "attached_files": "Attached files",
  "there_are_no_files": "There are no uploaded files",
  "why_we_need_verification_info": "The anti money laundering law (AML) requires us to know our clients better. Therefore, it is necessary for you to tell us some information about yourself, which of course remains safely stored only with us.",
  "proceed_to_verification_action": "Proceed to verification, please",
  "verification_request_line2": "There are a few steps in our guide and then you can make full use of our wallet.",
  "verification_request_line1": "Now we need to get to know each other a little bit",
  "document_issued_by_government_info": "Please attach a government-issued document confirming the information provided in the previous step.",
  "secondary_document_info": "Please attach another document that confirms the information provided in the first step.",
  "selfie_with_id_info": "Please attach a photo of you holding the personal document  in your hand. The details on the document must be legible and your face must be visible.",
  "understood_button": "Understood",
  "no_prooved_incomes_info": "There is no proved income yet. You can still trade cryptocurrencies up to the base limit (10.000 EUR/month for verified clients). To increase the trading limit above 10 000 EUR/month, you will need to provide proof of income.",
  "verified": "Verified",
  "payment_instructions": "Payment instructions",
  "make_payment_to_one_of_our_accounts": "Make payment to one of our accounts",
  "payment_in": "Payment in",
  "iban_payment_instructions": "IBAN payment instructions",
  "new_price_is_being_loaded": "New price is being loaded...",
  "price_refresh_timer_tooltip": "We update the displayed price and rate for you every few seconds.",
  "send_to_address": "Send to address",
  "payment_specific_data": "Payment data",
  "bank_account_owner": "Bank account owner",
  "filter_all_currencies": "All currencies",
  "ticket_detail": "Ticket detail",
  "last_verification_step_warning": "This is the last step of the verification process. Once you have submitted it, you will no longer be able to change the information you have entered. Your profile will be verified by our back-office staff.",
  "exchange_form_limit_alert": "The order of this amount already exceeds your allowed trading limit {{limit}} {{code}}. You can still create the order, but it will not be processed until you verify yourself or provide proof of appropriate incomes.",
  "order_limit_alert": "Once you will pay for this order, you will exceed your allowed trading limit and the order will not be processed until you verify yourself or provide proof of appropriate incomes.",
  "remove_recipient_confirmation_question": "Do you really want to delete this recipient? You will not be able to undo this action.",
  "label_bank_account_iban": "IBAN",
  "validation_given_field_required": "Field '{{fieldName}}' is required",
  "inheritance": "Inheritance",
  "relationship_to_testator": "Relationship to testator",
  "inherited_from": "Inherited from (name)",
  "landing_how_to_get_started_button": "How to get started",
  "affiliate_partners_must_be_verified": "To become our affiliate partner, we first need to grab a few information about you.",
  "regular_income": "Regular income",
  "tax_declaration": "Tax declaration",
  "annual_report": "Annual report",
  "date_of_acceptance": "Date of acceptance",
  "document_confirming_acceptance_of_inheritance": "Document confirming acceptance of inheritance",
  "financial_closing_statement": "Financial closing statement",
  "decision_on_sharing_profit": "Decision on sharing profit",
  "sale_contract": "Sales contract",
  "real_estate_registry_extract": "Real estate registry extract",
  "typical_income_attachments": "Typical attachments",
  "sale_of_immovable_property": "Sale of immovable property",
  "address_of_property": "Address of property",
  "date_of_sale": "Date of sale",
  "sale_channel": "Sales channel / Way of sale",
  "paycheck": "Paycheck",
  "employment_contract": "Employment contract",
  "field_of_business": "Field of business",
  "company_id": "Company ID",
  "monetary_donation": "Monetary donation",
  "donor": "Donator",
  "donor_relationship_to_recipient": "Relationship of donor to recipient",
  "purpose_of_donation": "Purpose of donation",
  "source_of_donated_funds": "Source of donated funds",
  "email_subscribe_success": "You have successfully subscribed to our newsletter.",
  "landing_index_meta_tag_title": "Simplecoin - The largest cryptocurrency exchange in the Czech Republic",
  "landing_index_meta_tag_description": "Trusted since 2013",
  "landing_how_to_get_started_meta_tag_title": "Simplecoin - How to start buying cryptocurrencies",
  "landing_how_to_get_started_meta_tag_description": "A simple step-by-step guide for everyone",
  "landing_about_meta_tag_description": "We are cryptocurrency experts",
  "landing_about_meta_tag_title": "Simplecoin - About us",
  "landing_faq_meta_tag_description": "What do I need to buy cryptocurrencies? What do I gain by signing up?",
  "landing_faq_meta_tag_title": "Simplecoin - FAQ",
  "landing_support_meta_tag_title": "Simplecoin - Help",
  "landing_support_meta_tag_description": "Detailed instructions on how to set up a wallet, place an order or set up a DCA",
  "landing_tos_meta_tag_description": "Fair trading with cryptocurrencies",
  "landing_tos_meta_tag_title": "Simplecoin - Terms and conditions",
  "landing_privacy_meta_tag_title": "Simplecoin - Privacy Policy",
  "landing_privacy_meta_tag_description": "Your data is safe with us",
  "landing_affiliate_meta_tag_description": "Become a Simplecoin partner and earn commission",
  "landing_affiliate_meta_tag_title": "Simplecoin - Affiliate Program",
  "landing_limits_meta_tag_description": "Purchase options without registration and when you need to be verified",
  "landing_limits_meta_tag_title": "Simplecoin - Limits and Verification",
  "landing_contact_meta_tag_description": "Book a personal appointment at our client centre or take advantage of our client support 9am-9pm every day of the week.",
  "landing_contact_meta_tag_title": "Simplecoin - contact",
  "react_new_app_version_available_dialog_content": "A new version is available. Please refresh the page to ensure you're using the latest version.",
  "wallet_recipient_warning_1": "Anyone who knows the access secrets to the crypto-wallet (especially the recovery seed - 12-24 words used to recover the wallet) can freely use the funds in this wallet.",
  "wallet_recipient_warning_2": "If the wallet was created by someone else than myself, he/she probably has access to this wallet and can freely use the funds in this wallet.",
  "wallet_recipient_warning_3": "If I lose access to my wallet and do not have a backup (recovery seed), I lose access to all funds in my wallet.",
  "wallet_recipient_warning_4": "If I add an address of crypto-wallet that is not mine, I will not be able to directly use of the funds in that wallet. It is up to the third party to allow me to dispose of the funds.",
  "warning": "Warning",
  "ticket_attachments_info": "Attachments can be added to the ticket in the next step (after the ticket is created).",
  "one_off_income": "One-off income",
  "button_previous": "Previous",
  "years_of_service": "Years of service",
  "primary_or_secondary_activity": "Primary / secondary activity",
  "rental_contract": "Rental contract",
  "bank_statement_with_incoming_payment": "Bank statement with incoming payment",
  "pension_confirmation_letter": "Pension confirmation letter",
  "loan": "Loan",
  "loan_maturity_date": "Maturity date",
  "loan_effective_date": "Effective date",
  "lender": "Lender",
  "loan_agreement": "Loan agreement",
  "donation_agreement": "Donation agreement",
  "savings": "Savings",
  "bank_statement": "Bank statement",
  "sale_of_movable_property": "Sale of movable property",
  "sale_of_company_share": "Sale of company share",
  "investment_return": "Investment return",
  "investment_period": "Investment period",
  "crypto_mining": "Crypto mining",
  "mining_target_crypto_address": "Target crypto-address of mined coins",
  "verified_clients_limit_info": "Verified clients can trade up to EUR 10.000 per month without providing further proof of incomes.",
  "expected_trading_volume": "Expected trading volume",
  "expected_trading_volume_info": "Setting up expected trading volume is optional and is not a commitment. It will help us to better set your trading limits and thus speed up your trading.",
  "add_expected_trading_volume": "Add expected trading volume",
  "trading_volume_period": "Trading period",
  "landing_quarterly_volume": "90 day volume",
  "landing_quarterly_exchanges": "Exchanges in the last 90 days",
  "income_state_revoked": "revoked",
  "crypto_deposit_fee_info": "Speed of your crypto transaction depends on the transaction fee so it is important to use the correct transaction fee in order for transaction to be verified soon.",
  "deposit_on_account": "Deposit on account",
  "show": "show",
  "hide": "hide",
  "account_status_suspended": "suspended",
  "account_status_closed": "closed",
  "account_status_active": "active",
  "suspended_accounts": "Suspended accounts",
  "closed_accounts": "Closed accounts",
  "active_accounts": "Active accounts",
  "create_wallet_account_form_header": "Add a new account",
  "account_number": "Account number",
  "accounted_transactions": "Accounted transactions",
  "counterparty": "Counterparty",
  "all_financial_operations": "All",
  "accounting_balance": "Accounting balance",
  "pending_deposits": "Pending deposits",
  "pending_withdrawals": "Pending withdrawals",
  "pending_transactions": "Pending transactions",
  "blocked_amount_alert": "There is an amount {{amount}} {{currency}} blocked on this account. Please contact support for more information.",
  "available_balance_tooltip": "Available balance is the amount of money you currently have available for trading. The available balance does not include pending deposits but already takes into account amounts blocked, e.g. due to pending withdrawals.",
  "total_balance_tooltip": "The account total balance is the sum of all confirmed transactions in your account.",
  "deposit_requests_total_tooltip": "Pending deposits are incoming transactions to your account for which we are waiting for confirmation on the block-chain or have not been credited due to exceeding your trading limit.",
  "withdrawal_requests_total_tooltip": "Pending withdrawals are withdrawals awaiting confirmation on the block-chain.",
  "cancel_account": "Close account",
  "cancel_account_confirmation_question": "Are you sure you want to close this account? Closing the account is an irreversible operation.",
  "empty_account_required_to_close_it": "Only accounts with zero balance can be closed.",
  "account_closed_success": "The account has been closed.",
  "approved_incomes": "Approved incomes",
  "pending_incomes": "Pending incomes",
  "show_details": "show details",
  "hide_details": "hide details",
  "hide_approved_incomes": "Hide approved incomes",
  "show_approved_incomes": "Show approved incomes",
  "increase_trading_limit": "Increase trading limit",
  "save_as_draft": "Save as draft",
  "withdrawal_success": "Withdrawal has been successfully requested",
  "withdrawal_confirmation_transaction_fee_label": "Transaction fee: {{feeAmount}} {{feeCurrencyCode}}",
  "withdrawal_form_fee_in_amount_switch_label": "Withdrawal fee included",
  "withdrawal_recap_table_fee": "Fee amount",
  "withdrawal_recap_table_deducted_amount": "Withdrawal amount",
  "withdrawal_recap_table_net_withdraw_amount": "Recipient will get",
  "remove_income_confirmation": "Do you really want to delete this income?",
  "deposit_form_show_instructions": "Show deposit instructions",
  "income_state_readyForApproval": "awaits approval",
  "withdrawal_form_show_bank_fields_toggle": "Show modifiable fields",
  "end_income": "End income",
  "edit_income": "Edit income",
  "delete_income": "Delete income",
  "pending_transaction_detail": "Pending transaction detail",
  "account": "Account",
  "verification_wizard": "Verification wizard",
  "portfolio_volume": "Your portfolio volume",
  "profile_verification_info_message_for_business": "For business verification <0>read our guide</0>.",
  "unknown_source": "unknown source",
  "amount_netto": "Amount (netto)",
  "deposit_request_status_on_hold": "On Hold",
  "exchange_form_verified_client_discount_message": "Verified clients can save up to <0>{{nominalDiscount}} {{discountCurrency}}</0> per {{toCurrency}}. </1>",
  "sign_up_to_get_discount": "Sign up to get the discount",
  "exchange_form_verified_client_discount_message_generic": "Verified clients get a better price",
  "exchange_form_verified_client_discount_message_from_crypto": "Verified clients can immediately get <0>{{nominalDiscount}} {{discountCurrency}}</0> more per {{toCurrency}}.",
  "price_for_verified_clients": "Price for verified clients:",
  "contact_page_customer_center_reservation_info": "If you are interested in visiting our office, please <0>make an appintment</0>",
  "label_house_number": "House No",
  "react_file_upload_count_error": "the maximum number of uploaded files is {{maxFiles}}",
  "sorry_no_bank_for_given_currency": "We are sorry, but payments in {{currency}} are not possible at this moment.",
  "payment_instructions_delay_warning": "Due to the new provider of banking services, bank transfers may take longer than usual.\n<br/>\nTo ensure the original exchange rate from the order, you have the option to attach a PDF with payment confirmation from your bank. The price extension service is available daily from 9:00 to 21:00. Each request is assessed individually, and we will contact you as soon as possible.",
  "payment_confirmation_upload_info": "The duration of original exchange-rate is evaluated on an individual basis and depends on your payment history and order amount.",
  "attach_payment_confirmation": "Attach payment confirmation",
  "price_fixed_until": "The price is fixed until {{time}}",
  "payment_confirmation_attached": "Payment confirmation attached"
}