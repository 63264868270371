import {Button, Stack, styled, Typography, Box, Link, Chip, Hidden, Card, CardContent, Alert} from '@mui/material';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {BankTransaction, CryptoTransaction, Order, STATUS_RETURN_TO_CLIENT} from '~/typings/Order';
import logoSrc from '../../assets/images/simplecoin-logo-orange-rgb.svg';
import dottedSphere from '../../assets/images/dotted-sphere.svg';
import FiatPaymentInstructions from './FiatPaymentInstructions';
import DetailView, {DetailViewItem} from '../DetailView';
import Dialog, {InvokerProps} from '../Dialog';
import useBackendSettings from '../../hooks/useBackendSettings';
import {Currencies} from '~/typings/currency';
import CryptoPaymentInstructions from './CryptoPaymentInstructions';
import dayjs from '../../config/dayjs';
import trezorLogoSrc from '../../assets/icons/ui/trezor.svg';
import {toSatoshis} from '~/helpers/crypto';
import {getTestnetCoinName} from '~/helpers/trezorUtils';
import {AppDispatch, useCoreAppDispatch} from '~/redux/store';
import {SendTransactionParams} from '~/typings/OrderViewTypings';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import {truncateMiddle} from '~/helpers/text';
import wipImg from '../../assets/images/wip.svg';
import progressIcon from '../../assets/images/progress-icon-filled.svg';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import FormattedAmount from '../FormattedAmount';
import {UserAuth} from '~/typings/User';
import {getConfigValue} from '~/helpers/config';

interface PaymentInfoInterface {
    type: 'incoming' | 'outgoing';
    order: Order;
    userAuth?: UserAuth;
    trezorSendTransaction?: (sendParams: SendTransactionParams, dispatch: AppDispatch) => void;
    trezorSentTransactionTxId?: string;
    paymentConfirmationUpload?: JSX.Element;
}

//todo: move to some config!
export const TREZOR_SUPPORTED_CURRENCY_PAYMENT = ['BTC', 'LTC', 'BCH'];

const StyledImg = styled('img')`
    vertical-align: middle;
`;

const PurpleBall = styled('div')`
    border-radius: 50%;
    position: absolute;
    width: 96px;
    height: 96px;
    right: -52px;
    top: 104px;
    background: linear-gradient(180deg, #41277e 23.65%, #f4f2ff 113.33%);
`;

const OrangeBall = styled('div')`
    border-radius: 50%;
    position: absolute;
    width: 180px;
    height: 180px;
    bottom: -50px;
    left: -102px;
    background: linear-gradient(200.04deg, #fd6300 37.65%, #fff1e7 84.29%);
`;

const DottedSphere = styled('img')`
    position: absolute;
    left: 100px;
    top: 130px;
    height: 88px;
    width: 88px;
    z-index: 0;
`;

/**
 * Renders widget with dialog showing the progress and instructions of either incoming or outgoing payment.
 * Type outgoing = payment from client to exchange
 * Type incoming = payment sent from exchange to client
 */
export default function PaymentInfoWidget({
    type,
    order,
    userAuth,
    trezorSendTransaction,
    trezorSentTransactionTxId,
    paymentConfirmationUpload,
}: PaymentInfoInterface) {
    const {t} = useTranslation();
    const dispatch = useCoreAppDispatch();
    const currencies = useBackendSettings<Currencies>('currencies', {});
    const fromCurrency = currencies[order.from_currency_id];
    const toCurrency = currencies[order.to_currency_id];
    const toCurrencyCode = toCurrency ? toCurrency.name : '';
    const fromCurrencyCode = fromCurrency ? fromCurrency.name : '';
    const fromIsCrypto = fromCurrency?.type === 'crypto';
    const hasIncomingPayment = order.incomingBankTransaction || order.incomingCryptoTransaction;
    const hasOutgoingPayment = order.outgoingBankTransaction || order.outgoingCryptoTransaction;
    const hasPayment = (type === 'outgoing' && hasOutgoingPayment) || (type === 'incoming' && hasIncomingPayment);

    let label;
    let paymentInfo;

    // Outgoing from Simplecoin to client
    if (type === 'outgoing') {
        label = t('order_detail_outgoing_payment_label');

        // no tx
        if (order.status === 'new') {
            paymentInfo = t('order_detail_outgoing_payment_waiting_for_incoming_transaction');
        }

        // confirming incoming
        if (order.status === 'confirming_incoming') {
            paymentInfo = t('order_detail_outgoing_payment_waiting_to_confirm_incoming_transaction');
        }

        // received
        if (order.status === 'received') {
            if (order.status_direction === 'suspended') {
                paymentInfo = t('order_detail_outgoing_payment_order_suspended');
            } else if (order.status_direction === 'return_to_client') {
                paymentInfo = t('order_detail_outgoing_payment_order_return_to_client');
            } else {
                paymentInfo = (
                    <Stack alignItems='center' spacing={5} sx={{maxWidth: '400px'}}>
                        <Box sx={{color: 'primary'}}>
                            <img src={wipImg} width={100} height={100} />
                        </Box>
                        <Typography>{t('order_detail_outgoing_payment_waiting_to_be_sent')}</Typography>
                    </Stack>
                );
            }
        }

        // confirming outgoing + tx -> show payment

        // done + tx
    }
    // Payment incoming to Simplecoin from client
    else {
        label = t('order_detail_incoming_payment_label');
        if (order.status === 'new' && !order.is_over_limit_when_paid) {
            paymentInfo = (
                <Stack spacing={1}>
                    <Typography>{t('order_detail_incoming_payment_waiting_for_outgoing_transaction')}.</Typography>
                    {!order.is_payment_confirmation_uploaded && (
                        <Typography variant='body2'>
                            {t('price_fixed_until', {
                                time: dayjs.unix(order.created_at + order.price_fixation_period).format('LLL'),
                            })}
                        </Typography>
                    )}
                </Stack>
            );
        }
    }

    /**
     * Creates transaction and pushes it to trezor
     */
    function sendByTrezor(address: string, amount: string, currencyName: string) {
        const amountSatoshis = toSatoshis(amount);
        if (getConfigValue('environment') === 'development') {
            // eslint-disable-next-line no-console
            console.warn(`Going to send ${amountSatoshis} satoshis to ${address}`);
        }

        // coin names differ for testnets in trezor connect
        if (getConfigValue('environment') === 'development') {
            // @ts-ignore
            currencyName = getTestnetCoinName(currencyName);
        }

        if (!amountSatoshis) {
            if (getConfigValue('environment') === 'development') {
                // eslint-disable-next-line no-console
                console.warn('Amount in satoshis incorrect', amountSatoshis, amount);
            }
        } else {
            trezorSendTransaction?.(
                {
                    currencyName,
                    amountSatoshis,
                    address,
                },
                dispatch
            );
        }
    }

    const onPayByTrezorButtonClick = () => {
        if (order.pairing_data) {
            sendByTrezor(order.pairing_data, order.from_amount, fromCurrencyCode);
        }
    };

    const paymentInstructionsDialogInvoker = ({handleOpen}: InvokerProps) => {
        return (
            <Stack direction='column' spacing={3}>
                <Button color='secondary' variant='contained' onClick={handleOpen}>
                    {t('show_payment_instructions_dialog_opener_button')}
                </Button>
                {TREZOR_SUPPORTED_CURRENCY_PAYMENT.includes(fromCurrencyCode.toUpperCase()) && (
                    <Stack direction='column' alignItems='center' spacing={2}>
                        <Button
                            data-testid='pay-with-trezor-button'
                            variant='outlined'
                            onClick={onPayByTrezorButtonClick}
                            endIcon={<StyledImg src={trezorLogoSrc} alt='Trezor wallet icon' />}
                        >
                            {t('send_by_trezor_button')}
                        </Button>
                        <Typography variant='caption' align='center' maxWidth='200px'>
                            {t('send_by_trezor_description_tooltip')}
                        </Typography>
                    </Stack>
                )}
            </Stack>
        );
    };

    const paymentInstructionsComponent = fromIsCrypto ? (
        <CryptoPaymentInstructions
            qrLogoSrc={logoSrc}
            address={order.pairing_data ?? ''}
            qrText={order.receive_address_bip21 ?? ''}
            amount={order.from_amount}
            currency={fromCurrency}
            waitingTimeInSecs={order.price_fixation_period}
        />
    ) : (
        <FiatPaymentInstructions
            qrLogoSrc={logoSrc}
            note={order.fiat_note}
            amount={order.from_amount}
            currency={fromCurrency}
            showWalletBankAccounts={false}
        />
    );

    const getCryptoPaymentInfo = (
        currencyCode: string,
        cryptoTransaction?: CryptoTransaction | null
    ): DetailViewItem[] => {
        if (!cryptoTransaction) {
            return [];
        }

        const amount = Math.abs(parseFloat(cryptoTransaction.amount));

        const isConfirmed = cryptoTransaction.confirmations >= (currencies[currencyCode]?.minimum_confirmations ?? 0);

        return [
            {
                label: t('label_amount_and_currency'),
                value: (
                    <Typography variant='body2' fontWeight={700}>
                        <FormattedAmount value={amount} currencyCode={currencyCode} appendCurrencyCode />
                    </Typography>
                ),
            },
            {
                label: t('label_crypto_sent_to_address'),
                value: (
                    <Box sx={{wordWrap: 'break-word'}}>
                        <Link
                            href={cryptoTransaction.address_url ?? undefined}
                            rel='noopener noreferrer'
                            underline='hover'
                        >
                            <Typography maxWidth='350px' align='right' variant='body2' fontWeight={700} color='primary'>
                                {truncateMiddle({text: cryptoTransaction.address})}{' '}
                            </Typography>
                        </Link>
                    </Box>

                ),
                valueMaxLength: 25,
            },
            {
                label: t('label_confirmations'),
                value: (
                    <Stack direction='row' spacing={1} alignItems='center'>
                        {isConfirmed ? (
                            <CheckCircleOutlineRoundedIcon color='primary' />
                        ) : (
                            <img src={progressIcon} alt='progress' />
                        )}
                        <Typography variant='body2' fontWeight={700} color={isConfirmed ? 'warning' : 'text'}>
                            {cryptoTransaction.confirmations} / {currencies[currencyCode]?.minimum_confirmations}
                        </Typography>
                    </Stack>
                ),
            },
            {
                label: t('label_in_block_id'),
                value: cryptoTransaction.block_id,
            },
            {
                label: t('label_transaction_id'),
                value: (
                    <Box sx={{wordWrap: 'break-word'}}>
                        <Link
                            href={cryptoTransaction.hash_url ?? undefined}
                            rel='noopener noreferrer'
                            underline='hover'
                        >
                            <Typography maxWidth='350px' align='right' variant='body2' fontWeight={700} color='primary'>
                                {truncateMiddle({text: cryptoTransaction.hash})}{' '}
                            </Typography>
                        </Link>
                    </Box>
                ),
            },
            {
                label: t('label_payment_sent_at'),
                value: dayjs.unix(cryptoTransaction.created_at).format('LLL'),
            },
        ];
    };

    const getFiatPaymentInfo = (
        currencyCode: string,
        bankTransaction?: BankTransaction | null
    ): DetailViewItem[] | null => {
        if (!bankTransaction) {
            return [];
        }

        const amount = Math.abs(parseFloat(bankTransaction.amount));

        return [
            {
                label: t('label_amount_and_currency'),
                value: (
                    <Typography variant='body2' fontWeight={700}>
                        <FormattedAmount value={amount} currencyCode={currencyCode} appendCurrencyCode />
                    </Typography>
                ),
            },
            {
                label: t('label_bank_account'),
                value: `${bankTransaction.account_number ?? ''} (${bankTransaction.owner_name ?? ''})`,
            },
            {
                label: t('label_note'),
                value: bankTransaction.note,
                valueMaxLength: 0,
            },
            {
                label: t('label_payment_sent_at'),
                value: dayjs.unix(bankTransaction.created_at).format('LLL'),
            },
        ];
    };

    let paymentInfoDetails;

    if (hasPayment) {
        if (type === 'outgoing') {
            if (order.status_direction === STATUS_RETURN_TO_CLIENT) {
                // return payment
                if (toCurrency?.type === 'fiat') {
                    paymentInfoDetails = getFiatPaymentInfo(fromCurrencyCode, order.outgoingBankTransaction);
                } else {
                    paymentInfoDetails = getCryptoPaymentInfo(fromCurrencyCode, order.outgoingCryptoTransaction);
                }
            } else {
                // normal outgoing payment
                if (toCurrency?.type === 'fiat') {
                    paymentInfoDetails = getFiatPaymentInfo(toCurrencyCode, order.outgoingBankTransaction);
                } else {
                    paymentInfoDetails = getCryptoPaymentInfo(toCurrencyCode, order.outgoingCryptoTransaction);
                }
            }
        } else {
            // incoming payment
            if (fromCurrency?.type === 'fiat') {
                paymentInfoDetails = getFiatPaymentInfo(fromCurrencyCode, order.incomingBankTransaction);
            } else {
                paymentInfoDetails = getCryptoPaymentInfo(fromCurrencyCode, order.incomingCryptoTransaction);
            }
        }
    }

    const contentWithPayment = (
        <Stack direction='column' spacing={4}>
            <DetailView items={paymentInfoDetails ?? []} valueMaxLength={40} />
        </Stack>
    );

    const showPaymentInstructions = // show payment instructions ...
        type === 'incoming' && // only for incomming payments
        order.status === 'new' && // only for orders that has not been paid yet
        (!order.is_over_limit_when_paid || (userAuth?.user?.verification_level ?? 0) > 0); // only for orders that are not over limit OR verified users

    const contentWithoutPayment = (
        <Stack direction='column' alignItems='center' spacing={4} pb={4}>
            {order.is_over_limit_when_paid && type === 'incoming' ? (
                <Alert severity='warning' sx={{maxWidth: '360px', flexDirection: 'column', textAlign: 'center'}}>
                    {t('order_limit_alert')}
                    <br />
                    <Link href='/verification'>{t('verification_and_incomes')}</Link>
                </Alert>
            ) : (
                <Typography sx={{zIndex: 2}} align='center'>
                    {paymentInfo}
                </Typography>
            )}

            {!fromIsCrypto && (
                <Alert severity='warning' sx={{maxWidth: '360px', flexDirection: 'column', textAlign: 'center'}}>
                    <Trans i18nKey='payment_instructions_delay_warning' />
                </Alert>
            )}

            {showPaymentInstructions && (
                <Dialog
                    invoker={paymentInstructionsDialogInvoker}
                    dialogTitle={t('order_payment_instructions_header')}
                    dialogContent={paymentInstructionsComponent}
                />
            )}

            {!fromIsCrypto && paymentConfirmationUpload}
        </Stack>
    );

    const sentByTrezorContent = (
        <Stack direction='column' alignItems='center'>
            <DoneRoundedIcon color='secondary' />

            <Typography maxWidth='350px' variant='caption' align='center' sx={{zIndex: 2}}>
                {t('trezor_transaction_sent_dialog_content', {txid: trezorSentTransactionTxId})}
            </Typography>
        </Stack>
    );

    let content;

    if (hasPayment) {
        content = contentWithPayment;
    } else if (trezorSentTransactionTxId) {
        content = sentByTrezorContent;
    } else {
        content = contentWithoutPayment;
    }

    let checkLabel;
    if (type === 'incoming' && hasIncomingPayment) {
        checkLabel = t('order_status_paid');
    } else if (type === 'outgoing' && hasOutgoingPayment) {
        checkLabel = t('order_status_dispatched');
    }

    return (
        <Card sx={{position: 'relative', maxWidth: '800px'}}>
            <CardContent>
                {type === 'incoming' && !hasIncomingPayment && (
                    <>
                        <Hidden mdDown>
                            <OrangeBall />
                            <PurpleBall />
                        </Hidden>
                    </>
                )}

                {type === 'outgoing' && !hasOutgoingPayment && (
                    <>
                        <DottedSphere src={dottedSphere} />
                    </>
                )}

                <Stack direction='column' spacing={4}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Typography variant='h2'>{label}</Typography>
                        {checkLabel && (
                            <Chip icon={<DoneRoundedIcon />} label={checkLabel} color='success' size='small' />
                        )}
                    </Stack>

                    {content}
                </Stack>
            </CardContent>
        </Card>
    );
}
