/**
 * Checks if application version matches the provided backend version
 */
import {getConfigValue} from '~/helpers/config';

export function isApplicationVersionOutdated(backendVersion: string, appVersion: string): boolean {
    if (backendVersion && appVersion && backendVersion !== appVersion) {
        if (getConfigValue('environment') === 'development') {
            // eslint-disable-next-line no-console
            console.warn(`Versions do not match. Frontend: ${appVersion} Backend: ${backendVersion}`);
        }
        return isRelevantVersionChange(appVersion, backendVersion);
    }

    return false;
}

/**
 * Checks if relevant subversion has changed
 *  version should be a valid semantic version (https://semver.org/)
 * @param {string} appVersion
 * @param {string} backendVersion
 * @return {boolean}
 */
export function isRelevantVersionChange(appVersion: string, backendVersion: string) {
    // modified semantic version regex from https://semver.org/ - matches v-prefixed versions
    const re =
        /^(v[0-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/;
    const backendSubVersions = new RegExp(re).exec(backendVersion);
    const frontendSubVersions = new RegExp(re).exec(appVersion);

    if (!backendSubVersions || !frontendSubVersions) {
        // one of the versions is invalid
        return false;
    }

    if (backendSubVersions.length < 3 || frontendSubVersions.length < 3) {
        // fewer than 3 subversion divisions, this should not happen
        return false;
    }

    for (let i = 1; i < 4; i++) {
        // is first 3 version number version are matching, there's no need to refresh
        // exec array has the first match in index 1
        if (backendSubVersions[i] !== frontendSubVersions[i]) {
            return true;
        }
    }

    return false;
}
