interface Props {
    text: string;
    shortenChar?: string;
    maxSize?: number;
}

/**
 * Truncated text to desired length by removing middle part and adding separator there
 */
export function truncateMiddle({text = '', maxSize = 24, shortenChar = '...'}: Props): string {
    const textLength = text ? text.length : 0;

    if (maxSize <= shortenChar?.length) {
        maxSize = shortenChar?.length + 2;
    }

    if (!text || textLength <= maxSize || textLength < 2) return text;

    const sepLen = shortenChar.length;
    const charsToShow = maxSize - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return text.substring(0, frontChars) + shortenChar + text.substring(textLength - backChars, textLength);
}

/**
 * Limits length of the text to the specified value.
 */
export function shorten({text = '', maxSize = 24, shortenChar = '...'}: Props): string {
    if (!text || text.length <= maxSize) return text;

    return text.substring(0, maxSize) + shortenChar;
}
