import React, {useEffect} from 'react';
import {styled, Link, Stack, Typography, useMediaQuery} from '@mui/material';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Menu from '~/src/components/Auth/Menu';
import {RootState, useAppDispatch} from '~/src/redux/store';
import NotificationList from '~/src/components/Common/NotificationList';
import {WidthBreakpoint} from '~/src/config/localParams';
import Header from './AppHeader';
import {fetchAccountSettings} from '~/src/pages/settings/SettingsActions';
import getLink from '~/src/config/links';
import {dayjs, getConfigValue, useBackendSettings} from '@simplecoin/core';
import {ThemeSelect} from '~/src/MuiTheme';
import {Tile} from '../Common/Tile';

const Content = styled('main')`
    flex: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;

    // Should be synced with src/components/Auth/Sidebar/index.js
    @media (max-width: ${WidthBreakpoint.md}) {
        margin-left: 0;
        padding-top: 65px;
    }
`;

const Footer = styled(Tile)(({theme}) => `
    margin: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px 48px;
    ${theme.logo.name === 'devizovaburza' ? '' : 'background: #f4f2ff;'}

    @media (max-width: ${WidthBreakpoint.md}) {
        padding: 27px 20px;
        //margin: 0;
    }
`);

const ContentWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    padding-right: 36px;
    padding-left: 36px;
    padding-top: 8px;
    min-height: 90vh;
    @media (max-width: ${WidthBreakpoint.md}) {
        flex-direction: column;
        padding-right: 0;
        padding-left: 0;
    }
`;

/**
 * Renders core application layout for authenticated users.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export default function AuthLayout({children}: {children: React.ReactChildren}) {
    const {t} = useTranslation();
    const smallScreen = useMediaQuery(`(max-width:${WidthBreakpoint.md})`);
    const dispatch = useAppDispatch();
    const {auth} = useSelector((state: RootState) => state.user);
    const companyName = useBackendSettings('companyName', '');
    const authenticated = auth?.response?.is_auth;
    const isDev = getConfigValue('environment') === 'development';
    const {userSettings} = useSelector((state: RootState) => state.accountSettings);
    const isWallet = process.env.BUILD_TYPE === 'wallet';

    useEffect(() => {
        if (authenticated && !userSettings.isFetching && !userSettings.response?.base_currency) {
            dispatch(fetchAccountSettings());
        }
    }, [authenticated]);

    return (
        <>
            <header>
                <Header />
            </header>

            <ContentWrapper>
                {authenticated && !smallScreen && <Menu />}

                <Content>
                    <NotificationList />
                    {children}
                </Content>
            </ContentWrapper>

            <footer>
                <Footer>
                    <Stack
                        direction={{xs: 'column', md: 'row'}}
                        alignItems='center'
                        justifyContent={{xs: 'center', md: 'space-between'}}
                        width='100%'
                        spacing={2}
                    >
                        <Stack direction={{xs: 'column', md: 'row'}} alignItems='center' spacing={{xs: 1, md: 4}}>
                            <Typography variant='subtitle1'>
                                <Link
                                    underline='none'
                                    href={getLink(isWallet ? 'wallet-terms-of-services' : 'terms-of-services')}
                                    component='a'
                                >
                                    {t('menu_guest_link_terms')}
                                </Link>
                            </Typography>

                            <Typography variant='subtitle1'>
                                <Link underline='none' href={getLink('privacy-policy')} component='a'>
                                    {t('menu_guest_link_privacy_policy')}
                                </Link>
                            </Typography>

                            <Typography variant='subtitle1'>
                                <Link underline='none' href={getLink('support')} component='a'>
                                    {t('menu_guest_link_help')}
                                </Link>
                            </Typography>

                            {isDev && <ThemeSelect />}
                        </Stack>

                        <Typography variant='subtitle1'>
                            {t('footer_rights_reserved', {
                                year: dayjs().format('YYYY'),
                                companyName: companyName,
                            })}
                        </Typography>
                    </Stack>
                </Footer>
            </footer>
        </>
    );
}

AuthLayout.displayName = 'AuthLayout';
