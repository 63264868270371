{
  "bank_account": "Konto bankowe",
  "cancel": "Anuluj",
  "crypto_wallet": "Portfel kryptowalutowy",
  "exchange": "Kantor",
  "order_has_been_canceled": "Zam\u00f3wienie zosta\u0142o anulowane",
  "order_has_been_proceeded": "Stan zam\u00f3wienia zosta\u0142 zmieniony na kontynuuj",
  "order_has_been_suspended": "Zam\u00f3wienie zosta\u0142o wstrzymane",
  "order_status_history": "Historia stan\u00f3w zam\u00f3wienia",
  "orders": "Zam\u00f3wienia",
  "payment_information": "Dane odbiorcy przelewu",
  "proceed": "Kontynuuj",
  "rates": "Kursy walut",
  "return": "Zwrot",
  "setting_language_preference_text": "Wybierz preferowany j\u0119zyk wiadomo\u015bci e-mail.",
  "setting_send_email_after_login": "Wy\u015blij e-mail po ka\u017cdym zalogowaniu",
  "suspend": "Wstrzymaj",
  "wallet_updated_successfully": "Portfel zosta\u0142 zaktualizowany",
  "we_will_send_you_money_back_shortly": "Wkr\u00f3tce wy\u015blemy \u015brodki do Ciebie ",
  "guest_login_and_signup_order_separation": "lub",
  "order_button_create_order": "Z\u0142\u00f3\u017c zam\u00f3wienie",
  "guest_login": "Zaloguj",
  "guest_signup": "Za\u0142\u00f3\u017c konto",
  "menu_guest_link_about_us": "O nas",
  "menu_guest_link_help": "Pomoc",
  "menu_guest_link_terms": "Warunki handlowe",
  "order_form_caption": "Tutaj stworzysz zam\u00f3wienie",
  "order_form_email": "E-mail",
  "forgot_password": "Zapomnia\u0142e\u015b has\u0142a?",
  "login_form_submit_button": "Zaloguj",
  "page_login_title": "Logowanie do {{brandName}}",
  "page_signup_title": "Rejestracja",
  "signup_form_please_fill": "Wype\u0142nij nast\u0119puj\u0105ce pola aby stworzy\u0107 konto:",
  "signup_form_submit_button": "Stw\u00f3rz konto",
  "order_form_placeholder_currency": "Waluta",
  "refund_widget_add_new_refund_address": "Dodaj adres do zwrotu",
  "refund_address_select_label": "Adres do zwrotu",
  "copied": "Skopiowany",
  "suspended_widget_return_money_back": "Zwrot \u015brodk\u00f3w",
  "suspended_widget_top_alert_message": "Zam\u00f3wienie jest w stanie wstrzymanym i czeka na Twoj\u0105 decyzj\u0119.",
  "add_order": "Stw\u00f3rz zam\u00f3wienie",
  "language": "J\u0119zyk",
  "logged_in": "Zalogowany",
  "search": "Wyszukiwanie",
  "status_direction": "Kierunek",
  "status_direction_description_cancelled": "Zam\u00f3wienie anulowane",
  "status_direction_description_proceed": "Zrealizuj",
  "status_direction_description_return_to_client": "Zwrot \u015brodk\u00f3w",
  "status_direction_description_suspended": "Zam\u00f3wienie wstrzymane",
  "status_final": "Status ko\u0144cowy",
  "status_name_cancelled": "Anulowane",
  "status_name_confirming_incoming": "P\u0142atno\u015b\u0107 przychodz\u0105ca potwierdzana",
  "status_name_confirming_outgoing": "P\u0142atno\u015b\u0107 wychodz\u0105ca potwierdzana",
  "status_name_delivered": "Zrealizowane",
  "status_name_done": "Zako\u0144czone",
  "status_name_new": "Nowe",
  "status_name_proceed": "Do realizacji",
  "status_name_received": "P\u0142atno\u015b\u0107 otrzymana",
  "status_name_return_to_client": "Zwrot do klienta",
  "status_name_returned": "Zwr\u00f3cone",
  "status_name_suspended": "Wstrzymane",
  "suspended_widget_table_change_percent": "Zmiana",
  "client_bank_account_list_add_new_account": "Dodaj konto",
  "account_form_create_button": "Dodaj konto bankowe",
  "account_form_save_button": "Zapisz",
  "create_new_wallet_header": "Dodaj portfel",
  "delete_account": "Usu\u0144 konto bankowe",
  "delete_wallet": "Usu\u0144 portfel",
  "update_account": "Zaktualizuj konto",
  "update_wallet": "Zaktualizuj portfel",
  "wallet_form_create_button": "Dodaj portfel",
  "header_what_now": "Co teraz",
  "profile_page_header_my_documents": "Za\u0142\u0105czone dokumenty",
  "profile_page_header_my_profile": "M\u00f3j profil",
  "profile_page_ready_for_verification_we_are_checking_your_profile": "Tw\u00f3j profil jest w trakcie weryfikacji",
  "profile_page_save_button": "Zapisz",
  "profile_page_tab_level1": "Poziom 1",
  "profile_page_tab_level2": "Poziom 2",
  "profile_page_tab_this_level_is_accepted": "Poziom zosta\u0142 zaakcepotowany",
  "refund_address_set_successfully": "Adres do zwrotu ustawiony pomy\u015blnie.",
  "profile_level_checkbox_ready_for_verification_you_would_not_be_able_to_modify_your_information_it_sent_to_admin": "Je\u015bli zatwierdzisz poni\u017csze pole wyboru i wy\u015blesz formularz, nie b\u0119dziesz m\u00f3g\u0142 modyfikowa\u0107 ani usuwa\u0107 swoich plik\u00f3w. Dane zostan\u0105 przes\u0142ane do zatwierdzenia przez naszych pracownik\u00f3w.",
  "client_sidebar_avatar_hash_description": "Zapami\u0119taj ten obrazek. Zosta\u0142 wygenerowany ekskluzywnie dla Twojego konta. Je\u015bli zauwa\u017cysz jego zmian\u0119, mo\u017cliwe, \u017ce kto\u015b pr\u00f3buje podszy\u0107 si\u0119 pod nasze strony. Sprawd\u017a tak\u017ce adres paska przegl\u0105darki, czy adres jest prawid\u0142owy i czy widoczna jest zielona k\u0142\u00f3dka.",
  "navigation_link_logout": "Wyloguj",
  "navigation_link_rates": "Kursy",
  "company_about": "Simplecoin to europejski kantor kryptowalut z siedzib\u0105 s samym sercu Europy, w Pradze, Republice Czeskiej. Naszym g\u0142\u00f3wnym celem jest oferowanie mo\u017cliwo\u015bci korzystania z kryptowalut w prosty spos\u00f3b. Z Simplecoin mo\u017cesz \u0142atwo i bezpiecznie kupowa\u0107, sprzedawa\u0107 lub wysy\u0142a\u0107 kryptowaluty.",
  "sidebar_navigation_orders": "Zam\u00f3wienia",
  "sidebar_navigation_profile": "Profil",
  "sidebar_navigation_security": "Bezpiecze\u0144stwo",
  "sidebar_navigation_settings": "Ustawienia",
  "company_header": "O {{brandName}}",
  "company_header_contacts": "Kontakt",
  "company_header_social": "Platformy spo\u0142eczno\u015bciowe",
  "company_subheader": "Prosty kantor kryptowalut",
  "company_social_group": "Grupa {{brandName}}",
  "company_header_billing_address": "Adres ",
  "page_header_company": "{{brandName}} kantor Bitcoin & Kryptowalut | BTC LTC ETH BCH XRP",
  "footer_sidebar_link_about": "O nas",
  "client_order_reset_all_label": "Resetuj wszystkie",
  "client_order_search_btn": "Opcje wyszukiwania",
  "transaction_accepted_by_banks_now_waiting_for_transaction_being_listed_on_bank_record": "Przelew zosta\u0142 przyj\u0119ty przez bank, czekamy a\u017c pojawi si\u0119 na li\u015bcie transakcji wychodz\u0105cych.",
  "error": "B\u0142\u0105d",
  "client_bank_accounts_widget_header": "Dokumenty dotycz\u0105ce konta bankowego",
  "new_return_to_client_waiting_for_transaction_to_be_matched_and_return_your_funds": "Czekamy na zaksi\u0119gowanie transakcji przychodz\u0105cej. Po otrzymaniu zwr\u00f3cimy j\u0105, gdy\u017c kierunek zam\u00f3wienia ustawiony jest na zwrot.",
  "page_subheader_settings_order_emails": "Wiadomo\u015bci e-mail zam\u00f3wienia",
  "page_subheader_settings_security_emails": "Wiadomo\u015bci bezpiecze\u0144stwa",
  "client_setting_hint_send_email_when_order_confirming_incoming": "Wy\u015blij wiadomo\u015b\u0107 e-mail po wykryciu przychodz\u0105cej p\u0142atno\u015b\u0107i kryptowalut\u0105, kt\u00f3ra czeka na potwierdzenie",
  "client_setting_hint_send_email_when_order_confirming_outgoing": "Wy\u015blij wiadomo\u015b\u0107 e-mail po wys\u0142aniu p\u0142atno\u015bci przelewem bankowym lub kryptowalut\u0105 przed uzyskaniem potwierdzenia",
  "client_setting_hint_send_email_when_order_done": "Wy\u015blij wiadomo\u015b\u0107 e-mail po zmianie stanu zam\u00f3wienia na zrealizowane.",
  "client_setting_hint_send_email_when_order_proceed": "Wy\u015blij wiadomo\u015b\u0107 e-mail kiedy stan kt\u00f3rego\u015b zam\u00f3wienia zmieni si\u0119 na gotowe do realizacji.",
  "client_setting_hint_send_email_when_order_received": "Wy\u015blij wiadomo\u015b\u0107 e-mail po zaksi\u0119gowaniu przelewu bankowego lub zatwierdzeniu przychodz\u0105cej p\u0142atno\u015bci kryptowalut\u0105",
  "client_setting_hint_send_email_when_order_return_to_client": "Wy\u015blij wiadomo\u015b\u0107 e-mail kiedy stan kt\u00f3rego\u015b zam\u00f3wienia zmieni si\u0119 na zwr\u00f3cone.",
  "client_setting_hint_send_email_when_order_suspended": "Wy\u015blij wiadomo\u015b\u0107 e-mail kiedy stan kt\u00f3rego\u015b zam\u00f3wienia zmieni si\u0119 na wstrzymane.",
  "client_settings_label_order_confirming_incoming": "P\u0142atno\u015b\u0107 przychodz\u0105ca niepotwierdzona",
  "client_settings_label_order_confirming_outgoing": "P\u0142atno\u015b\u0107 wychodz\u0105ca niepotwierdzona",
  "client_settings_label_order_done": "Zrealizowane",
  "client_settings_label_order_proceed": "Gotowe do zrealizowania",
  "client_settings_label_order_received": "Zaksi\u0119gowane",
  "client_settings_label_order_return_to_client": "Oczekuj\u0105ce na zwrot",
  "client_settings_label_order_suspended": "Wstrzymane",
  "page_subheader_settings_general": "Og\u00f3lne",
  "profile_page_level1_my_document_subheader_paragraph": "Jakikolwiek inny dokument mog\u0105cy s\u0142u\u017cy\u0107 do Twojej identyfikacji. Mo\u017cesz za\u0142\u0105czy\u0107 kilka plik\u00f3w.",
  "profile_page_level2_my_document_subheader_paragraph": "Mo\u017cesz za\u0142\u0105czy\u0107 kilka plik\u00f3w",
  "profile_page_level2_note_description": "Je\u015bli rejestracja dotyczy formy, podaj swoje stanowisko, nazw\u0119 firmy i REGON.",
  "profile_page_level2_trading_purpose_description": "Prosimy o podanie celu wymiany. Regulacje czeskie zobowi\u0105zuj\u0105 nas do uzyskania tej informacji. ",
  "profile_page_level2_trading_source_of_income_description": "Prosimy o opisanie sposobu uzyskania \u015brodk\u00f3w. W przypadku bardzo du\u017cych kwot \u017c\u0105damy za\u0142\u0105czenia dokumentu potwierdzaj\u0105cego o\u015bwiadczenie. Je\u015bli nie mo\u017cesz go za\u0142\u0105czy\u0107, napisz jawnie, \u017ce nie ma takich dowod\u00f3w.",
  "action_reset_password_error_email_not_sent": "Przepraszamy, ale nie mogli\u015bmy zresetowa\u0107 has\u0142a dla danego adresu e-mail.",
  "action_reset_password_success": "Wys\u0142ali\u015bmy wiadomo\u015b\u0107 e-mail dalszymi instrukcjami",
  "address_was_deleted": "Adres zosta\u0142 usuni\u0119ty",
  "bank_account_was_added": "Konto bankowe zosta\u0142o dodane",
  "bank_account_was_deleted": "Konto bankowe zosta\u0142o usuni\u0119te",
  "bank_account_was_updated": "Konto bankowe zosta\u0142o zaktualizowane",
  "change": "Zmie\u0144",
  "change_password": "Zmiana has\u0142a",
  "delete": "Usu\u0144",
  "email_confirmed": "Tw\u00f3j adres e-mail zosta\u0142 zweryfikowany.",
  "enter_email_address_to_recover_account": "Podaj sw\u00f3j adres e-mail. Link do zresetowania has\u0142a zostanie tam wys\u0142any.",
  "file_was_deleted": "Plik zosta\u0142 usuni\u0119ty",
  "information_successfully_saved": "Twoje dane zosta\u0142y zapisane.",
  "new_address_was_added": "Nowy adres zosta\u0142 zapisany.",
  "new_password_saved": "Nowe has\u0142o zosta\u0142o zapisane.",
  "order_has_been_created_guest": "Zam\u00f3wienie zosta\u0142o stworzone. Sprawd\u017a wiadomo\u015b\u0107 e-mail aby zobaczy\u0107 jego szczeg\u00f3\u0142y i dane dotycz\u0105ce p\u0142atno\u015bci.",
  "pls_choose_new_password": "Utw\u00f3rz nowe has\u0142o",
  "request_password_reset": "\u017b\u0105danie zresetowania has\u0142a",
  "reset_password": "Zresetuj has\u0142o",
  "save": "Zapisz",
  "security": "Bezpiecze\u0144stwo",
  "send": "Wy\u015blij",
  "signup": "Rejestracja",
  "update": "Zmie\u0144",
  "are_you_sure_you_want_to_delete_this_file": "Czy na pewno chcesz usun\u0105\u0107 ten plik?",
  "client_bank_account_message_warning_about_symbol": "* Notatka zostanie dodana do tytu\u0142u przelewu",
  "from_amount_currency_label": "Kwota wyj\u015bciowa",
  "to_amount_currency_label": "Kwota docelowa",
  "menu_guest_link_rates": "Ceny",
  "is_automatic_proceed_on_duplicate_payment": "Automatycznie kontynuuj w przypadku podw\u00f3jnej p\u0142atno\u015bci",
  "is_automatic_proceed_on_late_payment": "Automatycznie kontynuuj przy op\u00f3\u017anionej p\u0142atno\u015bci",
  "setting_hint_is_automatic_proceed_on_duplicate_payment": "Przy zaksi\u0119gowaniu p\u0142atno\u015bci powielonej (nale\u017c\u0105cej do zam\u00f3wienia ju\u017c op\u0142aconego) system automatycznie stworzy nowe zam\u00f3wienie. Domy\u015blnie, nowe zam\u00f3wienie zostaje zawieszone i czeka na Twoj\u0105 decyzj\u0119. Je\u015bli uaktywnisz t\u0119 opcj\u0119, cena nowego zam\u00f3wienia zostanie zaktualizowana a jego stan ustawiony na \"kontynuuj\". W ten spos\u00f3b mo\u017cesz wymienia\u0107 waluty, wysy\u0142aj\u0105c tylko p\u0142atno\u015bci. Nie b\u0119dzie wymagana interakcja z nasz\u0105 stron\u0105 internetow\u0105.",
  "setting_hint_is_automatic_proceed_on_late_payment": "Je\u015bli p\u0142atno\u015b\u0107 dotrze z op\u00f3\u017anieniem, system automatycznie zaakceptuje now\u0105 cen\u0119 - aktualn\u0105 i zmieni stan na gotowe do realizacji. Domy\u015blnie, zam\u00f3wienie zostanie wstrzymane do czasu kiedy podejmiesz decyzj\u0119. ",
  "no_camera_found": "Nie znaleziono kamerki, lub nie otrzymano zezwolenia na korzystanie z niej.",
  "scan_address": "Zeskanuj kod QR",
  "button_disable": "Wy\u0142\u0105cz",
  "confirm": "Zatwierd\u017a",
  "header_disable_google_authenticator": "Uaktywnij dwusk\u0142adnikowe uwierzytelnienie (2FA)",
  "header_enable_google_authenticator": "Uaktywnij dwusk\u0142adnikowe uwierzytelnienie (2FA)",
  "label_2fa_password": "Has\u0142o",
  "label_2fa_secret": "Sekret",
  "2fa_secret_description": "Informacja: Zachowaj ten kod w bezpiecznym miejscu. W razie utraty telefonu podany kod umo\u017cliwi wznowienie dost\u0119pu do dwusk\u0142adnikowego uwierzytelnienia.",
  "btn_disable": "Wy\u0142\u0105cz",
  "btn_enable": "W\u0142\u0105cz",
  "two_factor_authentication": "Dwusk\u0142adnikowe uwierzytelnienie (2FA)",
  "fill_information_below_to_disable_two_factor_authentication": "Wype\u0142nij poni\u017csze pola aby wy\u0142\u0105czy\u0107 dwusk\u0142adnikowe uwierzytelnienie:",
  "alert_success_email_link_was_resent_again": "Wiadomo\u015b\u0107 aktywacyjna zosta\u0142a wys\u0142ana ponownie.",
  "hint_login_form_2fa_code": "Kod nie jest wymagany kiedy dwusk\u0142adnikowe uwierzytelnienie nie jest aktywne.",
  "footer_sidebar_link_terms": "Warunki handlowe",
  "button_download_order_pdf": "Pobierz PDF",
  "button_hint_download_order_pdf": "Pobierz dane zam\u00f3wienia jako plik PDF",
  "alert_success_affiliate_payout_request_successfully_created": "Wyp\u0142ata z programu partnerskiego zosta\u0142a zlecona.",
  "alert_success_you_have_become_affiliate": "Zosta\u0142e\u015b cz\u0142onkiem programu partnerskiego.",
  "button_create": "Utw\u00f3rz",
  "button_save": "Zapisz",
  "page_affiliate_dashboard_copy_url_header": "Partnerski URL",
  "page_affiliate_dashboard_overview_header": "Przegl\u0105d programu partnerskiego",
  "page_affiliate_dashboard_overview_paragraph": "Poni\u017cej znajduj\u0105 si\u0119 podsumowania Twojego konta partnerskiego.",
  "page_affiliate_dashboard_paragraph": "Na tej stronie znajduj\u0105 si\u0119 podsumowania Twojego programu partnerskiego, szczeg\u00f3\u0142owe statystyki i zlecenia wyp\u0142at we wszystkich walutach wspieranych przez {{brandName}}.",
  "page_affiliate_statistics_button": "Statystyki",
  "page_affiliate_stats_first_paragraph": "Na tej stronie znajduj\u0105 si\u0119 statystyki Twojego programu partnerskiego. Ile razy zosta\u0142 u\u017cyty i kiedy. ",
  "page_affiliate_stats_label_conversion": "Klikni\u0119cia banera",
  "page_affiliate_stats_label_period": "Data",
  "page_affiliate_withdraw_button": "Wyp\u0142a\u0107",
  "page_become_affiliate_first_paragraph": "Je\u015bli chcia\u0142by\u015b do\u0142\u0105czy\u0107 do programu partnerskiego, zaznacz poni\u017csze pole akceptuj\u0105c warunki programu i wy\u015blij formularz.",
  "page_header_affiliate_dashboard": "Dashboard programu partnerskiego",
  "page_header_become_affiliate": "Zosta\u0144 partnerem",
  "sidebar_navigation_affiliate": "Program partnerski",
  "label_agree_with_affiliate_conditions": "Akceptuj\u0119 warunki programu partnerskiego",
  "page_affiliate_withdraw_request_paragraph": "Wybierz konto bankowe lub adres do wyp\u0142aty \u015brodk\u00f3w z konta partnerskiego.",
  "page_affiliate_finance_first_paragraph": "Na tej stronie zobaczysz list\u0119 zam\u00f3wie\u0144 powi\u0105zanych z Twoim kontem i zysk w EUR za ka\u017cde z nich.",
  "page_affiliate_finance_label_order": "Zam\u00f3wienie",
  "page_affiliate_finance_button": "Przych\u00f3d",
  "page_affiliate_finance_label_profit_in_eur": "Zysk (EUR)",
  "page_affiliate_asset_banners_paragraph": "Mo\u017cesz zamie\u015bci\u0107 interaktywne banery na swoj\u0105 stron\u0119. Skopiuj kod kt\u00f3regokolwiek z poni\u017cszych i wstaw go na stron\u0119.",
  "page_affiliate_assets_button": "Materia\u0142y promocyjne",
  "api_order_unsupported_currency_pair": "Wymiana nie jest mo\u017cliwa dla wybranej pary walutowej",
  "footer_sidebar_link_data_processing": "RODO",
  "alert_success_ticket_created": "Zg\u0142oszenie zosta\u0142o utworzone",
  "alert_success_ticket_reply_sent": "Odpowied\u017a zosta\u0142a wys\u0142ana.",
  "button_send": "Wy\u015blij",
  "page_header_ticket_create": "Nowe zg\u0142oszenie",
  "page_header_ticket_list": "Zg\u0142oszenia",
  "page_header_ticket_reply": "Odpowied\u017a na zg\u0142oszenie",
  "page_ticket_reply_you": "Ty",
  "sidebar_navigation_ticket": "Pomoc",
  "alert_success_ticket_closed": "Zg\u0142oszenie w stanie rozwi\u0105zanym.",
  "button_create_support_ticket": "Pomoc",
  "button_hint_create_support_ticket": "Mo\u017cesz stworzy\u0107 zg\u0142oszenie je\u015bli masz pytanie lub problem z zam\u00f3wieniem.",
  "page_tickets_pending_tickets": "Zg\u0142oszenia oczekuj\u0105ce",
  "page_tickets_solved_tickets": "Zg\u0142oszenia rozwi\u0105zane",
  "page_tickets_no_pending_tickets": "Brak utworzonych zg\u0142osze\u0144",
  "page_tickets_no_solved_tickets": "Brak rozwi\u0105zanych zg\u0142osze\u0144",
  "take_selfie_button": "Zr\u00f3b selfie",
  "no_camera_access_provided": "Nie uzyskano dost\u0119pu do kamery. Prosimy o kontrol\u0119 uprawnie\u0144 i upewnienie si\u0119, \u017ce udzielony zosta\u0142 dost\u0119p.",
  "menu_guest_link_privacy_policy": "Polityka prywatno\u015bci",
  "change_password_social_reset_password_claim": "U\u017cyj poni\u017cszego przycisku aby zmieni\u0107 has\u0142o. Po naci\u015bni\u0119ciu otrzymasz wiadomo\u015b\u0107 e-mail z linkiem do strony na kt\u00f3rej ustawisz nowe has\u0142o.",
  "alert_change_password_email_was_sent": "Wiadomo\u015b\u0107 e-mail z linkiem do zmiany has\u0142a zosta\u0142a wys\u0142ana.",
  "page_confirm_two_factor": "Potwierd\u017a uwierzytelnienie dwusk\u0142adnikowe",
  "page_confirm_two_factor_paragraph": "Podaj kod z aplikacji:",
  "cookie_consent_i_agree": "Rozumiem",
  "cookie_consent_message": "Ta strona korzysta z plik\u00f3w cookie w celu u\u0142atwienia nawigacji i analizy korzystania z naszych produkt\u00f3w i us\u0142ug. <0>Polityka cookies.</0>",
  "try_again_button": "Spr\u00f3buj ponownie",
  "dropable_or_pastable_from_clipboard": "Przeci\u0105gnij i upu\u015b\u0107 pliki z komputera lub wklej ze schowka naciskaj\u0105c Ctrl+v",
  "hint_enter_two_factor_code_to_confirm_action": "Podaj kod z aplikacji Google Authenticator",
  "add_new_ticket": "Utw\u00f3rz zg\u0142oszenie",
  "close_ticket": "Zaznacz jako rozwi\u0105zane",
  "client_setting_hint_send_email_limit_price_changed": "Wy\u015blij wiadomo\u015b\u0107 je\u015bli limit zam\u00f3wienia zostanie ustawiony lub zmieniony.",
  "client_settings_label_order_limit_price_changed": "Limit kursu",
  "button_set_limit": "Ustaw limit",
  "suspended_widget_header_limit_rate": "Limit kursu",
  "suspended_widget_header_limit_rate_hint": "Je\u015bli ustawisz limit, zam\u00f3wienie zostanie zaakceptowane automatycznie kiedy kurs osi\u0105gnie podan\u0105 przez Ciebie warto\u015b\u0107.",
  "suspended_widget_header_market_rate": "Kurs rynkowy",
  "suspended_widget_header_market_rate_hint": "Cena rynkowa, jak\u0105 mo\u017cemy Ci w tej chwili zaoferowa\u0107. Wszelkie op\u0142aty s\u0105 ju\u017c wliczone.",
  "suspended_widget_header_order_rate": "Kurs zam\u00f3wienia",
  "suspended_widget_header_order_rate_hint": "Ten limit ustawili\u015bmy w momencie stworzenia zam\u00f3wienia. Wszelkie op\u0142aty s\u0105 ju\u017c wliczone.",
  "button_change_limit": "Zmie\u0144",
  "button_limit_cancel": "Anuluj",
  "suspended_widget_limit_form_paragraph": "Te dwa pola reprezentuj\u0105 t\u0119 sam\u0105 warto\u015b\u0107. Warto\u015b\u0107 drugiego to odwrotno\u015b\u0107 pierwszego. Dzi\u0119ki temu mo\u017cesz poda\u0107 kurs w spos\u00f3b dla Ciebie bardziej naturalny.",
  "hint_close_ticket_button": "Je\u015bli naci\u015bniesz na przycisk, zg\u0142oszenie zostanie zaznaczone jako rozwi\u0105zane i nie b\u0119dzie mo\u017cliwo\u015bci dodawania dalszych odpowiedzi bez utworzenia nowego zg\u0142oszenia.",
  "heading_chart": "Graf",
  "button_close": "Zamknij",
  "boolean_option_no": "Nie",
  "boolean_option_yes": "Tak",
  "generic_link_learn_more": "Wi\u0119cej",
  "profile_page_header_my_documents_level0_paragraph": "<strong>Prosimy o za\u0142\u0105czeni dokument\u00f3w to\u017csamo\u015bci w celu weryfikacji</strong><br>\nPotrzebne s\u0105 dokumenty skany jako\u015bci (300dpi) or lub czytelne zdj\u0119cia. \u017badne cz\u0119\u015bci nie powinny zosta\u0107 zedytowane lub usuni\u0119te.\nJako \"Inny dokument\" mo\u017cliwe jest za\u0142\u0105czenie dowolnego dokumentu zawieraj\u0105cego dane osobowe i informacje na temat typu dokumentu, numeru, organu wydaj\u0105cego, kraju wydaj\u0105cego i daty wa\u017cno\u015bci.\n",
  "profile_page_header_my_documents_level1_paragraph": "<strong>Prosimy o za\u0142\u0105czenie nast\u0119puj\u0105cych dokument\u00f3w</strong><br>\n- dokument, kt\u00f3ry mo\u017ce pom\u00f3c w udowodnieniu \u017ar\u00f3d\u0142a dochodu<br>\n- zdj\u0119cie z dokumentem to\u017csamo\u015bci<br>\n- dokumenty rejestracyjne firmy (je\u015bli dotyczy)<br>\n- inne dokumenty mog\u0105 by\u0107 wymagane",
  "chart_module_market_price_inverse": "Odwrotno\u015b\u0107",
  "chart_module_no_data_available": "Brak danych.",
  "bank_account_verified": "Zweryfikowane",
  "profile_page_bank_account_header": "Twoje konta bankowe",
  "profile_page_bank_accounts_paragraph": "W celu weryfikacji przez internet wymagane jest dodanie przynajmniej jednego konta bankowego. W sekcji odbiorc\u00f3w dodaj konto i za\u0142\u0105cz wymagane dokumenty lub dodatkowo wy\u015blij przelew pr\u00f3bny.",
  "bank_account_file_upload_unverified_level0_paragraph": "Aby doko\u0144czy\u0107 weryfikacj\u0119 konta na poziom 1, potrzebne jest za\u0142\u0105czenie dokumentu potwierdzaj\u0105cego istnienie konta bankowego. Prosimy o za\u0142\u0105czenie dokumentu wydanego przez bank z widocznym numerem konta i imieniem w\u0142a\u015bciciela. Mo\u017cliwe jest za\u0142\u0105czenie wyci\u0105gu z konta lub umowy w formacie PDF lub kopii. ",
  "guest_market_table": "Tabela cen",
  "validation_error_not_matching_passwords": "Has\u0142a si\u0119 nie zgadzaj\u0105",
  "react_send_from_amount_here": "Wy\u015blij {{amount}} <0>{{currency}}</0> na nast\u0119puj\u0105cy adres:",
  "react_we_received_your_payment_but_you_decided_to_get_it_we_will_send_it_shortly": "Otrzymali\u015bmy p\u0142atno\u015b\u0107, ale wybra\u0142e\u015b zwrot. Wkr\u00f3tce wy\u015blemy do Ciebie {{amount}} <0>{{currency}}</0>.",
  "react_we_received_currency_from_your_order_and_we_will_send_you_currency_to": "Otrzymali\u015bmy {{amountFrom}} <0>{{currencyFrom}}</0> za zam\u00f3wienie i wy\u015blemy Ci {{amountTo}} <1>{{currencyTo}}</1>",
  "react_confirming_incoming_crypto_transaction_waiting_for_more_confirmations": "Zarejestrowali\u015bmy przychodz\u0105c\u0105 transakcj\u0119 na {{amount}} <0>{{currency}}</0>. Teraz czekamy na jej potwierdzenie.",
  "react_confirming_outgoing_crypto_transaction_waiting_for_more_confirmations": "Wys\u0142ali\u015bmy odchodz\u0105c\u0105 transakcj\u0119 na {{amount}} <0>{{currency}}</0>. Teraz czekamy na jej potwierdzenie.",
  "react_suspended_widget_accept_btn": "Zaakceptuj {{amount}} {{currency}}",
  "react_suspended_widget_return_btn": "Zwrot {{amount}} {{currency}}",
  "react_order_created_high_market_volatility_message": "Ze wzgl\u0119du na wahania rynku jeste\u015bmy w stanie utrzyma\u0107 t\u0119 cen\u0119 tylko przez {{duration}} minut. Czas potwierdzenia transakcji krypto zale\u017cny jest od op\u0142aty transakcyjnej. Aby transakcja zosta\u0142a szybko potwierdzona, wa\u017cne jest, aby przy wysy\u0142aniu transakcji ustawi\u0107 odpowiedni\u0105 wysoko\u015b\u0107 op\u0142aty. Je\u015bli transakcja potwierdzona zostanie po up\u0142ywie limitu czasowego, a cena kryptowaluty znacz\u0105co si\u0119 zmieni, skontaktujemy si\u0119 z Tob\u0105 mailowo aby zaoferowa\u0107 opcj\u0119 akceptacji nowego kursu lub zwrotu \u015brodk\u00f3w.",
  "react_order_suspend_over_limit_message": "Zam\u00f3wienie zosta\u0142o wstrzymane ze wzgl\u0119du na osi\u0105gni\u0119ty limit na {{limitDays}}. Musisz podnie\u015b\u0107 poziom weryfikacji profilu do poziomu {{level}}.",
  "chart_timeframe_option_all": "Ca\u0142o\u015b\u0107",
  "react_bank_account_file_upload_unverified_paragraph": "Aby zobaczy\u0107 imi\u0119 w\u0142a\u015bciciela konta potrzebne jest wys\u0142anie do nas przelewu. Je\u015bli wy\u015blesz do nas przelew op\u0142acaj\u0105c zam\u00f3wienie, nie musisz nic wi\u0119cej robi\u0107. Je\u015bli chcesz otrzyma\u0107 od nas przelew na podany rachunek pierwszy raz, potrzebne jest wys\u0142anie najpierw minimalnej kwoty na <0>kt\u00f3rekolwiek z naszych kont</0>.",
  "react_2fa_qr_code_description": "W celu podwy\u017cszenia poziomu bezpiecze\u0144stwa mo\u017cesz aktywowa\u0107 dwusk\u0142adnikowe uwierzytelnienie (2FA) korzystaj\u0105c z aplikacji mobilnej <0> Google Authenticator</0>. Aby zacz\u0105\u0107 korzysta\u0107 z 2FA, zeskanuj kod QR ze strony, podaj aktualne has\u0142o i kod z aplikacji Google Authenticator.",
  "chart_timeframe_option_day": "Dzie\u0144",
  "chart_timeframe_option_month": "Miesi\u0105c",
  "chart_timeframe_option_week": "Tydzie\u0144",
  "chart_timeframe_option_year": "Rok",
  "chart_timeframe_option_year_to_date": "Bie\u017c\u0105cy rok",
  "react_profile_page_level1_paragraph_before_form": "Pierwszy stopie\u0144 pozwala na handel do {{limit_level_2}} EUR na {{tradeLimitDays}} dni.",
  "react_profile_page_level2_paragraph_before_form": "Drugi stopie\u0144 jest wymagany do handlu ponad {{limit_level_2}} EUR na {{tradeLimitDays}} dni. W niekt\u00f3rych przypadkach mo\u017cliwe jest dodatkowe sprawdzenie zwi\u0105zane z AML.",
  "react_page_header_posts_by_category": "Kategoria: {{name}}",
  "chart_timeframe_option_3months": "3 miesi\u0105ce",
  "ticket_load_more": "Za\u0142aduj kolejne",
  "ticket_type_your_message": "Tutaj wpisz swoj\u0105 wiadomo\u015b\u0107...",
  "no_results_found": "Brak wynik\u00f3w",
  "validation_field_required": "Pole jest wymagane",
  "validation_min_characters": "Pole musi zawiera\u0107 przynajmniej ${min} znak\u00f3w",
  "uploaded_file_information_complete": "Informacje o pliku s\u0105 kompletne",
  "uploaded_file_click_to_fill_information": "Kliknij tutaj, aby wype\u0142ni\u0107 dodatkowe informacje",
  "react_order_header": "Zam\u00f3wienie {{id}}",
  "profile_page_header_my_personal_data": "Dane osobowe",
  "validation_error_incorrect_phone_number": "Niepoprawny numer telefonu",
  "react_file_upload_extension_error": "Obs\u0142ugiwane s\u0105 tylko nast\u0119puj\u0105ce formaty plik\u00f3w: {extensions}",
  "qr_code_scan_success": "Pomy\u015blnie zeskanowano kod QR",
  "upload_success": "Plik zosta\u0142 wgrany",
  "button_open_form": "Otw\u00f3rz formularz",
  "client_bank_trade_volume_label": "Wolumen obrotu rachunku bankowego",
  "qr_payment_hint": "Zeskanuj kod za pomoc\u0105 aplikacji bankowo\u015bci mobilnej wspieraj\u0105cej system QR Platba",
  "react_signup_to_get_trading_volume_or_personal_discount": "<0>Stw\u00f3rz konto</0> aby uzyska\u0107 zni\u017ck\u0119 ilo\u015bciow\u0105 i osobist\u0105.",
  "sidebar_user_traded_volume": "Wolumen za {{tradeLimitDays}} dni: {{traded}}/{{limit}}EUR",
  "trading_limits_info_message": "Limit bez weryfikacji  {{limitLevel1}} EUR/{{level1TradeLimitDays}} dni <br/>\nPoziom 1:  {{limitLevel2}} EUR/{{level2TradeLimitDays}} dni <br/>\nPoziom 2: limit nieokre\u015blony",
  "sidebar_user_traded_volume_sum": "Wolumen handlu: {{traded}} EUR",
  "react_refund_widget_already_chosen_refund_address": "Wybra\u0142e\u015b {{refundAddress}} jako adres do zwrotu.",
  "react_we_received_your_crypto_payment_but_you_decided_to_get_it_we_will_send_it_shortly": "Otrzymali\u015bmy p\u0142atno\u015b\u0107, jednak wybra\u0142e\u015b opcj\u0119 zwrotu. Zwrot obci\u0105\u017cony jest op\u0142at\u0105 za transakcj\u0119. Kwota, jak\u0105 otrzymasz to: {{amount}} <0>{{currency}}</0>.",
  "refund_widget_btn_change_refund_address": "Zmie\u0144 adres do zwrotu",
  "react_order_crypto_return_fee_info_message": "Zwrot obci\u0105\u017cony jest op\u0142at\u0105 za transakcj\u0119. Kwota, jak\u0105 otrzymasz to:  {{amount}} <0>{{currency}}</0>.",
  "react_suspended_widget_return_currency_btn": "Zwr\u00f3\u0107 {{amount}} {{currency}} na ten portfel",
  "we_will_send_you_crypto_back_shortly": "Wkr\u00f3tce wy\u015blemy kryptowalut\u0119",
  "button_remove_limit": "Usu\u0144 limit",
  "react_alert_limit_removed": "Limit zosta\u0142 usuni\u0119ty",
  "unconfirmed_email_please_confirm_or_resend": "Potwierd\u017a adres e-mail albo <0>wy\u015blij wiadomo\u015b\u0107 ponownie</0>",
  "button_back": "Wr\u00f3\u0107",
  "button_next": "Dalej",
  "button_submit_profile": "Wy\u015blij formularz do weryfikacji",
  "you_need_to_log_out_before_seeing_anonymous_order": "Aby wy\u015bwietli\u0107 zam\u00f3wienie anonimowe musisz si\u0119 wylogowa\u0107",
  "react_form_rate_explenation": "Jak liczony jest kurs?",
  "sign_up_to_return_guest_order": "Aby zwr\u00f3ci\u0107 zam\u00f3wienie, zarejestruj si\u0119 i dodaj adres do zwrotu.",
  "error_page_not_found": "Strony nie znaleziono",
  "react_page_affiliate_withdraw_request_button": "Wyp\u0142a\u0107 {{amount}} {{currency}}",
  "affiliate_withdraw_history_table_converted_amount": "Kwota skonwertowana",
  "react_page_affiliate_dashboard_copy_url_paragraph": "Skopiuj poni\u017cszy URL na swojego bloga, portale spo\u0142eczno\u015bciowe lub wy\u015blij znajomym. Ka\u017cdy, kto kliknie na link i zarejestruje si\u0119 lub stworzy zam\u00f3wienie w ci\u0105gu {{duration}} dni b\u0119dzie powi\u0105zany z Twoim programem partnerskim. Otrzymasz prowizj\u0119 z ka\u017cdej wymiany przeprowadzonej przez tego klienta.",
  "react_affiliaite_balance_information": "Saldo: {{balance}}<br/>Zysk ca\u0142kowity: {{totalProfit}}",
  "react_affiliaite_balance_information_tooltip": "Saldo to kwota, kt\u00f3r\u0105 mo\u017cesz wyp\u0142aci\u0107. Ca\u0142kowity zysk jest sum\u0105 bie\u017c\u0105cego salda + wyp\u0142aconej kwoty.",
  "label_affiliate_overview_joined_at": "Do\u0142\u0105czono",
  "label_repeat_password": "Powt\u00f3rz has\u0142o",
  "react_consent_to_tos": "O\u015bwiadczam, \u017ce zapozna\u0142em si\u0119 z <0>Warunkami Handlowymi</0> formy {{brandName}} i je akceptuj\u0119.",
  "label_delete_account": "Usu\u0144 konto",
  "button_delete_account": "Usu\u0144 swoje konto",
  "delete_account_warning_paragraph": "Je\u015bli przejdziesz dalej, dane Twojego konta zostan\u0105 trwale usuni\u0119te. Przykro nam, \u017ce odchodzisz. Mo\u017cesz zarejestrowa\u0107 si\u0119 ponownie z tym samym adresem e-mail, ale twoje konto b\u0119dzie puste.",
  "account_delete_success": "Twoje konto zosta\u0142o usuni\u0119te",
  "log_out_success": "Nast\u0105pi\u0142o wylogowanie",
  "session_time_remaining_label": "Czas do ko\u0144ca sesji",
  "session_expire_in_label": "Twoja sesja wyga\u015bnie",
  "order_filter_label": "Filtry",
  "order_filter_button_all": "Wszystkie",
  "to_currency_amount_field_label": "Chc\u0119 otrzyma\u0107",
  "status_name_unfinished": "Nie sko\u0144czone",
  "status_description_unfinished": "Zam\u00f3wienie jeszcze nie posiada stanu ko\u0144cowego",
  "filter_drawer_incomplete_filters_warning": "Prosimy o wybranie przynajmniej jednego stanu z ka\u017cdej sekcji",
  "help_pages_label": "Centrum Pomocy",
  "react_file_upload_size_error": "Plik jest zbyt du\u017cy. Maksymalna wielko\u015b\u0107 to {{size}}MB",
  "alert_submit_previous_level_first": "Prosimy o wype\u0142nienie poprzedniego poziomu weryfikacji",
  "wrong_vs_format_message": "Variabilni symbol powinien sk\u0142ada\u0107 si\u0119 z cyfr (maksymalnie 10)",
  "wrong_ss_format_message": "Specificky symbol powinien sk\u0142ada\u0107 si\u0119 z cyfr (maksymalnie 10)",
  "wrong_ks_format_message": "Konstantni symbol powinien sk\u0142ada\u0107 si\u0119 z cyfr (maksymalnie 4)",
  "button_extend_session": "Przed\u0142u\u017c sesj\u0119",
  "fiat_payment_recipient_name": "Nazwa odbiorcy",
  "fiat_payment_recipient_street": "Ulica odbiorcy",
  "fiat_payment_recipient_city": "Miejscowo\u015b\u0107 odbiorcy",
  "fiat_payment_recipient_country": "Kraj odbiorcy",
  "react_you_may_send_money_on_one_of_the_following_bank_accounts_singular": "Wy\u015blij {{amount}} <0>{{currency}}</0> na nast\u0119puj\u0105cy rachunek bankowy",
  "crypto_qr_hint": "Mo\u017cesz zeskanowa\u0107 kod lub nacisn\u0105\u0107 na niego aby otworzy\u0107 portfel na Twoim urz\u0105dzeniu.",
  "react_validation_error_insufficient_funds": "Niewystarczaj\u0105ce saldo. Masz tylko {{amount}} {{currencyName}}.",
  "client_settings_label_affiliate_new_income": "Nowe \u015brodki zosta\u0142y zaksi\u0119gowane",
  "client_setting_hint_affiliate_new_income": "Wy\u015blij wiadomo\u015b\u0107 kiedy otrzymam nowe \u015brodki z programu partnerskiego",
  "page_subheader_settings_affiliate": "Program partnerski",
  "check_address_on_trezor_header": "Sprawd\u017a adres na urz\u0105dzeniu Trezor",
  "check_address_on_trezor_body": "I naci\u015bnij przycisk dalej",
  "show_address_on_trezor_button_label": "Poka\u017c adres na urz\u0105dzeniu Trezor",
  "send_by_trezor_button": "Wy\u015blij z Trezora",
  "send_by_trezor_description_tooltip": "Mo\u017cesz skorzysta\u0107 z tej funkcji aby wys\u0142a\u0107 transakcj\u0119 bezpiecznie z pod\u0142\u0105czonego portfela sprz\u0119towego Trezor.",
  "trezor_address_import_dialog_header": "Nast\u0119puj\u0105ce adresy zosta\u0142y wgrane z Trezora. Prosz\u0119 wybierz jeden.",
  "import_from_trezor_button": "Importuj z Trezora",
  "address_used": "wykorzystany",
  "trezor_transaction_sent_dialog_title": "Transakcja zosta\u0142a wys\u0142ana pomy\u015blnie.",
  "trezor_transaction_sent_dialog_content": "Transakcja ID: {{txid}} Prosimy o moment cierpliwo\u015bci ni\u017c transakcja zostanie dopasowana do zam\u00f3wienia. Naci\u015bnij przycisk od\u015bwie\u017c aby zobaczy\u0107 zmiany.",
  "trezor_address_import_available_for_currencies": "Dodanie adresu z Trezora jest mo\u017cliwe dla: {{currencies}}",
  "trezor_link_learn_more": "Dowiedz si\u0119 wi\u0119cej o u\u017cywaniu Trezora na {{brandName}}",
  "page_refresh_button_label": "Od\u015bwie\u017c",
  "profile_level_one_send_transfer_instead_of_document_upload": "Wy\u015bl\u0119 przelew bankowy z konta prowadzonego w moim imieniu zamiast za\u0142\u0105czenia dokumentu",
  "profile_level1_primary_document_front_label": "Przednia strona pierwszego dokumentu",
  "profile_level1_primary_document_reverse_label": "Tylna strona drugiego dokumentu",
  "profile_level1_secondary_document_front_label": "Przednia strona drugiego dokumentu",
  "profile_level1_secondary_document_reverse_label": "Tylna strona drugiego dokumentu",
  "profile_page_level1_primary_document_label": "Pierwszy dokument to\u017csamo\u015bci",
  "profile_page_level1_secondary_document_label": "Drugi dokument to\u017csamo\u015bci",
  "profile_page_level1_additional_documents_label": "Dodatkowe dokumenty",
  "profile_page_level1_document_passport": "Paszport",
  "profile_page_level1_document_national_id": "Dow\u00f3d osobisty",
  "profile_page_level1_document_other": "Inny dokument",
  "profile_page_level1_document_driving_license": "Prawo jazdy",
  "profile_page_level1_primary_document_type_select_information": "Wybierz rodzaj pierwszego dokumentu",
  "profile_page_level1_secondary_document_type_select_information": "Wybierz rodzaj drugiego dokumentu ",
  "profile_page_level1_additional_document_type_select_information": "Dodaj dokument dodatkowy",
  "profile_page_level1_additional_document_skip_label": "Nie mam dodatkowych dokument\u00f3w do za\u0142\u0105czenia.",
  "address_format_bech32_wallet_support_warning": "Wybrany adres jest w formacie bech32, kt\u00f3re nie jest w pe\u0142ni wspierany przez Portfel Trezor. Aby zapewni\u0107 ca\u0142kowit\u0105 funkcjonalno\u015b\u0107 wybierz \"Segwit accounts\" lub \"Legacy Accounts\". Adres w formacie bech32 mo\u017ce by\u0107 u\u017cyty w portfelu electrum.org.",
  "label_document_title": "Tytu\u0142 dokumentu",
  "react_new_app_version_available_dialog_title": "Nowa wersja platformy {{brandName}} jest dost\u0119pna",
  "update_application_version": "Zaktualizuj",
  "third_party_guest_order_provide_email_paragraph": "Podaj adres email aby kontynuowa\u0107",
  "third_party_order_claim_email_verification_message": "Prosz\u0119 sprawdzi\u0107 poczt\u0119, gdzie mo\u017cna znale\u017a\u0107 link potwierdzaj\u0105cy.",
  "third_party_guest_order_confirm_or_resend_email": "Potwierd\u017a adres email lub naci\u015bnij poni\u017cszy przycisk aby wys\u0142a\u0107 wiadomo\u015b\u0107 ponownie. ",
  "log_in_to_see_order_registered_client": "Zaloguj si\u0119 aby wy\u015bwietli\u0107 zam\u00f3wienie",
  "client_recipients_list_header": "Odbiorcy",
  "sidebar_navigation_recipients": "Odbiorcy",
  "recipients_filter_label": "Filtruj odbiorc\u00f3w",
  "client_add_new_recipient_button": "Dodaj odbiorc\u0119",
  "reset_filters_button": "Zresetuj",
  "create_new_recipient_header": "Dodaj nowego odbiorc\u0119",
  "disable_social_login_label": "Od\u0142\u0105cz konto portalu spo\u0142eczno\u015bciowego",
  "btn_unlink": "Od\u0142\u0105cz",
  "alert_error_please_set_password_to_disable_social_login": "Nadaj has\u0142o aby wy\u0142\u0105czy\u0107 logowanie poprzez portal spo\u0142eczno\u015bciowy.",
  "disable_social_login_warning_paragraph": "Kontynuuj\u0105c, od\u0142\u0105czysz konto portalu spo\u0142eczno\u015bciowego. Logowanie b\u0119dzie mo\u017cliwe tylko poprzez has\u0142o do konta {{brandName}}.",
  "social_login_disable_success": "Logowanie poprzez portal spo\u0142eczno\u015bciowy zosta\u0142o wy\u0142\u0105czone",
  "file_attached_to_ticket_auto_message": "Za\u0142\u0105czono plik: {{fileNames}}",
  "network_error_warning": "Ups, jeste\u015b offline",
  "password_strength_very_weak": "Bardzo s\u0142abe has\u0142o",
  "password_strength_weak": "S\u0142abe has\u0142o",
  "password_strength_good": "Zadowalaj\u0105ce has\u0142o",
  "password_strength_great": "Silne has\u0142o",
  "status_description_on_hold": "Zam\u00f3wienie jest zawieszone",
  "status_direction_description_on_hold": "Zam\u00f3wienie jest w stanie zawieszonym",
  "exchange_form_fee_included_tooltip_title": "Wszelkie op\u0142aty zosta\u0142y wliczone w wy\u015bwietlony kurs.",
  "exchange_form_fee_not_included_tooltip_title": "Poni\u017csze op\u0142aty nie s\u0105 wliczone w kurs. Podaj ilo\u015b\u0107 aby zobaczy\u0107 cen\u0119 ko\u0144cow\u0105.",
  "exchange_form_total_fee_eur": "Ca\u0142kowita op\u0142ata: ~{{totalFeeEur}} EUR",
  "exchange_form_fee_tooltip_fee_in": "Op\u0142ata za transakcj\u0119 przychodz\u0105c\u0105: {{amount}} {{currency}}",
  "exchange_form_fee_tooltip_fee_out": "Op\u0142ata za transakcj\u0119 wychodz\u0105c\u0105: {{amount}} {{currency}}",
  "exchange_form_fee_label_no_fee": "Zerowa op\u0142ata",
  "exchange_form_fee_label_no_fee_explanation": "Ta wymiana nie ma dodatkowych koszt\u00f3w transakcyjnych",
  "exchange_form_fee_label_included": "Op\u0142ata transakcyjna wliczona",
  "exchange_form_fee_label_excluded": "Bez op\u0142aty transakcyjnej",
  "order_view_back_to_vendor_button_label": "Wr\u00f3\u0107 do {{providerName}}",
  "table_sort_label": "Sortuj",
  "pagination_next_page": "Nast\u0119pna strona",
  "pagination_previous_page": "Poprzednia strona",
  "pagination_rows_per_page": "Wierszy na stron\u0119",
  "pagination_display_rows": "z",
  "button_download_csv": "Pobierz CSV",
  "button_print": "Drukuj",
  "table_view_columns": "Poka\u017c kolumny",
  "table_filter": "Filtruj tabel\u0119",
  "table_filters_label": "Filtry",
  "show_columns_label": "Poka\u017c kolumny",
  "table_rows_selected": "wierszy wybranych",
  "add_account": "Dodaj konto",
  "account_balance": "Saldo:",
  "account_minimal_balance_label": "Saldo minimalne:",
  "page_header_accounts": "Konta",
  "sidebar_navigation_accounts": "Konta",
  "sidebar_navigation_trade": "Handluj",
  "landing_page_chart_widget_buy_button": "Kup",
  "exchange_form_from_amount_label": "Wysy\u0142asz",
  "exchange_form_to_amount_label": "Otrzymujesz",
  "footer_trading_since": "Handel kryptowalutami od roku 2013",
  "footer_headers_subscribe_to_newsletter": "Zapisz si\u0119 do naszego newslettera",
  "footer_rights_reserved": "\u00a9 {{year}} {{companyName}} Wszelkie prawa zastrze\u017cone.",
  "landing_header": "Kantor kryptowalut z zaufaniem od roku 2013",
  "landing_subheader": "\u0141atwiej si\u0119 nie da. <br /> Obs\u0142ugujemy ponad 30 par walutowych",
  "landing_button_join": "Do\u0142\u0105cz",
  "landing_button_about_us": "O nas",
  "landing_recent_orders_header": "Ostatnie zam\u00f3wienia",
  "landing_statistics_30_day_order_count_label": "Wymian dokonanych w ci\u0105gu ostatnich 30 dni",
  "landing_statistics_verified_clients_count": "Zweryfikowanych klient\u00f3w",
  "landing_statistics_volume_in_currency": "30-dniowy wolumen handlu w {{currency}}",
  "landing_header_help": "Pomoc",
  "button_buy_now": "Kup teraz",
  "footer_header_support": "Wsparcie",
  "footer_link_help": "Pomoc",
  "footer_header_company": "Firma",
  "footer_header_features": "Funkcje",
  "footer_features_trezor_support": "Wsparcie Trezora",
  "footer_features_limit_order": "Zam\u00f3wienie z limitem",
  "footer_email_newsletter_form_text": "Do\u0142\u0105cz do naszego newslettera i nigdy nie przegap niczego wa\u017cnego z {{brandName}}.",
  "landing_email_newsletter_subscribe_button": "Subskrybuj",
  "landing_email_newsletter_subscribe_field_placeholder": "Podaj adres email",
  "landing_email_subscribe_consent_warning": "Podaj\u0105c adres email zgadzasz si\u0119 naszymi <0>Warunkami Handlowymi</0> i <1>Polityk\u0105 prywatno\u015bci</1>",
  "footer_link_transaction_fees": "Op\u0142aty transakcyjne",
  "footer_link_trade_limits": "Limity handlu",
  "add_the_following_comment_to_payment": "Podaj nast\u0119puj\u0105cy tytu\u0142 przelewu",
  "alert_error_please_set_password_to_enable_two_factor": "Najpierw ustaw has\u0142o konta aby w\u0142\u0105czy\u0107 uwierzytelnienie dwusk\u0142adnikowe",
  "alert_success_google_authenticator_disabled_successfully": "Dwusk\u0142adnikowe uwierzytelnienie zosta\u0142o wy\u0142\u0105czone.",
  "alert_success_google_authenticator_enabled_successfully": "Dwusk\u0142adnikowe uwierzytelnienie zosta\u0142o uaktywnione pomy\u015blnie.",
  "confirmed": "Potwiedzona",
  "created_at": "Data z\u0142o\u017cenia",
  "enter_form_email_placeholder": "E-mail",
  "enter_form_password_placeholder": "Has\u0142o",
  "fiat_payments_without_note_cause_delay_warning": "Wszystkie p\u0142atno\u015bci bez tego tekstu b\u0119d\u0105 sprawdzane manualnie co mo\u017ce spowodowa\u0107 op\u00f3\u017anienia w przetwarzaniu zam\u00f3wie\u0144.",
  "form_label_new_password": "Nowe has\u0142o",
  "incoming_bank_transaction": "Przychodz\u0105cy przelew bankowy",
  "incoming_crypto_transaction": "Przychodz\u0105ca transakcja kryptowalutowa",
  "label_2fa_code": "Kod 2FA",
  "label_account_constant": "Symbol sta\u0142y (Czechy)",
  "label_account_label": "Nazwa",
  "label_account_number": "Numer konta",
  "label_account_specific": "Symbol specyficzny (Czechy)",
  "label_account_vs": "Symbol zmienny (Czechy)",
  "label_address": "Adres",
  "label_address_label": "Nazwa",
  "label_addresses": "Adres",
  "label_affiliate_overview_affiliate_id": "ID partnera",
  "label_affiliate_overview_estimated_profit": "Zam\u00f3wienia w trakcie realizacji",
  "label_affiliate_overview_referrals_count": "Zaproszeni u\u017cytkownicy ",
  "label_affiliate_overview_url_hits_count": "Klikni\u0119cia banera",
  "label_amount": "Kwota",
  "label_arbitrary_data": "Destination Tag",
  "label_bank_account": "Konto bankowe",
  "label_bank_account_id": "Konto bankowe",
  "label_bank_accounts": "Konta bankowe",
  "label_birthday": "Data urodzenia",
  "label_city": "Miejscowo\u015b\u0107",
  "label_confirmations": "Potwierdzenia",
  "label_country": "Kraj",
  "label_created_at": "Stworzone",
  "label_currency": "Waluta",
  "label_current_password": "Aktualne has\u0142o",
  "label_document_number": "Numer dokumentu",
  "label_expires_at": "Wa\u017cny do ",
  "label_first_name": "Imi\u0119",
  "label_from_amount_currency": "Z",
  "label_hash": "Hash",
  "label_id": "ID",
  "label_in_block_id": "Numer bloku",
  "label_is_politician": "Osoba zajmuj\u0105ca eksponowane stanowisko polityczne",
  "label_issued_by": "Wydany przez",
  "label_issued_by_country": "Wydany przez pa\u0144stwo",
  "label_last_name": "Nazwisko",
  "label_limit_price": "Limit kursu",
  "label_message": "Tytu\u0142 p\u0142atno\u015bci",
  "label_monthly_volume": "Wolumen miesi\u0119czny",
  "label_new_password_again": "Powt\u00f3rz nowe has\u0142o",
  "label_note": "Notatka",
  "label_other_account_number": "Konto bankowe",
  "label_our_bank_account_id": "Konto bankowe {{brandName}}",
  "label_owner_address": "Adres",
  "label_owner_city": "Miasto",
  "label_owner_country": "Kraj zamieszkania",
  "label_owner_name": "Imi\u0119 w\u0142a\u015bciciela",
  "label_paid_at": "Op\u0142acone o ",
  "label_personal_id": "PESEL",
  "label_phone": "Telefon",
  "label_rate": "Kurs zam\u00f3wienia",
  "label_ready_for_verification": "Gotowe do weryfikacji",
  "label_receive_address": "Adres odbierania",
  "label_status": "Stan",
  "label_status_direction": "Stan kierunku",
  "label_status_final": "Stan ko\u0144cowy",
  "label_street": "Ulica",
  "label_ticket_body": "Opis zg\u0142oszenia",
  "label_ticket_category": "Kategoria",
  "label_ticket_title": "Temat",
  "label_to_amount_currency": "Na",
  "label_trading_purpose": "Cel handlu",
  "label_trading_source_of_income": "\u0179r\u00f3d\u0142o dochodu",
  "label_zipcode": "Kod pocztowy",
  "mail_client_identification_bullet_point_fill_personal_information": "Wype\u0142nij dane osobowe",
  "oops_something_went_wrong": "Co\u015b posz\u0142o nie tak. Spr\u00f3buj ponownie p\u00f3\u017aniej.",
  "order_id": "ID zam\u00f3wienia",
  "outgoing_bank_transaction": "Przelew wychodz\u0105cy",
  "outgoing_crypto_transaction": "Wychodz\u0105ca transakcja kryptowalutowa",
  "page_header_settings": "Ustawienia",
  "password_was_changed": "Has\u0142o zosta\u0142o zmienione",
  "settings_updated": "Ustawienia zosta\u0142y zaktualizowane",
  "social_login_with_facebook": "Zaloguj za pomoc\u0105 konta Facebook",
  "social_login_with_vkontakte": "Zaloguj za pomoc\u0105 konta Vkontakte",
  "social_signin_with_google": "Zaloguj za pomoc\u0105 konta Google",
  "social_signin_with_linkedin": "Zaloguj za pomoc\u0105 LinkedIn",
  "status": "Stan",
  "status_description_cancelled": "Zam\u00f3wienie jest w stanie anulowanym",
  "status_description_confirming_incoming": "Zam\u00f3wienie czeka za potwierdzenie przychodz\u0105cej transakcji",
  "status_description_confirming_outgoing": "Zam\u00f3wienie czeka na potwierdzenie wychodz\u0105cej transakcji",
  "status_description_delivered": "Zam\u00f3wienie jest zrealizowane",
  "status_description_done": "Zam\u00f3wienie jest zako\u0144czone",
  "status_description_new": "Zam\u00f3wienie czeka na op\u0142acenie, nie wykryto transakcji przychodz\u0105cej",
  "status_description_proceed": "Zam\u00f3wienie zrealizowane zostanie zgodnie z informacjami podanymi przy jego tworzeniu.",
  "status_description_received": "Zam\u00f3wienie op\u0142acone i gotowe do kontynuacji",
  "status_description_return_to_client": "Zam\u00f3wienie gotowe do zwrotu",
  "status_description_returned": "P\u0142atno\u015b\u0107 za zam\u00f3wienie zosta\u0142a zwr\u00f3cona",
  "status_description_suspended": "Zam\u00f3wienie jest w stanie wstrzymanym z jednego z nast\u0119puj\u0105cych powod\u00f3w: przekroczony zosta\u0142 czas p\u0142atno\u015bci, wstrzyma\u0142e\u015b zam\u00f3wienie, zosta\u0142 przekroczony limit miesi\u0119czny lub wymagana jest weryfikacja",
  "ticket_category_affiliate": "Program partnerski",
  "ticket_category_api": "API",
  "ticket_category_business_verification": "Weryfikacja firmy",
  "ticket_category_order": "Zam\u00f3wienia",
  "ticket_category_other": "Inne",
  "ticket_category_security": "Bezpiecze\u0144stwo",
  "ticket_category_suggestion": "Sugestie",
  "ticket_category_verification": "Weryfikacja",
  "validation_error_incorrect_email_format": "Niepoprawny format adresu e-mail",
  "you_have_accepted_current_suspended_deal": "Zaakceptowa\u0142e\u015b aktualn\u0105 ofert\u0119.",
  "close": "Zamknij",
  "status_on_hold": "Wstrzymane",
  "submit": "Zatwierd\u017a",
  "resend": "Wy\u015blij ponownie",
  "reviews": "Napisali o nas",
  "label_counter_address": "Adres kontrahenta",
  "react_you_may_send_money_on_one_of_the_following_bank_accounts_plural": "Wy\u015blij {{amount}} <0>{{currency}}</0> na jeden z nast\u0119puj\u0105cych rachunk\u00f3w bankowych",
  "order_filter_button_new": "Nowe",
  "order_filter_button_in_progress": "Aktywne",
  "order_filter_button_finished": "Zako\u0144czone",
  "order_filter_button_custom": "W\u0142asne",
  "client_order_status_base_label": "Status",
  "client_order_status_direction_label": "Kierunek",
  "client_order_status_final_label": "Stan ko\u0144cowy",
  "ticket_status_solved": "Rozwi\u0105zane",
  "ticket_status_in_progress": "W trakcie",
  "ticket_status_waiting_for_admin": "Czekanie na odpowied\u017a supportu",
  "ticket_status_waiting_for_client": "Czekanie na odpowied\u017a klienta",
  "member_of": "Cz\u0142onek zwi\u0105zku",
  "level_one_verification_warning_back_transfer_instead_of_statement": "Wi\u0119cej informacji znajdziesz w <0>tym artykule</0>",
  "label_bank_account_number": "Number konta bankowego",
  "label_bank_account_details": "Detale konta bankowego",
  "validation_max_characters": "Pole mo\u017ce zawiera\u0107 maksymalnie ${max} znak\u00f3w",
  "verification_required_if_order_made_warning_for_registered_clients": "Z uwagi na przekroczenie limitu handlu niezb\u0119dne b\u0119dzie zweryfikowanie. Twoje zam\u00f3wienie zostanie automatycznie wstrzymane i  po zako\u0144czeniu procesu weryfikacji b\u0119dziesz musia\u0142 zaakceptowa\u0107 nowy kurs. Aby uzyska\u0107 wi\u0119cej informacji odwied\u017a <0>t\u0119 stron\u0119</0>.",
  "verification_required_if_order_made_warning_for_guests": "Z uwagi na przekroczenie limitu handlu niezb\u0119dne b\u0119dzie stworzenie konta i jego weryfikacja. Twoje zam\u00f3wienie zostanie automatycznie wstrzymane i  po zako\u0144czeniu procesu weryfikacji b\u0119dziesz musia\u0142 zaakceptowa\u0107 nowy kurs. Aby uzyska\u0107 wi\u0119cej informacji odwied\u017a <0>t\u0119 stron\u0119</0>.",
  "dialog_text_anonymous_trading_disabled_please_log_in_or_register": "Zaloguj si\u0119 lub stw\u00f3rz konto",
  "get_verified_to_start_trading_warning_message": "Musisz podnie\u015b\u0107 sw\u00f3j poziom weryfikacji aby zacz\u0105\u0107 handlowa\u0107. Aktualnie masz <strong>poziom {{currentVerificationLevel}}</strong> a wymagany jest <strong>poziom {{requiredVerificationLevel}}</strong>. Przejd\u017a prosz\u0119 do zak\u0142adki profil i kontynuuj zgodnie z instrukcjami. ",
  "offer_will_expire_in": "Oferta wyga\u015bnie",
  "offer_has_expired": "Oferta wygas\u0142a. Prosimy o stworzenie nowej na stronie partnera lub bezpo\u015brednio na <0>{{company}}</0>.",
  "order_offer_header": "Oferta {{company}}",
  "verification_required_for_order_creation_partner_order": "Aby tworzy\u0107 zam\u00f3wienia na {{brandName}} wymagane jest zweryfikowanie konta na poziom {{level}}. Prosimy o stworzenie konta i jego nast\u0119pn\u0105 weryfikacj\u0119.",
  "label_specify_destination_tag": "Wa\u017cne: wprowad\u017a {{destinationTag}} jako destination tag.",
  "validation_error_required_characters_exactly": "Pole ${path} musi zawiera\u0107 dok\u0142adnie ${length} znak\u00f3w",
  "csv_export_column_from_currency": "Waluta wyj\u015bciowa",
  "csv_export_column_to_currency": "Waluta docelowa",
  "react_order_crypto_no_fee_info_message": "Ta opcja nie ma dodatkowych op\u0142at. Zwr\u00f3cona zostanie ca\u0142a kwota.",
  "login_confirmation_form_check_email_paragraph": "Kod weryfikacyjny zosta\u0142 wys\u0142any na Tw\u00f3j adres mailowy. Sprawd\u017a skrzynk\u0119 i wpisz kod poni\u017cej aby potwierdzi\u0107 logowanie.",
  "login_confirmation_title": "Weryfikacja adresu email",
  "login_confirmation_email_resent": "Email weryfikacyjny zosta\u0142 wys\u0142any",
  "login_confirmation_email_not_received": "Nie otrzyma\u0142e\u015b wiadomo\u015bci weryfikacyjnej?",
  "login_confirmation_code_input_placeholder": "6 cyfrowy kod",
  "lost_2fa_device_contact_support": "Zgubi\u0142e\u015b urz\u0105dzenie?> <0>Skontaktuj si\u0119 z nami</0>",
  "higher_verification_limit_required_warning": "Aby wy\u015bwietli\u0107 dane do przelewu konieczne jest zweryfikowanie konta",
  "verification_through_onfido_disclaimer": "Identity verification will be performed by Onfido.  ",
  "signup_and_verification_required_warning": "Please <0>sign up</0> and verify your account in order to proceed with your order.",
  "go_to_profile_button": "Go to profile",
  "profile_page_divex_address_ownership_disclaimer_heading": "Wallet security statement",
  "profile_page_divex_address_ownership_disclaimer_message": "Please attach a scan or a photocopy of the declaration of wallet ownership which shows that you are the only one with access to the cryptocurrency wallet that you will use within Divex.",
  "profile_page_divex_address_ownership_disclaimer_warning": "The statement is required to ensure that your advisor does not have access to your cryptocurrency wallet and therefore can not steal your funds in future. </br> If you would like to know more about recommended wallet security, <0>click here</0>.",
  "signup_for_invited_only_divex_message": "Registration is only for Divex exchange clients. </br>\nDo you want to become a client? Contact one of our partners to help you register and set up the entire process. </br>\nThe list of all partners is available <0>here</0>.",
  "divex_address_ownership_disclaimer_upload_label": "Attach document here",
  "email_verification_token_expired_log_in_again": "This verification link is incorrect or has expired. Please log in again",
  "pagination_total_visible_rows": "{visibleCount} of {totalCount}",
  "phone_hint_include_prefix": "Please provide the number with prefix",
  "sidebar_navigation_transactions": "Transactions",
  "account_balance_formatted": "Balance: {{balanceWithCurrency}}",
  "transfer_sent_successfully": "Transfer sent successfully",
  "cookie_consent_i_agree_to_all": "Akceptuj\u0119 wszystkie",
  "cookie_consent_i_agree_to_required": "Akceptuj\u0119 tylko funkcjonalne",
  "divex_affil_find_more": "Find out more about affiliate program in <0>this article.<0/>",
  "button_i_confirm": "I confirm",
  "wallet_ownership_declaration_paragraph_1": "I have been advised to use the Trezor hardware wallet for storage of digital assets and use it with PIN code.",
  "wallet_ownership_declaration_paragraph_2": "I am the owner of the master seed (12-24 words) that I have stored securely. I am aware that the cryptocurrencies in the wallet can be spent using the master seed, even without the device itself.",
  "wallet_ownership_declaration_paragraph_3": "The advisor who was helping me set up my wallet did not have access to my master seed and could not make a copy of it.",
  "page_header_exchanges": "Exchange currencies",
  "sidebar_navigation_dashboard": "Dashboard",
  "dashboard_header": "Dashboard",
  "page_header_transfers": "Transfers",
  "settings_button_general_settings": "General",
  "settings_button_security_settings": "Security",
  "settings_button_email_settings": "Rules for sending emails",
  "settings_button_profile_verification": "Profile verification",
  "action_activate_account_email_sent": "We have sent you an e-mail with link for activation of your password to the e-mail address.",
  "action_activate_account_error_email_not_sent": "The e-mail you have provided does not match account that may be activated this way. Please choose another e-mail.",
  "request_account_activation": "Account activation",
  "account_activation_enter_email_provided_to_support": "Activate your Divex account by entering the email you used to register for the Introduction to Bitcoin or Diverzo Academy course.",
  "account_activate_button": "Activate account",
  "account_activation_password_saved": "Your password has been saved. You can now log in.",
  "breadcrumb_category_order": "Order",
  "breadcrumb_subcategory_order_id": "Order #{{id}}",
  "page_header_general_settings": "General settings",
  "page_header_email_settings": "Email rules",
  "dashboard_order_table_last_orders": "Last orders",
  "dashboard_order_table_all_orders": "All orders",
  "user_discount": "Your discount",
  "get_discount": "Get discount",
  "volume_discount": "Volume discount",
  "personal_discount": "Personal discount",
  "ticket_status_all": "All",
  "id": "ID",
  "exchange_form_total_fee": "Total fees",
  "exchange_form_out_fee_crypto": "Outgoing transaction fee",
  "exchange_form_out_fee_fiat": "Outgoing bank transfer fee",
  "exchange_form_in_fee_crypto": "Incoming network fee",
  "exchange_form_in_fee_fiat": "Incoming bank transfer fee",
  "support": "Support",
  "tickets": "Tickets",
  "ticket": "Ticket",
  "message": "Message",
  "upload_file": "Upload file",
  "you": "You",
  "page_become_affiliate_partner_header": "Become Simplecoin <0>affiliate</0> partner",
  "page_become_affiliate_partner_bonus": "Recommend our exchange office to people and get a <strong>  commission of <br> up to {{affiliatePercent}}%</strong> of each exchange they make.",
  "page_become_affiliate_partner_involved": "I want to get involved",
  "page_become_affiliate_partner_information": "More information",
  "page_affiliate_dashboard_header": "Affiliate program",
  "page_affiliate_dashboard_current_balance_header": "Current balance",
  "page_affiliate_dashboard_current_balance_withdraw": "Withdraw balance",
  "order_related_support": "Support related to order",
  "page_affiliate_dashboard_total_profit": "Total profit",
  "page_become_affiliate_partner_copy_link_header": "Your affiliate link",
  "page_become_affiliate_partner_bonus_start": "Start of cooperation:",
  "page_become_affiliate_partner_bonus_tip": "<strong> Tip! </strong> Also use pre-made promotional banners in various sizes and motifs.",
  "page_become_affiliate_partner_promotional": "Show promotional materials",
  "document_issued_by_government": "Document issued by government",
  "document_back_side_scan": "Document back side scan",
  "document_front_side_scan": "Document front side scan",
  "document_type": "Document type",
  "scan_quality_warning": "Good quality scans (300dpi) or photos which are fully readable are required. No parts of any document can be edited nor removed.",
  " page_become_affiliate_partner_income_header": "Overview of income",
  "page_become_affiliate_partner_withdrawal_header": "Overview of withdrawal",
  "page_become_affiliate_partner_statistics_header": "Overwiew of statistics",
  "secondary_document": "Secondary document",
  "selfie_with_id": "Selfie with ID card",
  "affiliate_withdraw_breadcrumb": "Withdraw",
  "affiliate_withdraw_header": "Withdraw affiliate balance",
  "affiliate_withdraw_info_alert": "Your affiliate balance is <strong>{{balanceCurrencyEur}}</strong>. You may withdraw it in any of the supported currencies. You can see the converted amount after selecting the currency and recipient. ",
  "bank_account_create_form_header": "Add a new {{currencyCode}} bank account",
  "crypto_address_create_form_header": "Add a new {{currencyCode}} wallet",
  "settings_social_create_password_button": "Set password",
  "recipient_page_table_name": "Name",
  "recipient_page_table_address": "Address / Bank account no.",
  "recipient_page_table_currency": "Currency",
  "recipient_page_table_header": "List",
  "wallet_welcome_page": "You are about to go to <br /> Simplecoin Wallet",
  "wallet_welcome_page_condions": "I agree with the terms and <0>conditions</0> of Simplecoin Wallet",
  "wallet_welcome_page_button": "Continue to Simplecoin Wallet",
  "wallet_welcome_page_benefits_1": "Create a portfolio at Simplecoin",
  "wallet_welcome_page_benefits_2": "Easy deposit of funds",
  "wallet_welcome_page_benefits_3": "Trade cryptocurrencies easier and faster",
  "wallet_welcome_page_benefits_4": "More advantageous shift fees",
  "wallet_welcome_page_benefits_5": "Priority support",
  "verification_transaction": "Verification transaction",
  "verification_call_info": "As part of the verification process, we also need to verify your phone number, so we will call you to confirm that the verification was completed without any problems. You can choose the approximate time that is most convenient for you for the final phone call.",
  "verification_call": "Verification call",
  "when_should_we_call": "When should we call you",
  "asap": "As soon as possible",
  "email": "E-mail",
  "verification_and_limits": "Verifications and limits",
  "profile_verification": "Profile verification",
  "limits": "Limits",
  "start_with_verification": "Start with verification",
  "verification_in_progress": "Verification in progress",
  "level1": "Level 1",
  "done": "Done",
  "ok": "OK",
  "profile_verification_done_info": "We've emailed you the verification payment information in case you want to get back to them. We will let you know by email that you have been verified.",
  "sidebar_navigation_portfolios": "Porfolio",
  "page_dashboard_insert_deposit": "Deposit funds",
  "page_trade_head_title": "Trade",
  "transaction_tabs_title_exchange": "Exchange",
  "transaction_tabs_title_deposit": "Deposit",
  "transaction_tabs_title_withdraw": "Withdraw",
  "transaction_tabs_title_transfer": "Transfer",
  "label_summary": "Summary",
  "increase_limit": "Increase limit",
  "read_only": "Read-only",
  "page_portfolio_header": "Portfolio",
  "amount": "Amount",
  "purpose_of_crypto_trading": "Purpose of trading with cryptocurrencies",
  "what_is_purpose_of_crypto_trading": "What is your purpose of trading with cryptocurencies",
  "get_familiar_with_cryptocurrencies": "Get familiar with cryptocurencies",
  "occasional_buing_selling": "Occasional buying/selling",
  "one_off_investment": "One-off investment",
  "regular_savings": "Regular savings",
  "other": "Other",
  "speculations": "Speculations",
  "describe_your_purpose_of_cryptotrading": "Describe your purpose of trading with cryptocurrencies",
  "today": "today",
  "morning": "morning",
  "evening": "evening",
  "afternoon": "afternoon",
  "tomorrow": "tomorrow",
  "proof_of_income": "Proof of income",
  "proof_of_income_info": "The Anti-Money Laundering and Anti-Terrorism Financing Act (AML Act) requires to verify the source of your income if you want to trade with higher amounts. We therefore need to ask you some questions about the origin of the money you want to trade with us.",
  "income": "Income",
  "one-off_income": "one-off income",
  "monthly_income": "monthly income",
  "quarterly_income": "quarterly income",
  "yearly_income": "yearly income",
  "frequency": "Frequency",
  "source_of_income": "Source of income",
  "employment": "Employment",
  "position_in_company": "Position in company",
  "company_name": "Company name",
  "running_own_business": "Running own business",
  "rental": "Rental",
  "share_of_profit": "Share of profit",
  "pension": "Pension",
  "documents_supporting_the_income": "Documents supporting the income",
  "income_source_description": "Description",
  "add_income": "Add next income",
  "footer_sidebar_link_blog": "Blog",
  "proof_of_basic_income": "Proof of basic income",
  "proof_of_basic_income_info": "In order to set at least a basic trading and deposit limit, we need to know if you have at least a minimum income. There is no problem to increase this basic limit later if you want to trade \"at full throttle\". Even if you don't have that income, it is still OK. Later you will describe the source of the funds you want to trade with and we will set the limit accordingly.",
  "step": "Step",
  "basic_income_agreement": "I confirm that my income corresponds to at least 600 EUR per month",
  "semi-yearly_income": "semi-yearly income",
  "incomes": "Incomes",
  "income_state_submited": "awaits approval",
  "income_state_approved": "approved",
  "income_state_new": "new",
  "updating_incomes_info": "Any modification of your incomes (adding a new income or deleting an existing one) must be assessed by us and we will adjust your trading limit accordingly.",
  "submit_for_approval": "Submit for approval",
  "income_state_unapproved_change": "unapproved change",
  "prooved_incomes": "Prooved incomes",
  "verification_and_incomes": "Verification and incomes",
  "trading_limits": "Trading limits",
  "current_allowed_trading_amount": "Allowed amount for trading",
  "current_allowed_trading_amount_info": "This is the maximum amount you can right now deposit into Simplecoin Wallet or exchange in Simplecoin Exchange. This amount changes over time depending on how often you trade. It can gradually decrease to zero or increase back to your original limit. Only paid orders and confirmed deposits counts.",
  "individual_limits": "Individual limits",
  "time_period": "Time period",
  "limit_extension": "Limit extension",
  "remaining_limit_amount": "Remaining amount you can still draw down",
  "state_of_limit_execution": "State of limit execution",
  "in_time_period": "in",
  "individual_trading_limits_info": "If you have more individual limits, you must meet all of them. The one that is currently the most restrictive is always applied.",
  "trading_limit_extension_info": "If the standard limit is not enough for you, we can increase your limit even further by providing proof of one-off income. We will gradually reduce this limit whenever you trade above the standard limit.",
  "income_state_deleted": "deleted",
  "account_update_success": "Account was updated",
  "account_label_edit": "Edit account",
  "account_action_archive": "Archive account",
  "waiting_for_payment": "waiting for payment",
  "waiting_for_payment_approval": "waiting for payment approval",
  "verification_transaction_info": "For successful remote identification, you will need to verify at least one of your bank accounts (in your name). For this we only need to receive a single euro from this account.",
  "verification_transaction_received_info": "The verification transaction has been received. All we have to do is verify that it came from your bank account.",
  "verification_transaction_approved_info": "Your bank account has been verified. So we can skip this step.",
  "export_to_csv": "Export to CSV",
  "new_account_button": "Create new Account",
  "remove_recipient": "Remove recipient",
  "recipient_detail": "Recipient detail",
  "account_label": "Account label",
  "veriffied": "veriffied",
  "not_verified": "not verified",
  "label_create_trade": "Create trade",
  "remove_income": "Remove income",
  "transaction_detail_header": "Transaction detail",
  "transaction_type": "Type",
  "trade": "Trade",
  "withdraw_label_about": "You are about to withdraw",
  "withdraw_summary_fees": "Summary and fees",
  "Choose-destination-bank-account": "Choose destination bank account",
  "wallet_account_destination": "Chose destination {{currencyName}} address",
  "account_unique_name_label": "Provide a unique name of this account",
  "account_note_label": "You can add any additional note here",
  "account_detail_suspend_alert": "Your account is suspended. Please email us for support.",
  "current_balance_label": "Current balance",
  "balance_blocked_label": "Blocked balance",
  "label_not_choosen_account": "You do not have an account selected",
  "label_not_enough_money": "You have insufficient funds",
  "label_set_all_funds": "Set all funds",
  "label_available_balance": "Available balance",
  "label_withdraw": "Withdraw",
  "account_label_description": "Account description",
  "label_balance_after_transaction": "Balance after transaction",
  "label_fee": "Fee",
  "label_counter_party": "Counterparty",
  "label_operation_confirm_withdraw": "Confirm your withdrawal",
  "label_operation_confirm_transfer": "Confirm your transfer",
  "label_operation_confirm_exchange": "Confirm your exchange",
  "label_no_account_this_currency": "You do not have an account in this currency",
  "transaction_info_acc_head": "Amount and currency",
  "label_from_account": "From account",
  "label_target_account": "Target account",
  "exchanges_balances_alert_fiat": "Conversion between non-crypto is currently not possible",
  "account_create_success": "Account was created",
  "withdraw_label_requests": "Withdraw requests",
  "operations_label_dashboard_header": "Operations",
  "label_currency_general": "Base currency",
  "income_state_draft": "unverified",
  "label_client_currency_balance": "Balance in base currency",
  "label_show_all": "Show all",
  "dashboard_balances_last_transactions": "Latest transactions",
  "dashboard_balances_no_accounts": "You don't have an account yet",
  "dashboard_balances_no_withdrawal_requests": "No withdrawal requests",
  "dashboard_balances_no_transaction": "You don't have transactions yet",
  "general_settings_label_base_currency": "This currency will be used as default throughout the app",
  "general_settings_header_base_currency": "Base currency",
  "page_portfolio_closed_suspend_accounts_header": "Suspended and canceled Accounts",
  "confirm_withdraw_button": "Withdraw",
  "button_withdraw_continue_to_confirmation": "Continue",
  "recipient_name": "Recipient name",
  "button_create_transfer": "Create transfer",
  "button_transfer_continue_to_confirmation": "Continue",
  "button_exchange_continue_to_confirmation": "Continue",
  "total_balance": "Total balance",
  "no_trading_limit_extension": "There is no extension of the trading limit.",
  "deposit_component_automatic_create_acc_label": "You do not have an account at Simplecoin in this currency yet. We'll set it up for you automatically.",
  "deposit": "Deposit",
  "withdraw": "Withdraw",
  "create_default_account_label": "Default account for",
  "create_default_account_description": "Automatically created account when depositing funds",
  "create_default_account_button": "Create default {{currencyCode}} account",
  "dashboard_blog_posts_header": "Last blog posts",
  "dashboard_link_to_blog": "Blog",
  "credited_amount": "Credited amount",
  "exchange_rate": "Exchange rate",
  "accounting_time": "Accounting time",
  "date": "Date",
  "amounts": "Amounts",
  "success_order_header": "What's next? Pay for your order to complete.",
  "success_order_email": "We have sent you payment instructions by email <0>{{email}}</0>.",
  "finop_type_DEPOSIT": "deposit",
  "finop_type_EXCHANGE": "exchange",
  "finop_type_TRANSFER": "transfer",
  "finop_type_WITHDRAWAL": "withdraw",
  "exchange_made": "Exchange successful",
  "from_account": "From account",
  "to_account": "To account",
  "accounts": "Accounts",
  "portfolio_balance_summary_fiat_header": "Fiat:",
  "portfolio_balance_summary_crypto_header": "Cryptocurrency:",
  "order_success_check_crypto": "Please check if your wallet address is correct.",
  "success_order_label_id": "Your order number is <0>{{order_id}}</0> created. Thank you!",
  "exchange_form_crypto_address_field_label": "Cryptocurrency address",
  "order_success_check_bank": "Please check if the bank account is correct.",
  "other_currencies": "Other currencies",
  "no_funds_in_portfolio": "You have no funds in your portfolio now. Deposit fiat or crypto funds into your accounts and then you can start trading immediately.",
  "exchange_form_register": "for higher limits.",
  "list": "List",
  "withdrawal_request_status_new": "Pending",
  "withdrawal_request_status_confirming_outgoing": "Dispatched",
  "withdrawal_request_status_done": "Done",
  "withdrawal_request_status_canceled": "Cancelled",
  "landing_main_header": "<0>The biggest</0> cryptocurrency exchange in Czechia",
  "next_landing_subheader": "Exchanging cryptocurrencies <0>since 2013</0>.",
  "header_link_about_us": "About us",
  "header_link_get_started": "Get started",
  "header_link_blog": "Blog",
  "header_service_hours": "pon-niedz 9-21",
  "buy_now_button": "Buy now",
  "chart_header": "Buy and sell",
  "exchange_form_label": "Buy and sell",
  "how_it_works_paragraph_1_content": "It only takes a minute to place an order. Up to 200 \u20ac / 10 days, all we need is your email and cryptocurrency wallet address. From higher amounts you will need to register and get verified.",
  "how_it_works_paragraph_1_heading": "Order",
  "how_it_works_paragraph_2_heading": "Pay",
  "how_it_works_paragraph_3_heading": "Receive cryptocurrency",
  "how_it_works_paragraph_2_content": "Once your order is complete, we will provide you with payment instructions.",
  "how_it_works_paragraph_3_content": "Upon receiving of your payment, we will send the cryptocurrency to your wallet. We can usually do it within 30 minutes!",
  "landing_support_section_paragraph": "Need help with exchanging cryptocurrencies or using the Trezor hardware wallet? Contact us anytime via email, phone, or visit our client center for personalized assistance.",
  "landing_support_section_heading": "Genuine people, real customer support",
  "contact_us_button": "Contact",
  "landing_advantages_1_heading": "Simple and safe cryptocurrency trade",
  "landing_advantages_1_text": "In only takes a minute to create an order. And once the funds are credited to our account, we will manually verify the transaction and send you the cryptocurrency within 30 minutes on average.",
  "landing_advantages_2_heading": "Real people, human customer support",
  "landing_advantages_3_heading": "Biggest and oldest exchange in Czechia",
  "landing_advantages_2_text": "Need advice on exchanging cryptocurrencies? You can always write, call or send us a ticket. We handle most requests promptly.",
  "landing_advantages_3_text": "We have been exchanging cryptocurrencies since 2013. We have exchanged cryptocurrencies for more than 21 thousand people with a total value of over 8 billion CZK. We are one of the founding members of <0>\u010cKMA</0>.",
  "ckma_founder": "Founding member of",
  "newsletter_field_placeholder": "Your e-mail",
  "footer_sub_header_newsletter_subscription": "Sign up for news",
  "footer_link_career": "Career",
  "footer_link_affiliate_program": "Affiliate program",
  "footer_link_privacy_notice": "Privacy",
  "footer_link_terms_and_conditions": "Terms and conditions",
  "footer_link_contact": "Contact",
  "footer_link_limits_and_verification": "Limits and account verification",
  "footer_link_faq": "Frequently asked questions",
  "footer_link_blog": "Blog",
  "footer_header_company_info": "We have exchanged over 8 billion Czech Crowns since 2013.",
  "landing_bottom_section_header_trade_easily": "Trade coins with ease",
  "landing_bottom_section_button_buy_coins": "Buy cryptocurrency",
  "how_it_works_header": "How does it work?",
  "landing_wrote_about_us_header": "Wrote about us",
  "landing_last_orders_header": "Last transactions",
  "header_faq": "Frequently asked questions",
  "faq_general_questions_header": "General",
  "log_in_button": "Log in",
  "header_button_buy_crypto": "Buy cryptocurrency",
  "header_short_button_buy": "Buy",
  "search_button": "Search",
  "header_support": "Support",
  "send_button": "Send",
  "email_subscribe_header": "Subscribe for news from the cryptocurrency world",
  "affiliate_advantage_payout_anytime": "Payout possible at any moment",
  "affiliate_advantage_banner": "Banners for your website or blog",
  "affiliate_advantage_referral_link": "Own referral link",
  "affiliate_advantage_fiat_or_crypto": "Get paid in crypto or fiat currency",
  "affiliate_advantage_cookie": "Cookies for {{affiliateCookieValidityDays}} days",
  "affiliate_advantage_reward_from_margin": "{{affiliateMarginReward}}% reward from our margin",
  "affiliate_header": "Affiliate program",
  "affiliate_subheader": "Become a Simplecoin affiliate partner and get commission from every exchange done by people you brought.",
  "header_earn_with_us": "Earn with {{brand}}",
  "create_account_button": "Sign up",
  "affiliate_how_it_works_header": "How does it work?",
  "affiliate_how_it_works_step_1_header": "Create an account and sign up for our affiliate program",
  "affiliate_how_it_works_step_2_header": "A unique link will be created for you",
  "affiliate_how_it_works_step_3_text": "Every person who comes to the Simplecoin site via your link receives a cookie. If they create an order within 7 days, you will earn a profit of {{affiliateMarginReward}}% of our margin. You will earn these returns for every order he trades with us. For orders created after the 7 day limit, the link is no longer active.",
  "affiliate_how_it_works_step_2_text": "You have a URL and banners that you can share on your website, blog, social media or with your friends. Anyone who uses this link and then signs up or creates an order within 7 days will be entered into your affiliate program for two years.",
  "affiliate_how_it_works_step_1_text": "Both registration and verification are required to join the affiliate program. Registration allows you to access the administration and manage your account. Verification is required for payment of rewards. We follow good manners and do not encourage unfair practices or dealings. We expect the same from our partners.",
  "affiliate_how_it_works_step_4_text": "You can view how much you have earned at any time in your affiliate program administration. When you feel like it, you can choose the amount in crypto or fiat currency. We will send the payment to your bank account or crypto wallet.",
  "affiliate_how_it_works_step_3_header": "You will receive {{affiliateMarginReward}}% from our profit margin for every customer you bring",
  "affiliate_how_it_works_step_4_header": "You can withdraw your earnings at any time",
  "limits_and_verification_header": "Limits and verification",
  "limits_and_verification_subheader": "For legal reasons, like all other exchanges, we must <0>verify every transaction above a certain limit.</0> If this applies to you, you can easily register and have your account verified. See the boxes below for information on limits and verification.",
  "limits_table_level_0_header": "up to {{limitLevel0Eur}} \u20ac",
  "limits_table_level_0_subheader": "per {{limitLevel0DurationDays}} days",
  "limits_table_registration": "Compulsory registration and identification",
  "limits_table_row_2_documents_photo": "Photo of 2 personal documents",
  "limits_table_row_selfie": "Selfie with document",
  "limits_table_row_verification_bank_transfer": "Verification bank transfer",
  "limits_table_row_verification_phone_call": "Verification phone call",
  "limits_table_row_purpose_od_crypto_purchase": "Purpose of cryptocurrency purchase",
  "limits_table_row_source_of_income": "Source of income",
  "limits_table_row_individual_limits_base_on_income_source": "Individual limits based of source of income",
  "limits_table_level_1_header": "{{limitLevel0Eur}} \u2014 {{limitLevel1Eur}} \u20ac",
  "limits_table_level_1_subheader": "per {{limitLevel1DurationDays}} days",
  "limits_table_level_2_subheader": "per {{limitLevel2DurationDays}} days",
  "limits_table_level_2_header": "over {{limitLevel2EurStart}} \u20ac ",
  "footer_mobile_navigation_expand_label": "Site navigation",
  "support_search_placeholder": "What would you like to know?",
  "error_404_header": "We couldn't find that page",
  "error_404_subheader": "But you can continue to...",
  "error_page_contact_us": "If you think this is a mistake, write us on {{supportEmail}}. Thank you!",
  "link_blog_label": "What's new on Simplecoin",
  "link_contact_label": "Contact us",
  "link_faq_label": "Find answers here",
  "about_page_recommendations_section_header": "Recommended by",
  "cookie_dialog_text_content": "{{brand}} uses cookies to make using our services more comfortable and to constantly improve user experience. You can can choose which types of cookies will be used below. For more information read our <0>cookie policy</0>",
  "cookie_dialog_necessary_cookies_header": "Necessary cookies",
  "cookie_dialog_necessary_cookies_text": "Cookies which are needed for the functioning of the website",
  "cookie_dialog_necessary_cookies_always_active": "Always active",
  "cookie_dialog_statistical_cookies_header": "Statistical cookies",
  "cookie_dialog_statistical_cookies_text": "Cookies which help us measure and understand how you use our services",
  "cookie_dialog_marketing_cookies_header": "Marketing cookies",
  "cookie_dialog_marketing_cookies_text": "Cookies that help us in some way",
  "cookie_dialog_personalization_cookies_header": "Personalization cookies",
  "cookie_dialog_personalization_cookies_text": "Cookies thanks to which you will not need to set some things twice",
  "cookie_dialog_accept_all_button": "Accept all",
  "cookie_dialog_confirm_choices_button": "Confirm my choices",
  "cookie_dialog_header": "Your cookie preferences",
  "support_label_breadcrumbs_navigation": "Help",
  "buy_currency_button": "Buy {{currencyName}}",
  "exchange_form_get_wallet_help": "No wallet?",
  "scan_address_qr_code": "Scan QR",
  "exchange_form_crypto_address_field_placeholder": "bc1...",
  "order_form_email_and_account_step_label": "Order information",
  "order_form_amounts_step_label": "Amount and currency",
  "order_form_header": "Order",
  "landing_login_dialog_title": "Introducing Simplecoin Pro. A new platform with lower fees and instant currency exchanges. No need for a crypto wallet.\n",
  "landing_login_dialog_pro_header": "Try Simplecoin Pro",
  "landing_login_dialog_exchange_header": "Go to Simplecoin Exchange",
  "landing_login_dialog_exchange_text": "Simple crypto exchange service you know and love",
  "landing_login_dialog_pro_text": "Try Pro for even better trading experience",
  "landing_login_dialog_pro_login_button": "Log in to Pro",
  "landing_login_dialog_exchange_login_button": "Log in to Exchange",
  "about_us_section_small_header": "About us",
  "about_us_section_1_header": "We make the world of buying or selling <0>Bitcoin</0> and other digital currencies <0>easier</0>.",
  "about_us_section_1_subheader": "The world of cryptocurrencies erases boundaries and brings you real freedom and a new perspective on finance.",
  "about_us_section_2_header": "We are cryptocurrency experts",
  "about_us_section_2_subheader": "We help put money back to regular people's hands",
  "about_us_section_2_content": "Simplecoin is first and foremost a technology company that relies on the expertise of our programmers. We invest time and attention specifically in a high standard of security for your client accounts and data.",
  "about_us_section_3_header": "We've been making it easy for people to buy cryptocurrencies <0>since 2013</0>",
  "about_us_section_3_text": "From the very beginning, we have been a purely Czech company with a clear ownership structure. Anyone can trade cryptocurrencies. We bring another possibility of working with finances that are not managed by banks and the state. We bring you options that you manage yourself. We show people that the cryptocurrency world is simple.",
  "about_us_section_4_header": "Modern technology and security",
  "about_us_section_4_subheader": "We take care of the high security of user accounts",
  "about_us_section_4_text": "Simplecoin is primarily a technology company. We base our experience on the expert knowledge of our programmers. We invest time and attention in a high level of security for our clients' accounts and data.",
  "contact_header": "Get in touch",
  "contact_page_email_label": "Email",
  "contact_page_phone_label": "Phone",
  "contact_page_customer_center": "Customer center",
  "contact_page_billing_info": "Billing information",
  "support_search_result_count": "{{resultsCount}} results",
  "limits_table_item_not_needed": "No",
  "limits_table_item_required": "Required",
  "how_to_get_started_step_1_name": "Step 1",
  "how_to_get_started_step_1_header": "You need a crypto wallet",
  "how_to_get_started_step_1_cta": "How to set up a crypto wallet",
  "how_to_get_started_step_1_text": "The wallet is used to store cryptocurrencies. You will need it when making a purchase. If you don't have a wallet, you can download it as an app on your mobile phone or computer. We'll show you how. ",
  "how_to_get_started_step_2_name": "Step 2",
  "how_to_get_started_step_2_header": "Sign up",
  "how_to_get_started_step_2_subheader": "It has its advantages",
  "how_to_get_started_step_2_text": "You will need an email address and a secure password. You can place an order without registering, but you will lose the volume discount and the ability to manage your order in your user account.",
  "how_to_get_started_step_3_name": "Step 3",
  "how_to_get_started_step_3_header": "Create an order",
  "how_to_get_started_step_3_subheader": "Now it only takes a minute",
  "how_to_get_started_step_3_text": "Fill out the order form - you already have everything you need (email address and crypto wallet address).  We will send you payment instructions by email. Once we have verified the funds have been credited, we will send you the desired currency. We can usually do this within 30 min.",
  "how_to_get_started_step_3_hint": "Pay attention to payment instructions. Remember to insert the text in the message to the recipient as instructed. This will help us process your order faster.",
  "how_to_get_started_step_4_name": "",
  "how_to_get_started_step_4_header": "",
  "how_to_get_started_step_4_subheader": "",
  "how_to_get_started_step_4_text": "",
  "how_to_get_started_step_4_cta": "",
  "landing_clients_since_2013": "clients since 2013",
  "landing_monthly_volume": "of volume in the last 30 days ",
  "landing_exchanges_per_month": "exchanges in the last 30 days",
  "cookie_bar_header": "<0>Simplecoin</0> uses cookies to function correctly",
  "cookie_bar_text": "We use cookies to for login as well as navigation and traffic analysis. Thank you for your consent to their use. Select your cookies.",
  "cookie_bar_agree_and_continue_button": "Agree and continue",
  "cookie_bar_manage_cookie_settings_button": "Cookie preferences",
  "order_detail_info_header": "Order details",
  "order_detail_order_id": "Order number",
  "order_detail_created_at": "Created at",
  "order_detail_status": "Status",
  "order_detail_rate": "Order rate",
  "order_detail_button_cancel": "Cancel",
  "order_detail_button_suspend": "Suspend",
  "order_detail_button_download": "Download in PDF",
  "order_detail_button_new_ticket": "Create a ticket",
  "order_detail_history_table_header": "Order history",
  "order_history_datetime_column_name": "Date and time",
  "order_history_status_column_name": "Status",
  "order_detail_outgoing_payment_label": "Outgoing payment",
  "order_detail_incoming_payment_label": "Your payment",
  "order_detail_incoming_payment_waiting_for_transaction": "Your transaction has not been detected yet",
  "order_detail_incoming_payment_waiting_for_outgoing_transaction": "We are waiting for your payment",
  "order_payment_instructions_header": "Send payment with the following details",
  "order_form_payment_instructions_bank_account_number_label": "Bank account number",
  "order_form_payment_instructions_bank_amount_label": "Amount",
  "order_form_payment_instructions_bank_message_label": "Payment message",
  "order_form_payment_instructions_use_qr": "Or scan the following QR code",
  "order_form_payment_instructions_choose_one_of_company_accounts": "Company account",
  "order_status_done": "Done",
  "order_status_delivered": "Delivered",
  "order_status_canceled": "Canceled",
  "order_status_returned": "Returned",
  "order_status_in_progress": "In progress",
  "order_status_confirming_incoming": "Confirming incoming",
  "order_status_confirming_outgoing": "Confirming outgoing",
  "order_status_new": "New",
  "order_status_suspended": "Suspended",
  "order_status_received": "Received",
  "order_detail_outgoing_payment_waiting_for_incoming_transaction": "Your transaction has not been detected yet",
  "show_payment_instructions_dialog_opener_button": "Show payment instructions",
  "support_section_header": "Did not find the answer?",
  "support_section_text": "The customer service line can be reached Monday to Sunday between 9:00 and 21:00. Or send us an email. Our colleagues will always be happy to advise you and guide you safely through your purchase.",
  "label_crypto_sent_to_address": "Address",
  "label_transaction_id": "Transaction ID",
  "order_detail_button_proceed": "Proceed",
  "label_amount_and_currency": "Amount",
  "label_payment_sent_at": "Created",
  "order_detail_outgoing_payment_waiting_to_confirm_incoming_transaction": "Incoming transaction is being confirmed",
  "order_detail_outgoing_payment_order_suspended": "Order is suspended, now waiting for your decision. ",
  "order_detail_outgoing_payment_order_return_to_client": "Your payment will be returned",
  "order_form_payment_instructions_company_billing_details": "Billing information",
  "order_has_been_created": "Order successfully created",
  "fiat_payment_instructions_amount_label": "Amount",
  "order_detail_button_return": "Return",
  "order_view_original_price_label": "Previous offer",
  "order_view_limit_price_label": "Limit price",
  "order_view_current_price_label": "Current offer",
  "cryypto_payment_instructions_amount_label": "Amount",
  "payment_instruction_hint_fill_amount_to_show_qr": "Fill amount filed to generate QR code",
  "deposit_payment_instruction_amount_label": "Amount to deposit",
  "form_email_field": "Email",
  "confirm_button": "Confirm",
  "email_placeholder": "Email",
  "change_in_24hours": "Change in the last 24 hours",
  "order_offer_amounts_label": "Your offer",
  "order_offer_your_crypto_address": "Your cryptocurrency address",
  "order_offer_continue_to_login": "Continue with {{companyName}} account",
  "order_offer_or_separator": "or",
  "order_offer_continue_without_registration": "Continue without registration",
  "order_offer_validity_notice": "This offer will expire in <strong>{{timeRemaining}}</strong>. Please log in or continue with email. ",
  "order_offer_continue_as_guest": "Continue as guest",
  "order_offer_existing_email_please_click_continue_button": "This email is already registered. Please choose the \"Continue with {{companyName}} account\" option",
  "landing_header_references": "References",
  "crypto_payment_instructions_amount_label": "Amount",
  "crypto_payment_instructions_destination_tag_field_label": "Destination tag",
  "order_form_mail_help_text": "We will send you instructions for completing the order to this e-mail",
  "validation_required_email": "Enter your e-mail address",
  "validation_required_crypto_account": "Enter your crypto account",
  "landing_help_section_header": "Tutaj znajd\u0105 Pa\u0144stwo odpowiedzi na najcz\u0119stsze pytania. Poza pisemnymi instrukcjami pomo\u017cemy Pa\u0144stwu r\u00f3wnie\u017c na naszej linii obs\u0142ugi klienta pod numerem +420 608 746 753 lub e-mailem pod adresem info@simplecoin.eu, od poniedzia\u0142ku do niedzieli w godzinach od 9.00 do 21.00. Poprowadzimy Pa\u0144stwa przez pierwszy zakup, pomo\u017cemy w zam\u00f3wieniu lub rejestracji, doradzimy jak ustawi\u0107 portfel lub odpowiemy na Pa\u0144stwa pytania. Zapraszamy do zadawania nam wszelkich pyta\u0144.",
  "guest_exchange_form_limit_alert": "Purchase without registration is possible up to {{code}} {{limit}}. For higher amounts, please log in or register.",
  "exchange_form_email_label": "Your e-mail",
  "exchange_form_alert_change": "The exchange rate between these currencies cannot be traded. Please adjust your currency selection.",
  "welcome_in_wallet": "Welcome in Wallet!",
  "start_with_funding_text_2": "You have been verified. Now you can deposit funds and start trading.",
  "start_with_funding_text_1": "Well done!",
  "amount_in_fiat_payment_instructions_info": "The amount is required to generate the QR code",
  "limit_table_level_0_text": "You can buy up to \u20ac{{limitLevel0Eur}} within 10 days without registration and without he need to verify personal documents.",
  "label_go_to_order": "Create Order",
  "label_register": "Register",
  "account_detail": "Account detail",
  "signup_form_subtitle": "You will get a better rate and higher trading limits. You will have an overview of your transactions.",
  "label_guest_login": "If you already have an account <0>Login</0>",
  "label_guest_signup": "If you don't have an account yet, <0>sign up</0>",
  "label_register_success": "Registration was successful",
  "label_register_success_verify": "Please activate your account now",
  "label_register_success_sended_verify": "We have sent you a link to activate your account to the e-mail {{mail}}.",
  "label_account_notes": "Account notes",
  "deposit_request_card_head": "Deposit requests",
  "dashboard_balances_no_deposit_requests": "No deposit requests",
  "deposit_request_status_pending": "Pending",
  "exchange_form_terms_must_be_accepted": "Terms and conditions should be accepted",
  "validation_required_bank_account": "Enter your bank account number",
  "order_status_dispatched": "Dispatched",
  "order_status_paid": "Paid",
  "order_status_paidUnconfirmed": "Paid (1/2)",
  "order_status_tooltip_new": "The order is waiting to be paid.",
  "order_status_tooltip_paidUnconfirmed": "We see an incoming transaction. It is waiting to be confirmed on the block-chain.",
  "order_status_tooltip_paid": "The order has been paid. We're working on dispatching the ordered currency.",
  "order_status_tooltip_paid_suspended": "The order has been paid.",
  "order_status_tooltip_dispatched": "We have dispatched the ordered currency to the target address/account. We are waiting for the confirmation on the block-chain/bank.",
  "order_status_tooltip_done": "The order is successfully processed.",
  "order_status_tooltip_canceled": "The order has been canceled.",
  "suspended_order_tooltip": "Order processing is suspended.",
  "order_detail_outgoing_payment_waiting_to_be_sent": "We are working to dispatch the ordered funds as soon as possible.",
  "exchange_limit_for_verification_level_2_text": "Your trading limit is not formally set. For large amounts, it will be assessed individually according to your <0>documented income</0>.",
  "your_limit_is": "Your limit is",
  "trading_limit": "Trading limit",
  "exchange_trading_limit_info": "The limit is automatically adjusted after we receive payment for your order. New unpaid orders do not affect the limit.",
  "you_have_no_suitable_wallet_among_recipients": "You have no wallet for {{currencyCode}} currency among your recipients.",
  "you_have_no_suitable_account_among_recipients": "You have no bank account for {{currencyCode}} currency among your recipients.",
  "rate_when_order_created": "Rate when order created",
  "order_status_proceed": "Proceed processing",
  "waiting_for_limit": "Waiting for limit",
  "button_more": "More ...",
  "exchange_form_expand_optional_fields": "Expand optional fields",
  "return_fiat_payment_info": "We have received your payment already. You have decided to get it back. But return payment has additional fee. You can get {{amount}} {{currency}} back to original bank account.",
  "cancel_order_and_return_payment": "Cancel order and return payment",
  "remaining_limit": "Remaining",
  "trading_limit_reached": "You have reached your trading limit for given period.",
  "attached_files": "Attached files",
  "there_are_no_files": "There are no uploaded files",
  "why_we_need_verification_info": "The anti money laundering law (AML) requires us to know our clients better. Therefore, it is necessary for you to tell us some information about yourself, which of course remains safely stored only with us.",
  "proceed_to_verification_action": "Proceed to verification, please",
  "verification_request_line2": "There are a few steps in our guide and then you can make full use of our wallet.",
  "verification_request_line1": "Now we need to get to know each other a little bit",
  "document_issued_by_government_info": "Please attach a government-issued document confirming the information provided in the previous step.",
  "secondary_document_info": "Please attach another document that confirms the information provided in the first step.",
  "selfie_with_id_info": "Please attach a photo of you holding the personal document  in your hand. The details on the document must be legible and your face must be visible.",
  "understood_button": "Understood",
  "no_prooved_incomes_info": "There is no proved income yet. You can still trade cryptocurrencies up to the base limit (10.000 EUR/month for verified clients). To increase the trading limit above 10 000 EUR/month, you will need to provide proof of income.",
  "verified": "Verified",
  "payment_instructions": "Payment instructions",
  "make_payment_to_one_of_our_accounts": "Make payment to one of our accounts",
  "payment_in": "Payment in",
  "iban_payment_instructions": "IBAN payment instructions",
  "new_price_is_being_loaded": "New price is being loaded...",
  "price_refresh_timer_tooltip": "We update the displayed price and rate for you every few seconds.",
  "send_to_address": "Send to address",
  "payment_specific_data": "Payment data",
  "bank_account_owner": "Bank account owner",
  "filter_all_currencies": "All currencies",
  "ticket_detail": "Ticket detail",
  "last_verification_step_warning": "This is the last step of the verification process. Once you have submitted it, you will no longer be able to change the information you have entered. Your profile will be verified by our back-office staff.",
  "exchange_form_limit_alert": "The order of this amount already exceeds your allowed trading limit {{limit}} {{code}}. You can still create the order, but it will not be processed until you verify yourself or provide proof of appropriate incomes.",
  "order_limit_alert": "Once you will pay for this order, you will exceed your allowed trading limit and the order will not be processed until you verify yourself or provide proof of appropriate incomes.",
  "remove_recipient_confirmation_question": "Do you really want to delete this recipient? You will not be able to undo this action.",
  "label_bank_account_iban": "IBAN",
  "validation_given_field_required": "Field '{{fieldName}}' is required",
  "inheritance": "Inheritance",
  "relationship_to_testator": "Relationship to testator",
  "inherited_from": "Inherited from (name)",
  "landing_how_to_get_started_button": "How to get started",
  "affiliate_partners_must_be_verified": "To become our affiliate partner, we first need to grab a few information about you.",
  "regular_income": "Regular income",
  "tax_declaration": "Tax declaration",
  "annual_report": "Annual report",
  "date_of_acceptance": "Date of acceptance",
  "document_confirming_acceptance_of_inheritance": "Document confirming acceptance of inheritance",
  "financial_closing_statement": "Financial closing statement",
  "decision_on_sharing_profit": "Decision on sharing profit",
  "sale_contract": "Sales contract",
  "real_estate_registry_extract": "Real estate registry extract",
  "typical_income_attachments": "Typical attachments",
  "sale_of_immovable_property": "Sale of immovable property",
  "address_of_property": "Address of property",
  "date_of_sale": "Date of sale",
  "sale_channel": "Sales channel / Way of sale",
  "paycheck": "Paycheck",
  "employment_contract": "Employment contract",
  "field_of_business": "Field of business",
  "company_id": "Company ID",
  "monetary_donation": "Monetary donation",
  "donor": "Donator",
  "donor_relationship_to_recipient": "Relationship of donor to recipient",
  "purpose_of_donation": "Purpose of donation",
  "source_of_donated_funds": "Source of donated funds",
  "email_subscribe_success": "You have successfully subscribed to our newsletter.",
  "landing_index_meta_tag_title": "Simplecoin - The largest cryptocurrency exchange in the Czech Republic",
  "landing_index_meta_tag_description": "Trusted since 2013",
  "landing_how_to_get_started_meta_tag_title": "Simplecoin - How to start buying cryptocurrencies",
  "landing_how_to_get_started_meta_tag_description": "A simple step-by-step guide for everyone",
  "landing_about_meta_tag_description": "We are cryptocurrency experts",
  "landing_about_meta_tag_title": "Simplecoin - About us",
  "landing_faq_meta_tag_description": "What do I need to buy cryptocurrencies? What do I gain by signing up?",
  "landing_faq_meta_tag_title": "Simplecoin - FAQ",
  "landing_support_meta_tag_title": "Simplecoin - Help",
  "landing_support_meta_tag_description": "Detailed instructions on how to set up a wallet, place an order or set up a DCA",
  "landing_tos_meta_tag_description": "Fair trading with cryptocurrencies",
  "landing_tos_meta_tag_title": "Simplecoin - Terms and conditions",
  "landing_privacy_meta_tag_title": "Simplecoin - Privacy Policy",
  "landing_privacy_meta_tag_description": "Your data is safe with us",
  "landing_affiliate_meta_tag_description": "Become a Simplecoin partner and earn commission",
  "landing_affiliate_meta_tag_title": "Simplecoin - Affiliate Program",
  "landing_limits_meta_tag_description": "Purchase options without registration and when you need to be verified",
  "landing_limits_meta_tag_title": "Simplecoin - Limits and Verification",
  "landing_contact_meta_tag_description": "Book a personal appointment at our client centre or take advantage of our client support 9am-9pm every day of the week.",
  "landing_contact_meta_tag_title": "Simplecoin - contact",
  "react_new_app_version_available_dialog_content": "A new version is available. Please refresh the page to ensure you're using the latest version.",
  "wallet_recipient_warning_1": "Anyone who knows the access secrets to the crypto-wallet (especially the recovery seed - 12-24 words used to recover the wallet) can freely use the funds in this wallet.",
  "wallet_recipient_warning_2": "If the wallet was created by someone else than myself, he/she probably has access to this wallet and can freely use the funds in this wallet.",
  "wallet_recipient_warning_3": "If I lose access to my wallet and do not have a backup (recovery seed), I lose access to all funds in my wallet.",
  "wallet_recipient_warning_4": "If I add an address of crypto-wallet that is not mine, I will not be able to directly use of the funds in that wallet. It is up to the third party to allow me to dispose of the funds.",
  "warning": "Warning",
  "ticket_attachments_info": "Attachments can be added to the ticket in the next step (after the ticket is created).",
  "one_off_income": "One-off income",
  "button_previous": "Previous",
  "years_of_service": "Years of service",
  "primary_or_secondary_activity": "Primary / secondary activity",
  "rental_contract": "Rental contract",
  "bank_statement_with_incoming_payment": "Bank statement with incoming payment",
  "pension_confirmation_letter": "Pension confirmation letter",
  "loan": "Loan",
  "loan_maturity_date": "Maturity date",
  "loan_effective_date": "Effective date",
  "lender": "Lender",
  "loan_agreement": "Loan agreement",
  "donation_agreement": "Donation agreement",
  "savings": "Savings",
  "bank_statement": "Bank statement",
  "sale_of_movable_property": "Sale of movable property",
  "sale_of_company_share": "Sale of company share",
  "investment_return": "Investment return",
  "investment_period": "Investment period",
  "crypto_mining": "Crypto mining",
  "mining_target_crypto_address": "Target crypto-address of mined coins",
  "verified_clients_limit_info": "Verified clients can trade up to EUR 10.000 per month without providing further proof of incomes.",
  "expected_trading_volume": "Expected trading volume",
  "expected_trading_volume_info": "Setting up expected trading volume is optional and is not a commitment. It will help us to better set your trading limits and thus speed up your trading.",
  "add_expected_trading_volume": "Add expected trading volume",
  "trading_volume_period": "Trading period",
  "landing_quarterly_volume": "90 day volume",
  "landing_quarterly_exchanges": "Exchanges in the last 90 days",
  "income_state_revoked": "revoked",
  "crypto_deposit_fee_info": "Speed of your crypto transaction depends on the transaction fee so it is important to use the correct transaction fee in order for transaction to be verified soon.",
  "deposit_on_account": "Deposit on account",
  "show": "show",
  "hide": "hide",
  "account_status_suspended": "suspended",
  "account_status_closed": "closed",
  "account_status_active": "active",
  "suspended_accounts": "Suspended accounts",
  "closed_accounts": "Closed accounts",
  "active_accounts": "Active accounts",
  "create_wallet_account_form_header": "Add a new account",
  "account_number": "Account number",
  "accounted_transactions": "Accounted transactions",
  "counterparty": "Counterparty",
  "all_financial_operations": "All",
  "accounting_balance": "Accounting balance",
  "pending_deposits": "Pending deposits",
  "pending_withdrawals": "Pending withdrawals",
  "pending_transactions": "Pending transactions",
  "blocked_amount_alert": "There is an amount {{amount}} {{currency}} blocked on this account. Please contact support for more information.",
  "available_balance_tooltip": "Available balance is the amount of money you currently have available for trading. The available balance does not include pending deposits but already takes into account amounts blocked, e.g. due to pending withdrawals.",
  "total_balance_tooltip": "The account total balance is the sum of all confirmed transactions in your account.",
  "deposit_requests_total_tooltip": "Pending deposits are incoming transactions to your account for which we are waiting for confirmation on the block-chain or have not been credited due to exceeding your trading limit.",
  "withdrawal_requests_total_tooltip": "Pending withdrawals are withdrawals awaiting confirmation on the block-chain.",
  "cancel_account": "Close account",
  "cancel_account_confirmation_question": "Are you sure you want to close this account? Closing the account is an irreversible operation.",
  "empty_account_required_to_close_it": "Only accounts with zero balance can be closed.",
  "account_closed_success": "The account has been closed.",
  "approved_incomes": "Approved incomes",
  "pending_incomes": "Pending incomes",
  "show_details": "show details",
  "hide_details": "hide details",
  "hide_approved_incomes": "Hide approved incomes",
  "show_approved_incomes": "Show approved incomes",
  "increase_trading_limit": "Increase trading limit",
  "save_as_draft": "Save as draft",
  "withdrawal_success": "Withdrawal has been successfully requested",
  "withdrawal_confirmation_transaction_fee_label": "Transaction fee: {{feeAmount}} {{feeCurrencyCode}}",
  "withdrawal_form_fee_in_amount_switch_label": "Withdrawal fee included",
  "withdrawal_recap_table_fee": "Fee amount",
  "withdrawal_recap_table_deducted_amount": "Withdrawal amount",
  "withdrawal_recap_table_net_withdraw_amount": "Recipient will get",
  "remove_income_confirmation": "Do you really want to delete this income?",
  "deposit_form_show_instructions": "Show deposit instructions",
  "income_state_readyForApproval": "awaits approval",
  "withdrawal_form_show_bank_fields_toggle": "Show modifiable fields",
  "end_income": "End income",
  "edit_income": "Edit income",
  "delete_income": "Delete income",
  "pending_transaction_detail": "Pending transaction detail",
  "account": "Account",
  "verification_wizard": "Verification wizard",
  "portfolio_volume": "Your portfolio volume",
  "profile_verification_info_message_for_business": "For business verification <0>read our guide</0>.",
  "unknown_source": "unknown source",
  "amount_netto": "Amount (netto)",
  "deposit_request_status_on_hold": "On Hold",
  "exchange_form_verified_client_discount_message": "Verified clients can save up to <0>{{nominalDiscount}} {{discountCurrency}}</0> per {{toCurrency}}. </1>",
  "sign_up_to_get_discount": "Sign up to get the discount",
  "exchange_form_verified_client_discount_message_generic": "Verified clients get a better price",
  "exchange_form_verified_client_discount_message_from_crypto": "Verified clients can immediately get <0>{{nominalDiscount}} {{discountCurrency}}</0> more per {{toCurrency}}.",
  "price_for_verified_clients": "Price for verified clients:",
  "contact_page_customer_center_reservation_info": "If you are interested in visiting our office, please <0>make an appintment</0>",
  "label_house_number": "House No",
  "react_file_upload_count_error": "the maximum number of uploaded files is {{maxFiles}}",
  "sorry_no_bank_for_given_currency": "We are sorry, but payments in {{currency}} are not possible at this moment.",
  "payment_instructions_delay_warning": "Due to the new provider of banking services, bank transfers may take longer than usual.\n<br/>\nTo ensure the original exchange rate from the order, you have the option to attach a PDF with payment confirmation from your bank. The price extension service is available daily from 9:00 to 21:00. Each request is assessed individually, and we will contact you as soon as possible.",
  "payment_confirmation_upload_info": "The duration of original exchange-rate is evaluated on an individual basis and depends on your payment history and order amount.",
  "attach_payment_confirmation": "Attach payment confirmation",
  "price_fixed_until": "The price is fixed until {{time}}",
  "payment_confirmation_attached": "Payment confirmation attached",
  "validation_incorrect_format_generic": "Invalid format",
  "exchange_fee_percentage_info": "Fee makes {{feePercentage}}% of the traded amount",
  "citizenship": "Citizenship",
  "second_citizenship": "Second citizenship",
  "second_citizenship_helper": "If you have two citizenships state both",
  "dashboard_intro_text_how_to_start": "Our wallet works similarly to internet banking. In order to start using Simplecoin Wallet, you need to deposit funds into virtual accounts you create in it. To get started, we recommend you set up one account each for the currencies you want to start trading. Later, of course, you can add more currency accounts.",
  "how_to_start_using_wallet": "How to start using Simplecoin wallet?",
  "create_accounts": "Create accounts",
  "default_account_label": "{{currencyCode}} default account",
  "default_account_description": "This account was created automatically for you by the initial wizard.",
  "about_virtual_account_info": "A virtual account is where you keep your funds in your Simplecoin wallet. If you want to hold funds in your chosen currency, you must create at least one account for that currency. Setting up and maintaining an account is free. Trading is done by transferring funds between accounts, \nwhich may be in different currencies.",
  "preferred_currencies_question": "Which currencies will you most often trade in?",
  "crypto": "Crypto",
  "fiat": "Fiat",
  "create_accounts_for_selected_currencies": "Create accounts for selected currencies",
  "accounts_successfully_created": "Accounts has been successfully created! We have created {{accountsCount}} accounts for you.",
  "create_your_first_accounts": "Create your first accounts",
  "wallet_logo": "wallet",
  "just_one_citizenship": "just one citizenship",
  "information_about_verification_status_change": "We will inform you about the change of verification status to e-mail {{email}}.",
  "amount_too_low": "Amount too low",
  "footer_link_legal_documents": "Legal documents",
  "log_into_wallet": "Wallet",
  "log_into_exchange": "Exchange",
  "log_into_exchange_caption": "One-off purchases to your wallet",
  "log_into_wallet_caption": "The easiest cryptocurrency trading",
  "payment_request": "Payment request",
  "payment_hash": "Payment hash",
  "memo": "Memo",
  "scan_invoice_qr": "Scan invoice QR",
  "lightning_payment": "Lightning payment",
  "scan_qr": "Scan QR",
  "pay": "Pay"
}